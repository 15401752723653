import { requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'
import { INSTITUTION_CONSTRAINTS } from './actionsType'

const {
  ADD_INSTITUTION_CONSTRAINT, DELETE_INSTITUTION_CONSTRAINT, SET_INSTITUTION_CONSTRAINTS,
  SET_INSTITUTION_CONSTRAINTS_LOADING, UPDATE_INSTITUTION_CONSTRAINT
} = INSTITUTION_CONSTRAINTS

export const getInstitutionConstraints = state => {
  return state.institutionConstraints
}

export const fetchInstitutionConstraints = user => {
  return async (dispatch, getState) => {
    requestWithPromise('/api/institution-constraints', HttpMethods.GET, null, user)
      .then(jsonResponse => {
        dispatch({
          type: SET_INSTITUTION_CONSTRAINTS_LOADING,
          payload: false
        })
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: SET_INSTITUTION_CONSTRAINTS,
            payload: jsonResponse.data
          })
        }
      })
  }
}

export const addInstitutionConstraint = (institutionConstraint, user, callback) => {
  return async (dispatch, getState) => {
    requestWithPromise('/api/institution-constraints', HttpMethods.POST,hods.POST,hods.POST, institutionConstraint, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          if (typeof callback === 'function') {
            callback()
          }
          dispatch({
            type: ADD_INSTITUTION_CONSTRAINT,
            payload: jsonResponse.data
          })
        }
      })
  }
}

export const removeInstitutionConstraint = (institutionConstraint, user, callback) => {
  return async (dispatch, getState) => {
    requestWithPromise(`/api/institution-constraints/${institutionConstraint.id}`, HttpMethods.DELETE, null, user, true)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: DELETE_INSTITUTION_CONSTRAINT,
            payload: institutionConstraint.id
          })
        }
      }).catch(jsonResponse => {
        if (typeof callback === 'function') {
          callback()
        }
      })
  }
}

export const updateInstitutionConstraint = (institutionConstraint, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(`/api/institution-constraints/${institutionConstraint.id}`, HttpMethods.PATCH, institutionConstraint, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: UPDATE_INSTITUTION_CONSTRAINT,
            payload: jsonResponse.data
          })
        }
      })
  }
}
