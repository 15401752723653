import React, { useMemo, useState } from 'react'

import { getInternshipDocuments } from '../../../../utils/api/internship'
import useRequest from '../../../../hooks/useRequest'
import { TYPES } from '../../../../utils/entities/internshipDocument'
import { deleteInternshipDocument } from '../../../../utils/api/internshipDocument'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import InternshipFilesTable from './InternshipFilesTable'
import { TableParameters } from '../../../../utils/entities/tableParameters'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipFilesManager = ({ user, internship, readOnly, onClose, onFileDelete, t }) => {
  const [parameters, setParameters] = useState(new TableParameters())
  const { data, loading, reload } = useRequest({ request: getInternshipDocuments, parameters: useMemo(() => [user, internship], [user?.id, internship]) })

  const displayedFiles = useMemo(() => data.filter(f => f.type !== TYPES.CONVENTION), [data])

  const handleDelete = file => {
    if (!readOnly) {
      deleteInternshipDocument(user, file).then(reload)
    }
  }

  return (
    <InternshipFilesTable
      data={displayedFiles}
      readOnly={readOnly}
      internship={internship}
      loading={loading}
      parameters={parameters}
      onDelete={handleDelete}
      onParametersChange={setParameters}
      reload={reload}
    />
  )
}

export default connect(mapStateToProps)(InternshipFilesManager)
