import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import FilesManager from '../shared/FilesManager'
import AreaManager from '../shared/AreaManager'
import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput, generalErrorHandler, requestWithPromise } from '../../utils'
import Constraints from './Constraints/Constraints'
import { Card, List } from 'antd'
import InstitutionOptionTable, { BOOLEAN_FIELD, STRING_FIELD } from './InstitutionOptionTable'
import { getInstitutionDocuments } from '../../utils/api/institution'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { faGlobe, faFile } from '@fortawesome/pro-solid-svg-icons'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import TitleIcon from '../icons/TitleIcon'
import { HttpMethods } from '../../utils/apiHelper'

const CARD_IDENTITY_TITLE = 'Identity of the institution'
const CARD_OPTION_TITLE = 'Institution Options'

const listId = [
  'institution-identity-list',
  'institution-student-data-list',
  'institution-options-list'
]

const InstitutionParameters = props => {
  const { institution, reload } = useContext(InstitutionContext)
  const { countries } = useContext(GlobalContext)

  const [selectedInstitutionFiles, setSelectedInstitutionFiles] = useState(null)
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [selectedInstitutionArea, setSelectedInstitutionArea] = useState(null)
  const [institutionIdentityFocus, setInstitutionIdentityFocus] = useState(true)
  const [institutionStudentDataFocus, setInstitutionStudentDataFocus] = useState(true)
  const [institutionOptionsFocus, setInstitutionOptionsFocus] = useState(true)
  const [localCountries, setLocalCountries] = useState([])

  const identityRef = useRef(null)
  const studentDataRef = useRef(null)
  const optionsRef = useRef(null)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => { // eslint-disable-line no-undef
        switch (entry.target) {
          case identityRef.current:
            setInstitutionIdentityFocus(entry.isIntersecting)
            break
          case studentDataRef.current:
            setInstitutionStudentDataFocus(entry.isIntersecting)
            break
          case optionsRef.current:
            setInstitutionOptionsFocus(entry.isIntersecting)
            break
          default:
        }
      }),
    []
  )

  useEffect(() => {
    observer.observe(identityRef.current)
    observer.observe(studentDataRef.current)
    observer.observe(optionsRef.current)

    return () => {
      observer.disconnect()
    }
  }, [identityRef, studentDataRef, optionsRef, observer])

  useEffect(() => {
    if (institutionIdentityFocus) {
      document.getElementById('institution-identity-list').style.backgroundColor = 'lightgray'
      document.getElementById('institution-student-data-list').style.backgroundColor = 'white'
      document.getElementById('institution-options-list').style.backgroundColor = 'white'
    }

    if (institutionStudentDataFocus && !institutionIdentityFocus) {
      document.getElementById('institution-identity-list').style.backgroundColor = 'white'
      document.getElementById('institution-student-data-list').style.backgroundColor = 'lightgray'
      document.getElementById('institution-options-list').style.backgroundColor = 'white'
    }

    if (institutionOptionsFocus && !institutionStudentDataFocus && !institutionIdentityFocus) {
      document.getElementById('institution-identity-list').style.backgroundColor = 'white'
      document.getElementById('institution-student-data-list').style.backgroundColor = 'white'
      document.getElementById('institution-options-list').style.backgroundColor = 'lightgray'
    }
  }, [institutionIdentityFocus, institutionStudentDataFocus, institutionOptionsFocus])

  useEffect(() => {
    setLocalCountries(countries.map(c => {
      return { id: c.id, name: props.t(c.name) }
    }))
  }, [countries, props.t])

  const handleEditInstitution = institution => {
    const updatedInstitution = { ...institution }

    delete updatedInstitution.filesCount

    requestWithPromise('/api/Institution/' + institution.id, HttpMethods.PATCH, updatedInstitution, props.getUser).then(json => {
      const updatedInstitutions = props.getInstitutions.map(item => {
        if (item.id === institution.id) {
          const { address, city, country, description, name, zipcode, globalMessage } = institution
          return { ...item, address, city, country: { id: country }, description, name, zipcode, globalMessage }
        }
        return item
      })
      props.setInstitutions(updatedInstitutions)
      reload()
    })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handleClick = key => {
    switch (key) {
      case 0:
        if (identityRef && identityRef.current) {
          identityRef.current.scrollIntoView()
          setInstitutionIdentityFocus(true)
          setInstitutionStudentDataFocus(true)
          setInstitutionOptionsFocus(true)
        }
        break
      case 1:
        if (studentDataRef && studentDataRef.current) {
          studentDataRef.current.scrollIntoView()
          setInstitutionIdentityFocus(false)
          setInstitutionStudentDataFocus(true)
          setInstitutionOptionsFocus(true)
        }
        break
      case 2:
        if (optionsRef && optionsRef.current) {
          optionsRef.current.scrollIntoView()
          setInstitutionIdentityFocus(false)
          setInstitutionStudentDataFocus(false)
          setInstitutionOptionsFocus(true)
        }
        break
      default:
    }
  }

  const fetchDocuments = () => {
    getInstitutionDocuments(props.getUser, props.getInstitutions[0]).then(json => {
      if (json?.data) {
        setSelectedInstitutionFiles(json.data)
        setSelectedInstitution(props.getInstitutions[0])
      } else {
        setSelectedInstitutionFiles(null)
      }
    })
  }

  const render = () => {
    let data = []

    if (institution) {
      data = [{
        id: institution.id,
        name: institution.name,
        address: institution.address,
        zipcode: institution.zipcode,
        country: institution.country ? institution.country.id : null,
        city: institution.city,
        description: institution.description,
        filesCount: institution.filesCount,
        globalMessage: institution.globalMessage,
        area: institution.areas ? institution.areas.map(element => element.localName).join(', ') : props.t('No region linked')
      }]
    }

    const columnsIdentity = [
      { type: SmartTableDataTypes.ID, key: 'id' },
      { type: SmartTableDataTypes.STRING, name: props.t('Name'), key: 'name', validate: (data) => validateFormInput('freeText', data, true) },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Global message'),
        key: 'globalMessage',
        tooltipTitle: props.t('institution_parameters.global_message_column.tooltip')
      },
      { type: SmartTableDataTypes.STRING, name: props.t('Address'), key: 'address', validate: (data) => validateFormInput('freeText', data, true) },
      { type: SmartTableDataTypes.STRING, name: props.t('Zipcode'), key: 'zipcode', validate: (data) => validateFormInput('freeText', data, true) },
      { type: SmartTableDataTypes.STRING, name: props.t('City'), key: 'city', validate: (data) => validateFormInput('name', data, true) },
      { type: SmartTableDataTypes.SELECT, name: props.t('Country'), key: 'country', options: localCountries },
      {
        type: SmartTableDataTypes.STRING,
        name: <TitleIcon title={props.t('Areas')} tooltip={props.t('descriptionAreas')} />,
        key: 'area',
        validate: (data) => validateFormInput('freeText', data, false),
        disabled: true,
        disabledTooltip: true
      }
    ]

    const columnsOptions = [
      { type: STRING_FIELD, name: 'Description' },
      { name: 'Value' },
      { type: BOOLEAN_FIELD, name: 'Enabled' }
    ]

    return (
      <div className='institution-parameters'>
        <div className='parameters-navbar'>
          <List
            className='list-parameters'
            size='large'
            bordered
            dataSource={[
              props.t('Identity of the institution'),
              props.t('Student data required for an internship'),
              props.t('Institution Options')
            ]}
            renderItem={(item, key) => <List.Item id={listId[key]} onClick={() => { handleClick(key) }}>{item}</List.Item>}
          />
        </div>
        <div className='parameters'>
          <div className='institution-identity' ref={identityRef}>
            <Card title={props.t(CARD_IDENTITY_TITLE)}>
              <SmartTable
                hideTableControls
                columns={columnsIdentity}
                data={data}
                loading={!props.getDataReady.institutions}
                onDataEdit={handleEditInstitution}
                additionalActions={[
                  { iconName: faFile, type: 'primary', title: props.t('Manage files'), onClick: fetchDocuments },
                  { iconName: faGlobe, type: 'primary', title: props.t('linked-regions'), onClick: () => setSelectedInstitutionArea(institution) }
                ]}
              />
            </Card>
          </div>
          <div className='institution-student-data' ref={studentDataRef}>
            <Constraints />
          </div>
          <div className='institution-options' ref={optionsRef}>
            <Card title={props.t(CARD_OPTION_TITLE)}>
              <InstitutionOptionTable
                columns={columnsOptions}
                loading={!props.getDataReady.institutions}
              />
            </Card>
          </div>
          <FilesManager
            files={selectedInstitutionFiles}
            entityName='Institution'
            entity={selectedInstitution}
            onClose={() => {
              setSelectedInstitutionFiles(null)
              setSelectedInstitution(null)
            }}
            userContextFiltered
          />
          <AreaManager
            entityName='Institution'
            entity={selectedInstitutionArea}
            onClose={() => setSelectedInstitutionArea(null)}
            onUpdate={reload}
            userContextFiltered
          />
        </div>
      </div>
    )
  }

  return render()
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionParameters)
