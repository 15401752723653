import React, { Component } from 'react'

import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import School from '../../Components/school/School'
import Users from '../../Components/school/Users'
import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Analytics from '../../utils/analytics' 
import UserActTypesDrawerProvider from '../../Providers/Drawer/UserActTypesDrawer/UserActTypesDrawerProvider'

const { TabPane } = Tabs

class SchoolSettingsView extends Component {
  componentDidMount() {
    Analytics.pageView('/school-settings')
  }

  render() {
    return (
      <Tabs defaultActiveKey='1' animated={false}>
        <TabPane
          key='1'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon icon='user' style={{ marginTop: '3px' }} />
              <div className='h-spacing' />
              {this.props.t('Manage users')}
            </div>
          }
        >
          <UserActTypesDrawerProvider>
            <Users enableDelete />
          </UserActTypesDrawerProvider>
        </TabPane>
        <TabPane
          key='2'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon icon='school' style={{ marginTop: '3px' }} />
              <div className='h-spacing' />
              {this.props.t('School identity')}
            </div>
          }
        >
          <School />
        </TabPane>
      </Tabs>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettingsView)
