import React, { useContext } from 'react'
import IconButton from './IconButton'

const FormButton = ({ className = '', context, disabled, ghost, icon, loading = false, size, text = '', type, onClick }) => {
  const { setData } = useContext(context)

  return (
    <IconButton
      className={className}
      disabled={disabled}
      ghost={ghost}
      icon={icon}
      loading={loading}
      size={size}
      text={text}
      type={type}
      onClick={() => onClick(setData)}
    />
  )
}

export default FormButton
