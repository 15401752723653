import { isObject } from '../../../utils'
import { EventCodeTypes } from '../../../utils/entities/eventCodeType'

const ABSENT_EVENT_BORDER_COLOR = 'red'
const ABSENT_EVENT_TEXT_COLOR = 'red'
const DEFAULT_TEXT_COLOR = 'black'
const DEFAULT_EVENT_BORDER_COLOR = 'black'
const DEFAULT_PERIOD_CODE_COLOR = 'white'
const EMPTY_MAIN_TITLE = ''
const EMPTY_SUB_TITLE = ''

export const formatDate = (date, start = 0, end = 10) => {
  return date.substring(start, end)
}

export const formatTime = (date, start = 11, end = 16) => {
  if (date === null) {
    return '00h00'
  }

  return date.substring(start, end)
}

const buildTitle = shift => {
  const mainTitle = buildMainTitle(shift)
  const subtitle = buildSubtitle(shift)

  if (mainTitle === EMPTY_MAIN_TITLE) {
    return { main: subtitle }
  }

  if (subtitle === EMPTY_SUB_TITLE) {
    return { main: mainTitle }
  }

  return { main: mainTitle, subtitle: subtitle }
}

const buildMainTitle = shift => {
  if (shift.periodCode && shift.eventCode && shift.eventCode.code !== '') {
    return `${shift.periodCode} - ${shift.eventCode.code}`
  }

  if (shift.periodCode) {
    return shift.periodCode
  }

  if (shift.eventCode) {
    return shift.eventCode.code
  }

  return EMPTY_MAIN_TITLE
}

const buildSubtitle = shift => {
  if (typeof shift.startDate === 'undefined' || typeof shift.endDate === 'undefined') {
    return 'Invalid shedule'
  }

  let startDate = null

  if (shift.startDate) {
    startDate = isObject(shift.startDate) && shift.startDate.date ? shift.startDate.date : shift.startDate
  }

  let endDate = null

  if (shift.endDate !== null) {
    endDate = isObject(shift.endDate) && shift.endDate.date ? shift.endDate.date : shift.endDate
  }

  const startTime = formatTime(startDate)
  const endTime = formatTime(endDate)

  if (startTime === endTime) {
    return EMPTY_SUB_TITLE
  }

  return `${startTime} - ${endTime}`
}

const isAbsent = (shift) => {
  return shift?.eventCodeType?.category === EventCodeTypes.ABSENCE
}

const getBorderColorByShiftAndPreset = (shift, preset) => {
  if (isAbsent(shift)) {
    return ABSENT_EVENT_BORDER_COLOR
  }

  if (preset === null || typeof preset !== 'object' || !preset.color) {
    return DEFAULT_EVENT_BORDER_COLOR
  }

  return preset.color
}

const getColorByPreset = preset => {
  if (!preset || !preset.color) {
    return DEFAULT_PERIOD_CODE_COLOR
  }

  return preset.color
}

const getTextColorByShift = shift => {
  if (isAbsent(shift)) {
    return ABSENT_EVENT_TEXT_COLOR
  }

  return DEFAULT_TEXT_COLOR
}

export {
  buildTitle,
  getBorderColorByShiftAndPreset,
  getColorByPreset,
  getTextColorByShift
}
