import React, { useContext, useMemo } from 'react'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import EntitiesSelector from './EntitiesSelector'

const InstitutionsSelector = ({ disabled, multiple, values, style = {}, onChange }) => {
  const { institutions } = useContext(GlobalContext)

  const institutionsOptions = useMemo(() => institutions.map(institution => ({
    label: institution.acronym ?? institution.name,
    value: institution.id,
    tooltip: institution.name
  })), [institutions])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={institutionsOptions}
      multiple={multiple}
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default InstitutionsSelector
