import React, { useState, useEffect, useCallback } from 'react'

import { setLanguage } from '../../utils/locale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, getUrlParameterByName, generalErrorHandler } from '../../utils'

import '../../assets/confirm-additional-email.scss'
import { LanguageLocales } from '../../utils/constants'
import { HttpMethods } from '../../utils/apiHelper'

const ConfirmAdditionalEmail = ({ addTranslationForLanguage, getActiveLanguage, getUser, setActiveLanguage, onBack, t }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const validateEmail = useCallback(async () => {
    const token = getUrlParameterByName('email-validation-token')
    let language = getUrlParameterByName('lg')
    if (!language || language.length === 0) {
      language = LanguageLocales.FR_BE
    }

    setLanguage({
      language: language.toLowerCase(),
      addTranslationForLanguage,
      setActiveLanguage,
      getActiveLanguage,
      getUser: () => { return getUser }
    })

    try {
      const { status, message } = await request('/student/confirm-additional-email/' + token, HttpMethods.GET, null, null, { catchError: false })
      if (status === 'success') {
        setSuccess(true)
      } else if (message) {
        setError(t(message))
      } else {
        setError(t('There was a problem validating your additional contact email.'))
      }
      setLoading(false)
    } catch (err) {
      setError(t(err.message))
      generalErrorHandler(err)
      setLoading(false)
    }
  }, [setLoading, setSuccess, setError, addTranslationForLanguage, setActiveLanguage, getActiveLanguage, getUser, t])

  useEffect(() => validateEmail(), [validateEmail])

  return (
    <div className='additional-email-confirmation'>
      <h2>{t('Confirming your additional email')}</h2>
      {loading && <div className='loading-ring' />}
      {error && <div className='confirmation-error'>{error}</div>}
      {success && (
        <div className='confirmation-success'>
          <FontAwesomeIcon icon='check' />
          <div>{t('Your additional contact email is confirmed. You can use it in future to recover your password.')}</div>
        </div>
      )}
      {!loading && <Button type='primary' onClick={onBack}>{t('Log in to Interneo')}</Button>}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAdditionalEmail)
