import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getJobResult = (jobId, user) => {
  return requestWithPromise(
    `/job-result/${jobId}`,
    HttpMethods.GET,
    null,
    user,
    true
  )
}
