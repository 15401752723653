import React, { useEffect, useState } from 'react'

import FormItem from 'antd/es/form/FormItem'
import IconLabel, { FontSizes } from '../../../Components/icons/IconLabel'
import { Button, DatePicker, Divider, message } from 'antd'
import SchoolsSelector from '../../../Components/shared/InternshipsManager/Selectors/SchoolsSelector'
import { faCalendar, faCheck, faClinicMedical, faGraduationCap, faSchool, faStethoscope } from '@fortawesome/pro-solid-svg-icons'
import InstitutionsSelector from '../../../Components/shared/InternshipsManager/Selectors/InstitutionsSelector'
import SectorSelector from '../../../Components/shared/InternshipsManager/Selectors/SectorSelector'
import { isValid, ValidationFields } from '../../../utils/validators'
import { EUROPEAN_DATE } from '../../../utils/constants'
import SchoolSectionSelector from '../../../Components/shared/InternshipsManager/Selectors/SchoolSectionSelector'
import { isSchoolUser } from '../../../utils/roles'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import IconButton from '../../../Components/antd/Buttons/IconButton'
import { Internship, INTERNSHIP_WHITHOUT_STUDENT_VALIDATORS } from '../../../utils/entities/internship'
import { getSchoolSchoolSections } from '../../../utils/api/school'
import { getSchoolSectionSectionStudents } from '../../../utils/api/schoolSection'
import StudentsSelector from '../../../Components/shared/InternshipsManager/Selectors/StudentsSelector'
import { getInstitutionSectors } from '../../../utils/api/institution'

const COLUMN_WIDTH = '350px'
const FIELD_WIDTH = '200px'
const { START_DATE, END_DATE, SCHOOL, SECTION, INSTITUTION, SECTOR, STUDENT } = ValidationFields

message.config({
  top: 20,
  duration: 3,
  maxCount: 1,
  placement: 'topRight'
})

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const InternshipForm = ({ data, sectors, user, onSubmit, onCancel, t }) => {
  const [error, setError] = useState({ missingFields: [] })
  const [localData, setLocalData] = useState(data)
  const [sections, setSections] = useState({ data: [], loading: false })
  const [students, setStudents] = useState({ data: [], loading: false })
  const [localSectors, setLocalSectors] = useState({ data: [], loading: false })

  const disabled = isSchoolUser(user)

  useEffect(() => {
    if (localData?.school) {
      setSections({ data: [], loading: true })
      getSchoolSchoolSections(user, { id: localData.school }).then(sections => {
        setSections({ data: sections.filter(s => !!s.sectionToYear), loading: false })
      })
    }
  }, [user, localData?.school])

  useEffect(() => {
    if (localData?.section && disabled) {
      setStudents({ data: [], loading: true })
      getSchoolSectionSectionStudents(user, { id: localData.section }).then(({ data }) => {
        setStudents({ data, loading: false })
      })
    }
  }, [user, localData?.section])

  useEffect(() => {
    if (localData?.institution && disabled) {
      setLocalSectors({ data: [], loading: true })
      getInstitutionSectors(user, { id: localData.institution }).then(({ data }) => {
        setLocalSectors({ data, loading: false })
      })
    }
  }, [user, localData?.institution])

  const handleSubmit = (e) => {
    const errors = isValid(localData, INTERNSHIP_WHITHOUT_STUDENT_VALIDATORS)

    if (localData.startDate > localData.endDate) {
      message.warning(t('internship_form.error_dates'))
    } else if (errors.keys.length === 0) {
      onSubmit(new Internship(localData))
    }

    setError({
      ...error,
      title: t('internship_form.error_field'),
      visible: true,
      missingFields: errors.keys
    })
  }

  const getStyle = key => {
    const style = { width: FIELD_WIDTH }

    if (error.missingFields.includes(key)) {
      style.border = '2px solid red'
      style.borderRadius = '4px'
    }

    return style
  }

  return (
    <div className='flex-column'>
      <div className='form-class' style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'flex-start' }}>
        {/* Colonne de gauche */}
        <div style={{ width: COLUMN_WIDTH }}>
          <FormItem label={<IconLabel icon={faCalendar} text={t('common.start_date')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <DatePicker
              value={localData.startDate}
              onChange={startDate => setLocalData({ ...localData, startDate })}
              format={EUROPEAN_DATE}
              style={getStyle(START_DATE)}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label={<IconLabel icon={faSchool} text={t('common.school')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <SchoolsSelector
              multiple='default'
              values={localData.school}
              onChange={school => setLocalData({ ...localData, school, section: null })}
              style={getStyle(SCHOOL)}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label={<IconLabel icon={faGraduationCap} text={t('common.section_and_year')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <SchoolSectionSelector
              disabled={sections.data.length === 0 || sections.loading || disabled}
              schoolSections={sections.data}
              values={localData.section}
              onChange={section => setLocalData({ ...localData, section })}
              style={getStyle(SECTION)}
              loading={sections.loading}
            />
          </FormItem>
          {disabled && (
            <FormItem label={<IconLabel icon={faGraduationCap} text={t('common.student')} fontSize={FontSizes.SMALL} padding='0px' />}>
              <StudentsSelector
                disabled={students.data.length === 0 || students.loading}
                students={students.data}
                values={localData.student}
                onChange={student => setLocalData({ ...localData, student })}
                style={getStyle(STUDENT)}
                loading={students.loading}
              />
            </FormItem>
          )}
        </div>

        {/* Barre verticale */}
        <div
          style={{
            width: '1px',
            background: 'linear-gradient(to bottom, transparent, #d9d9d9, transparent)',
            alignSelf: 'stretch',
            margin: '0 8px'
          }}
        />

        {/* Colonne de droite */}
        <div style={{ width: COLUMN_WIDTH }}>
          <FormItem label={<IconLabel icon={faCalendar} text={t('common.end_date')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <DatePicker
              value={localData.endDate}
              onChange={endDate => setLocalData({ ...localData, endDate })}
              format={EUROPEAN_DATE}
              style={getStyle(END_DATE)}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label={<IconLabel icon={faClinicMedical} text={t('common.institution')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <InstitutionsSelector
              multiple='default'
              values={localData.institution}
              onChange={institution => setLocalData({ ...localData, institution, sector: null })}
              disabled
              style={getStyle(INSTITUTION)}
            />
          </FormItem>
          <FormItem label={<IconLabel icon={faStethoscope} text={t('common.sector')} fontSize={FontSizes.SMALL} padding='0px' />}>
            <SectorSelector
              disabled={!localData.institution || disabled}
              sectors={disabled ? localSectors.data : sectors}
              values={localData.sector}
              onChange={sector => setLocalData({ ...localData, sector })}
              style={getStyle(SECTOR)}
            />
          </FormItem>
        </div>
      </div>
      <div className='modal-footer'>
        <Divider />
        <div className='form-footer flex-row aln-ctr jst-end'>
          <Button key='cancel' type='default' onClick={onCancel}> {t('common.cancel')} </Button>
          <IconButton key='save' type='primary' onClick={handleSubmit} icon={faCheck} text={t('common.save')} />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipForm)
