import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'

import { ACTIONS } from 'react-joyride'
import InterneoJoyride from '../../../Components/shared/InterneoJoyride'
import { notification } from 'antd'
import SectionsTable from '../../../Components/school/Sections/SectionsTable'
import { makeDisablingLineCondition } from '../../../utils/componentFactory'
import { faUser, faSyringe } from '@fortawesome/pro-solid-svg-icons'
import SectorSectionUsers from '../../../Components/shared/SectorSectionUsers/index'
import { makeSchoolSection } from '../../../utils/entitiesFactory/SchoolSectionFactory'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { getTutorialSteps, validateTutorial } from '../../../utils/api/tutorial'
import { initTutorial } from '../../../utils/tutorials'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getSchools } from '../../../reducers/SchoolsReducer'
import { bindActionCreators } from 'redux'
import { addSchoolSection, fetchSchoolSectionsBySchool, getSchoolSections, modifySchoolSection, removeSchoolSection } from '../../../reducers/SchoolSectionsReducer/actions'
import { connect } from 'react-redux'
import { SchoolRoles } from '../../../utils/constants'
import { isArray } from 'lodash'
import SectionActTypesDrawer from './SectionActTypesDrawer'

import '../../../assets/school-sections-view.scss'
import { isEncoder } from '../../../utils/roles'

const NO_SECTIONS_MESSAGE_HEADER = "Your institution doesn't yet have a section / year of study."
const NO_SECTIONS_MESSAGE_BODY = 'This page must contain all the years of study of each section which are useful to Interneo to be able to "classify" the students who go on internship. For example :'

const mapStateToProps = state => ({
  schools: getSchools(state.getSchools),
  schoolSections: getSchoolSections(state.schoolSections),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  add: bindActionCreators(addSchoolSection, dispatch),
  fetch: bindActionCreators(fetchSchoolSectionsBySchool, dispatch),
  modify: bindActionCreators(modifySchoolSection, dispatch),
  remove: bindActionCreators(removeSchoolSection, dispatch)
})

const SchoolSectionsTable = ({ managed, schools, schoolSections, user, t, add, fetch, modify, remove, onActEdition }) => {
  const { tutorials, setTutorials, isReadOnly } = useContext(GlobalContext)

  const [tourState, setTourState] = useState(null)
  const [selectedSectionActTypes, setSelectedSectionActTypes] = useState(null)
  const [selectedSectorUsers, setSelectedSectorUsers] = useState(null)
  const [newData, setNewData] = useState(null)
  const [disableCallBack, setDisableCallback] = useState(false)

  const isUserEncoder = useMemo(() => isEncoder(user), [user])
  const schoolId = useMemo(() => isArray(schools) && schools.length > 0 ? schools[0].id : null, [schools])
  const columns = useMemo(() => {
    const columns = [
      {
        type: d => d.sectionToYear === null ? 'Input' : 'SectionsSelect',
        dataType: 'string',
        name: 'Name',
        key: d => d.sectionToYear === null ? 'name' : 'sectionId',
        filterKey: [
          section => section.sectionToYear ? section.abbreviation : section.name,
          section => section.sectionToYear ? section.schoolYearName : section.oldSchoolYearName
        ]
      },
      {
        type: d => d.sectionToYear === null ? 'Input' : 'SchoolYearsSelect',
        dataType: 'string',
        name: 'Year',
        key: d => d.sectionToYear === null ? 'oldSchoolYearName' : 'sectionToYear',
        filterKey: section => section.sectionToYear ? section.schoolYearName : section.oldSchoolYearName
      },
      {
        type: 'Input',
        dataType: 'string',
        name: 'Comment for the students',
        key: 'comment'
      }
    ]

    if (managed) {
      columns.push({
        type: 'Switch',
        dataType: 'boolean',
        name: 'Acts encoding',
        key: 'actsMandatory'
      })
    }

    return columns
  }, [managed])
  const additionalActions = useMemo(() => {
    const additionalActions = [
      {
        icon: faUser,
        type: 'primary',
        title: 'Show section users',
        handleOnClick: s => setSelectedSectorUsers(s)
      }
    ]

    if (managed) {
      additionalActions.push(
        {
          icon: faSyringe,
          type: 'primary',
          title: `school_sections_table.additional_actions.act_types_button.${isReadOnly ? 'see' : 'edit'}`,
          handleOnClick: s => setSelectedSectionActTypes(s),
          disabled: data => {
            return !data.actsMandatory
          }
        }
      )
    }

    return additionalActions
  }, [managed, isReadOnly])
  const sections = useMemo(() => {
    let sections = []

    if (schoolId !== null && schoolSections[schoolId]) {
      sections = schoolSections[schoolId].map((x, index) => {
        return { ...x, arrayIndex: index }
      })
    }

    return sections
  }, [schoolId, schoolSections])
  const noDataMessage = useMemo(() => {
    return (
      <span>
        {t(NO_SECTIONS_MESSAGE_HEADER)}
        <br />
        <br />
        {t(NO_SECTIONS_MESSAGE_BODY)}
        <br />
        <br />
        BIRSG (BLOC 1)<br />
        BIRSG (BLOC 2)<br />
        BIRSG (BLOC 3)<br />
        ISH (BLOC 1)<br />
        ISH (BLOC 2)<br />
        ISH (BLOC 3)<br />
      </span>
    )
  }, [t])
  const schoolTutorial = useMemo(() => tutorials['/school-section'], [tutorials])

  useEffect(() => {
    if (schoolTutorial && (schoolTutorial.userTutorial === null || !schoolTutorial.userTutorial.stateTutorial)) {
      getTutorialSteps(user, schoolTutorial).then(json => {
        if (json?.data) {
          setTourState(initTutorial(schoolTutorial, json.data))
        }
      })
    }
  }, [schoolTutorial, user])

  useEffect(() => {
    if (schoolId) {
      fetch(schoolId, user)
    }
  }, [fetch, schoolId, user])

  const handleDataDelete = useCallback(schoolSection => {
    remove(schoolId, schoolSection, user)
  }, [remove, schoolId, user])

  const handleDataSave = useCallback(schoolSection => {
    schoolSection.id !== -1 ? modify(schoolId, schoolSection, user) : add(schoolId, schoolSection, user)
  }, [schoolId, user, add, modify])

  const handleDataAdd = useCallback(() => {
    setNewData(makeSchoolSection(schoolId))
  }, [schoolId])

  const callback = ({ action, index, type, status }) => {
    if ((action === ACTIONS.CLOSE || action === ACTIONS.RESET) && !disableCallBack) {
      setDisableCallback(true)
      setTourState(null)

      validateTutorial(user, schoolTutorial).then(json => {
        if (json?.data) {
          const newTutos = { ...tutorials }

          newTutos['/school-section'] = { ...newTutos['/school-section'], userTutorial: json.data }

          setTutorials(newTutos)
        }

        setDisableCallback(false)
        notification.info({ message: t('Find this tutorial in the help section'), placement: 'bottomRight' })
      })
    }
  }

  return (
    <div className='sections-container'>
      <InterneoJoyride
        tourState={tourState}
        callback={callback}
        t={t}
      />
      <SectionsTable
        data={sections}
        newData={newData}
        columns={columns}
        isEncoder={isUserEncoder}
        noDataMessage={noDataMessage}
        additionalActions={additionalActions}
        onDataDelete={handleDataDelete}
        onDataSave={handleDataSave}
        disableLineIf={makeDisablingLineCondition('sectionToYear', 'isNull')}
        onDataAdd={handleDataAdd}
      />
      {managed && (
        <SectionActTypesDrawer
          schoolSection={selectedSectionActTypes}
          onClose={() => setSelectedSectionActTypes(null)}
          onActEdition={onActEdition}
        />
      )}
      <SectorSectionUsers
        entity={selectedSectorUsers}
        onClose={() => setSelectedSectorUsers(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSectionsTable)
