import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { INTERNSHIPS_MANAGER } from '../../reducers/InternshipsManagerReducer/actionsType'
import { getUser } from '../../reducers/UserReducer'
import { getJobResult } from '../../utils/api/asynchronous'
import { acceptInternship, cancelInternship, duplicateInternship, preAccept, resetToCreated, resetToPending, updateInternship } from '../../utils/api/internship'
import { InternshipStates } from '../../utils/constants'
import { InternshipMessageContext } from '../../Providers/InternshipMessageProvider'

const { CREATED, PENDING, PRE_ACCEPTED, ACCEPTED, CANCELED, REFUSED } = InternshipStates

const TIME_INTERVAL = 1000

const InternshipContext = createContext()

const mapStateToProps = state => ({ user: getUser(state.getUser) })

let InternshipProvider = ({ children, internship, quotas, user, onEarlyStartDate, refreshInternships, refreshQuotas }) => {
  const { setSelectedInternship } = useContext(InternshipMessageContext)

  const [localInternship, setLocalInternship] = useState({})
  const [overlaping, setOverlaping] = useState(false)

  const dispatch = useDispatch()
  const timeInternval = useRef()
  const updateTimer = useRef()

  useEffect(() => {
    if (internship) {
      setLocalInternship(internship)
    }
  }, [internship])

  const onUpdate = body => {
    updateInternship(localInternship, body, user).then(json => {
      if (json?.data) {
        if (institutionHasChange(json.data, localInternship)) {
          setSelectedInternship(null)
        }

        setLocalInternship(json.data)
        refreshQuotas()
      }

      if (json?.status && json.status === 'OVERLAP') {
        setOverlaping(true)
      }
    })
  }

  const institutionHasChange = (internship, oldInternship) => {
    let change = true

    if (internship.institution === null) {
      change = !!oldInternship.institution
    } else if (oldInternship.institution !== null) {
      change = internship.institution.id !== oldInternship.institution.id
    }

    return change
  }

  const onPeriodUpdate = internship => {
    const { startDate, endDate } = internship

    setLocalInternship(internship)

    if (updateTimer.current) {
      clearTimeout(updateTimer.current)
    }

    updateTimer.current = setTimeout(() => {
      updateInternship(
        internship,
        { startDate: startDate, endDate: endDate }
        , user
      ).then(json => {
        if (json?.data) {
          setLocalInternship(json.data)
          refreshQuotas()

          if (moment(startDate) <= moment().startOf('isoWeek')) {
            onEarlyStartDate(true)
          }
        }

        if (json?.status && json.status === 'OVERLAP') {
          setOverlaping(true)
        }
      })
    }, 1000)
  }

  const handleStateChange = state => {
    if (state === REFUSED) {
      const newInternship = { ...localInternship }

      newInternship.state = state

      setLocalInternship(newInternship)
      dispatch({
        type: INTERNSHIPS_MANAGER.SET_REFUSED_INTERNSHIP,
        payload: newInternship
      })

      return
    }

    const action = getActionByState(state)

    if (action !== null) {
      action(localInternship, user)
        .then(json => {
          if (json && json.data) {
            refreshInternships()
          }
        })
    }
  }

  const getActionByState = state => {
    if (state === PENDING) {
      return resetToPending
    }

    if (state === ACCEPTED) {
      return acceptInternship
    }

    if (state === CANCELED) {
      return cancelInternship
    }

    if (state === PRE_ACCEPTED) {
      return preAccept
    }

    if (state === CREATED) {
      return resetToCreated
    }

    return null
  }

  const handleDuplicateInternship = () => {
    duplicateInternship(localInternship, user).then(json => {
      timeInternval.current = setInterval(() => {
        getJobResult(json.job, user).then(json => {
          if (json && json.data) {
            clearInterval(timeInternval.current)
            refreshInternships()
          }
        })
      }, TIME_INTERVAL)
    })
  }

  return (
    <InternshipContext.Provider value={{
      overlaping,
      internship: localInternship,
      quotas,
      handleStateChange,
      handleDuplicateInternship,
      onPeriodUpdate,
      onUpdate,
      refreshQuotas,
      setOverlaping,
      setLocalInternship
    }}
    >
      {children}
    </InternshipContext.Provider>
  )
}

InternshipProvider = connect(mapStateToProps)(InternshipProvider)

export {
  InternshipProvider as default,
  InternshipContext
}
