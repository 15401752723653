import React, { Component } from 'react'

import SmartTable, { SmartTableDataTypes } from '../../shared/SmartTable'
import StudentInfo from '../../../Components/shared/StudentInfo'
import moment from 'moment'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { validateFormInput, request, objectDeepCopy, generalErrorHandler } from '../../../utils'
import { SchoolRoles } from '../../../utils/constants'
import { HttpMethods } from '../../../utils/apiHelper'

class SchoolStudentsList extends Component {
  state = {
    selectedStudentInfo: null,
    students: this.props.students
  };

  get schoolWithSectionFromGlobalState () {
    if (this._cachedSchool && this._cachedSchool.id === this.props.schoolId) {
      return this._cachedSchool
    }
    this._cachedSchool = this.props.getSchools.find(item => item.id === this.props.schoolId)
    return this._cachedSchool
  }

  handleAddStudent = (student, stopLoadingCallback) => {
    student.birthdate =
      student.birthdate === null || student.birthdate.trim().length === 0
        ? undefined
        : `${student.birthdate.split('/')[2]}-${
            student.birthdate.split('/')[1]
          }-${student.birthdate.split('/')[0]}`
    student.stillInSchool = true

    request('/api/Student', HttpMethods.POST, student, this.props.getUser)
      .then(json => {
        if (json.status && json.status === 'error') {
          stopLoadingCallback()
        } else {
          const students = [...this.state.students]
          json.data.section = this.schoolWithSectionFromGlobalState.sections.filter(
            s => s.id === json.data.section
          )[0]
          if (typeof json.data.section === 'undefined') { json.data.section = this.props.schoolWithSectionFromGlobalState.rootSection }

          students.unshift(json.data)
          this.setState({ students })
        }
      })
      .catch(error => {
        generalErrorHandler(error)
      })
  };

  handleEditStudent = (student, stopLoadingCallback) => {
    student.birthdate =
      student.birthdate === null || student.birthdate.trim().length === 0
        ? null
        : `${student.birthdate.split('/')[2]}-${
            student.birthdate.split('/')[1]
          }-${student.birthdate.split('/')[0]}`
    request('/api/Student/' + student.id, HttpMethods.PATCH, student, this.props.getUser)
      .then(json => {
        if (json.status && json.status === 'error') {
          stopLoadingCallback()
        } else {
          const student = json.data
          let students = [...this.state.students]
          students = students.map(s => {
            let studentSection = this.schoolWithSectionFromGlobalState.sections.filter(
              s => s.id === student.section
            )[0]
            if (!studentSection) {
              studentSection = this.schoolWithSectionFromGlobalState.rootSection
            }
            if (s.id === student.id) {
              s = student
              s.section = studentSection
            }
            return s
          })
          this.setState({ students })
        }
      })
      .catch(error => { generalErrorHandler(error) })
  };

  handleDeleteStudent = student => {
    request('/api/Student/' + student.id, HttpMethods.DELETE, null, this.props.getUser)
    this.setState({ students: this.state.students.filter(item => item.id !== student.id) })
  };

  render () {
    let students = []
    if (this.state.students.length) {
      students = this.state.students.map(s => {
        return {
          id: s.id,
          section:
            !s.section
              ? null
              : typeof s.section.id === 'undefined'
                ? s.section
                : s.section.id,
          lastname: s.lastname,
          firstname: s.firstname,
          email: s.email,
          matricule: s.matricule,
          phoneNumber: s.phoneNumber,
          birthdate:
            s.birthdate === null
              ? null
              : moment(s.birthdate.date).format('DD/MM/YYYY')
        }
      })
    }

    let sections = []
    if (this.props.getSchools.length > 0) {
      const school = this.schoolWithSectionFromGlobalState
      sections = objectDeepCopy(school.sections)

      if (this.props.getUser.roles.indexOf(SchoolRoles.ENCODER) > -1) {
        sections.unshift(school.rootSection)
      }

      sections = sections.map(s => {
        return {
          id: s.id,
          name: s.name
        }
      })
    }

    const columns = [
      { type: SmartTableDataTypes.ID, key: 'id' },
      {
        type: SmartTableDataTypes.SELECT,
        name: this.props.t('common.section'),
        key: 'section',
        options: sections
      },
      {
        type: SmartTableDataTypes.STRING,
        name: this.props.t('Lastname'),
        key: 'lastname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: this.props.t('Firstname'),
        key: 'firstname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: this.props.t('Email'),
        key: 'email',
        validate: data => validateFormInput('email', data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: this.props.t('Registration number'),
        key: 'matricule',
        validate: data => validateFormInput('freeText', data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: this.props.t('Phone'),
        key: 'phoneNumber',
        validate: data => validateFormInput('freeText', data, false)
      },
      {
        type: SmartTableDataTypes.DATE,
        name: this.props.t('Birthdate'),
        key: 'birthdate',
        format: 'DD/MM/YYYY',
        validate: data => {
          if (data) {
            return data.length > 0
          }
          return true
        }
      }
    ]

    return (
      <div>
        <SmartTable
          columns={columns}
          data={students}
          onDataAdd={this.handleAddStudent}
          onDataEdit={this.handleEditStudent}
          onDataDelete={this.handleDeleteStudent}
          noDataText={this.props.t('You do not have any student.')}
          addDataText={this.props.t('Add a student')}
          additionalActions={[
            {
              iconName: 'user-graduate',
              type: 'primary',
              title: this.props.t('Student sheet'),
              onClick: s => this.setState({ selectedStudentInfo: s })
            }
          ]}
        />
        <StudentInfo
          student={this.state.selectedStudentInfo}
          onClose={() => this.setState({ selectedStudentInfo: null })}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolStudentsList)
