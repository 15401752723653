import React, { useCallback, useMemo, useState } from 'react'

import InternshipsRequestsTable from './InternshipsRequestsTable'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import useRequest from '../../../../hooks/useRequest'
import {
  deleteInternshipRequest,
  getAllInternshipRequests,
  refuseInternshipRequest
} from '../../../../utils/api/internshipRequest'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { OrderBy, OrderDirections } from '../../../../utils/constants'

const mapStateToProps = state => ({ user: getUser(state.getUser), t: getTranslate(state.locale) })

const InternshipsRequestsView = ({ user, t }) => {
  const [parameters, setParameters] = useState(new TableParameters({ orderBy: OrderBy.CREATED_AT, orderDirection: OrderDirections.DESC }))
  const { data, loading, metadata, reload } = useRequest({ request: getAllInternshipRequests, parameters: useMemo(() => [user, parameters.toQueryParametersOld()], [user?.id, parameters]) })

  const handleDelete = useCallback(request => {
    deleteInternshipRequest(user, request).then(json => {
      onSuccess(t('internship_request.delete.success'))
      reload()
    }).catch(() => {
      onError(t('internship_request.delete.error'))
    })
  }, [user, t])

  const handleRefuse = useCallback(request => {
    refuseInternshipRequest(user, request).then(json => {
      if (json?.data) {
        onSuccess(t('internship_request.refuse.success'))
        reload()
      } else {
        onError(t('internship_request.refuse.error'))
      }
    })
  }, [user, t])

  return (
    <div>
      <InternshipsRequestsTable
        data={data}
        institution={user.institutions[0]}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onDelete={handleDelete}
        onParametersChange={setParameters}
        onRefuse={handleRefuse}
        reload={reload}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsRequestsView)
