import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getEvaluationQuestions = (user, institution) => {
  return requestWithPromise(
      `/evaluation/questions/${institution.id}`,
      HttpMethods.GET,
      null,
      user
  )
}

export const saveEvaluationByStudent = (user, data) => {
  return requestWithPromise(
    '/evaluation/internship/by-student',
    HttpMethods.POST,
    data,
    user
  )
}
