import React, { useMemo } from 'react'

import DataTable from '../../../Components/shared/DataTable/DataTable'
import Cookie from 'js-cookie'
import { TableActions, Action, DeleteAction } from '../../../Components/shared/DataTable/utils/actions'
import { faEdit, faStethoscope } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Institution, getAddress, getCity, getCountry, getDescription, getGlobalMessage, getName, getZipcode } from '../../../utils/entities/institution'
import { OrderBy, InstitutionRoles } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionsTable = ({ data, loading, metadata, parameters, user, onParametersChange, onSelectInstitution, onTriggerAction, t }) => {
  const switchUserId = useMemo(() => Cookie.get('switch_user_id'), [])
  const maxHeight = useMemo(() => `calc(100vh - ${switchUserId ? '180px' : '139px'})`, [switchUserId])
  const columns = useMemo(() => [
    {
      title: t('institutions_view.column_title.name'),
      orderBy: OrderBy.NAME,
      key: i => {
        const name = getName(i)

        return name.length > 50 ? `${name.slice(0, 47)}...` : name
      },
      tooltip: getName
    },
    { title: t('data_table.column_title.global_message'), key: getGlobalMessage, tooltip: t('institution_parameters.global_message_column.tooltip') },
    { title: t('institutions_view.column_title.address'), orderBy: OrderBy.ADDRESS, key: getAddress },
    { title: t('institutions_view.column_title.zipcode'), orderBy: OrderBy.ZIPCODE, key: getZipcode },
    { title: t('institutions_view.column_title.city'), orderBy: OrderBy.CITY, key: getCity },
    { title: t('institutions_view.column_title.country'), orderBy: OrderBy.COUNTRY, key: getCountry },
    { title: t('institutions_view.column_title.description'), orderBy: OrderBy.DESCRIPTION, key: getDescription }
  ], [t])
  const actions = useMemo(() => [
    new Action({ title: 'institutions_view.actions.edit_institution', icon: faEdit, onClick: onSelectInstitution }),
    new Action({
      title: 'institutions_view.actions.set_institution_sectors',
      icon: faStethoscope,
      onClick: i => onTriggerAction(i, TableActions.DISPLAY_INSTITUTION_SECTORS)
    }),
    new DeleteAction({ onDelete: i => onTriggerAction(i, TableActions.DELETE) })
  ], [onTriggerAction, onSelectInstitution])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onSelectInstitution(new Institution({ managed: user.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN) }))}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionsTable)
