import React, { useState } from 'react'

import { Button } from 'antd'
import ActsModal from './ActsModal'
import { InternshipStates } from '../../../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => { return { t: getTranslate(state.locale) } }

const ActsButton = ({ actTypes, internshipState, shift, onSubmit, t }) => {
  const [actModalVisible, setActModalVisible] = useState(false)

  let actsButton

  if (actTypes <= 0) {
    actsButton = null

    return actsButton
  }

  internshipState === InternshipStates.SCHEDULE_ADDED
    ? actsButton = null
    : actsButton =
      (
        <div>
          <Button
            onClick={() => setActModalVisible(true)}
            size='large'
            style={{ width: '100%' }}
          >
            {t('Encode an act')}
          </Button>
          <ActsModal
            title={t('Encode an act')}
            visible={actModalVisible}
            onCancel={() => setActModalVisible(false)}
            onOk={newActs => {
              setActModalVisible(false)
              onSubmit(newActs)
            }}
            actTypes={actTypes}
            shift={shift}
            isEditable
          />
        </div>
      )

  return actsButton
}

export default connect(mapStateToProps)(ActsButton)
