import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs } from 'antd'
import Analytics from '../../utils/analytics'
import BadgesManager from '../../Components/institution/Badges/BadgesManager'
import { Contexts, InstitutionRoles } from '../../utils/constants'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import InternshipsReporting from '../../Components/shared/InternshipsReporting'
import { InstitutionGroupContext } from '../../Providers/InstitutionGroupProvider'

const { TabPane } = Tabs

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const ReportingView = ({ user, t }) => {
  const institutionGroupContext = useContext(InstitutionGroupContext)

  useEffect(() => {
    Analytics.pageView('/institution-badge')
  }, [])

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      <TabPane
        key='1'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon='user' style={{ marginTop: '3px' }} />
            <div className='h-spacing' />
            {t('View students informations')}
          </div>
        }
      >
        <InternshipsReporting institutions={institutionGroupContext?.institutions} />
      </TabPane>
      {user.context === Contexts.INSTITUTION && !user.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN) && (
        <TabPane
          key='2'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon='id-badge'
                style={{ marginTop: '3px' }}
              />
              <div className='h-spacing' />
              {t('Manage badges assignations')}
            </div>
          }
        >
          <BadgesManager />
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(ReportingView)
