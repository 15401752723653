import React from 'react'

import { Contexts } from '../../../../utils/constants'
import InstitutionStatesSelector from '../../Selectors/StatesSelector/InstitutionStatesSelector'
import SchoolStatesSelector from '../../Selectors/StatesSelector/SchoolStatesSelector'
import InstitutionDateTitle from '../DateTitle/InstitutionDateTitle'
import SchoolDateTitle from '../DateTitle/SchoolDateTitle'
import InstitutionInternshipLine from '../InternshipLine/InstitutionInternshipLine'
import SchoolInternshipLine from '../InternshipLine/SchoolInternshipLine'

export const getInternshipLine = context => {
  if (context === Contexts.SCHOOL) {
    return SchoolInternshipLine
  }

  if (context === Contexts.INSTITUTION) {
    return InstitutionInternshipLine
  }

  return <div />
}

export const getStatesPellet = context => {
  if (context === Contexts.SCHOOL) {
    return SchoolStatesSelector
  }

  if (context === Contexts.INSTITUTION) {
    return InstitutionStatesSelector
  }

  return <div />
}

export const getDateTitle = context => {
  if (context === Contexts.SCHOOL) {
    return SchoolDateTitle
  }

  if (context === Contexts.INSTITUTION) {
    return InstitutionDateTitle
  }

  return null
}
