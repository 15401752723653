import React, { useContext, useState, useEffect } from 'react'

import ShiftCell from '../ShiftCells/ShiftCell'
import { getMultiSelectionShiftEncapsulation } from '../../../../utils/entities/ShiftEncapsulation'
import { getIndexKey, getShiftByDayAndInternship, canOnlyEditEventCode, setShiftDateAsMoment, createShiftOrConfigureIt, getSectorPresets } from '../helper'
import moment from 'moment'
import { DATE_FORMAT_API, Contexts, InternshipStates } from '../../../../utils/constants'
import { MultiSelectModes } from '../constants'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import { validateInternshipDesideratas } from '../../../../utils/api/internship'
import { setInternshipShifts } from '../../../../reducers/ShiftsReducer/ShiftsReducer'
import { useDispatch } from 'react-redux'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import InternshipCard from '../InternshipCard'
import { isObserver, isSupervisor } from '../../../../utils/roles'
import { InternshipMessageContext } from '../../../../Providers/InternshipMessageProvider'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const { ROW } = MultiSelectModes

const Line = props => {
  const { isReadOnly } = useContext(GlobalContext)
  const internshipMessages = useContext(InternshipMessageContext)

  const [internship, setInternship] = useState({})
  const [shiftsDisabled, setShiftsDisabled] = useState(true)
  const [eventCodeOnly, setEventCodeOnly] = useState(false)
  const [canValidateDesiderata, setCanValidateDesiderata] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.internship) {
      const institutionId = props.internship.institution?.id ?? -1
      const sectorId = props.internship.sector?.id ?? -1

      const newInternship = { ...props.internship, presets: getSectorPresets(institutionId, sectorId, props.getShiftPresets) ?? {} }

      setInternship(newInternship)
      setShiftsDisabled(getShiftDisablingState(newInternship))
    }
  }, [props.internship.institution, props.internship.sector, props.getShiftPresets])

  useEffect(() => {
    setEventCodeOnly(
      isObserver(props.getUser) ||
      (isSupervisor(props.getUser) && !props.supervisorsEditSchedule) ||
      (canOnlyEditEventCode(props.getUser, internship) && internship.institution?.managed)
    )
  }, [internship, props.getUser, props.supervisorsEditSchedule])

  useEffect(() => {
    const startOfTheDay = moment().startOf('day')
    const hasDesiderata = props.getShifts[props.internship.id] && props.getShifts[props.internship.id].find(s => s.isDesiderata)

    setCanValidateDesiderata(
      hasDesiderata &&
      (
        props.getUser.context === Contexts.INSTITUTION ||
        (!eventCodeOnly && !shiftsDisabled) ||
        moment(props.internship.endDate) <= startOfTheDay ||
        !isReadOnly
      )
    )
  }, [eventCodeOnly, shiftsDisabled, props.getUser, props.internship, props.getShifts[props.internship.id]])

  const handleRowSelection = () => {
    if (props.multiSelectionMode && !eventCodeOnly) {
      addLineShiftsToMultiSelection()
    }
  }

  const addLineShiftsToMultiSelection = () => {
    const currentDate = props.startDate.clone()
    const shiftsToAdd = []

    for (let colIndex = 0; colIndex < props.numberOfDays; colIndex++) {
      const shiftOfTheDay = getShiftByDayAndInternship(internship, currentDate)

      shiftsToAdd.push(getMultiSelectionShiftEncapsulation(
        createShiftOrConfigureIt(shiftOfTheDay, internship.id, currentDate.clone(), currentDate.clone()),
        props.internshipIndex,
        colIndex,
        internship
      ))

      currentDate.add(1, 'days')
    }

    props.multiSelectionAddingHandler(shiftsToAdd, ROW)
  }

  const handleDisplayValidationDrawer = () => {
    props.setIntershipToValidate(internship)
  }

  const handleStudentSelection = student => {
    internshipMessages.setSelectedInternship(null)
    props.onStudentSelected(student)
  }

  const handleInternshipSelection = internship => {
    internshipMessages.setSelectedInternship(null)
    props.onInternshipSelected(internship)
  }

  const handleValidateDesideratas = () => {
    validateInternshipDesideratas(props.getUser, internship).then(json => {
      if (json?.data) {
        dispatch(setInternshipShifts(internship.id, props.getShifts[internship.id].map(s => {
          s.isDesiderata = false

          return s
        })))

        onSuccess(props.t('all_internship_desiderata_validation.success'))
      } else {
        onError(props.t('all_internship_desiderata_validation.error'))
      }
    })
  }

  const renderStudentCard = () => {
    if (internship.id) {
      return (
        <td onClick={handleRowSelection}>
          <InternshipCard
            canValidateDesiderata={canValidateDesiderata}
            canValidateInternship={props.getUser.canValidateInternship}
            checked={props.checked}
            context={props.getUser.context}
            displayInfoButton={!eventCodeOnly && !shiftsDisabled}
            internship={internship}
            inDraftMode={props.inDraftMode}
            inWidgetMode={props.inWidgetMode}
            useCheckbox
            onClickedDraft={props.onClickedDraft}
            onDeleteSchedule={props.onDeleteSchedule}
            onInternshipChecked={props.onInternshipChecked}
            onSelectInternship={handleInternshipSelection}
            onSelectStudent={handleStudentSelection}
            onSelectValidation={handleDisplayValidationDrawer}
            onValidateDesideratas={handleValidateDesideratas}
          />
        </td>
      )
    }

    return (
      <td>
        <div className='loading-shift-container'>
          <div className='loading-backdrop' />
          <div className='loading-text'>
            <div className='loading-icon' />
            <span>{props.t('Loading shifts')}</span>
          </div>
        </div>
      </td>
    )
  }

  const getShiftDisablingState = internship => {
    return (
      internship.state === InternshipStates.SCHEDULE_VALIDATED ||
      !internship.presets ||
      !internship.presets.length ||
      props.limitedActions
    )
  }

  const renderShifts = () => {
    const shifts = []
    const dayIndex = props.startDate.clone()
    const shiftsByDayIndex = {}

    if (props.getShifts[internship.id]) {
      props.getShifts[internship.id].forEach(shift => {
        shiftsByDayIndex[moment(shift.startDate, DATE_FORMAT_API).diff(props.startDate, 'day')] = setShiftDateAsMoment(shift)
      })
    }

    for (let colIndex = 0; colIndex < props.numberOfDays; colIndex++) {
      const shift = shiftsByDayIndex[colIndex]

      shifts.push(
        <ShiftCell
          key={internship.id + '_' + colIndex}
          currentShift={shift ? { ...shift } : {}}
          inDraftMode={props.inDraftMode}
          internship={internship}
          contentEditable={!shiftsDisabled || isObserver(props.getUser)}
          eventCodeOnly={eventCodeOnly}
          currentDay={moment(dayIndex)}
          columnIndex={colIndex}
          index={props.internshipIndex}
          multiSelectionMode={props.multiSelectionMode}
          isSelected={!!props.selectedShifts[getIndexKey(props.internshipIndex, colIndex)]}
          inWidgetMode={props.inWidgetMode}
          multiSelectionAddingHandler={props.multiSelectionAddingHandler}
          onDesiderataSelect={props.onDesiderataSelect}
        />
      )

      dayIndex.add(1, 'days')
    }

    return shifts
  }

  return (
    <tr
      key={'internship-' + props.internshipIndex}
      className={props.internshipIndex % 2 ? 'row-background' : 'row-no-background'}
    >
      {renderStudentCard()}
      {internship && renderShifts()}
    </tr>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Line)
