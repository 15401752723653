import React, { useCallback, useMemo, useState } from 'react'

import { Button, Drawer, Modal } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { fetchAllActTypes, fetchLinkedActTypes, linkActTypes, unlinkActTypes } from '../../../utils/api/actType'
import useRequest from '../../../hooks/useRequest'
import Loading from '../../../HOC/Loading'
import SearchCheckboxList from '../../../Components/antd/Lists/SearchCheckboxList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/pro-solid-svg-icons'
import { ColorsPalette } from '../../../config/colors'
import { onError, onSuccess, resolvePromises } from '../../../utils/apiHelper'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const UserActTypesDrawer = ({ t, user, selectedUser, setSelectedUser }) => {
  const [checkedActs, setCheckedActs] = useState([])
  const [confirmMessage, setConfirmMessage] = useState('')

  const actTypesHandler = useRequest({
    request: fetchAllActTypes,
    parameters: useMemo(() => [user, { school: user?.school?.id }], [user.id])
  })

  const linkedActTypesHandler = useRequest({
    request: fetchLinkedActTypes,
    parameters: useMemo(() => [user, selectedUser], [user.id, selectedUser?.id])
  })

  const linkCallback = useCallback(ids => linkActTypes(user, selectedUser, { ids }), [user.id, selectedUser?.id])
  const unlinkCallback = useCallback(ids => unlinkActTypes(user, selectedUser, { ids }), [user.id, selectedUser?.id])

  const handleSaveClick = useCallback(() => {
    const currentlyLinked = linkedActTypesHandler.data

    const toLink = checkedActs
      .filter(act => !currentlyLinked.includes(act))
      .map(act => act.id)
    const toUnlink = currentlyLinked
      .filter(act => !checkedActs.includes(act))
      .map(act => act.id)

    const promises = []

    if (toLink.length > 0) {
      promises.push(linkCallback(toLink))
    }

    if (toUnlink.length > 0) {
      promises.push(unlinkCallback(toUnlink))
    }

    resolvePromises(promises).then(linkedActTypesHandler.reload)
      .finally(() => onSuccess(t('user.actions.link_act_type.success')))
      .catch(() => onError(t('user.actions.link_act_type.error')))
  }, [linkedActTypesHandler.data, linkedActTypesHandler.reload, checkedActs, linkCallback, unlinkCallback])

  const handleCloseDrawer = useCallback(() => {
    if (!(linkedActTypesHandler.data.every((value) => checkedActs.includes(value)) && linkedActTypesHandler.data.length === checkedActs.length)) {
      setConfirmMessage(t('user_act_types_drawer.confirm_modal.body'))
    } else {
      setSelectedUser(null)
    }
  }, [t, linkedActTypesHandler.data, checkedActs, setConfirmMessage, setSelectedUser])

  const handleConfirmExit = useCallback(() => {
    setConfirmMessage('')
    setSelectedUser(null)
  }, [setSelectedUser, setConfirmMessage])

  return (
    <>
      <Modal
        title={t('user_act_types_drawer.confirm_modal.title')}
        visible={confirmMessage.length > 0}
        zIndex={5001}
        onCancel={() => setConfirmMessage('')}
        onClose={() => setConfirmMessage('')}
        onOk={handleConfirmExit}
        cancelText={t('modal.cancel_button')}
      >
        <h4><FontAwesomeIcon icon={faWarning} color={ColorsPalette.RED} /> {confirmMessage}</h4>
      </Modal>
      <Drawer
        title={t('user_act_types_drawer.title') + (selectedUser?.email ? ` (${selectedUser.email})` : '')}
        width='640px'
        onClose={handleCloseDrawer}
        visible={selectedUser !== null}
        zIndex={5000}
        destroyOnClose
        bodyStyle={{ height: 'calc(100% - 55px)', display: 'flex', flexDirection: 'column' }}
      >
        <Loading loading={linkedActTypesHandler.loading}>
          <div style={{ maxHeight: 'calc(100% - 48px)' }}>
            <SearchCheckboxList
              data={actTypesHandler.data}
              label='name'
              values={linkedActTypesHandler.data}
              onChange={setCheckedActs}
            />
          </div>
        </Loading>
        <div className='mt-8 pd-8' style={{ height: '48px' }}>
          <Button type='primary' onClick={handleSaveClick}>{t('actions.save')}</Button>
        </div>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(UserActTypesDrawer)
