import React, { useState, useRef } from 'react'
import { bool, func } from 'prop-types'
import { Button, Modal, Upload, notification, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { requestMultipart, generalErrorHandler } from '../../utils'
import '../../assets/students-import-modal.scss'
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { HttpMethods } from '../../utils/apiHelper'

const { Dragger } = Upload

const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const StudentsImportModal = props => {
  const [fileToUpload, setFileToUpload] = useState(null)
  const [importing, setImporting] = useState(false)
  const [importSuccessful, setImportSuccessful] = useState(false)
  const [importPercentage, setImportPercentage] = useState(0)
  const [resetUpload, setResetUpload] = useState(false)

  const progressTimer = useRef(null)

  const updateProgress = () => {
    let percentage = 0
    setImportPercentage(percentage)
    progressTimer.current = setInterval(() => {
      if (percentage >= 99) {
        clearInterval(progressTimer.current)
        return false
      }
      percentage += percentage >= 90 ? 1 : 10
      setImportPercentage(percentage)
    }, 600)
  }

  const finishedUpload = () => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }
    setImportPercentage(100)
    setImportSuccessful(true)
  }

  const handleFileUpload = async () => {
    if (!fileToUpload) {
      return
    }
    setImporting(true)

    if (fileToUpload && ACCEPTED_DOCUMENT_TYPES.indexOf(fileToUpload.type) > -1) {
      const body = {
        file: fileToUpload
      }
      try {
        updateProgress()

        const response = await requestMultipart('/import-students', HttpMethods.POST, body, props.getUser, true)
        const blob = await response.blob()

        // now "download" the file
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'import_results.xlsx'
        document.body.appendChild(a)
        a.click()
        a.remove()

        notification.success({ message: props.t('Students imported successfully.'), placement: 'bottomRight' })
        setFileToUpload(null)
        finishedUpload()

        // and now reload the students
        props.reloadStudentsAfterImport()
      } catch (err) {
        setImporting(false)
        setFileToUpload(null)
        setImportPercentage(0)
        notification.error({ message: props.t(err.message), placement: 'bottomRight' })
        setFileToUpload(null)
        generalErrorHandler(err)
      }
    } else {
      notification.error({ message: props.t('Only Excel and CSV file types are supported.'), placement: 'bottomRight' })
    }
  }

  const handleClose = () => {
    if (importing && !importSuccessful) {
      return
    }
    props.onClose()
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }
    setFileToUpload(null)
    setImporting(false)
    setImportPercentage(0)
    setImportSuccessful(false)

    setTimeout(() => {
      setResetUpload(true)
      setTimeout(() => {
        setResetUpload(false)
      })
    }, 50)
  }

  return (
    <Modal
      title={props.t('Import students')}
      visible={props.displayed}
      onCancel={handleClose}
      footer={
        importSuccessful
          ? [
            <Button
              onClick={handleClose}
              key='cancel'
              type='primary'
            >
              {props.t('Close')}
            </Button>]
          : [
            <Button
              onClick={handleClose}
              key='cancel'
              type='default'
              disabled={importing}
            >
              <FontAwesomeIcon icon={faTimes} />&nbsp;
              {props.t('Cancel')}
            </Button>,
            <Button
              key='save'
              type='primary'
              onClick={handleFileUpload}
              disabled={importing || !fileToUpload}
            >
              <FontAwesomeIcon icon={faCheck} />&nbsp;
              {props.t('OK, proceed')}
            </Button>]
      }
    >
      <div className='students-import-modal-body'>
        <div className='upload-type-description'>
          <ul>
            <li> {props.t('student.import.type.description.update')} </li>
            <li> {props.t('student.import.type.description.new')} </li>
            <li> {props.t('student.import.type.description.delete')} </li>
          </ul>
        </div>
        <div className='upload-area'>
          {!importing && !resetUpload &&
            <Dragger
              name='file'
              accept='text/csv,.csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              beforeUpload={(file) => {
                setFileToUpload(file)
                return false
              }}
              onRemove={() => { setFileToUpload(null) }}
            >
              <p className='ant-upload-text'>{props.t('Click or drag file to this area to upload')}</p>
              <p className='ant-upload-hint'>{props.t('Only Excel and CSV file types are supported.')}
              </p>
            </Dragger>}
          {importing &&
            <div className='importing-animation'>
              <Progress type='circle' percent={importPercentage} />
            </div>}
        </div>
      </div>

    </Modal>
  )
}

StudentsImportModal.propTypes = {
  displayed: bool.isRequired,
  onClose: func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsImportModal)
