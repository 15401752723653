import React from 'react'

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import { store } from '../../../..'
import InternshipProvider, { InternshipContext } from '../../../../Context/InternshipsManager/InternshipProvider'
import { getStudents } from '../../../../reducers/StudentsReducer/actions'
import { EntityTypes, InstitutionConstraintTypes, InternshipStates, Contexts } from '../../../../utils/constants'
import { ADD, COLUMN_TYPE_TIME, DEFAULT_CELL_PARAMETERS, END_DATE_KEY, QUANTITY, QUOTA_ACCESSORS, REMOVE, SCHOOL_INTERNSHIP_DISABLING_STATES, START_DATE_KEY, UNIT } from './constants'

const { SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED } = InternshipStates

export const DEFAULT_USER_STATUS = {
  isValidator: false,
  isSupervisor: false,
  isEvaluator: false,
  context: Contexts.NONE
}

const { INTERNSHIPS_BLOCKING } = InstitutionConstraintTypes
const { STUDENT } = EntityTypes

const DEFAULT_TOOLTIP_TEXT = 'Validate internship'
const TO_VALIDATE_TOOTIP_TEXT = 'Internship to validate, student uploaded signed document'
const VALIDATED_TOOLTIP_TEXT = 'Internship validated'
const ADD_ONE_WEEK_TEXT = 'Add a week'
const REMOVE_ONE_WEEK_TEXT = 'Delete this week'

export const getValidationTooltipText = internship => {
  if (internship.state === SCHEDULE_TO_VALIDATE) {
    return TO_VALIDATE_TOOTIP_TEXT
  }

  if (internship.state === SCHEDULE_VALIDATED) {
    return VALIDATED_TOOLTIP_TEXT
  }

  return DEFAULT_TOOLTIP_TEXT
}

export const getInternshipProvider = context => {
  if (context === Contexts.SCHOOL || context === Contexts.INSTITUTION) {
    return {
      Provider: InternshipProvider,
      context: InternshipContext
    }
  }

  return { provider: null, context: null }
}

export const getParametersByIndexes = (periodIndexes, index) => {
  if (index === periodIndexes.startIndex - 1) {
    return {
      icon: faArrowLeft,
      direction: REMOVE,
      key: START_DATE_KEY,
      title: ADD_ONE_WEEK_TEXT
    }
  }

  if (index === periodIndexes.startIndex) {
    return {
      icon: periodIndexes.startIndex === periodIndexes.lastIndex ? null : faArrowRight,
      direction: ADD,
      key: START_DATE_KEY,
      title: REMOVE_ONE_WEEK_TEXT
    }
  }

  if (index === periodIndexes.lastIndex) {
    return {
      icon: faArrowLeft,
      direction: REMOVE,
      key: END_DATE_KEY,
      title: REMOVE_ONE_WEEK_TEXT
    }
  }

  if (index === periodIndexes.lastIndex + 1) {
    return {
      icon: faArrowRight,
      direction: ADD,
      key: END_DATE_KEY,
      title: ADD_ONE_WEEK_TEXT
    }
  }

  return DEFAULT_CELL_PARAMETERS
}

export const getNewPeriod = (parameters, startDate, endDate) => {
  if (parameters.direction === REMOVE && parameters.key === START_DATE_KEY) {
    return {
      startDate: startDate.clone()
    }
  }

  if (parameters.direction === ADD && parameters.key === START_DATE_KEY) {
    return {
      startDate: startDate.clone().add(QUANTITY, UNIT)
    }
  }

  if (parameters.direction === REMOVE && parameters.key === END_DATE_KEY) {
    return {
      endDate: endDate.clone().add(-QUANTITY, UNIT)
    }
  }

  if (parameters.direction === ADD && parameters.key === END_DATE_KEY) {
    return {
      endDate: endDate.clone()
    }
  }

  return null
}

export const getDateCellClassName = (index, periodIndexes) => {
  if (index < periodIndexes.startIndex || index > periodIndexes.lastIndex) {
    return ''
  }

  let className = 'has-internship'

  if (index === periodIndexes.startIndex) {
    className += ' has-internship-start'
  }

  if (index === periodIndexes.lastIndex) {
    className += ' has-internship-end'
  }

  return className
}

export const getRemainingQuotas = (internship, sectorTree) => {
  if (!sectorTree) {
    return null
  }

  const state = store.getState()
  let remainingPlaces = sectorTree.remaining ?? null
  let treeNode = sectorTree

  Object.keys(QUOTA_ACCESSORS).forEach(key => {
    const treeKey = QUOTA_ACCESSORS[key](internship, state)

    if (!treeNode[treeKey]) {
      return remainingPlaces
    }

    if (
      typeof treeNode[treeKey].remaining !== 'undefined' &&
      treeNode[treeKey].quotaIds.length > 0
    ) {
      remainingPlaces = treeNode[treeKey].remaining
    }

    treeNode = treeNode[treeKey]
  })

  return remainingPlaces
}

export const getIndexesByColumns = (internship, columns) => {
  const startDate = moment(internship.startDate.split('+')[0])
  const endDate = moment(internship.endDate.split('+')[0])
  let startIndex = -1
  let lastIndex = columns.length - 1

  if (columns[lastIndex].endDate < startDate) {
    return { startIndex: columns.length, lastIndex: columns.length }
  }

  if (columns[0].startDate > endDate) {
    return { startIndex: -1, lastIndex: -1 }
  }

  columns.forEach((dates, index) => {
    if (startDate >= dates.startDate && startDate < dates.endDate) {
      startIndex = index
    }

    if (endDate <= dates.endDate && endDate > dates.startDate) {
      lastIndex = index
    }
  })

  return { startIndex, lastIndex }
}

export const getColumnsDates = (startDate, columnsCount) => {
  const columnsDates = []
  const currentDate = startDate.clone()

  for (let i = 0; i < columnsCount; i++) {
    columnsDates.push({
      startDate: moment(currentDate.startOf(COLUMN_TYPE_TIME.period)),
      endDate: moment(currentDate.endOf(COLUMN_TYPE_TIME.period))
    })

    currentDate.add(COLUMN_TYPE_TIME.count, COLUMN_TYPE_TIME.period)
  }

  return columnsDates
}

export const buildMissingPropertiesMessage = (internship, constraints, t) => {
  if (constraints) {
    const constraintsList = getMissingPropertiesList(internship, constraints, t)

    return (
      <div>
        {t('In order to send this internship into the institution') + ` ${internship.institution.name}, `}
        {t(`please complete the following ${constraintsList.length > 1 ? 'fields' : 'field'}:`)}
        {constraintsList}
        {t('Once this is done, the demand will be automatically transfert to the institution.')}
      </div>
    )
  }

  return t("Their are some student's data that are missing.")
}

const getMissingPropertiesList = (internship, constraints, t) => {
  const body = []

  if (internship.student) {
    const student = getStudents(store.getState().getStudents).find(s => s.id === internship.student.id)

    if (student) {
      constraints.forEach((c, index) => {
        if (
          c.type === INTERNSHIPS_BLOCKING &&
        c.entityName === STUDENT &&
        !student[c.propertyName]
        ) {
          body.push(
            <li key={`constraint_${index}`}>
              {t(c.propertyName)}
            </li>
          )
        }
      })
    }
  }

  return (
    <ul>
      {body}
    </ul>
  )
}

export const getDisablingState = internship => {
  return SCHOOL_INTERNSHIP_DISABLING_STATES.includes(internship.state)
}
