import React, { useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Routes } from '../routes'
import ChangeLogView from '../Views/shared/ChangeLogView'
import InternshipsView from '../Views/school/InternshipsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import SchoolProvider from '../Providers/SchoolProvider'
import SchoolStudentsView from '../Views/school/SchoolStudentsView'

const DomainEvaluatorRouter = () => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  return (
    <SchoolProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={setCurrentDate} />
          </Route>
          <Route path={Routes.SHIFTS_MANAGER}>
            <ShiftsManagerView currentDate={currentDate} />
          </Route>
          <Route path={Routes.STUDENTS}>
            <SchoolStudentsView />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
        </Switch>
    </SchoolProvider>
  )
}

export default withRouter(DomainEvaluatorRouter)
