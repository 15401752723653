import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getAllSchools } from '../../utils/api/school'
import { debounce } from 'lodash'
import { TableActions } from '../../Components/shared/DataTable/utils/actions'
import { TableParameters } from '../../utils/entities/tableParameters'
import { OrderBy } from '../../utils/constants'
import { onSuccess } from '../../utils/apiHelper'
import SchoolsTable from '../institutionGroup/SchoolsView/SchoolsTable'
import { getUser } from '../../reducers/UserReducer'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import { Metadata, retrieveMetadata } from '../../utils/http'
import SchoolSections from '../../Components/institution/SchoolSections'
import { linkSchoolToInstitution, unlinkSchoolFromInstitution } from '../../utils/api/institution'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})
const SchoolsView = ({ user, t }) => {
  const iContext = useContext(InstitutionContext)

  const [rawSchools, setRawSchools] = useState(DEFAULT_DATA)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: OrderBy.NAME, pageSize: 20 }))
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchSchools = useCallback((user, tableParameters) => {
    setLoading(true)

    getAllSchools(user, { ...tableParameters.toQueryParametersOld(), paginate: true }).then(json => {
      if (json?.data || json?.meta) {
        const schools = {}

        schools.data = json?.data ?? []
        schools.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setRawSchools(schools)
      }

      setLoading(false)
    })
  }, [getAllSchools, setLoading, setRawSchools])

  const linkToInstitution = useCallback(school => {
    if (iContext?.institution && iContext?.setSchools) {
      linkSchoolToInstitution(user, iContext.institution, school).then(json => {
        if (json?.data) {
          onSuccess(t('schools_view.api.link_school'))
          iContext.setSchools(json.data.schools)
        }
      })
    }
  }, [user, iContext?.institution, iContext?.setSchools, t])

  const unlinkFromInstitution = useCallback(school => {
    if (iContext?.institution && iContext?.setSchools) {
      unlinkSchoolFromInstitution(user, iContext.institution, school).then(json => {
        if (json?.data) {
          onSuccess(t('schools_view.api.unlink_school'))
          iContext.setSchools(json.data.schools)
        }
      })
    }
  }, [user, iContext?.institution, iContext?.setSchools, t])

  const handleTriggerAction = useCallback((school, action) => {
    if (action === TableActions.LINK_INSTITUTION_SCHOOL) {
      linkToInstitution(school)
    } else if (action === TableActions.UNLINK_INSTITUTION_SCHOOL) {
      unlinkFromInstitution(school)
    } else if (action === TableActions.SELECT) {
      setSelectedSchool(school)
    }
  }, [linkToInstitution, unlinkFromInstitution])

  const schools = useMemo(() => {
    const schools = { ...rawSchools }

    schools.data = schools.data.map(s => {
      return { ...s, linked: iContext.schools.some(igs => igs.id === s.id) }
    })

    return schools
  }, [rawSchools, iContext?.schools])

  useEffect(() => {
    const fetch = debounce(() => fetchSchools(user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user.id, tableParameters])

  return (
    <>
      <SchoolsTable
        data={schools.data}
        help={iContext?.institution?.institutionGroup ? 'data_table.help.missing_schools.contact_admin' : 'data_table.help.missing_schools.contact_opal'}
        loading={loading || iContext.loading}
        metadata={schools.meta}
        parameters={tableParameters}
        onParametersChange={setTableParameters}
        onTriggerAction={handleTriggerAction}
      />
      <SchoolSections
        school={selectedSchool}
        onClose={() => setSelectedSchool(null)}
      />
    </>
  )
}

export default connect(mapStateToProps)(SchoolsView)
