import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip, Card, List, Tag } from 'antd'
import { TextColors, Contexts } from '../../../../../utils/constants'
import SchoolNote from './SchoolNote'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../../../reducers/UserReducer'
import { GlobalContext } from '../../../../../Providers/GlobalProvider'

const INFO_BUTTON_COLOR = '#3787BF'
const CARD_GLOBAL_STYLE = { backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px' }
const CARD_HEAD_STYLE = { color: 'white', fontSize: '12px' }
const TITLE_STYLE = { overflow: 'unset', whiteSpace: 'pre-wrap' }
const CARD_BODY_STYLE = { padding: '0px 12px 0px 12px', textAlign: 'center' }
const LIST_STYLE = { color: 'white', fontSize: '10px' }
const TAG_STYLE = { color: 'black', minWidth: '50px' }
const DEFAULT_TITLE = 'Available codes:'
const LIST_ITEM_STYLE = {
  borderBottom: '0px', display: 'flex', flexDirection: 'row', alignItem: 'center', alignContent: 'space-between'
}

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const PresetsTooltip = props => {
  const { isReadOnly } = useContext(GlobalContext)

  const makePresetsCodesTooltipContent = (presets, sectorNote) => {
    return (
      <>
        {props.user.context === Contexts.SCHOOL && <SchoolNote note={sectorNote} />}
        <Card
          size='small'
          style={CARD_GLOBAL_STYLE}
          headStyle={CARD_HEAD_STYLE}
          bodyStyle={CARD_BODY_STYLE}
          title={getTitle()}
        >
          <List
            style={LIST_STYLE}
            size='small'
            dataSource={presets}
            renderItem={item => {
              return (
                <List.Item
                  style={LIST_ITEM_STYLE}
                >
                  <Tag
                    color={item.color}
                    style={{ ...TAG_STYLE, color: item.textColor ?? TextColors.DARK }}
                  >
                    {item.periodCode}
                  </Tag>
                  ({item.startTime.slice(0, item.startTime.length - 3)} - {item.endTime.slice(0, item.startTime.length - 3)})
                </List.Item>
              )
            }}
          />
        </Card>
      </>
    )
  }

  const getTitle = () => {
    return (
      <span style={TITLE_STYLE}>
        {props.t(props.title ?? DEFAULT_TITLE)}
      </span>
    )
  }

  return (
    <>
      {!isReadOnly &&
        <Tooltip
          placement={props.placement ?? 'right'}
          title={makePresetsCodesTooltipContent(props.presets, props.sectorNote)}
          className='tutoriel-bouton-aide'
        >
          <FontAwesomeIcon
            icon={props.icon ?? faInfoCircle} color={props.iconColor ?? INFO_BUTTON_COLOR}
          />
        </Tooltip>
      }
    </>
  )
}

export default connect(mapStateToProps)(PresetsTooltip)
