import React, { useCallback, useContext, useMemo } from 'react'

import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { OrderBy } from '../../../../utils/constants'
import { DeleteAction, DownloadFile } from '../../../../Components/shared/DataTable/utils/actions'
import { Tag } from 'antd'
import { getOriginalName, getType, getTypeColor, InternshipFile } from '../../../../utils/entities/internshipFile'
import { getUser } from '../../../../reducers/UserReducer'
import { onError } from '../../../../utils/apiHelper'
import { downloadInternshipDocument } from '../../../../utils/api/internshipDocument'
import { InternshipFileModalContext } from '../../../../Providers/Forms/InternshipFileModal'
import { isStudent } from '../../../../utils/roles'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipFilesTable = ({ data, internship, loading, metadata, parameters, readOnly, user, onDelete, onParametersChange, reload, t }) => {
  const { setSelectedFile } = useContext(InternshipFileModalContext)

  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: OrderBy.ORIGINAL_NAME, key: getOriginalName },
    {
      title: t('data_table.column_title.type'),
      orderBy: OrderBy.TYPE,
      key: f => <Tag color={getTypeColor(f)}>{t('internship_file.types.' + getType(f).toLowerCase())}</Tag>
    }
  ], [t])

  const onFileDownload = useCallback(file => {
    const fileObject = data.filter(f => f.id === file.id)[0]

    try {
      downloadInternshipDocument(user, fileObject).then(reload)
    } catch (err) {
      onError(err)
    }
  }, [user, data, reload])

  const actions = useMemo(() => {
    const actions = [new DownloadFile({ onClick: onFileDownload }).build()]

    if (!readOnly) {
      actions.push(new DeleteAction({ onDelete, disabled: d => isStudent(user.roles) && d.createdBy !== null && d.createdBy !== user.id }))
    }

    return actions
  }, [readOnly, user, onDelete, onFileDownload])

  return (
    <DataTable
      actions={actions}
      columns={columns}
      data={data}
      disabledAdd={readOnly}
      loading={loading}
      metadata={metadata}
      parameters={parameters}
      onCreate={() => setSelectedFile({ data: new InternshipFile({ internship }), callback: reload })}
      onParametersChange={onParametersChange}
    />
  )
}

export default connect(mapStateToProps)(InternshipFilesTable)
