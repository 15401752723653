import { requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const getExternalContacts = (user, parameters) => {
  return requestWithPromise(
    `/api/external-contacts${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const addExternalContact = (user, externalContact) => {
  return requestWithPromise(
    '/api/external-contacts',
    HttpMethods.POST,
    externalContact.toRequestBody(),
    user,
    true,
    true
  )
}

export const updateExternalContact = (user, externalContact) => {
  return requestWithPromise(
    `/api/external-contacts/${externalContact.id}`,
    HttpMethods.PATCH,
    externalContact.toRequestBody(),
    user,
    true,
    true
  )
}

export const deleteExternalContact = (user, externalContact) => {
  return requestWithPromise(
    `/api/external-contacts/${externalContact.id}`,
    HttpMethods.DELETE,
    null,
    user,
    true,
    true
  )
}
