import { request, generalErrorHandler } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const SET_EVALUATION_QUESTIONS = 'SET_EVALUATION_QUESTIONS'
const CLEAR_EVALUATION_QUESTIONS = 'CLEAR_EVALUATION_QUESTIONS'

export const getEvaluationQuestions = (state, props) => {
  return state
}

export const fetchEvaluationQuestions = (institutionId, currentUser) => {
  return async dispatch => {
    try {
      const { data } = await request(`/evaluation/questions/${institutionId}`, HttpMethods.GET, null, currentUser)
      dispatch({
        type: SET_EVALUATION_QUESTIONS,
        payload: { institutionId, questions: data !== undefined ? data : [] }
      })
      return data
    } catch (err) {
      generalErrorHandler(err)
      return null
    }
  }
}

export const clearEvaluationQuestions = () => {
  return {
    type: CLEAR_EVALUATION_QUESTIONS
  }
}

export default (state = { defaultQuestions: [], institutionQuestions: {} }, action) => {
  switch (action.type) {
    case SET_EVALUATION_QUESTIONS:
    {
      const { questions, institutionId } = action.payload
      if (state.defaultQuestions.length) {
        // this happens when loading questions for a new institution
        const questionWithoutDefaultOnes = questions.filter(item => item.institution !== null && item.isDefault === false)
        if (questionWithoutDefaultOnes.length) {
          const updatedInstitutionQuestions = { ...state.institutionQuestions }
          updatedInstitutionQuestions[institutionId] = questionWithoutDefaultOnes
          return {
            ...state,
            institutionQuestions: { ...updatedInstitutionQuestions }
          }
        }
      } else {
        // this happens once, for the first loading
        const questionWithoutDefaultOnes = questions.filter(item => item.institution !== null && item.isDefault === false)
        const newInstitutionQuestions = {}
        newInstitutionQuestions[institutionId] = questionWithoutDefaultOnes
        return {
          defaultQuestions: questions,
          institutionQuestions: { ...newInstitutionQuestions }

        }
      }
      return { ...state }
    }
    case CLEAR_EVALUATION_QUESTIONS:
      return { defaultQuestions: [], institutionQuestions: {} }
    default:
      return state
  }
}
