import { requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'
import apiClient from './apiClient'

export const getSchoolSectionYears = (section, user) => {
  return requestWithPromise(
    `/api/sections/${section.id}/section-to-years`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getActTypes = (user, section, parameters = { 'get-hours': 1 }) => {
  return requestWithPromise(
    `/api/school-sections/${section.id}/act-types${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getSchoolSectionStudents = (user, schoolSection) => {
  return apiClient.get(user, `api/school-sections/${schoolSection.id}/students`)
}

export const getSchoolSectionSectionStudents = (user, schoolSection) => {
  return apiClient.get(user, `api/school-sections/${schoolSection.id}/section/students`)
}
