import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'

const TooltipIconButton = ({
  disabled = false, ghost, icon, iconColor, overlayStyle = {}, placement = 'top', size = 'default', text, title, type = 'default', onClick = () => {}
}) => {
  return (
    <Tooltip title={title} overlayStyle={overlayStyle} placement={placement}>
      <Button
        onClick={onClick}
        type={type}
        size={size}
        disabled={disabled}
        ghost={ghost}
      >
        <FontAwesomeIcon icon={icon} color={iconColor} />
        {text && <span> &nbsp; {text}</span>}
      </Button>
    </Tooltip>
  )
}

export default TooltipIconButton
