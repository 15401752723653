import React, { useEffect, useState } from 'react'
import { Button, List, Modal, Popconfirm, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generalErrorHandler, requestWithPromise } from '../../utils'
import { getAllSectors, getInternshipsBySector } from '../../utils/api/sector'
import { HttpMethods, onSuccess } from '../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getInstitutionSectors } from '../../utils/api/institution'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ArchivedSectorsModal = ({ institution, visible, unarchive, user, onCancel, onDelete, onOk, t }) => {
  const [archivedSectors, setArchivedSectors] = useState([])
  const [deleteSectorModalVisible, setDeleteSectorModalVisible] = useState(false)
  const [selectedSector, setSelectedSector] = useState(null)

  useEffect(() => {
    let promise = null

    if (institution && visible) {
      promise = getInstitutionSectors(user, institution, { archived: true, orderBy: 'name' })
    } else if (institution === null && visible) {
      promise = getAllSectors(user, { orderBy: 'name', filters: { archived: true } })
    }

    if (promise !== null) {
      promise.then(json => {
        if (json?.data) {
          setArchivedSectors(json.data)
        }
      })
    }
  }, [user.id, institution, visible])

  const deleteSector = sector => {
    setSelectedSector(sector)
    getInternshipsBySector(user, sector).then(json => {
      if (json?.data?.length > 0) {
        setDeleteSectorModalVisible(true)
      } else {
        deleteSectorRequest(sector)
      }
    })
  }

  const deleteSectorRequest = sector => {
    requestWithPromise('/api/sector/' + sector.id, HttpMethods.DELETE, null, user, { catchError: false }).then(json => {
      if (json?.data) {
        onSuccess(t('The care unit has been successfully deleted'))
        setDeleteSectorModalVisible(false)
        setArchivedSectors(archivedSectors.filter(s => s.id !== sector.id))

        if (typeof onDelete === 'function') {
          onDelete(json.data)
        }
      }
    })
      .catch(error => { generalErrorHandler(error) })
  }

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={onCancel}
        title={<div>{t('Archived care units')}</div>}
        footer={
          <Button
            onClick={onOk}
            type='primary'
          > {t('Close')}
          </Button>
        }
        destroyOnClose
      >
        <List
          style={{ overflow: 'auto', height: '300px' }}
          size='large'
          dataSource={archivedSectors}
          renderItem={item =>
            <List.Item
              key={item.id}
              actions={[
                <div key='actions_div'>
                  <Tooltip
                    placement='top'
                    title={t('Unarchive')}
                  >
                    <Button
                      type='primary'
                      size='small'
                      onClick={() => {
                        setArchivedSectors(archivedSectors.filter(s => s.id !== item.id))
                        unarchive(item, false)
                      }}
                    ><FontAwesomeIcon icon='box-open' />
                    </Button>
                  &nbsp;
                  </Tooltip>
                  <Tooltip
                    placement='top'
                    title={t('Delete')}
                  >
                    <Popconfirm
                      title={
                        t('Are you sure you want to delete this care unit?')
                      }
                      onConfirm={() => deleteSector(item)}
                      okText={t('Yes')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('No')}
                    >
                      <Button
                        type='danger'
                        size='small'
                      ><FontAwesomeIcon icon='trash' />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              ]}
            >
              {item.name}
            </List.Item>}
        />
      </Modal>
      <Modal
        visible={deleteSectorModalVisible}
        onCancel={() => setDeleteSectorModalVisible(false)}
        title={<div>{t('Delete care unit')}</div>}
        footer={
          <div>
            <Button
              onClick={() => setDeleteSectorModalVisible(false)}
              type='primary'
            > {t('Cancel')}
            </Button>
            <Button
              onClick={() => deleteSectorRequest(selectedSector)}
              type='danger'
            > {t('Delete')}
            </Button>
          </div>
        }
        destroyOnClose
      >
        <p>
          <FontAwesomeIcon color='orange' icon='exclamation-circle' />
          &nbsp;
          <span style={{ fontWeight: 900, color: 'orange' }}>{t('Careful')}</span>
          ,&nbsp;
          {t('this care unit contains internships. If you delete it all related internships will be also deleted')}
        </p>
      </Modal>
    </div>

  )
}

export default connect(mapStateToProps)(ArchivedSectorsModal)
