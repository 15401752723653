import React, { useContext, useEffect, useRef, useState } from 'react'
import { getTableComponent } from '../../../utils/componentFactory'
import { validatorsFactory } from '../../../utils/validators'
import { Tooltip, Button, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { setFunctionIntoHook } from './utils'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const BUTTON_STYLE = { width: '28px', marginLeft: '2px' }
const DEFAULT_DENSITY = '32px'

const BodyRow = props => {
  const { isReadOnly } = useContext(GlobalContext)

  const [data, setData] = useState({})
  const [isDirty, setIsDirty] = useState(false)
  const [validator, setValidator] = useState(setFunctionIntoHook(validatorsFactory()))
  const [errors, setErrors] = useState({})

  const timeoutId = useRef(null)
  const clearTimeoutId = useRef(null)

  useEffect(() => {
    if (props.data) {
      setData(props.data)
    }

    return () => {
      setData({})
      setIsDirty(false)
    }
  }, [props.data])

  useEffect(() => {
    if (props.validator) {
      setValidator(setFunctionIntoHook(validatorsFactory(props.validator)))
    }
  }, [props.validator])

  const handleDataDelete = () => {
    props.onDataDelete(data)
  }

  const handleChange = (updatedData) => {
    setIsDirty(true)
    setData(updatedData)
  }

  const handleSave = (newData) => {
    const validity = validator(newData)

    if (validity.isValid) {
      setIsDirty(false)
      props.onDataSave(chooseDataToSave(newData))
    }

    setErrors(validity.errors)
  }

  const renderRowBody = () => {
    return props.columns.map(column => {
      const CellComponent = getTableComponent(typeof column.type === 'string'
        ? column.type
        : column.type(data)
      )
      const cellKey = typeof column.key === 'string'
        ? column.key
        : column.key(data)

      return (
        <td
          key={props.index + column.name}
          className={errors[cellKey] ? 'incorrect-data' : 'tutoriel-lignes-grisees'}

        >
          <CellComponent
            data={data}
            valueKey={cellKey}
            onChange={handleChange}
            disabled={props.disable}
            onBlur={setSavingTimeout}
            onFocus={clearSavingTimeout}
          />
        </td>
      )
    })
  }

  const renderAdditionalActions = (rowData) => {
    const additionalActionsJSX = props.additionalActions.map(action => {
      return (
        <Tooltip
          placement='top'
          title={props.t(action.title)}
          key={'action-' + action.title}
        >
          <Button
            type={action.type}
            size='small'
            style={BUTTON_STYLE}
            onClick={() => action.handleOnClick(rowData)}
            disabled={action.disabled ? action.disabled(rowData) : false}
          >
            <FontAwesomeIcon icon={action.icon} />
          </Button>
        </Tooltip>
      )
    })

    return (
      <td className='buttons'>
        <div className='flex-row'>
          {additionalActionsJSX}
          {props.onDataDelete ? renderDeletionButton(rowData) : undefined}
        </div>
      </td>
    )
  }

  const renderDeletionButton = (rowData) => {
    return (
      <Tooltip
        placement='top'
        title={props.t('Delete this data ?')}
      >
        <Popconfirm
          placement='top'
          okType='danger'
          title={props.t('Delete this data ?')}
          okText={props.t('Yes')}
          cancelText={props.t('Cancel')}
          onConfirm={() => handleDataDelete()}
        >
          <Button
            disabled={isReadOnly}
            type='danger'
            size='small'
            style={BUTTON_STYLE}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Popconfirm>
      </Tooltip>
    )
  }

  const setSavingTimeout = (haveToBeDirty = false, newData = null) => {
    if (isDirty || haveToBeDirty) {
      clearTimeout(clearTimeoutId.current)

      timeoutId.current = setTimeout(() => {
        handleSave(chooseDataToSave(newData))
      }, 300)
    }
  }

  const chooseDataToSave = (newData = null) => {
    return newData === null ? data : newData
  }

  const clearSavingTimeout = () => {
    clearTimeoutId.current = setTimeout(() => {
      clearTimeout(timeoutId.current)
    }, 100)
  }

  return (
    <tr
      key={props.key}
      className={props.disable ? 'disable' : 'tutoriel-row'}
      style={{ height: props.density ?? DEFAULT_DENSITY }}
    >
      {renderRowBody()}
      {props.additionalActions ? renderAdditionalActions(data) : undefined}
    </tr>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BodyRow)
