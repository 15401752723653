import React, { createContext, useCallback, useMemo, useState } from 'react'

import { Modal, Select } from 'antd'
import IndividualInternshipRequestForm from '../../Components/shared/Forms/IndividualInternshipRequestForm'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { onError, onSuccess, resolvePromises } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import {
  acceptInternshipRequest,
  addInternshipRequestFile,
  createInternshipRequest,
  createInternshipRequestPeriod,
  deleteInternshipRequestPeriods,
  setInternshipRequestToOnHold,
  updateInternshipRequest,
  updateInternshipRequestPeriod
} from '../../utils/api/internshipRequest'
import FormItem from '../../Components/shared/Forms/FormItem'
import { faAt, faBirthdayCake, faCalendar, faPhone, faUser } from '@fortawesome/pro-solid-svg-icons'
import { EUROPEAN_DATE } from '../../utils/constants'
import Mailing from '../../Components/shared/Mailing'
import { isArray, isFunction } from 'lodash'
import { RequestPeriod } from '../../utils/entities/requestPeriod'

export const IndividualInternshipRequestModalContext = createContext()

/** @enum {string} */
export const ModalTypes = Object.freeze({
  FORM: 'FORM',
  DISPLAY: 'DISPLAY',
  ACCEPT: 'ACCEPT'
})

const DEFAULT_REQUEST = { data: null, callback: null, type: ModalTypes.FORM }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const IndividualInternshipRequestModal = ({ children, institutions, schools, sectors, user, t }) => {
  const [selectedRequest, setSelectedRequest] = useState(DEFAULT_REQUEST)
  const [selectedPeriods, setSelectedPeriods] = useState([])

  const displayForm = useMemo(() => !!selectedRequest.data && selectedRequest.type === ModalTypes.FORM, [selectedRequest])
  const displayStudentData = useMemo(() => !!selectedRequest.data && selectedRequest.type === ModalTypes.DISPLAY, [selectedRequest])
  const displayAcceptModal = useMemo(() => !!selectedRequest.data && selectedRequest.type === ModalTypes.ACCEPT, [selectedRequest])

  const handleSubmit = useCallback((request, files) => {
    const promise = request.id < 0 ? { call: createInternshipRequest, action: 'create' } : { call: updateInternshipRequest, action: 'update' }
    const oldRequest = selectedRequest.data
    const requestPeriodsToCreate = []
    const requestPeriodsToUpdate = []
    const requestPeriodsToDelete = []

    if (promise.action === 'update') {
      request.requestPeriods.forEach(p => {
        if (p.id < 0) {
          requestPeriodsToCreate.push(new RequestPeriod(p))
        } else {
          const oldPeriod = oldRequest.requestPeriods.find(rp => rp.id === p.id)

          if (oldPeriod && (oldPeriod.startDate !== p.startDate || oldPeriod.endDate !== p.endDate)) {
            requestPeriodsToUpdate.push(new RequestPeriod(p))
          }
        }
      })

      oldRequest.requestPeriods.forEach(p => {
        if (!request.requestPeriods.find(rp => rp.id === p.id)) {
          requestPeriodsToDelete.push(p)
        }
      })
      request.requestPeriods = []
    }

    promise.call(user, request).then(json => {
      if (json?.data) {
        const promisesStack = []

        requestPeriodsToCreate.forEach(rp => {
          promisesStack.push(createInternshipRequestPeriod(user, oldRequest, rp.toRequestBody()))
        })
        requestPeriodsToUpdate.forEach(rp => {
          promisesStack.push(updateInternshipRequestPeriod(user, oldRequest, rp.toRequestBody()))
        })

        if (requestPeriodsToDelete.length > 0) {
          promisesStack.push(deleteInternshipRequestPeriods(user, oldRequest, { ids: requestPeriodsToDelete.map(rp => rp.id) }))
        }

        if (isArray(files)) {
          files.forEach(f => {
            promisesStack.push(addInternshipRequestFile(user, json.data, f))
          })
        }

        resolvePromises(promisesStack).then(() => {
          onSuccess(t('internship_request.' + promise.action + '.success'))

          if (selectedRequest.callback) {
            selectedRequest.callback(json.data)
          }
        })
      }
    }).catch(() => {
      onError(t('internship_request.' + promise.action + '.error'))
    }).finally(() => {
      setSelectedRequest(DEFAULT_REQUEST)
    })
  }, [selectedRequest, user, t])

  const setRequestAsPending = useCallback(() => {
    setInternshipRequestToOnHold(user, selectedRequest.data).then(json => {
      if (json?.data) {
        if (isFunction) {
          selectedRequest.callback(json.data)
        }

        onSuccess(t('internship_request.set_pending.success'))
        setSelectedRequest(DEFAULT_REQUEST)
      } else {
        onError(t('internship_request.set_pending.error'))
      }
    })
  }, [selectedRequest, user, setSelectedRequest, t])

  const acceptRequest = useCallback(() => {
    acceptInternshipRequest(user, selectedRequest.data, selectedPeriods).then(json => {
      if (json?.data) {
        if (isFunction) {
          selectedRequest.callback(json.data)
        }

        onSuccess(t('internship_request.actions.accept.success'))
        setSelectedRequest(DEFAULT_REQUEST)
        setSelectedPeriods([])
      } else {
        onError(t('internship_request.actions.accept.error'))
      }
    })
  }, [user, selectedRequest, selectedPeriods, t, setSelectedPeriods, setSelectedRequest])

  return (
    <>
      <IndividualInternshipRequestModalContext.Provider
        value={{ setSelectedRequest }}
      >
        {children}
      </IndividualInternshipRequestModalContext.Provider>
      <Modal
        width={1024}
        title={t('internship_request_modal.title')}
        visible={displayForm}
        onCancel={() => setSelectedRequest(DEFAULT_REQUEST)}
        footer={null}
        destroyOnClose
      >
        <IndividualInternshipRequestForm
          item={displayForm ? selectedRequest.data : null}
          schools={schools}
          sectors={sectors}
          institutions={institutions}
          onSubmit={handleSubmit}
        />
      </Modal>
      <Modal
        title={t('student_informations_modal.title')}
        visible={displayStudentData}
        onCancel={() => setSelectedRequest(DEFAULT_REQUEST)}
        onOk={setRequestAsPending}
        okText={t('student_informations_modal.ok_button')}
        cancelText={t('student_informations_modal.cancel_button')}
      >
        {displayStudentData && (
          <>
            <div className='flex-row w-100'>
              <FormItem className='w-50 pdr-4' icon={faUser} label='form.label.firstname'>
                {selectedRequest.data.firstName}
              </FormItem>
              <FormItem className='w-50 pdl-4' icon={faUser} label='form.label.lastname'>
                {selectedRequest.data.lastName}
              </FormItem>
            </div>
            <div className='flex-row w-100'>
              <FormItem className='w-50 pdr-4' icon={faPhone} label='form.label.phone_number'>
                {selectedRequest.data.phone}
              </FormItem>
              <FormItem className='w-50 pdl-4' icon={faBirthdayCake} label='form.label.birthdate'>
                {selectedRequest.data.birthday?.format(EUROPEAN_DATE)}
              </FormItem>
            </div>
            <FormItem icon={faAt} label='form.label.email'>
              <Mailing email={selectedRequest.data.email} />
            </FormItem>
          </>
        )}
      </Modal>
      <Modal
        title={t('accept_internship_request_modal.title')}
        visible={displayAcceptModal}
        onCancel={() => setSelectedRequest(DEFAULT_REQUEST)}
        onOk={acceptRequest}
        okText={t('student_informations_modal.accept_internship_request_button')}
        okButtonProps={{ disabled: selectedPeriods.length === 0 }}
        cancelText={t('student_informations_modal.cancel_button')}
      >
        {displayAcceptModal && (
          <>
            <div className='flex-row w-100'>
              <FormItem className='w-50 pdr-4' icon={faUser} label='form.label.firstname'>
                {selectedRequest.data.firstName}
              </FormItem>
              <FormItem className='w-50 pdl-4' icon={faUser} label='form.label.lastname'>
                {selectedRequest.data.lastName}
              </FormItem>
            </div>
            <FormItem icon={faAt} label='form.label.email'>
              <Mailing email={selectedRequest.data.email} />
            </FormItem>
            <FormItem icon={faCalendar} label='form.label.period' required>
              <Select style={{ width: '100%' }} onChange={setSelectedPeriods} mode='multiple'>
                {selectedRequest.data.requestPeriods.map(p => <Select.Option key={p.id} value={p.id}> {p.startDate.format(EUROPEAN_DATE) + ' - ' + p.endDate.format(EUROPEAN_DATE)} </Select.Option>)}
              </Select>
            </FormItem>
          </>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(IndividualInternshipRequestModal)
