import { getTimezoneLessMoment } from '../momentjs'

export const getStudentFullSection = student => {
  let section = 'NONE'

  if (student.sections && student.sections.length > 0 && student.sections[0].sectionToYear) {
    const { sectionToYear } = student.sections[0]

    section = `${sectionToYear.sectionAbbreviation} (${sectionToYear.schoolYearName})`
  }

  return section
}

export class Student {
  constructor ({ id = -1, birthDate = null, email = '', firstname = '', lastname = '', phone = null }) {
    this.id = id
    this.birthDate = getTimezoneLessMoment(birthDate)
    this.email = email
    this.firstname = firstname
    this.lastname = lastname
    this.phone = phone
  }

  toRequestBody = () => {
    return { ...this }
  }
}
