import React, { useCallback, useContext, useMemo } from 'react'

import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { OrderBy } from '../../../../utils/constants'
import { DeleteAction, DownloadFile } from '../../../../Components/shared/DataTable/utils/actions'
import { getUser } from '../../../../reducers/UserReducer'
import { onError } from '../../../../utils/apiHelper'
import { InstitutionDocumentModalContext } from '../../../../Providers/Forms/InstitutionDocumentModal'
import { getOriginalName, InstitutionDocument } from '../../../../utils/entities/institutionDocument'
import { downloadInstitutionDocument } from '../../../../utils/api/institution'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionDocumentTable = ({ data, institution, loading, metadata, parameters, readOnly, user, onDelete, onParametersChange, reload, t }) => {
  const { setSelectedFile } = useContext(InstitutionDocumentModalContext)

  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: OrderBy.ORIGINAL_NAME, key: getOriginalName }
  ], [t])

  const onFileDownload = useCallback(file => {
    const fileObject = data.filter(f => f.id === file.id)[0]

    try {
      downloadInstitutionDocument(user, fileObject).then(reload)
    } catch (err) {
      onError(err)
    }
  }, [user, data, reload])

  const actions = useMemo(() => {
    const actions = [new DownloadFile({ onClick: onFileDownload }).build()]

    if (!readOnly) {
      actions.push(new DeleteAction({ onDelete }))
    }

    return actions
  }, [readOnly, onDelete, onFileDownload])

  return (
    <DataTable
      actions={actions}
      columns={columns}
      data={data}
      disabledAdd={readOnly}
      loading={loading}
      metadata={metadata}
      parameters={parameters}
      onCreate={() => setSelectedFile({ data: new InstitutionDocument({ institution }), callback: reload })}
      onParametersChange={onParametersChange}
    />
  )
}

export default connect(mapStateToProps)(InstitutionDocumentTable)
