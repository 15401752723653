import { requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const createSectorShiftPreset = (user, sector, shiftPreset) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/shift-presets`,
    HttpMethods.POST,
    shiftPreset.toRequestBody(),
    user,
    true,
    true
  )
}

export const createDeletedSectorShiftPreset = (user, sector, shiftPreset) => {
  const body = shiftPreset.toRequestBody()
  body.deleted = true

  return requestWithPromise(
    `/api/sectors/${sector.id}/shift-presets`,
    HttpMethods.POST,
    body,
    user,
    true,
    true
  )
}

export const updateShiftPreset = (user, shiftPreset) => {
  return requestWithPromise(
    `/api/shift-presets/${shiftPreset.id}`,
    HttpMethods.PATCH,
    shiftPreset.toRequestBody(),
    user,
    true,
    true
  )
}

export const deleteShiftPreset = (user, shiftPreset) => {
  return requestWithPromise(
    `/api/shift-presets/${shiftPreset.id}`,
    HttpMethods.DELETE,
    null,
    user,
    true,
    true
  )
}

export const getAllShiftPresets = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/shift-presets${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}
