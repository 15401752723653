import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Checkbox, List, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import SearchPopover from '../Popovers/SearchPopover'
import { ColorsPalette } from '../../../config/colors'
import { isArray, isFunction } from 'lodash'
import { getFullAbbreviation } from '../../../utils/entities/sectionToYear'

const { Item } = List

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const DynamicList = ({ bordered = true, checked, columns = [], data = [], displayFilters, maxHeight = '400', onCheckedChange, t }) => {
  const getKey = useCallback(pathway => `${pathway.id}_${getFullAbbreviation(pathway.sectionToYear)}`, [])

  const [filters, setFilters] = useState({})
  const [checkedValues, setCheckedValues] = useState({})

  useEffect(() => {
    if (isArray(checked)) {
      setCheckedValues(checked.reduce((acc, c) => {
        acc[getKey(c)] = c

        return acc
      }, {}))
    }
  }, [getKey, checked, setCheckedValues])

  const localData = useMemo(() => Object.values(filters).reduce((acc, f) => {
    return acc.filter(d => f.getter(d).toLowerCase().includes(f.value))
  }, data), [filters, data])

  const isAllChecked = useMemo(() => {
    let checked = localData.length > 0

    for (const value of localData) {
      if (!checked) {
        break
      }

      checked = !!checkedValues[getKey(value)]
    }

    return checked
  }, [localData, checkedValues, getKey])

  const updateCheckedValues = useCallback(newCheckedValues => {
    setCheckedValues(newCheckedValues)

    if (isFunction(onCheckedChange)) {
      onCheckedChange(Object.values(newCheckedValues))
    }
  }, [setCheckedValues, onCheckedChange])

  const handleChecked = useCallback((value) => {
    const newCheckedValues = { ...checkedValues }
    const key = getKey(value)

    if (newCheckedValues[key]) {
      delete newCheckedValues[key]
    } else {
      newCheckedValues[key] = value
    }

    updateCheckedValues(newCheckedValues)
  }, [checkedValues, updateCheckedValues, getKey])

  const handleCheckedAll = useCallback(() => {
    const newCheckedValues = { ...checkedValues }

    if (isAllChecked) {
      localData.forEach(d => delete newCheckedValues[getKey(d)])
    } else {
      localData.forEach(d => {
        const key = getKey(d)

        if (!newCheckedValues[key]) {
          newCheckedValues[key] = d
        }
      })
    }

    updateCheckedValues(newCheckedValues)
  }, [isAllChecked, updateCheckedValues, checkedValues, localData, getKey])

  const renderItem = useCallback(d => (
    <Item>
      <Checkbox checked={!!checkedValues[getKey(d)]} onChange={() => handleChecked(d)} />
      <div className='mr-4' />
      {columns.map(c => (
        <div key={c.title} className='w-100 pdr-4 text-ellipsis'>
          {c.tooltip && (
            <Tooltip title={c.tooltip(d)} placement='topLeft'>
              {c.getter(d)}
            </Tooltip>
          )}
          {!c.tooltip && c.getter(d)}
        </div>
      ))}
    </Item>
  ), [columns, localData, handleChecked])

  const updateFilters = useCallback((key, value, getter) => {
    const newFilters = { ...filters }

    newFilters[key] = { value: value.trim().toLowerCase(), getter }

    setFilters(newFilters)
  }, [filters, setFilters])

  return (
    <div className='list-container'>
      <div className='list-title flex-row w-100'>
        <Checkbox checked={isAllChecked} onChange={handleCheckedAll} />
        <div className='mr-4' />
        {columns.map(c => (
          <div key={c.title} className='w-100'>
            <b> {t(c.title)} </b>
            &nbsp;
            <SearchPopover color={filters[c.title]?.value ? ColorsPalette.LIGHT_GREEN : ColorsPalette.FIRST} onSearch={v => updateFilters(c.title, v, c.getter)} />
          </div>
        ))}
      </div>
      <div className='scroll-overflow' style={{ maxHeight: maxHeight + 'px' }}>
        <List
          bordered={bordered}
          dataSource={localData}
          renderItem={renderItem}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(DynamicList)
