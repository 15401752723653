import { camelCasetoKebabCase, snakeCaseToCamelCase } from './string'

/** @enum */
export const HttpStatuses = Object.freeze({
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500
})

export const buildUrlParameters = parameters => {
  const urlParameters = {}

  if (typeof parameters === 'object') {
    Object.keys(parameters).forEach(key => {
      urlParameters[camelCasetoKebabCase(key)] = parameters[key]
    })
  }

  return urlParameters
}

export const retrieveMetadata = meta => {
  const rawMetadata = {}

  if (typeof meta === 'object' && typeof meta.pages === 'object') {
    Object.keys(meta.pages).forEach(key => {
      if (meta.pages[key]) {
        rawMetadata[snakeCaseToCamelCase(key)] = meta.pages[key]
      }
    })
  }

  return new Metadata(rawMetadata)
}

export const getJsonBody = body => {
  return body.getReader().read().then(({ value }) => {
    const decoded = new TextDecoder('utf-8').decode(value)

    return JSON.parse(decoded)
  })
}

export class Metadata {
  constructor ({ page, first, last, next, prev, perPage, pageSize, totalPages, total } = {}) {
    this.page = page ?? 1
    this.first = first ?? 1
    this.last = last ?? 0
    this.next = next ?? 0
    this.prev = prev ?? 0
    this.perPage = perPage ?? 10
    this.pageSize = pageSize ?? perPage ?? 10
    this.totalPages = totalPages ?? 1
    this.total = total ?? 0
  }

  isSame ({ page, perPage, next }) {
    return this.page === page && this.perPage === perPage && this.next === next
  }

  toOldQueryParameters () {
    return {
      page: this.page,
      perPage: this.perPage
    }
  }

  toQueryParameters () {
    return {
      page: this.page,
      pageSize: this.pageSize
    }
  }
}
