import React, { useState, useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import helpFr from '../../config/help/help-fr'
import helpNl from '../../config/help/help-nl'
import { Button, Collapse, Drawer, Tooltip } from 'antd'
import { INTERNEO_FAQ_URL_PART_1, INTERNEO_FAQ_URL_PART_2, LanguageLocales } from '../../utils/constants'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { invalidateTutorial } from '../../utils/api/tutorial'

import '../../assets/faq-link.scss'
import { getLanguageByLocale } from '../../utils'

const Panel = Collapse.Panel

const DEFAULT_FAQ_LINK_LABEL = 'A question? Visit our dedicated FAQ website!'
const FAQ_LINK_LABEL_WITH_AT_LEAST_ONE_VIDEO = 'Another question? Visit our dedicated FAQ website!'

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const Help = ({ activeLanguage, page, user, t }) => {
  const { tutorials, setTutorials } = useContext(GlobalContext)
  const [visible, setVisible] = useState(false)

  let helpFile = helpFr

  if (activeLanguage === LanguageLocales.NL) {
    helpFile = helpNl
  }

  const renderTutoLink = () => {
    const body = []

    Object.keys(tutorials).forEach((key, index) => {
      const tuto = tutorials[key]
      body.push(
        <div key={index}>
          <label
            className='form-check-label tutoName'
            htmlFor='flexSwitchCheckDefault'
          >
            {t(tuto.name)}
          </label>
          <Link to={tuto.link}>
            <Button
              className='btn btn-primary play'
              style={{ float: 'right' }}
              onClick={() => click(tuto)}
              data-bs-toggle='tooltip' data-bs-placement='bottom'
              title={t('Restart the tutorial')}
            >
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          </Link>
        </div>
      )
    })

    return (
      <div>{body}</div>
    )
  }

  const renderHelpContent = () => {
    const helpQuestions = page.key
      ? helpFile.filter(h => h.key === page.key)[0].content
      : []

    const helpContent = helpQuestions.map(h => {
      return (
        <Panel header={h.titleKey} key={h.titleKey}>
          <div style={{ textAlign: 'justify' }}>{h.text}</div>
          <div className='v-spacing' />
          <div className='youtube-iframe-wrapper' style={{ display: typeof h.youtubeEmbed === 'undefined' ? 'none' : 'block' }}>
            <iframe
              title={h.titleKey}
              src={'https://www.youtube.com/embed/' + h.youtubeEmbed}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
              allowfullscreen='true'
            />
          </div>
        </Panel>
      )
    })

    helpContent.push(renderFAQLink(helpQuestions.length))

    return (
      <Collapse bordered={false} accordion destroyInactivePanel>
        {helpContent}
      </Collapse>
    )
  }

  const renderFAQLink = questionsNumbers => {
    return (
      <div className='faq-link' key='faq-link'>
        <h3>
          <a href={`${INTERNEO_FAQ_URL_PART_1}${getLanguageByLocale(activeLanguage)}${INTERNEO_FAQ_URL_PART_2}`} target='_blank' rel='noopener noreferrer'>
            {t(questionsNumbers === 0
              ? DEFAULT_FAQ_LINK_LABEL
              : FAQ_LINK_LABEL_WITH_AT_LEAST_ONE_VIDEO
            )}
          </a>
        </h3>
      </div>
    )
  }

  const click = tutorial => {
    invalidateTutorial(user, tutorial).then(json => {
      if (json?.data) {
        const newTutorials = { ...tutorials }

        newTutorials[tutorial.link] = { ...newTutorials[tutorial.link], userTutorial: json.data }

        setTutorials(newTutorials)
      }
    })

    setVisible(false)
  }

  return (
    <div>
      {page && (
        <>
          <Tooltip placement='top' title={t('Help') + ' (FAQ)'}>
            <Button className='tutoriel-help' type='primary' onClick={() => setVisible(!visible)}>
              <FontAwesomeIcon icon='question-circle' />
            </Button>
          </Tooltip>
          <Drawer
            visible={visible}
            onClose={() => setVisible(false)}
            width='500px'
            title={t('Help about') + ' : ' + page.label}
          >
            {renderTutoLink()}
            {renderHelpContent()}
          </Drawer>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(Help)
