import { requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const INITIAL_STATE = {}

const SET_PAUSE_QUOTA = 'SET_PAUSE_QUOTA'
const SET_PAUSE_QUOTAS = 'SET_PAUSE_QUOTAS'
const REMOVE_PAUSE_QUOTA = 'REMOVE_PAUSE_QUOTA'

const getPauseQuotas = (state) => {
  return state.pauseQuotas
}

const fetchPausesQuotasByInstitution = (institutionId, user) => {
  return async function fetchSectorQuotasThunk (dispatch, getState) {
    requestWithPromise(`/api/institutions/${institutionId}/pause-quotas`,
      HttpMethods.GET,
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch(setPauseQuotas(jsonResponse.data))
      }
    })
  }
}

const createPauseQuota = (pauseQuota, user) => {
  return async function createPauseQuotaThunk (dispatch, getState) {
    requestWithPromise('/api/pause-quotas', HttpMethods.POST,hods.POST, pauseQuota, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch(setPauseQuota(jsonResponse.data))
        }
      })
  }
}

const updatePauseQuota = (pauseQuota, user) => {
  return async function (dispatch, getState) {
    requestWithPromise(`/api/pause-quotas/${pauseQuota.id}`,
      HttpMethods.PATCH,
      pauseQuota,
      user
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch(setPauseQuota(jsonResponse.data))
      }
    })
  }
}

const deletePauseQuota = (pauseQuota, user) => {
  return async function deletePauseQuotaThunk (dispatch, getState) {
    requestWithPromise(`/api/pause-quotas/${pauseQuota.id}`,
      HttpMethods.DELETE,
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch(removePauseQuota(jsonResponse.data))
      }
    })
  }
}

const setPauseQuota = pauseQuota => {
  return {
    type: SET_PAUSE_QUOTA,
    payload: pauseQuota
  }
}

const setPauseQuotas = pauseQuotas => {
  return {
    type: SET_PAUSE_QUOTAS,
    payload: pauseQuotas
  }
}

const removePauseQuota = pauseQuota => {
  return {
    type: REMOVE_PAUSE_QUOTA,
    payload: pauseQuota
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAUSE_QUOTA: {
      const newState = { ...state }
      const pauseQuota = action.payload

      newState[pauseQuota.id] = formatEntityDatesInFrontentFormat(pauseQuota)

      return newState
    }
    case SET_PAUSE_QUOTAS: {
      const newState = { ...state }
      const pauseQuotas = action.payload

      pauseQuotas.forEach(pauseQuota => {
        newState[pauseQuota.id] = formatEntityDatesInFrontentFormat(pauseQuota)
      })

      return newState
    }
    case REMOVE_PAUSE_QUOTA: {
      const newState = { ...state }
      const pauseQuota = action.payload

      delete newState[pauseQuota.id]

      return newState
    }
    default:
      return state
  }
}

const formatEntityDatesInFrontentFormat = entity => {
  entity.startDate = entity.startDate.split('+')[0]
  entity.endDate = entity.endDate.split('+')[0]

  return entity
}

export {
  getPauseQuotas,
  fetchPausesQuotasByInstitution,
  createPauseQuota,
  updatePauseQuota,
  deletePauseQuota
}
