import React, { useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { EUROPEAN_DATE } from '../../../utils/constants'
import IconButton from '../../../Components/antd/Buttons/IconButton'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import EvaluationQuestionsWithAnswers from './EvaluationQuestionsAndAnswers'
import SatisfactionRatio from '../../../Components/shared/SatisfactionRatio'
import moment from 'moment'
import { ColorsPalette } from '../../../config/colors'

import '../../../assets/evaluation-details.scss'

const BOX_STYLE = { border: '1px solid ' + ColorsPalette.SECOND, padding: '10px', margin: '10px 0' }
const RATIO_STYLE = { justifyContent: 'left' }

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const EvaluationCard = ({ evaluation = {}, t }) => {
  const [displayDetails, setDisplayDetails] = useState(false)
  const { internshipId, startDate, endDate, sectorName, sectionName, internshipYearName, studentEvaluationAnswers, institutionEvaluationAnswers, studentEvaluationSatisfactionRatio, studentEvaluationComment, institutionEvaluationSatisfactionRatio, institutionEvaluationComment, individualDetailsDisplayed, evaluationId } = evaluation

  const hasAnswers = studentEvaluationAnswers.length > 0 || institutionEvaluationAnswers.length > 0
  const titles = useMemo(() => [
    { title: t('evaluation_card.titles.internship_id'), body: internshipId },
    { title: t('evaluation_card.titles.period'), body: `${moment(startDate.date).format(EUROPEAN_DATE)} - ${moment(endDate.date).format(EUROPEAN_DATE)}` },
    { title: t('evaluation_card.titles.sector'), body: sectorName },
    { title: t('evaluation_card.titles.section_and_year'), body: `${sectionName} (${internshipYearName})` }
  ], [t, internshipId, startDate, endDate, sectorName, sectionName, internshipYearName])

  return (
    <div className='evaluation-card flex-col' style={BOX_STYLE}>
      <div className='ft-lg fw-b mb-8'>
        {`${moment(startDate.date).format(EUROPEAN_DATE)} - ${moment(endDate.date).format(EUROPEAN_DATE)}`}
      </div>
      <div className='flex-row'>
        {hasAnswers && (
          <IconButton className='borderless' icon={individualDetailsDisplayed ? faChevronUp : faChevronDown} onClick={() => setDisplayDetails(!displayDetails)} />
        )}
        <div className='flex-col w-34' style={{ marginRight: '18px' }}>
          <h3><span>{t('evaluation_card.information_generic')}</span></h3>
          {titles.map((t, index) => (
            <div className='flex-row' key={t.title}>
              <span className='fw-b mw-34'>{t.title}</span>
              <div>{t.body}</div>
            </div>
          ))}
        </div>
        <div className='student-results w-33'>
          <h3><span>{t('evaluation_details.student_results')}</span></h3>
          <label className='fw-b'>{t('evaluation_card.student_results.score')}</label>
          <SatisfactionRatio ratio={studentEvaluationSatisfactionRatio} style={RATIO_STYLE} />
          <label className='fw-b'>{t('evaluation_card.student_results.comment')}</label>
          <div>
            {studentEvaluationComment && studentEvaluationComment.length ? studentEvaluationComment : t('None')}
          </div>
        </div>
        <div className='care-unit-results w-33'>
          <h3><span>{t('evaluation_details.care_unit_results')}</span></h3>
          <label className='fw-b'>{t('evaluation_card.care_unit_results.score')}</label>
          <SatisfactionRatio ratio={institutionEvaluationSatisfactionRatio} style={RATIO_STYLE} />
          <label className='fw-b'>{t('evaluation_card.care_unit_results.comment')}</label>
          <div>
            {institutionEvaluationComment && institutionEvaluationComment.length ? institutionEvaluationComment : t('None')}
          </div>
        </div>
      </div>
      {displayDetails && (
        <EvaluationQuestionsWithAnswers
          evaluationId={evaluationId}
          studentEvaluationAnswers={studentEvaluationAnswers}
          institutionEvaluationAnswers={institutionEvaluationAnswers}
          expanded
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(EvaluationCard)
