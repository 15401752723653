import {
  faEnvelope, faPeopleArrows, faSmile, faTags, faStethoscope, faGraduationCap, faCubes, faIdBadge, faClock, faClinicMedical, faInfoCircle, faSchool,
  faCalendar, faUserGraduate, faCogs, faChartPie
} from '@fortawesome/pro-solid-svg-icons'
import { recursiveIncludes } from '.'
import { InstitutionOptions, SupportedRoles } from './constants'
import { Routes } from '../routes'

const {
  COORDINATOR, ENCODER, HOSPITAL_ADMIN, INSTITUTION_GROUP_ADMIN, SUPERVISOR, VALIDATOR, DOMAIN_EVALUATOR
} = SupportedRoles

const {
  BADGES, CHANGELOG_MANAGEMENT, CONTACT_PERSONS, INSTITUTIONS, INSTITUTION_SECTORS, INTERNSHIPS, MAILS_LIST, PROFILE_PAGE, QUOTAS, SCHOOLS, SCHOOL_SECTION,
  SETTINGS, SHIFTS_MANAGER, STUDENTS, STUDENTS_EVALUATION, TAGS_MANAGEMENT
} = Routes

export const getAdminMenu = t => {
  return [
    {
      iconName: faClinicMedical,
      label: t('Internships institutions'),
      admin: false,
      route: INSTITUTIONS
    },
    {
      iconName: faSchool,
      label: t('Schools'),
      admin: false,
      route: SCHOOLS
    },
    {
      iconName: faEnvelope,
      label: t('Mails list'),
      admin: false,
      route: MAILS_LIST
    },
    {
      iconName: faTags,
      label: t('Tags management'),
      route: TAGS_MANAGEMENT
    },
    {
      iconName: faInfoCircle,
      label: t('Changelog management'),
      route: CHANGELOG_MANAGEMENT
    }
  ]
}

export const getSchoolMenu = (t, roles) => {
  const menu = [
    {
      iconName: faCalendar,
      label: t('Internships'),
      admin: false,
      route: INTERNSHIPS,
      key: 'SCHOOL_INTERNSHIPS'
    },
    {
      iconName: faUserGraduate,
      label: t('My students'),
      admin: false,
      route: STUDENTS,
      key: 'SCHOOL_STUDENTS'
    }
  ]

  if (!recursiveIncludes(roles, [SUPERVISOR, DOMAIN_EVALUATOR])) {
    menu.push({
      iconName: faGraduationCap,
      label: t('Sections and school years'),
      admin: false,
      route: SCHOOL_SECTION
    })
  }

  if (!roles.includes(DOMAIN_EVALUATOR)) {
    menu.push({
      iconName: faClinicMedical,
      label: t('Internships institutions'),
      admin: false,
      route: INSTITUTIONS
    })
  }

  if (recursiveIncludes(roles, [ENCODER, COORDINATOR])) {
    menu.push({
      iconName: faPeopleArrows,
      label: t('common.contact_persons'),
      admin: false,
      route: CONTACT_PERSONS
    })
    menu.push({
      iconName: faCogs,
      label: t('Settings'),
      admin: true,
      route: SETTINGS
    })
  }

  if(!roles.includes(DOMAIN_EVALUATOR)) {
    menu.push(
      {
        iconName: faTags,
        label: t('Tags management'),
        route: TAGS_MANAGEMENT
      }
    )
  }

  return menu
}

export const getInstitutionMenu = (t, roles, institution, institutionOptionsType, institutionOptions, setState) => {
  if (roles.includes(VALIDATOR)) {
    return [
      {
        iconName: faCalendar,
        label: t('Internships'),
        admin: false,
        route: INTERNSHIPS,
        key: 'INSTITUTION_INTERNSHIPS'
      },
      {
        iconName: faClock,
        label: t('Shifts Validation'),
        admin: false,
        route: SHIFTS_MANAGER
      },
      {
        iconName: faStethoscope,
        label: t('Care units'),
        admin: false,
        route: INSTITUTION_SECTORS
      }
    ]
  }

  const isAdmin = recursiveIncludes(roles, [HOSPITAL_ADMIN, INSTITUTION_GROUP_ADMIN])
  const menu = [
    {
      iconName: faCalendar,
      label: t('Internships'),
      admin: false,
      route: INTERNSHIPS,
      key: 'INSTITUTION_INTERNSHIPS'
    }
  ]

  if (!roles.includes(INSTITUTION_GROUP_ADMIN)) {
    menu.push({
      iconName: faClock,
      label: t('Shifts Manager'),
      admin: true,
      route: SHIFTS_MANAGER
    })
    /** @TODO 2024-01-22 move this outside de condition when Students view will be developed */
    menu.push({
      iconName: faUserGraduate,
      label: t('Students'),
      admin: false,
      route: STUDENTS,
      key: 'INSTITUTION_STUDENTS'
    })
  }

  if (recursiveIncludes(roles, [HOSPITAL_ADMIN, INSTITUTION_GROUP_ADMIN]) && institutionOptions) {
    const attractivityModuleOption = institutionOptions.find(io => io.optionType.type === 'recruitment_module')

    if (attractivityModuleOption && attractivityModuleOption.optionEnabled) {
      menu.push({
        iconName: faSmile,
        label: t('Student feedback'),
        admin: false,
        route: STUDENTS_EVALUATION
      })
    }
  }

  menu.push({
    iconName: faSchool,
    label: t('Schools'),
    admin: false,
    route: SCHOOLS
  })

  if (roles.includes(INSTITUTION_GROUP_ADMIN)) {
    menu.push({
      iconName: faClinicMedical,
      label: t('Institutions'),
      admin: true,
      route: INSTITUTIONS
    })
  }

  menu.push({
    iconName: faStethoscope,
    label: t('Care units'),
    admin: false,
    route: INSTITUTION_SECTORS
  })

  /** @TODO 2024-01-22 Remove this condition when view is developed for INSTITUTION_GROUP_ADMIN users */
  if (!roles.includes(INSTITUTION_GROUP_ADMIN)) {
    menu.push({
      iconName: faPeopleArrows,
      label: t('common.contact_persons'),
      admin: false,
      route: CONTACT_PERSONS
    })
  }

  if (isAdmin) {
    /** @TODO 2024-01-22 Remove this condition when view is developed for INSTITUTION_GROUP_ADMIN users */
    if (!roles.includes(INSTITUTION_GROUP_ADMIN)) {
      menu.push({
        iconName: faChartPie,
        label: t('Quotas and places'),
        admin: true,
        route: QUOTAS
      })
    }

    menu.push({
      iconName: faIdBadge,
      label: t('Badges management'),
      admin: true,
      route: BADGES
    })

    if (institutionOptions) {
      const moduleSettingsOption = institutionOptions.find(io => io.optionType.type === InstitutionOptions.MODULE_SETTINGS)

      if (moduleSettingsOption && moduleSettingsOption.optionEnabled) {
        setState({ moduleOption: moduleSettingsOption })
        menu.push({
          iconName: faCubes,
          label: t('Modules settings'),
          admin: false,
          route: Routes.MODULES_SETTINGS
        })
      }
    }

    menu.push({
      iconName: faCogs,
      label: t('Settings'),
      admin: true,
      route: SETTINGS
    })
  }

  if (!roles.includes(INSTITUTION_GROUP_ADMIN)) {
    menu.push({
      iconName: faTags,
      label: t('Tags management'),
      route: TAGS_MANAGEMENT
    })
  }

  return menu
}

export const getStudentMenu = t => {
  return [
    {
      iconName: faCalendar,
      label: t('My internships'),
      admin: false,
      route: INTERNSHIPS
    },
    {
      iconName: faIdBadge,
      label: t('My profile'),
      admin: false,
      route: PROFILE_PAGE
    }
  ]
}

export const getObserversMenu = t => {
  return [
    {
      iconName: faCalendar,
      label: t('Internships'),
      admin: false,
      route: INTERNSHIPS,
      key: 'INSTITUTION_INTERNSHIPS'
    },
    {
      iconName: faClock,
      label: t('Shifts Manager'),
      admin: false,
      route: SHIFTS_MANAGER
    },
    {
      iconName: faUserGraduate,
      label: t('Students'),
      admin: false,
      route: STUDENTS,
      key: 'INSTITUTION_STUDENTS'
    },
    {
      iconName: faStethoscope,
      label: t('Care units'),
      admin: false,
      route: INSTITUTION_SECTORS
    },
    {
      iconName: faCogs,
      label: t('Settings'),
      admin: false,
      route: SETTINGS
    }
  ]
}
