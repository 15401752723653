import React, { useState, useEffect } from 'react'

import { Drawer } from 'antd'
import SmartTable, { SmartTableDataTypes } from '../../../Components/shared/SmartTable'
import EvaluationDetails from './EvaluationDetails'
import EvaluationsExportButton from '../../../Components/shared/EvaluationsExportButton'
import SmartTableCustomExportButton from '../../../Components/shared/SmartTableCustomExportButton'
import { applyFilters } from './SmileyFilterHelper'
import moment from 'moment'
import { getSatisfactionRatioFilters } from '../../../reducers/SatisfactionRatioFilterReducer'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isLocalAdmin } from '../../../Selectors/localAdministrator/localAdministrator'
import { getAllEvaluations } from '../../../utils/api/evaluationInternship'
import StudentInfo from '../../../Components/shared/StudentInfo'
import { getStudentFullname } from '../../../utils/entities/internship'

const mapStateToProps = state => ({
  isLocalAdmin: isLocalAdmin(state.getUser),
  smileyFilters: getSatisfactionRatioFilters(state),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InstitutionEvaluationSummaryView = ({ isLocalAdmin, smileyFilters, user, t }) => {
  const [loading, setLoading] = useState(true)
  const [evaluatedStudents, setEvaluatedStudents] = useState([])
  const [evaluatedStudentsNonFiltered, setEvaluatedStudentsNonFiltered] = useState([])
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [displayStudentInfo, setDisplayStudentInfo] = useState(null)

  useEffect(() => { loadEvaluations() }, [])
  useEffect(() => {
    if (smileyFilters) {
      setEvaluatedStudents(applyFilters(smileyFilters, evaluatedStudentsNonFiltered))
    }
  }, [evaluatedStudentsNonFiltered, smileyFilters])

  const loadEvaluations = async () => {
    getAllEvaluations(user).then(json => {
      if (json && json.data) {
        setEvaluatedStudentsNonFiltered(
          json.data.map(ev => {
            return {
              didInstitutionEvaluate: ev.didInstitutionEvaluate,
              didStudentEvaluate: ev.didStudentEvaluate,
              evaluationDate: moment(ev.createdAt.split('+')[0]).format('DD/MM/YYYY'),
              studentName: `${getStudentFullname(ev.internship)}`.trim(),
              id: ev.id,
              institutionEvaluationSatisfactionRatio: ev.institutionEvaluationSatisfactionRatio,
              matchScore: ev.matchScore,
              sectionName: ev.internship.internshipYear.sectionAbbreviation === 'NONE'
                ? `${!ev.internship.section.comment ? ev.internship.section.name : ev.internship.section.comment} (${ev.internship.internshipYear.schoolYearName})`
                : `${ev.internship.internshipYear.sectionAbbreviation} (${ev.internship.internshipYear.schoolYearName})`,
              positiveMatchEmailingStatus: ev.positiveMatchEmailingStatus,
              school: ev.internship.school.name,
              sectorName: ev.internship.sector.name,
              studentEvaluationSatisfactionRatio: ev.studentEvaluationSatisfactionRatio,
              studentId: ev.internship.student?.id
            }
          })
        )
      }

      setLoading(false)
    })
  }

  /**
   * This method generates a dynamic tooltip for the matching column.
   * We use it to show if we wait for evaluations from institution of student
   */
  const generateMatchingTooltip = (data) => {
    if (!data.didInstitutionEvaluate && !data.didStudentEvaluate) {
      return t('Not evaluated yet')
    } else if (!data.didStudentEvaluate) {
      return t('Waiting for student evaluation')
    } else if (!data.didInstitutionEvaluate) {
      return t('Waiting for institution evaluation')
    }
    return null
  }

  const generateRecruitmentEmailTooltip = (data) => {
    if (data.positiveMatchEmailingStatus === 'NOT_ALLOWED') {
      return t('NO_RECRUITMENT_EMAILING')
    } else if (data.positiveMatchEmailingStatus === 'NONE') {
      return t('RECRUITMENT_EMAILING_NONE')
    } else if (data.positiveMatchEmailingStatus === 'ONLY_EARLY') {
      return t('RECRUITMENT_EMAILING_EARLY')
    } else if (data.positiveMatchEmailingStatus === 'ONLY_LATE') {
      return t('RECRUITMENT_EMAILING_LATE')
    } else if (data.positiveMatchEmailingStatus === 'BOTH') {
      return t('RECRUITMENT_EMAILING_BOTH')
    } else if (!data.positiveMatchEmailingStatus) {
      return t('NO_DATA')
    }
    return null
  }

  const columns = [
    { type: SmartTableDataTypes.ID, key: 'id' },
    { type: SmartTableDataTypes.STRING, name: t('Student name'), key: 'studentName' },
    { type: SmartTableDataTypes.STRING, name: t('Section(s)'), key: 'sectionName' },
    { type: SmartTableDataTypes.STRING, name: t('common.school'), key: 'school' },
    { type: SmartTableDataTypes.STRING, name: t('common.sector'), key: 'sectorName' },
    { type: SmartTableDataTypes.DATE, name: t('Evaluation date'), key: 'evaluationDate', format: 'DD/MM/YYYY' },
    { type: SmartTableDataTypes.SATISFACTION_RATIO, name: t('Obtained by the student'), key: 'studentEvaluationSatisfactionRatio', enableFiltering: true },
    { type: SmartTableDataTypes.SATISFACTION_RATIO, name: t('Obtained by the care unit'), key: 'institutionEvaluationSatisfactionRatio', enableFiltering: true },
    { type: SmartTableDataTypes.SATISFACTION_RATIO, name: t('Match'), key: 'matchScore', customTooltipGenerator: generateMatchingTooltip, enableFiltering: true },
    { type: SmartTableDataTypes.CHECK, name: t('Feedback mails'), key: 'positiveMatchEmailingStatus', customTooltipGenerator: generateRecruitmentEmailTooltip }
  ]

  const showDetails = (row) => {
    setSelectedStudent(row)
  }

  const handleDrawerClosing = () => {
    setSelectedStudent(null)
  }

  return (
    <div className='institution-evaluation-summary-wrapper' style={{ height: '80vh' }}>
      {loading && <div className='loading-ring' />}
      {!loading &&
        <SmartTable
          columns={columns}
          data={evaluatedStudents}
          defaultSortBy='evaluationDate'
          defaultSortDirection={-1}
          noDataText={t('No student evaluations available')}
          keepTableDisplayedWhenNoData
          customExportButton={
            <SmartTableCustomExportButton
              path='evaluations'
            />
          }
          ExportButtonWithRangePicker={
            <EvaluationsExportButton />
          }
          additionalActions={[
            { iconName: 'user-graduate', type: 'primary', title: t('Student sheet'), onClick: ev => setDisplayStudentInfo({ id: ev.studentId }) },
            { iconName: 'file', type: 'primary', title: t('Evaluation details'), onClick: showDetails }
          ]}
        />}
      <Drawer
        title={t('Evaluation details')}
        className='evaluation-details-drawer'
        width='50%'
        onClose={handleDrawerClosing}
        visible={selectedStudent !== null}
      >
        <EvaluationDetails {...selectedStudent} />
      </Drawer>
      <StudentInfo
        student={displayStudentInfo}
        onClose={() => setDisplayStudentInfo(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionEvaluationSummaryView)
