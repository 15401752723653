import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, notification, Tooltip } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const FormItem = Form.Item

class NormalPasswordResetAskForm extends React.Component {
  state = {
    loading: false
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        request('/user/forgot-password', HttpMethods.POST, { email: values.email }, null, { catchError: false })
          .then((json) => {
            this.setState({ loading: false })

            if (json.status === 'error') {
              notification.error({ message: this.props.t('This email address does not exist !'), placement: 'bottomRight' })
              return
            }

            notification.success({ message: this.props.t('We sent you an email containing your password reset link.'), placement: 'bottomRight' })
            this.props.onBack()
          })
          .catch((error) => {})
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.t('What is your email address ? We will send you an email containing a link to reset your password.')}
        <FormItem style={{ marginBottom: '10px' }}>
          {getFieldDecorator('email')(
            <Input prefix={<FontAwesomeIcon icon='at' />} placeholder={this.props.t('Email address')} />
          )}
        </FormItem>
        <div className='flex-row'>
          <Tooltip placement='top' title={this.props.t('Back')}>
            <Button type='default' onClick={this.props.onBack}>
              <FontAwesomeIcon icon='arrow-left' />
            </Button>
          </Tooltip>
          <div className='h-spacing' />
          <Button type='primary' htmlType='submit' className='full-width' loading={this.state.loading}>
            <FontAwesomeIcon icon='check' />&nbsp;{this.props.t('Confirm my email address')}
          </Button>
        </div>
      </Form>
    )
  }
}

const PasswordResetAskForm = Form.create()(NormalPasswordResetAskForm)
export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetAskForm)
