import { requestWithPromise } from '..'
import {HttpMethods, makeQueryString } from '../apiHelper'

export const getAllAreas = (user) => {
  return requestWithPromise(
    '/api/areas',
    HttpMethods.GET,
    null,
    user
  )
}
