import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { InternshipStates } from '../../../../utils/constants'
import StatesSelector from './StatesSelector'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { isEncoder, isSupervisor } from '../../../../utils/roles'

const {
  ACCEPTED, AUTO_ACCEPTED, ACCEPTED_UNMANAGED, CANCELED, CREATED,
  PENDING, PENDING_NO_SECTOR, PENDING_STUDENT_DATA_MISSING, PRE_ACCEPTED, REFUSED,
  SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED
} = InternshipStates
const DEFAULT_PARAMETERS = {
  disabled: true,
  states: [
    { title: '', state: PENDING_NO_SECTOR },
    { title: '', state: CREATED }
  ]
}
const MANAGED_DISABLE_STATES = [
  SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE
]
const UNMANAGED_ENABLE_STATES = [
  PENDING, CANCELED, ACCEPTED_UNMANAGED, ACCEPTED,
  SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE
]
const DISABLE_PENDING_INTERNSHIP = [
  PENDING_STUDENT_DATA_MISSING, PENDING_NO_SECTOR, CREATED, REFUSED
]
const ACCEPTED_STATES = [
  ACCEPTED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE,
  ACCEPTED_UNMANAGED, AUTO_ACCEPTED
]

const mapStateToProps = state => ({
  user: getUser(state.getUser)
})

const SchoolStatesSelector = ({ internship, user, onChange }) => {
  const { isReadOnly } = useContext(GlobalContext)

  const [disabled, setDisabled] = useState(true)
  const [states, setStates] = useState([])

  useEffect(() => {
    if (user && internship) {
      const parameters = getParametersByInternshipAndUser(internship, user)

      if (isReadOnly || isSupervisor(user)) {
        parameters.disabled = true
      }

      setDisabled(parameters.disabled)
      setStates(parameters.states)
    }
  }, [user, internship])

  const getParametersByInternshipAndUser = (internship, user) => {
    const parameters = { ...DEFAULT_PARAMETERS }

    if (internship.state === SCHEDULE_VALIDATED) {
      parameters.disabled = true
      parameters.states = [
        { title: 'Accept', state: SCHEDULE_VALIDATED }
      ]
    } else if (internship.institution && internship.institution.managed) {
      getManagedStates(parameters, internship, user)
    } else if (UNMANAGED_ENABLE_STATES.includes(internship.state)) {
      parameters.disabled = false
      parameters.states = [
        { title: 'Put on hold', state: PENDING },
        { title: 'Cancel', state: CANCELED }
      ]

      if (internship.state !== CANCELED) {
        parameters.states.push({ title: 'Accept', state: internship.state <= ACCEPTED ? ACCEPTED : internship.state })
      }
    }

    return parameters
  }

  const getManagedStates = (parameters, internship, user) => {
    if (ACCEPTED_STATES.includes(internship.state)) {
      parameters.disabled = MANAGED_DISABLE_STATES.includes(internship.state)
      parameters.states = [
        { title: 'Accept', state: internship.state },
        { title: 'Put on hold', state: PENDING },
        { title: 'Cancel', state: CANCELED }
      ]
    } else if (internship.state === CANCELED || internship.state === PENDING) {
      parameters.disabled = false
      parameters.states = [
        { title: 'Put on hold', state: PENDING },
        { title: 'Cancel', state: CANCELED }
      ]
    } else if (internship.state === PRE_ACCEPTED) {
      parameters.disabled = !isEncoder(user)
      parameters.states = [
        { title: 'Pre-accepted', state: PRE_ACCEPTED },
        { title: 'Create', state: CREATED }
      ]
    } else if (
      !isSupervisor(user) &&
      (
        !internship.internshipYear ||
        DISABLE_PENDING_INTERNSHIP.includes(internship.state)
      )
    ) {
      // In these cases, the school cannot change the state.
      // The selector is disabled and there is no need for a title.
      // See InternshipStatePellet for the state's list.
      parameters.disabled = true
      parameters.states = [
        { title: '', state: PENDING_NO_SECTOR },
        { title: '', state: CREATED },
        { title: '', state: PENDING },
        { title: '', state: REFUSED },
        { title: '', state: PENDING_STUDENT_DATA_MISSING }
      ]
    }
  }

  return (
    <StatesSelector
      disabled={disabled}
      internship={internship}
      states={states}
      onChange={onChange}
      showLabel={false}
    />
  )
}

export default connect(mapStateToProps)(SchoolStatesSelector)
