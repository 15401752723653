import { isArray } from 'lodash'
import { isSupportedLanguage, isSupportedRoles, recursiveIncludes, validateString } from '..'
import { getTimezoneLessMoment } from '../momentjs'
import { EUROPEAN_DATE, InstitutionRoles } from '../constants'
import { ValidationFields } from '../validators'

export const USER_VALIDATORS = [
  { key: ValidationFields.EMAIL, validator: u => validateString(u.email, { notEmpty: true, isEmail: true }) },
  { key: ValidationFields.USERNAME, validator: u => validateString(u.username, { notEmpty: true, isEmail: true }) },
  { key: ValidationFields.LASTNAME, validator: u => validateString(u.lastname, { notEmpty: true }) },
  { key: ValidationFields.FIRSTNAME, validator: u => validateString(u.firstname, { notEmpty: true }) },
  { key: ValidationFields.LANGUAGE, validator: u => isSupportedLanguage(u.language) },
  { key: ValidationFields.ROLES, validator: u => isSupportedRoles(u.roles ? u.roles[0] : null) },
  { key: ValidationFields.INSTITUTIONS, validator: u => !isSupportedRoles(u.roles ? u.roles[0] : null).valid || !recursiveIncludes([InstitutionRoles.HOSPITAL_ADMIN, InstitutionRoles.NURSE], u.roles) || u.institutions.length > 0 }
]

export const getEmail = user => {
  return user.email
}

export const getFirstname = user => {
  return user.firstname
}

export const getInstitutionsName = user => {
  let name = '/'

  if (isArray(user.institutions)) {
    name = user.institutions.map(i => i.name).join(', ')
  }

  return name
}

export const getLanguage = user => {
  return user.language
}

export const getLastConnection = user => {
  return user.lastLogin ? getTimezoneLessMoment(user.lastLogin).format(EUROPEAN_DATE) : ''
}

export const getLastname = user => {
  return user.lastname
}

export const getRole = user => {
  return isArray(user.roles) ? user.roles[0] : null
}

export const getUsername = user => {
  return user.username
}

export const getFullNameOrNull = user => {
  return user?.lastname && user?.firstname ? getFullName(user) : null
}

export const getFullName = user => `${user.lastname} ${user.firstname}`

export class User {
  constructor ({ id, email, firstname, institutionGroup, institutions, language, lastname, roles, username } = {}) {
    this.id = id ?? -1
    this.email = email ?? ''
    this.firstname = firstname ?? ''
    this.institutionGroup = institutionGroup ?? null
    this.institutions = institutions ?? []
    this.language = language ?? ''
    this.lastname = lastname ?? ''
    this.roles = roles ?? []
    this.username = username ?? ''
  }

  toRequestBody = () => {
    return {
      id: this.id,
      email: this.email,
      firstname: this.firstname,
      language: this.language,
      lastname: this.lastname,
      roles: this.roles,
      username: this.username,
      institutionGroup: this.institutionGroup?.id ?? null,
      institution: this.institutions.length > 0 ? this.institutions[0].id : null
    }
  }
}
