import { isObject, validateString } from '..'
import { ValidationFields } from '../validators'

export const INSTITUTION_VALIDATORS = [
  { key: ValidationFields.ACRONYM, validator: ig => validateString(ig.acronym, { notEmpty: true }) },
  { key: ValidationFields.COUNTRY, validator: ig => isObject(ig.country) },
  { key: ValidationFields.NAME, validator: ig => validateString(ig.name, { notEmpty: true }) }
]

export const getName = i => {
  return i.name
}

export const getAcronym = i => {
  return i.acronym
}

export const getAddress = i => {
  return i.address
}

export const getCity = i => {
  return i.city
}

export const getCountry = i => {
  return isObject(i.country) ? i.country.name : null
}

export const getDescription = i => {
  return i.description
}

export const getGlobalMessage = i => {
  return i.globalMessage
}

export const getZipcode = i => {
  return i.zipcode
}

export class Institution {
  constructor ({ id, acronym, address, city, country, description, globalMessage = null, managed, name, zipcode } = {}) {
    this.id = id ?? -1
    this.acronym = acronym ?? ''
    this.address = address ?? ''
    this.city = city ?? ''
    this.country = country ?? null
    this.description = description ?? ''
    this.globalMessage = globalMessage
    this.managed = managed ?? false
    this.name = name ?? ''
    this.zipcode = zipcode ?? ''
  }

  toRequestBody = () => {
    const body = { ...this }

    body.country = this.country === null ? null : this.country.code

    return body
  }
}
