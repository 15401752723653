import { fetch } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'
import { OrderDirections } from '../constants'

export const sendInternshipMessage = (user, { internship, content }) => {
  return fetch(
    '/internship-message/send',
    HttpMethods.POST,
    { internship, content },
    user
  )
}

export const getInternshipsMessages = (user, currentUser, params = { page: 1, pageSize: 50, unread: false, orderDirection: OrderDirections.DESC }) => {
  return fetch(
    `/api/users/${currentUser.id}/internship-messages${makeQueryString(params)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInternshipMessages = (user, internship) => {
  return fetch(
    `/internship-message/consult/${internship.id}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const readInternshipMessages = (user, internship) => {
  return fetch(
    '/internship-message/read',
    HttpMethods.POST,
    { internship: internship.id },
    user
  )
}

export const unreadInternshipMessage = (user, messageId) => {
  return fetch(
    '/internship-message/unread',
    HttpMethods.POST,
    { messageId },
    user
  )
}

export const getInternshipsMessagesCount = (user, params = { ids: [] }) => {
  return fetch(
    `/internships/internship-messages${makeQueryString(params)}`,
    HttpMethods.GET,
    null,
    user
  )
}
