import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarTimes } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = (state) => ({
  t: getTranslate(state.locale)
})

const DateTrimmer = ({ toggled, onToggle, t }) => {
  const displayValues = useMemo(() => toggled ? { icon: faCalendarTimes, label: 'misc.excluded' } : { icon: faCalendar, label: 'misc.included' }, [toggled])

  return (
    <div className='flex-row'>
      <Button onClick={() => onToggle(!toggled)}>
        <FontAwesomeIcon icon={displayValues.icon} />
      </Button>
      <span className='ml-8 vertical-center' style={{ color: 'gray' }}>
        {t(displayValues.label)}
      </span>
    </div>
  )
}

export default connect(mapStateToProps)(DateTrimmer)
