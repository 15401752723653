import React, { useCallback, useContext, useEffect, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import SectorFormModal from '../../../HOC/Forms/SectorFormModal'
import SwitchSectorInstitutionModal from '../../../HOC/SwitchSectorInstitutionModal'
import SectorsTable from '../InstitutionsView/SectorsTable'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { archiveSector, getAllSectors } from '../../../utils/api/sector'
import { onSuccess } from '../../../utils/apiHelper'
import { TableActions } from '../../../Components/shared/DataTable/utils/actions'
import { OrderBy } from '../../../utils/constants'
import { debounce } from 'lodash'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SectorsView = ({ user, t }) => {
  const { institutions } = useContext(InstitutionGroupContext)

  const [sectors, setSectors] = useState(DEFAULT_DATA)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: OrderBy.NAME, pageSize: 20 }))
  const [loading, setLoading] = useState(true)

  const fetchSectors = useCallback((user, tableParameters) => {
    setLoading(true)

    getAllSectors(user, { ...tableParameters, contexts: ['institution'], filters: { archived: false } }).then(json => {
      if (json?.data || json?.meta) {
        const sectors = {}

        sectors.data = json?.data ?? []
        sectors.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setSectors(sectors)
      }

      setLoading(false)
    })
  }, [getAllSectors, setLoading, setSectors])

  const handleArchiveSector = useCallback((sector, archive) => {
    archiveSector(user, sector, archive).then(json => {
      if (json?.data) {
        onSuccess(t('institution_sector_drawer.archive.success'))
        fetchSectors(user, tableParameters)
      }
    })
  }, [user, tableParameters, onSuccess, archiveSector, fetchSectors, t])

  const handleActionTrigger = useCallback((sector, action) => {
    if (action === TableActions.ARCHIVE_SECTOR) {
      handleArchiveSector(sector, true)
    } else if (action === TableActions.UNARCHIVE_SECTOR) {
      handleArchiveSector(sector, false)
    } else if (action === TableActions.DELETE) {
      // block the action bubulling
    }
  }, [handleArchiveSector])

  useEffect(() => {
    const fetch = debounce(() => fetchSectors(user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user.id, tableParameters, fetchSectors])

  return (
    <SectorFormModal institutions={institutions} useSelector onTriggerAction={handleActionTrigger} onSave={() => fetchSectors(user, tableParameters)}>
      <SwitchSectorInstitutionModal onSave={() => fetchSectors(user, tableParameters)}>
        <SectorsTable
          data={sectors.data}
          loading={loading}
          metadata={sectors.meta}
          parameters={tableParameters}
          onParametersChange={setTableParameters}
        />
      </SwitchSectorInstitutionModal>
    </SectorFormModal>
  )
}

export default connect(mapStateToProps)(SectorsView)
