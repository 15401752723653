import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Card } from 'antd'

import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import { BOOLEAN_FIELD, STRING_FIELD } from '../institution/InstitutionOptionTable'

import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput } from '../../utils'
import { editSchool, fetchSchoolOptions, switchSchoolOption } from '../../utils/api/school'
import SchoolOptionTable from './schoolOptionTable'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { SchoolContext } from '../../Providers/SchoolProvider'

const CARD_OPTIONAL_PARAMETERS = 'Optional parameters'

const School = props => {
  const { countries } = useContext(GlobalContext)
  const { reloadSchoolOptions } = useContext(SchoolContext)
  const { areas } = useContext(GlobalContext)

  const [schools, setSchools] = useState([])
  const [localCountries, setLocalCountries] = useState([])
  const [schoolOptions, setSchoolOptions] = useState([])

  useEffect(() => {
    if (props.getSchools && props.getSchools.length > 0) {
      const schoolsMap = props.getSchools.map(school => {
        return {
          id: school.id,
          name: school.name,
          email: school.email,
          address: school.address,
          zipcode: school.zipcode,
          country: school.country !== null ? school.country.id : null,
          city: school.city,
          area: school.area !== null ? school.area.id : null
        }
      })

      setSchools(schoolsMap)
    }
  }, [props.getSchools])

  const areaActions = useMemo(() => {
    return areas.map(area => ({
      id: area.id,
      name: area.localName
    }))
  }, [areas])

  useEffect(() => {
    setLocalCountries(countries.map(c => {
      return { id: c.id, name: props.t(c.name) }
    }))
  }, [countries, props.t])

  useEffect(() => {
    if (schools.length > 0) {
      fetchSchoolOptions(schools[0], props.getUser).then(response => setSchoolOptions(response.data))
    }
  }, [schools])

  const handleEditSchool = school => editSchool(school, props.getUser).then(response => props.updateSchool(school))

  const handleSwitchSchoolOption = (school, body) => switchSchoolOption(school, body, props.getUser).then(response => {
    fetchSchoolOptions(school, props.getUser).then(response => setSchoolOptions(response.data))
    reloadSchoolOptions(props.getUser)
  })

  const columns = [
    { type: SmartTableDataTypes.ID, key: 'id' },
    { type: SmartTableDataTypes.STRING, name: props.t('Name'), key: 'name', validate: (schools) => validateFormInput('freeText', schools, true) },
    { type: SmartTableDataTypes.STRING, name: 'Email', key: 'email', validate: (schools) => validateFormInput('freeText', schools, true) },
    { type: SmartTableDataTypes.STRING, name: props.t('Address'), key: 'address', validate: (schools) => validateFormInput('freeText', schools, true) },
    { type: SmartTableDataTypes.STRING, name: props.t('Zipcode'), key: 'zipcode', validate: (schools) => validateFormInput('freeText', schools, true) },
    { type: SmartTableDataTypes.STRING, name: props.t('City'), key: 'city', validate: (schools) => validateFormInput('name', schools, true) },
    { type: SmartTableDataTypes.SELECT, name: props.t('Country'), key: 'country', options: localCountries },
    { type: SmartTableDataTypes.SELECT, name: props.t('Area'), key: 'area', options: areaActions }
  ]

  const columnsOptions = [
    { type: STRING_FIELD, name: 'Name' },
    { type: BOOLEAN_FIELD, name: 'Enable' }
  ]

  return (
    <>
      <SmartTable
        hideTableControls
        columns={columns}
        data={schools}
        loading={!props.getDataReady.institutions}
        onDataEdit={handleEditSchool}
      />

      <div className='school-options'>
        <Card title={props.t(CARD_OPTIONAL_PARAMETERS)}>
          <SchoolOptionTable
            columns={columnsOptions}
            schoolOptions={schoolOptions}
            switchSchoolOption={handleSwitchSchoolOption}
          />
        </Card>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(School)
