import React, { useContext, useMemo } from 'react'

import { isObject } from '../../../../utils'
import { InternshipStates } from '../../../../utils/constants'
import { InternshipFormModalContext } from '../../../../Providers/Forms/InternshipForm/InternshipFormModal'
import { Internship } from '../../../../utils/entities/internship'
import InternshipCard from './InternshipCard'

const {
  PENDING_STUDENT_DATA_MISSING,
  PENDING_NO_SECTOR,
  CREATED,
  PENDING,
  ACCEPTED,
  AUTO_ACCEPTED
} = InternshipStates
const VALID_STATES = [PENDING_STUDENT_DATA_MISSING, PENDING_NO_SECTOR, CREATED, PENDING, ACCEPTED, AUTO_ACCEPTED]

const OwnedInternshipCard = ({ internship, refresh }) => {
  const { setData } = useContext(InternshipFormModalContext)

  const isClickable = useMemo(() => isObject(internship) && VALID_STATES.includes(internship.state), [internship])

  const className = useMemo(() => {
    let className = 'bdr-rds-8'

    if (isClickable) {
      className += ' cu-ptr'
    } else {
      className += ' cu-nt-alwd'
    }

    return className
  }, [isClickable])

  const handleClick = () => {
    if (isClickable) {
      setData({ object: new Internship(internship), callback: refresh })
    }
  }

  return (
    <div className={className} onClick={handleClick}>
      <InternshipCard internship={internship} legend='ownership_internship_card.legend' />
    </div>
  )
}

export default OwnedInternshipCard
