import React, { useCallback, useContext, useMemo } from 'react'

import Cookies from 'js-cookie'
import { getTranslate } from 'react-localize-redux'
import { OrderBy } from '../../../../utils/constants'
import { getComment, getEmplacement, getHospitalDepartmentName, getName, Sector } from '../../../../utils/entities/sector'
import { Action, ArchiveSector, ArchiveSectorModal, ConsultUsers, EditSector, ManageFiles } from '../../../../Components/shared/DataTable/utils/actions'
import { connect } from 'react-redux'
import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { DEFAULT_STATE, DISPLAY_MODE, EDITION_ONLY_MODE, SectorModalContext } from '../../../../Providers/Forms/SectorModal'
import { SectorActions } from './SectorsListView'
import { getLanguageByLocale } from '../../../../utils'
import { getUser } from '../../../../reducers/UserReducer'
import { makeExport } from '../../../../utils/api/export'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import { exportSectors } from '../../../../utils/api/sector'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { isFunction } from 'lodash'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorsTable = ({ data, disabledAdd, displayManagePresets, institution, loading, metadata, parameters, readOnly, user, onArchive, onAction, onDelete, onParametersChange, onReload, onUnarchive, t }) => {
  const { setSelectedSector } = useContext(SectorModalContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '230px' : '189px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: OrderBy.NAME, key: getName },
    { title: t('data_table.column_title.hospital_department'), orderBy: OrderBy.HOSPITAL_DEPARTMENT, key: getHospitalDepartmentName },
    { title: t('data_table.column_title.comment'), orderBy: OrderBy.COMMENT, key: getComment, style: { maxWidth: '200px' } },
    { title: t('data_table.column_title.emplacement'), orderBy: OrderBy.EMPLACEMENT, key: getEmplacement }
  ], [t])
  const actions = useMemo(() => {
    const actions = [
      new EditSector({ onClick: s => setSelectedSector({ ...DEFAULT_STATE, data: s, callback: onReload, mode: DISPLAY_MODE }), disabled: readOnly }).build(),
      new ConsultUsers({ onClick: s => onAction({ data: s, action: SectorActions.DISPLAY_AFFILIATED_USERS }) }).build()
    ]

    if (isFunction(displayManagePresets)) {
      actions.push(new Action({ icon: faClock, title: t('Manage presets'), onClick: s => displayManagePresets(s) }))
    }

    actions.push(new ManageFiles({ onClick: s => onAction({ data: s, action: SectorActions.MANAGE_FILES }), className: d => d.documents.length > 0 && !readOnly ? 'ant-green-button' : '', disabled: readOnly }).build())
    actions.push(new ArchiveSector({ onDelete: onArchive, disabled: () => readOnly }).build())

    return actions
  }, [onArchive, displayManagePresets, onAction, onReload, setSelectedSector, t, readOnly])
  const externalActions = useMemo(() => {
    const externalActions = []

    if (!readOnly) {
      externalActions.push(new ArchiveSectorModal({ institution, onUnarchive, onDelete, t }))
    }

    return externalActions
  }, [institution, setSelectedSector, onDelete, onUnarchive, t])

  const handleExport = useCallback(type =>
    makeExport(
      exportSectors,
      user,
      { language: getLanguageByLocale(user.language), type },
      () => onError(t('export_sectors.error')),
      () => onSuccess(t('export_sectors.success'))
    )
  , [user, t])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        disabledAdd={disabledAdd}
        externalActions={externalActions}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => setSelectedSector({ ...DEFAULT_STATE, data: new Sector({ institution }), callback: onReload, mode: EDITION_ONLY_MODE })}
        onExport={handleExport}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SectorsTable)
