import remove from 'lodash/remove'
import { request, generalErrorHandler } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const SET_CHANGELOG = 'SET_CHANGELOG'
const SET_LOADING = 'SET_LOADING'
const REMOVE_CHANGELOG = 'REMOVE_CHANGELOG'
const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
const RESET_CHANGELOG = 'RESET_CHANGELOG'

export const setChangelog = (changelog) => {
  return {
    type: SET_CHANGELOG,
    changelog
  }
}

export const dismissUserNotification = getUser => {
  return {
    type: DISMISS_NOTIFICATION,
    getUser
  }
}

export const resetChangelog = () => {
  return {
    type: RESET_CHANGELOG
  }
}

const setLoading = (loading, dispatch) => {
  dispatch({
    type: SET_LOADING,
    loading
  })
}

export const getChangelog = state => {
  return state.changelog
}

export const loadChangelog = (getUser) => {
  return async (dispatch) => {
    const res = await loadChangelogHelper(dispatch, getUser)
    return res
  }
}

const loadChangelogHelper = async (dispatch, getUser) => {
  try {
    setLoading(true, dispatch)
    const { data, showNotification } = await request('/user-notification/changelog', HttpMethods.GET, null, getUser)
    if (data.status === undefined) {
      dispatch({
        type: SET_CHANGELOG,
        changelog: { data, showNotification }
      })
      return data
    }
  } catch (err) {
    setLoading(false, dispatch)
    generalErrorHandler(err)
    return null
  }
}

export const saveChangelog = ({ changelog, getUser }) => {
  return async (dispatch) => {
    try {
      const { data } = await request('/user-notification', HttpMethods.POST, changelog, getUser)
      if (!data.status) {
        loadChangelogHelper(dispatch, getUser)
      }
      return data
    } catch (err) {
      setLoading(false, dispatch)
      generalErrorHandler(err)
      return null
    }
  }
}

export const removeChangelog = ({ changelog, getUser }) => {
  return async (dispatch) => {
    try {
      setLoading(true, dispatch)
      const { data } = await request(`/user-notification/${changelog.id}`, HttpMethods.DELETE, null, getUser)
      if (!data.status) {
        dispatch({
          type: REMOVE_CHANGELOG,
          changelog: data
        })
      }
    } catch (err) {
      setLoading(false, dispatch)
      generalErrorHandler(err)
      return null
    }
  }
}

const dismissUserNotificationRequest = (notificationId, getUser) => {
  try {
    const saveObject = { id: notificationId }
    request('/user-notification/dismiss', HttpMethods.POST, saveObject, getUser)
  } catch (err) {
    generalErrorHandler(err)
  }
}

export default (state = { changelog: [], loading: false, showNotification: false }, action) => {
  switch (action.type) {
    case SET_CHANGELOG:
      return {
        changelog: action.changelog.data,
        showNotification: action.changelog.showNotification,
        loading: false
      }
    case SET_LOADING:
      return { ...state, loading: action.loading }
    case REMOVE_CHANGELOG: {
      const changeLogList = state.changelog.slice()
      remove(changeLogList, item => item.id === action.changelog.id)
      return { changelog: changeLogList, loading: false }
    }
    case DISMISS_NOTIFICATION:
      dismissUserNotificationRequest(state.changelog[0].id, action.getUser)
      return { ...state, showNotification: false }
    case RESET_CHANGELOG: {
      return { changelog: [], loading: true, showNotification: false }
    }
    default:
      return state
  }
}
