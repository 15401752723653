import { InternshipStates } from '../../../../utils/constants'

const { ACCEPTED, CANCELED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE } = InternshipStates

export const NO_SECTION_TEXT = 'Without section'

export const DATE_FORMAT_SHOWN = 'DD/MM/YYYY'
export const DAY_AND_MONTH_FORMAT = 'DD/MM'
export const DATE_FORMAT_API = 'YYYY-MM-DD'

export const COLUMNS_INFO_COUNT = 6
export const DEFAULT_NUMBER_OF_WEEKS = 24

export const COLUMN_TYPE_TIME = { period: 'isoWeek', count: 1 }

export const SORT_BY_CREATION_DATE = 'SORT_BY_CREATION_DATE'
export const SORT_BY_NEW_MESSAGE = 'SORT_BY_NEW_MESSAGE'
export const SORT_BY_START_DATE = 'SORT_BY_START_DATE'
export const SORT_BY_STUDENT = 'SORT_BY_STUDENT'
export const SORT_BY_INSTITUTION = 'SORT_BY_INSTITUTION'
export const SORT_BY_SCHOOL = 'SORT_BY_SCHOOL'
export const SORT_BY_SECTION = 'SORT_BY_SECTION'
export const SORT_BY_SECTOR = 'SORT_BY_SECTOR'
export const ADD = 'UP'
export const REMOVE = 'DOWN'
export const QUANTITY = 7
export const UNIT = 'day'
export const START_DATE_KEY = 'startDate'
export const END_DATE_KEY = 'endDate'

export const DEFAULT_CELL_PARAMETERS = {
  icon: null,
  direction: null,
  key: null
}

/** @enum {string} */
export const OwnershipValues = Object.freeze({
  ALL: 'ALL',
  SCHOOL: 'SCHOOL',
  INSTITUTION: 'INSTITUTION'
})

export const SCHOOL_INTERNSHIP_DISABLING_STATES = [ACCEPTED, CANCELED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE]

export const QUOTA_ACCESSORS = {
  section: (internship, state) => {
    if (!internship.school || !internship.section) {
      return -1
    }

    const schoolSection = state.schoolSections[internship.school].find(s => s.id === internship.section)

    if (!schoolSection || !schoolSection.sectionToYear) {
      return -1
    }

    const sectionToYear = state.sectionToYears.asArray.find(sty => sty.id === schoolSection.sectionToYear)

    return sectionToYear?.section.id ?? -1
  },
  school: internship => internship.school ?? -1,
  internshipYear: (internship, state) => {
    if (!internship.internshipYear) {
      return -1
    }

    const sectionToYear = state.sectionToYears.asArray.find(sty => sty.id === internship.internshipYear)

    return sectionToYear?.id ?? -1
  }
}
