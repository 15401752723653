import React, { useEffect, useState } from 'react'

import SmartTable, { SmartTableDataTypes } from '../../shared/SmartTable'
import { notification, message } from 'antd'

import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { LanguageTags } from '../../../utils/constants'

const AUTHORIZED_TRANSLATIONS = [LanguageTags.EN, LanguageTags.FR, LanguageTags.NL]
const UPDATING_STATE = 'update'
const ADDING_STATE = 'add'

const TOOLTIP_ENCRYPTION_FREE_FIELD_YES = 'If you deactivate the encryption option, all the values already stored for this field will be decrypted inside the database making the content visible.'
const TOOLTIP_ENCRYPTION_FREE_FIELD_NO = 'Storing sensitive data such as passwords in online databases, even if encrypted, involves a certain degree of risk. By turning this option on, you accept that the content of the field, no matter its nature, is your entire responsibility and Opal Solution cannot be held responsible for the use that could be done of this feature.'

const FreeFieldsAdminPage = props => {
  const [columns, setColumns] = useState([])
  const [freeFieldsTypes, setFreeFieldsTypes] = useState([])
  const [loading, setLoading] = useState(true)

  /**
   * Set the columns properties and fetch all the FreeFieldsTypes for the user institution
   */
  useEffect(() => {
    props.fetchInstitutionFieldsTypes(props.getUser)

    setColumns([
      { type: SmartTableDataTypes.ID, key: 'id' },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Id'),
        key: 'fieldId',
        disabled: true
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Field type'),
        key: 'fieldType',
        validate: data => {
          return data !== null && data !== ''
        }
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('English translation'),
        key: LanguageTags.EN
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('French translation'),
        key: LanguageTags.FR
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Dutch translation'),
        key: LanguageTags.NL
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isEditable',
        name: props.t('Editable on student sheet')
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isBadgeDisplayed',
        name: props.t('Displayed on the Badges page')
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isStudentDisplayed',
        name: props.t('Displayed on the Student side')
      },
      {
        type: SmartTableDataTypes.HINTED_BOOLEAN,
        key: 'isEncryption',
        name: props.t('Encrypted field'),
        tooltipTitle: {
          yes: TOOLTIP_ENCRYPTION_FREE_FIELD_YES,
          no: TOOLTIP_ENCRYPTION_FREE_FIELD_NO
        },
        size: 'small',
        className: 'hinted-switch-small',
        loadMessage: {
          content: props.t('Loading...'),
          duration: 60
        }
      }
    ])
  }, [])

  useEffect(() => {
    setColumns([
      { type: SmartTableDataTypes.ID, key: 'id' },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Id'),
        key: 'fieldId',
        disabled: true
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Field type'),
        key: 'fieldType',
        validate: data => {
          return data !== null && data !== ''
        }
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('English translation'),
        key: LanguageTags.EN
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('French translation'),
        key: LanguageTags.FR
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Dutch translation'),
        key: LanguageTags.NL
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isEditable',
        name: props.t('Editable on student sheet')
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isBadgeDisplayed',
        name: props.t('Displayed on the Badges page')
      },
      {
        type: SmartTableDataTypes.BOOLEAN,
        key: 'isStudentDisplayed',
        name: props.t('Displayed on the Student side')
      },
      {
        type: SmartTableDataTypes.HINTED_BOOLEAN,
        key: 'isEncryption',
        name: props.t('Encrypted field'),
        tooltipTitle: {
          yes: TOOLTIP_ENCRYPTION_FREE_FIELD_YES,
          no: TOOLTIP_ENCRYPTION_FREE_FIELD_NO
        },
        size: 'small',
        className: 'hinted-switch-small',
        loadMessage: {
          content: props.t('Loading...'),
          duration: 60
        }
      }
    ])
  }, [props.getActiveLanguage])

  /**
   * Update FreeFieldsTypes data if the related store data are modified
   */
  useEffect(() => {
    if (props.getInstititionFieldsTypes) {
      setFreeFieldsTypes(adaptFreeFieldsDataFormat(props.getInstititionFieldsTypes))
      setLoading(false)
    }
  }, [props.getInstititionFieldsTypes])

  useEffect(() => {
    if (
      typeof props.getLoadMessageFieldsTypes !== 'undefined' &&
      typeof props.getLoadMessageFieldsTypes.duration !== 'undefined' &&
      props.getLoadMessageFieldsTypes.duration > 0
    ) {
      message.loading(props.getLoadMessageFieldsTypes)
      message.success({ content: props.t('Loading completed'), key: 'updatable', duration: 2 })

      setTimeout(() => {
        props.loadMessageFieldsTypes({ duration: 0 })
      }, 2000)
    }
  }, [props.getLoadMessageFieldsTypes])

  /**
   * Disable loading state when a least one data are store in the table
   */
  useEffect(() => {
    if (freeFieldsTypes.length > 0 && loading) {

    }
  }, [freeFieldsTypes])

  /**
   * Adapt data form in order to pass them to the Smartable component
   *
   * @param {Object} inputData the raw data get from the backend
   * @returns array of the freeFieldTypes extract from the inputData
   */
  const adaptFreeFieldsDataFormat = inputData => {
    const newDataFormat = []

    Object.keys(inputData).forEach(key => {
      const objectToPush = { ...inputData[key], fieldId: key }

      Object.keys(objectToPush).forEach(k => {
        if (objectToPush.isEncryption && (k === 'isEditable' || k === 'isBadgeDisplayed')) {
          objectToPush[k + '_disabled'] = true
        }
      })

      Object.keys(objectToPush.translations).forEach(translationKey => {
        objectToPush[translationKey] = objectToPush.translations[translationKey]
      })
      delete objectToPush.translations

      newDataFormat.push(objectToPush)
    })

    return newDataFormat
  }

  const freeFieldValidator = (freeFieldType, freeFieldTypeState, callback) => {
    const warningMessage = {
      add: props.t('This Free Field already exist for your institution. If you want to edit it, find the Free Field with id : '),
      update: props.t('A Free Field with value "') + freeFieldType.fieldType + props.t('" already exist for your institution. If you want to edit it, find the Free Field with id : ')
    }
    // Remove all blank space at the string's start and end
    freeFieldType.fieldType = freeFieldType.fieldType.trim()
    const existingFreeFields = Object.keys(freeFieldsTypes).filter(key =>
      freeFieldsTypes[key].fieldType === freeFieldType.fieldType &&
      freeFieldsTypes[key].id !== freeFieldType.id
    )

    if (existingFreeFields.length > 0) {
      notification.warning({
        message: (warningMessage[freeFieldTypeState] ?? '') + freeFieldsTypes[existingFreeFields[0]].id,
        placement: 'bottomRight'
      })
    } else {
      if (freeFieldTypeState === ADDING_STATE) {
        freeFieldType = reverseFreeFieldsDataFormat(freeFieldType)
      }
      callback(freeFieldType, props.getInstitutions[0].id, props.getUser)
    }
  }

  /**
   * Revert the data adaptation make by the function adaptFreeFieldsDataFormat
   *  in order to send them to the backend
   *
   * @param {array} adaptedFreeField the adapted data in the SmartTable format
   * @returns Object the freeFieldTypes data in the map form
   */
  const reverseFreeFieldsDataFormat = adaptedFreeField => {
    const reverseFreeFieldType = { translations: {} }

    Object.keys(adaptedFreeField).forEach(key => {
      if (AUTHORIZED_TRANSLATIONS.includes(key)) {
        reverseFreeFieldType.translations[key] = adaptedFreeField[key]
      } else {
        reverseFreeFieldType[key] = adaptedFreeField[key]
      }
    })

    return reverseFreeFieldType
  }

  /**
   * Handle modification of a new freeFieldType Object
   *
   * @param {Object} freeFieldType            the modified freeFieldType object
   * @param {function} stopSmartTableLoading  a function to stop loading of SmartTable
   */
  const onDataEdited = (freeFieldType, stopSmartTableLoading) => {
    freeFieldValidator(freeFieldType, UPDATING_STATE, props.updateFreeFieldType)
    stopSmartTableLoading()
  }

  /**
   * Handle creation of a new freeFieldType Object
   *
   * @param {Object} freeFieldType            the modified freeFieldType object
   * @param {function} stopSmartTableLoading  a function to stop loading of SmartTable
   */
  const onDataAdded = (freeFieldType, stopSmartTableLoading) => {
    freeFieldValidator(freeFieldType, ADDING_STATE, props.addOrUpdateFreeFieldType)
    stopSmartTableLoading()
  }

  /**
   * Handle deletion of a freeFieldType Object
   *
   * @param {Object} freeFieldType            the modified freeFieldType object
   */
  const onDataDeleted = freeFieldType => {
    const newFreeFieldsTypes = freeFieldsTypes.filter(fieldType => fieldType.id !== freeFieldType.id)

    setFreeFieldsTypes(newFreeFieldsTypes)
    props.deleteFreeFieldType(freeFieldType.id, props.getUser)
  }

  return (
    <div>
      <SmartTable
        columns={columns}
        data={freeFieldsTypes}
        loading={loading}
        onDataAdd={onDataAdded}
        onDataEdit={onDataEdited}
        onDataDelete={onDataDeleted}
        addDataText={props.t('Add Free Field type')}
        customExportButton={undefined}
        reverseDefaultBoolean
        removeExportButton
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeFieldsAdminPage)
