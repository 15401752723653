import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { request, generalErrorHandler } from '../../../utils'
import StudentsList from './StudentsList'
import { HttpMethods } from '../../../utils/apiHelper'

const SchoolStudentsDrawer = props => {
  const [loading, setLoading] = useState(true)
  const [students, setStudents] = useState(null)
  const [serverError, setServerError] = useState(null)

  const loadStudents = () => {
    request(`/school/${props.school.id}/students`, HttpMethods.GET, null, props.getUser)
      .then(json => {
        if (json.status && json.status === 'error') {
          generalErrorHandler(json.message)
          setLoading(false)
          setServerError(props.t('An unknown error has occured.'))
          return
        }
        setStudents(json.data)
        setLoading(false)
      })
      .catch((error) => {
        generalErrorHandler(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    // load the list of students for this school
    setServerError(null)
    if (props.school) {
      setLoading(true)
    } else {
      setStudents(null)
    }
  }, [props.school])
  return (
    <Drawer
      title={props.school !== null ? props.school.name : ''}
      width='80%'
      onClose={props.onClose}
      visible={props.showDrawer}
      afterVisibleChange={(opened) => {
        if (opened) {
          loadStudents()
        }
      }}
    >
      {loading && <div className='loading-ring' />}
      {!loading && props.school && students &&
        <StudentsList
          students={students}
          schoolId={props.school ? props.school.id : null}
        />}
      {serverError && <div>{serverError}</div>}
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolStudentsDrawer)
