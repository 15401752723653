import React, { useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from '../../../../../reducers/Dispatchers'
import { getUser } from '../../../../../reducers/UserReducer'
import { isDomainEvaluator } from '../../../../../utils/roles'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const SchoolNote = ({ note, t, user }) => {
  const localNote = useMemo(() => note || t('school_note.local_note.no_note'), [note, t])

  return !isDomainEvaluator(user) ? (
    <>
      <div
        style={{
          color: 'white',
          fontSize: '12px',
          paddingLeft: '12px',
          paddingBottom: '10px',
          paddingTop: '5px',
          borderBottom: '1px solid #E8E8E8',
          marginBottom: '10px'
        }}
      >
        {t('school_note.title') + ' :'}
      </div>

      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {localNote}
      </p>
    </>
  ) : null
}

export default connect(mapStateToProps)(SchoolNote)
