import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import IconButton from '../../antd/Buttons/IconButton'
import { Card, Button, Input, Form } from 'antd'
import IconTooltip from '../../antd/Icons/IconTooltip'

import '../../../assets/profile-page.scss'

const NISS_TITLE = 'My NISS'
const NISS_TIPS_TEXT = 'For non-Belgian citizens, this is your social security number'

const mapStateToProps = state => ({ user: getUser(state.getUser), t: getTranslate(state.locale) })

const NissForm = ({ form, niss = '', user, onSubmit, t }) => {
  const [nissEditionMode, setNissEditionMode] = useState(false)

  const { getFieldDecorator } = form

  const handleSubmit = e => {
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values.niss)
      }
    })

    setNissEditionMode(!nissEditionMode)
  }

  return (
    <div className='inner-margin'>
      <Card
        type='inner'
        title={[t(NISS_TITLE), ' ', <IconTooltip key='personal_section_tooltip' placement='right' title={t(NISS_TIPS_TEXT)} icon={faQuestionCircle} color='#1890ff' />]}
        extra={(<IconButton icon={faEdit} onClick={() => setNissEditionMode(!nissEditionMode)} />)}
      >
        {nissEditionMode && (
          <Form
            name='nissForm'
            className='student-extra-contact-details'
            onSubmit={handleSubmit}
          >
            <Form.Item>
              {getFieldDecorator('niss')(<Input prefix={<FontAwesomeIcon icon='user' />} placeholder={niss} />)}
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>{t('Save')}</Button>
            </Form.Item>
          </Form>
        )}
        {!nissEditionMode && <span>{niss || t('Please fill out your social security number')}</span>}
      </Card>
    </div>
  )
}

export default connect(mapStateToProps)(Form.create()(NissForm))
