import React, { useContext, useMemo } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { SchoolRoles, OtherRoles } from '../utils/constants'
import { Routes } from '../routes'
import SchoolStudentsView from '../Views/school/SchoolStudentsView'
import { getUser } from '../reducers/UserReducer'
import SchoolInstitutionsView from '../Views/school/SchoolInstitutionsView'
import SchoolSettingsView from '../Views/school/SchoolSettingsView'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { isArray } from 'lodash'
import { connect } from 'react-redux'
import { isMoment } from 'moment'
import { GlobalContext } from '../Providers/GlobalProvider'
import SchoolProvider from '../Providers/SchoolProvider'
import SchoolSectionsView from '../Views/school/SchoolSectionsView/SchoolSectionsView'
import InternshipsView from '../Views/school/InternshipsView'
import InstitutionDocumentsDrawer from '../Providers/Drawer/InstitutionDocumentsDrawer'
import ContactPersonsTabsView from '../Views/shared/ContactPersons/ContactPersonsTabsView'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const SchoolRouter = ({ history, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const currentRole = useMemo(() => isArray(user.roles) ? user.roles[0] : OtherRoles.UNKNOWN, [user.roles])

  const goToShiftManager = date => {
    history.push(Routes.SHIFTS_MANAGER)

    if (isMoment(date)) {
      setCurrentDate(date)
    }
  }

  return (
    <SchoolProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={goToShiftManager} />
          </Route>
          <Route path={Routes.STUDENTS}>
            <SchoolStudentsView />
          </Route>
          {currentRole !== SchoolRoles.SUPERVISOR && (
            <Route path={Routes.SCHOOL_SECTION}>
              <SchoolSectionsView />
            </Route>
          )}
          <Route path={Routes.INSTITUTIONS}>
            <InstitutionDocumentsDrawer>
              <SchoolInstitutionsView />
            </InstitutionDocumentsDrawer>
          </Route>
          {[SchoolRoles.ENCODER, SchoolRoles.COORDINATOR].includes(currentRole) && (
            <Route path={Routes.SETTINGS}>
              <SchoolSettingsView />
            </Route>
          )}
          <Route path={Routes.SHIFTS_MANAGER}>
            <ShiftsManagerView
              currentDate={currentDate}
              displayManagePresets={false}
            />
          </Route>
          {currentRole === SchoolRoles.ENCODER && (
            <Route path={Routes.PRESETS_MANAGER}>
              <ShiftPresetsManager
                sector={null}
              />
            </Route>
          )}
          <Route path={Routes.TAGS_MANAGEMENT}>
            <TagsManagementView />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          {[SchoolRoles.COORDINATOR, SchoolRoles.ENCODER].includes(currentRole) && (
            <Route path={Routes.CONTACT_PERSONS}>
              <ContactPersonsTabsView />
            </Route>
          )}
        </Switch>
    </SchoolProvider>
  )
}

export default withRouter(connect(mapStateToProps)(SchoolRouter))
