import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { getAllInternships } from '../../../../utils/api/internship'
import { getUser } from '../../../../reducers/UserReducer'
import { DATE_WITHOUT_TIME } from '../../../../utils/constants'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { unset, debounce, isString } from 'lodash'
import useLocalStorage from '../../../../hooks/UseLocalStorage'
import InternshipsTable from './InternshipsTable'
import InternshipProvider from '../../../../Context/InternshipsManager/InternshipProvider'
import InternshipsManagerProvider from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import { DEFAULT_INTERNSHIP_FILTERS, GlobalFiltersContext } from '../../../../Providers/GlobalFiltersProvider'
import LOCAL_STORAGE_KEYS from '../../../../utils/localStorage'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const InternshipsListView = ({ user }) => {
  const { internshipDate, internshipFilters, internshipSearch, setInternshipFilters, setInternshipSearch } = useContext(GlobalFiltersContext)
  const { areStartedExcluded, institutions, schools, sections, states, weeksShownCount, order } = internshipFilters

  const [internships, setInternships] = useState({ data: [], meta: { pageSize: 10 } })
  const [loading, setLoading] = useState(false)
  const [rawParameters, setRawParameters] = useLocalStorage(LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.PARAMETERS, {})

  const parameters = useMemo(() => new TableParameters({
    ...rawParameters,
    filters: { areStartedExcluded, institutions, schools, sections, states, weeksShownCount, search: internshipSearch },
    period: { startDate: internshipDate.format(DATE_WITHOUT_TIME), endDate: internshipDate.clone().add(internshipFilters.weeksShownCount ?? 1, 'years').format(DATE_WITHOUT_TIME) },
    order
  }), [rawParameters, internshipDate, internshipSearch, order])

  useEffect(() => {
    const fetch = debounce(() => {
      setLoading(true)

      fetchInternships(parameters)
    }, 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [institutions, parameters])

  const fetchInternships = useCallback(parameters => {
    const formattedParameters = {
      ...parameters,
      filters: { ...parameters.filters },
      order: { ...parameters.order },
      startDate: parameters.period.startDate,
      endDate: parameters.period.endDate,
      contexts: ['coordinator', 'school'],
      states: parameters.filters.states
    }

    if (isString(parameters.filters.search)) {
      formattedParameters.search = formattedParameters.filters.search
    }

    unset(formattedParameters, 'filters[states]')

    getAllInternships(user, formattedParameters).then(json => {
      if (json) {
        json.meta.pages = { ...json.meta.pages, pageSize: json.meta.pages.page_size, perPage: json.meta.pages.page_size, totalPages: json.meta.pages.total_pages }
        setInternships(json)
      }
    }).finally(() => setLoading(false))
  }, [setInternships, setLoading, user])

  const handleParametersChange = useCallback(parameters => {
    setInternshipSearch(parameters.filters.search)
    setRawParameters(parameters)
  }, [setRawParameters, setInternshipSearch])

  const handleFilterChange = () => {
    setRawParameters({ ...rawParameters, page: 1 })
  }

  const handleResetFilters = () => {
    setRawParameters({ ...rawParameters, page: 1 })
    setInternshipSearch('')
    setInternshipFilters(DEFAULT_INTERNSHIP_FILTERS)
  }

  return (
    <InternshipsManagerProvider>
      <InternshipProvider refreshQuotas={() => fetchInternships(parameters)}>
        <InternshipsTable
          data={internships}
          loading={loading}
          parameters={parameters}
          onParametersChange={handleParametersChange}
          onDataChange={fetchInternships}
          onFilterChange={handleFilterChange}
          onResetFilters={handleResetFilters}
        />
      </InternshipProvider>
    </InternshipsManagerProvider>

  )
}

export default connect(mapStateToProps)(InternshipsListView)
