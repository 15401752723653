import React, { useEffect, useState } from 'react'

import PlacesDefiner from '../../Components/institution/Quotas/definition/PlacesDefiner'
import { InstitutionOptions } from '../../utils/constants'
import moment from 'moment'
import { Tabs } from 'antd'
import Analytics from '../../utils/analytics'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import QuotasProvider from '../../Providers/Quotas/QuotasProvider'
import PlacesAllocationContainer from '../../Components/institution/Quotas/allocation/PlacesAllocationContainer'
import AllocationProvider from '../../Providers/Quotas/AllocationProvider'

const { TabPane } = Tabs

const InstitutionQuotasView = (props) => {
  const [date, setDate] = useState(moment())
  const [quotaPerPauseEnable, setQuotaPerPauseEnable] = useState(false)

  useEffect(() => {
    Analytics.pageView('/institution-quotas')
  }, [])

  useEffect(() => {
    if (props.getInstitutionOptions) {
      props.getInstitutionOptions.forEach(option => {
        if (option.optionType.type === InstitutionOptions.QUOTA_PER_PAUSE) {
          setQuotaPerPauseEnable(true)
        }
      })
    }
  }, [props.getInstitutionOptions])

  return (
    <QuotasProvider>
      <Tabs defaultActiveKey='1' animated={false} className='flex-fill' destroyInactiveTabPane>
        <TabPane tab={props.t('Define places')} key='1' className='flex-fill'>
          <PlacesDefiner
            date={date}
            pausesRequired={quotaPerPauseEnable}
            onDateChange={setDate}
          />
        </TabPane>
        <TabPane tab={props.t('Allow places to schools')} key='2' className='flex-fill'>
          <AllocationProvider>
            <PlacesAllocationContainer />
          </AllocationProvider>
        </TabPane>
      </Tabs>
    </QuotasProvider>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionQuotasView)
