import React, { useCallback } from 'react'

import { Select } from 'antd'

const EntitiesSelector = ({ disabled, entityOptions, loading, multiple = 'multiple', showSearch, style = {}, values = [], onChange }) => {
  const handleFilter = useCallback((input, option) => {
    const { label, tooltip } = option.props.infos

    return (
      (label && label.toLowerCase().includes(input.toLowerCase())) ||
      (tooltip && tooltip.toLowerCase().includes(input.toLowerCase()))
    )
  }, [])

  return (
    <Select
      disabled={disabled}
      loading={loading}
      mode={multiple}
      allowClear
      showSearch
      value={values}
      optionFilterProp='label'
      onChange={onChange}
      filterOption={handleFilter}
      style={{ ...style }}
    >
      {entityOptions.map(entityOption => (
        <Select.Option
          key={'entity_' + entityOption.value}
          title={entityOption.tooltip}
          value={entityOption.value}
          infos={entityOption}
        >
          {entityOption.label}
        </Select.Option>
      ))}
    </Select>
  )
}

export default EntitiesSelector
