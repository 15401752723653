import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'

import LinkActModal from './LinkActModal'
import { InternshipStates } from '../../../utils/constants'
import { Popconfirm, Button, Dropdown, Menu, Badge, Input, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isObject } from '../../../utils'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import {
  faCheck, faEllipsisH, faExchangeAlt, faSyringe, faTrash, faUserGraduate, faCalendar, faComment, faCopy, faFile, faPaperPlane, faMessagePlus,
  faMessageEdit, faCircleCheck, faCircleXmark
} from '@fortawesome/pro-solid-svg-icons'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { SchoolContext } from '../../../Providers/SchoolProvider'
import { isDomainEvaluator, isHospitalAdmin, isInstitutionUser, isObserver, isSchoolUser, isSupervisor } from '../../../utils/roles'
import { InternshipMessageContext } from '../../../Providers/InternshipMessageProvider'
import { StudentQuickMenuDropdownContext as DropdownContext } from '../../../Providers/StudentQuickMenuDropdownProvider'
import { deleteInternshipNote, fetchInternshipNote, upsertInternshipNote } from '../../../utils/api/internshipNote'
import { ColorsPalette } from '../../../config/colors'
import { Ownership, SCHEDULE_VALIDATED } from '../../../utils/entities/internship'
import { isFunction } from 'lodash'

import '../../../assets/shared/student-badge-quick-menu.css'

const { PENDING, ACCEPTED, AUTO_ACCEPTED, CANCELED, SCHEDULE_ADDED, PRE_ACCEPTED } = InternshipStates
const CAN_MODIFY_CARE_UNIT_STATES = [PENDING, ACCEPTED, AUTO_ACCEPTED, SCHEDULE_ADDED, PRE_ACCEPTED]
/** @enum */
const MessagesState = Object.freeze({
  NO_MESSAGES: 0,
  HAS_MESSAGES: 1,
  HAS_NEW_MESSAGES: 2
})

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const StudentQuickMenu = ({
  canValidateDesiderata, inShiftsManager, internship, noMargin, user, onClickedDraft, onDeleteSchedule,
  onSelectedStudentInfo, onSelectedInternshipInfo, onDuplicateInternship, onInternshipDelete, onSelectedForSectorChange,
  onValidateDesideratas, t
}) => {
  const { isReadOnly } = useContext(GlobalContext)
  const schoolContext = useContext(SchoolContext)
  const { selectedInternshipDropdown, setSelectedInternshipDropDown } = useContext(DropdownContext)
  const { setSelectedInternship, messagesCount } = useContext(InternshipMessageContext)

  const [removePopConfirmDisplayed, setIsDeletePopConfirmDisplayed] = useState(false)
  const [messagesState, setMessagesStates] = useState(MessagesState.NO_MESSAGES)
  const [actModalVisible, setActModalVisible] = useState(false)

  const toggleDropdown = () => setSelectedInternshipDropDown(selectedInternshipDropdown ? null : internship.id)
  const canReadNotes = useMemo(() => isSchoolUser(user) && !isDomainEvaluator(user), [user])

  const [isConfirmNoteDeletion, setIsConfirmNoteDeletion] = useState(false)
  const [displayedNote, setDisplayedNote] = useState(canReadNotes ? '' : null)
  const [isNoteEdition, setIsNoteEdition] = useState(false)
  const [editedNote, setEditedNote] = useState('')
  const [visible, setVisible] = useState(false)

  const canDeleteInternship = useMemo(() => {
    let isGranted = isFunction(onInternshipDelete)

    if (isSchoolUser(user)) {
      isGranted = isGranted && internship.ownership === Ownership.SCHOOL && ![ACCEPTED, CANCELED].includes(internship.state) && !isSupervisor(user)
    } else if (isHospitalAdmin(user)) {
      isGranted = isGranted && internship.ownership === Ownership.INSTITUTION && internship.state < SCHEDULE_ADDED
    }

    return isGranted
  }, [user, onInternshipDelete])

  const toggleEditNote = () => {
    setEditedNote(displayedNote)
    setIsNoteEdition(!isNoteEdition)
  }

  const upsertNote = useCallback(() => {
    if (editedNote === displayedNote) return

    toggleEditNote()

    upsertInternshipNote(user, internship.id, editedNote).then(response => {
      if ('note' in response?.data) {
        setDisplayedNote(response.data.note)
      }
    })
  }, [user, internship, editedNote, isNoteEdition, displayedNote])

  const deleteNote = () => {
    toggleEditNote()
    toggleNoteDeletion()
    setEditedNote('')
    deleteInternshipNote(user, internship.id)
  }

  const cancelEditNote = () => {
    toggleEditNote()
    setEditedNote('')
  }

  const toggleNoteDeletion = () => setIsConfirmNoteDeletion(!isConfirmNoteDeletion)

  useEffect(() => {
    if (selectedInternshipDropdown === internship.id && canReadNotes) {
      fetchInternshipNote(user, internship.id).then(response => {
        if (Array.isArray(response?.data)) {
          setDisplayedNote(response.data[0]?.note ?? '')
        }
      })
    }
  }, [user, internship, selectedInternshipDropdown, isNoteEdition])

  const resetNoteState = () => {
    setIsNoteEdition(false)
    setIsConfirmNoteDeletion(false)
    toggleDropdown()
  }

  const userIsHospitalAdmin = useMemo(() => isHospitalAdmin(user), [user])

  useEffect(() => {
    if (internship) {
      const messages = messagesCount[internship.id] ?? {}

      if (messages.unreadMessages > 0) {
        setMessagesStates(MessagesState.HAS_NEW_MESSAGES)
      } else if (messages.messages > 0) {
        setMessagesStates(MessagesState.HAS_MESSAGES)
      } else {
        setMessagesStates(MessagesState.NO_MESSAGES)
      }
    }
  }, [internship, messagesCount])

  const handleDeleteCancel = e => {
    setIsDeletePopConfirmDisplayed(false)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDeleteClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleConfirm = () => {
    setIsDeletePopConfirmDisplayed(false)
    onInternshipDelete(internship)
  }

  const renderMessageNotificationDot = () => {
    return <Badge color={getNotificationColorByInternship(internship)} />
  }

  const getNotificationColorByInternship = () => {
    if (messagesState === MessagesState.HAS_MESSAGES) {
      return ColorsPalette.SECOND
    }

    if (messagesState === MessagesState.HAS_NEW_MESSAGES) {
      return ColorsPalette.RED
    }

    return ColorsPalette.TRANSPARENT
  }

  const onRemoveInternshipClick = (e) => toggleDropdownAndDo(e => {
    e.domEvent.preventDefault()
    return false
  }, e)

  const toggleDropdownAndDo = (...parameters) => () => {
    toggleDropdown()
    parameters[0](...parameters.slice(1))
  }

  const renderInternshipMenu = internship => {
    return (
      <Menu>
        <div className='student-badge-internship'>
          <div className='student-badge-internship-id bold'>{t('common.id')}: {internship.id}</div>
          {isSchoolUser(user) &&
            <div className='student-badge-note' style={{ justifyContent: isNoteEdition ? 'space-between' : 'inherit' }}>
              {(displayedNote?.length > 0 || isNoteEdition) && <span className='bold'>{t('common.note')}: </span>}
              {!isNoteEdition
                ? <span className='student-badge-note-text'>{displayedNote}</span>
                : (
                  <span className='cu-ptr'>
                    <Tooltip placement='top' title={t('student_quick_menu.internship_menu.save_note_action')}>
                      <FontAwesomeIcon color={displayedNote === editedNote ? ColorsPalette.GREY : ColorsPalette.STEEL_BLUE} icon={faCircleCheck} onClick={upsertNote} />
                    </Tooltip>
                    <Tooltip placement='top' title={t('common.cancel')}>
                      <FontAwesomeIcon color={ColorsPalette.GREY} icon={faCircleXmark} onClick={cancelEditNote} />
                    </Tooltip>
                    {
                      displayedNote &&
                        <Tooltip placement='top' title={t('common.delete')}>
                          <Popconfirm
                            placement='right'
                            okType='danger'
                            title={t('student_quick_menu.internship_menu.delete_this_note_question')}
                            okText={t('common.yes')}
                            cancelText={t('common.cancel')}
                            visible={isConfirmNoteDeletion}
                            onConfirm={deleteNote}
                            onCancel={toggleNoteDeletion}
                          >
                            <FontAwesomeIcon color={ColorsPalette.ORANGE_RED} icon={faTrash} onClick={toggleNoteDeletion} />
                          </Popconfirm>
                        </Tooltip>
                    }
                  </span>
                )}
            </div>}
          {displayedNote !== null && isNoteEdition && (
            <div>
              <Input.TextArea
                rows={Math.min(4, Math.max(2, Math.round(editedNote.length / 16)))}
                maxLength={140}
                value={editedNote}
                onChange={e => setEditedNote(e.target.value)}
              />
            </div>
          )}
        </div>
        {!isReadOnly && displayedNote?.length === 0 && !isNoteEdition && (
          <Menu.Item onClick={toggleEditNote}>
            <FontAwesomeIcon icon={faMessagePlus} />
            {t('student_quick_menu.internship_menu.add_note_action')}
          </Menu.Item>
        )}
        {!isReadOnly && displayedNote?.length > 0 && !isNoteEdition && (
          <Menu.Item onClick={toggleEditNote}>
            <FontAwesomeIcon icon={faMessageEdit} />
            {t('student_quick_menu.internship_menu.edit_note_action')}
          </Menu.Item>
        )}
        <Menu.Item onClick={toggleDropdownAndDo(setSelectedInternship, internship)}>
          {renderMessageNotificationDot(internship)}
          <FontAwesomeIcon icon={faComment} />
          {t('student_quick_menu.internship_menu.discussion')}
        </Menu.Item>
        {internship.student && (
          <Menu.Item onClick={toggleDropdownAndDo(onSelectedStudentInfo, internship.student)}>
            <FontAwesomeIcon icon={faUserGraduate} />
            {t('student_quick_menu.internship_menu.student_sheet')}
          </Menu.Item>
        )}
        {!(internship.state < PENDING || internship.student === null) && internship.state !== PRE_ACCEPTED && (
          <Menu.Item onClick={toggleDropdownAndDo(onSelectedInternshipInfo, internship)}>
            <FontAwesomeIcon icon={faCalendar} />
            {t('student_quick_menu.internship_menu.internship_sheet')}
          </Menu.Item>
        )}
        {!(isReadOnly || internship.state < PENDING || internship.student === null || !isSchoolUser(user) || !isObject(internship.section) || !internship.section.actsMandatory) && (
          <Menu.Item onClick={() => setActModalVisible(true)}>
            <FontAwesomeIcon icon={faSyringe} />
            {t('student_quick_menu.internship_menu.link_to_an_act')}
          </Menu.Item>
        )}
        {!(isReadOnly || inShiftsManager || isInstitutionUser(user) || isSupervisor(user)) && (
          <Menu.Item onClick={toggleDropdownAndDo(onDuplicateInternship, internship)}>
            <FontAwesomeIcon icon={faCopy} />
            {t('common.duplicate')}
          </Menu.Item>
        )}
        {canDeleteInternship && (
          <Menu.Item style={{ padding: '0px' }} onClick={onRemoveInternshipClick}>
            <Popconfirm
              placement='right'
              okType='danger'
              title={t('student_quick_menu.internship_menu.delete_this_internship_question')}
              okText={t('common.yes')}
              cancelText={t('common.cancel')}
              visible={removePopConfirmDisplayed}
              onConfirm={handleConfirm}
              onCancel={handleDeleteCancel}
              onClick={handleDeleteClick}
            >
              <div style={{ padding: '5px 12px' }} onClick={() => setIsDeletePopConfirmDisplayed(true)}>
                <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
                {t('student_quick_menu.internship_menu.delete_internship')}
              </div>
            </Popconfirm>
          </Menu.Item>
        )}
        {!isReadOnly && userIsHospitalAdmin && !inShiftsManager && CAN_MODIFY_CARE_UNIT_STATES.includes(internship.state) && (
          <Menu.Item onClick={toggleDropdownAndDo(onSelectedForSectorChange, internship)}>
            <FontAwesomeIcon icon={faExchangeAlt} />
            {t('student_quick_menu.internship_menu.modify_care_unit')}
          </Menu.Item>
        )}
        {!isReadOnly && !isObserver(user) && canValidateDesiderata && (
          <Menu.Item onClick={toggleDropdownAndDo(onValidateDesideratas)}>
            <FontAwesomeIcon icon={faCheck} />
            {t('student_quick_menu.internship_menu.desiderata_action')}
          </Menu.Item>
        )}
        {!isReadOnly && inShiftsManager && schoolContext?.useDraftInternships && !internship.isDraft && internship.state !== SCHEDULE_VALIDATED && (
          <Menu.Item onClick={toggleDropdownAndDo(onClickedDraft, internship, true)}>
            <FontAwesomeIcon icon={faFile} />
            {t('student_quick_menu.internship_menu.draft_mode_action')}
          </Menu.Item>
        )}
        {inShiftsManager && schoolContext?.useDraftInternships && internship.isDraft && internship.state !== SCHEDULE_VALIDATED && (
          <Menu.Item onClick={toggleDropdownAndDo(onClickedDraft, internship, false)}>
            <FontAwesomeIcon icon={faPaperPlane} />
            {t('student_quick_menu.internship_menu.publish_schedule_action')}
          </Menu.Item>
        )}
        {!isReadOnly && inShiftsManager && internship.state !== SCHEDULE_VALIDATED && (
          <Menu.Item onClick={toggleDropdownAndDo(onDeleteSchedule, internship)}>
            <FontAwesomeIcon icon={faTrash} />
            {t('student_quick_menu.internship_menu.delete_schedule_action')}
          </Menu.Item>
        )}
      </Menu>
    )
  }

  return (
    <div>
      {internship && (
        <span
          className={`student-badge-quick-menu ${noMargin ? 'no-margin' : ''}`}
        >
          {isSchoolUser(user) && (
            <LinkActModal
              visible={actModalVisible}
              internship={internship}
              onCancel={() => setActModalVisible(false)}
            />
          )}

          {renderMessageNotificationDot(internship)}
          <Dropdown
            visible={selectedInternshipDropdown === internship.id && visible}
            overlay={renderInternshipMenu(internship)}
            trigger={['click']}
            onVisibleChange={(visible) => {
              if (!visible) {
                setIsDeletePopConfirmDisplayed(false)
                resetNoteState()
              }

              setVisible(visible)
            }}
          >
            <Button size='small' type='primary' shape='circle' onClick={toggleDropdown}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
          </Dropdown>
        </span>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(StudentQuickMenu)
