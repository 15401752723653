import React, { useEffect, useState } from 'react'

import { faTrash, faUserGraduate } from '@fortawesome/pro-solid-svg-icons'
import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSchools } from '../../reducers/SchoolsReducer'
import { getUser } from '../../reducers/UserReducer'
import { getSchoolStudents } from '../../utils/api/school'
import { addSupervisorStudents, deleteSupervisorStudents, getSupervisorStudents } from '../../utils/api/user'
import StudentInfo from '../shared/StudentInfo'
import UserStudentTable, { DATA_TYPE_SELECT, DATA_TYPE_STRING } from './UserStudentTable'
import { isEncoder } from '../../utils/roles'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  getUser: getUser(state.getUser),
  getSchools: getSchools(state.getSchools)
})

const UserSupervisedStudents = ({ user, getUser, getSchools, schoolId, t, onClose }) => {
  const [userSupervisedStudents, setUserSupervisedStudents] = useState([])
  const [schoolStudents, setSchoolStudents] = useState([])
  const [selectedStudentInfo, setSelectedStudentInfo] = useState(null)
  const [currentSchool, setCurrentSchool] = useState(null)

  useEffect(() => {
    if (user === null) {
      setUserSupervisedStudents([])
    }

    if (user !== null) {
      getSupervisorStudents(user, getUser).then(setUserSupervisedStudents)
    }
  }, [user, getUser])

  useEffect(() => {
    const school = selectedSchool(schoolId, getSchools)

    if (school) {
      setCurrentSchool(school)

      getSchoolStudents(school, getUser).then(setSchoolStudents)
    }
  }, [getSchools, getUser])

  const handleAddSection = student => {
    const newStudent = schoolStudents.filter(s => s.id === student.newId)[0]

    const newUserSupervisedStudents = userSupervisedStudents.concat(newStudent)

    const body = { student: student.newId }

    addSupervisorStudents(user, body, getUser).then((json) => {
      if (json?.data !== null) {
        setUserSupervisedStudents(newUserSupervisedStudents)
      }
    })
  }

  const handleEditSection = student => {
    const editedStudent = schoolStudents.filter(s => s.id === student.newId)[0]

    const newUserSupervisedStudents = userSupervisedStudents.map(s => s.id === student.prevId ? editedStudent : s)

    setUserSupervisedStudents(newUserSupervisedStudents)
    deleteSupervisorStudents(user, student.prevId, getUser)

    const body = { student: student.newId }

    addSupervisorStudents(user, body, getUser)
  }

  const handleDeleteStudent = student => {
    const newUserSupervisedStudents = userSupervisedStudents.filter(s => s.id !== student.id)

    setUserSupervisedStudents(newUserSupervisedStudents)
    deleteSupervisorStudents(user, student.id, getUser)
  }

  const searchWithFilter = value => {
    getSupervisorStudents(user, getUser, value).then(setUserSupervisedStudents)
  }

  const selectedSchool = (schoolId, getSchools) => {
    if (schoolId) {
      // also check cache
      if (this._previouslySelectedSchool && this._previouslySelectedSchool.id === schoolId) {
        return this._previouslySelectedSchool
      }
      const school = getSchools.find(item => item.id === schoolId)
      this._previouslySelectedSchool = school
    }
    return getSchools[0]
  }

  const render = () => {
    const students = schoolStudents
    const userIsEncoder = isEncoder(getUser)

    let columns = []

    if (userIsEncoder) {
      columns = [
        {
          type: DATA_TYPE_SELECT,
          name: 'Student',
          options: students
        },
        {
          type: DATA_TYPE_STRING,
          name: 'common.section'
        }
      ]
    } else {
      columns = [
        {
          type: DATA_TYPE_STRING,
          name: 'Student',
          key: s => `${s.lastname} ${s.firstname}`
        },
        {
          type: DATA_TYPE_STRING,
          name: 'common.section'
        }
      ]
    }

    const additionalActions = [
      {
        icon: faUserGraduate,
        type: 'primary',
        title: t('Student sheet'),
        handleOnClick: setSelectedStudentInfo
      },
      {
        icon: faTrash,
        type: 'danger',
        title: 'Delete',
        titlePopconfirm: t('Delete this student?'),
        handleOnClick: handleDeleteStudent
      }
    ]

    return (
      <Drawer
        title={user !== null ? t('Student supervised by') + ' ' + user.lastname + ' ' + user.firstname : '?'}
        width='640px'
        onClose={onClose}
        visible={user !== null}
      >
        <UserStudentTable
          onDataAdd={handleAddSection}
          onDataEdit={handleEditSection}
          data={userSupervisedStudents}
          columns={columns}
          additionalActions={additionalActions}
          searchWithFilter={searchWithFilter}
          school={currentSchool}
          disabled={!userIsEncoder}
        />
        <StudentInfo
          student={selectedStudentInfo}
          onClose={() => setSelectedStudentInfo(null)}
        />
      </Drawer>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps)(UserSupervisedStudents)
