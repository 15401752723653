import React, { useContext, useEffect, useState } from 'react'

import { Modal, Button } from 'antd'
import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import UserCoordinatedSections from './UserCoordinatedSections'
import UserManagedSectors from './UserManagedSectors'
import UserValidatorInstitutionSector from './UserValidatorInstitutionSector'
import moment from 'moment'
import remove from 'lodash/remove'
import { faGraduationCap, faKey, faSitemap, faStethoscope, faSyringe } from '@fortawesome/pro-solid-svg-icons'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput, generalErrorHandler, requestWithPromise, SmartTableInputTypes } from '../../utils'
import AdminResetPassword from '../shared/AdminResetPassword'
import { Contexts, OtherRoles, SchoolOptions, SchoolRoles } from '../../utils/constants'
import UserSupervisedStudents from './UserSupervisedStudents'
import { faHouseMedical } from '@fortawesome/pro-duotone-svg-icons'
import { getCountSupervisorStudents, restoreUserByEmail } from '../../utils/api/user'
import { DELETED_USER, DUPLICATED_USER, HttpMethods, onError, onSuccess } from '../../utils/apiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HttpStatuses } from '../../utils/http'
import { UserActTypesDrawerContext } from '../../Providers/Drawer/UserActTypesDrawer/UserActTypesDrawerProvider'
import UserActTypesDrawer from '../../Providers/Drawer/UserActTypesDrawer/UserActTypesDrawer'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { SchoolContext } from '../../Providers/SchoolProvider'

const ROLES = [
  { id: 1, name: 'common.coordinator', apiName: SchoolRoles.COORDINATOR },
  { id: 2, name: 'common.encoder', apiName: SchoolRoles.ENCODER },
  { id: 3, name: 'common.supervisor', apiName: SchoolRoles.SUPERVISOR },
  { id: 4, name: 'common.validator', apiName: SchoolRoles.VALIDATOR },
  { id: 5, name: 'common.domain_evaluator', apiName: SchoolRoles.DOMAIN_EVALUATOR }
]

const Users = props => {
  const { isReadOnly } = useContext(GlobalContext)
  const { schoolOptions } = useContext(SchoolContext)

  const [selectedUserCoordinatedSection, setSelectedUserCoordinatedSection] = useState(null)
  const [selectedUserSupervisedStudent, setSelectedUserSupervisedStudent] = useState(null)
  const [countSupervisedStudents, setCountSupervisedStudents] = useState([])
  const [selectedUserManagedSector, setSelectedUserManagedSector] = useState(null)
  const [selectedUserForPasswordReset, setSelectedUserForPasswordReset] = useState(null)
  const [selectedValidatorUser, setSelectedValidatorUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [isUserCoordinator, setIsUserCoordinator] = useState(false)
  const [isUserEncoder, setIsUserEncoder] = useState(false)
  const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false)
  const [languages, setLanguages] = useState({})
  const [deletedModal, setDeletedModal] = useState({ user: null, body: null, footer: false, title: '' })
  const [revivalLoading, setRevivalLoading] = useState(false)

  const {
    selectedUser: selectedUserActTypes,
    setSelectedUser: setSelectedUserActTypes
  } = useContext(UserActTypesDrawerContext)

  useEffect(() => {
    if (props.getUser.roles) {
      setIsUserCoordinator(props.getUser.roles.includes(SchoolRoles.COORDINATOR))
      setIsUserEncoder(props.getUser.roles.includes(SchoolRoles.ENCODER))
    }

    if (props.getUser.context) {
      setUserIsSuperAdmin(props.getUser.context === Contexts.ADMIN)
    }

    getCountSupervisorStudents(props.getUser)
      .then(setCountSupervisedStudents)

    if (props.getLanguages) {
      setLanguages(
        props.getLanguages.map(({ code, name }) => ({
          id: code,
          name: props.t(name)
        }))
      )
    }

    if (typeof props.getUser !== 'undefined') {
      updateRoles(props.getUser, schoolOptions)
    }
  }, [props.getUser, schoolOptions])

  // this return sa school when the Component is used by a super admin
  const schoolById = () => {
    let school = null
    const { schoolId, getSchools } = props
    if (schoolId) {
      // also check cache
      if (this._previouslySelectedSchool && this._previouslySelectedSchool.id === schoolId) {
        return this._previouslySelectedSchool
      }
      school = getSchools.find(item => item.id === schoolId)
      this._previouslySelectedSchool = school
    }
    return school
  }

  const canResetPassword = () => {
    return [SchoolRoles.ENCODER, OtherRoles.ADMIN].includes(props.getUser.roles[0])
  }

  const updateRoles = (currentUser, schoolOptions) => {
    let roles = [1, 2, 3, 4]

    if (currentUser.roles.includes(SchoolRoles.COORDINATOR)) {
      roles = currentUser.school.managed ? [3, 4] : []
    } else if (!currentUser.school.managed) {
      roles = [1, 2, 3]
    }

    const domainEvaluatorOption = schoolOptions.find(option => option.optionType.type === SchoolOptions.DOMAIN_EVALUATORS)

    if (domainEvaluatorOption && domainEvaluatorOption.optionEnabled) {
      roles.push(5)
    }

    setRoles(ROLES.filter(r => roles.includes(r.id)).map(r => ({ ...r, name: props.t(r.name) })))
  }

  const handleAddUser = (user) => {
    return new Promise(() => {
      const rawRoleId = user.roles
      user.roles = [roles.filter(r => r.id === user.roles)[0].apiName]
      if (userIsSuperAdmin && props.schoolId) {
        user.school = props.schoolId
      }

      if (isUserCoordinator && user.sector) {
        user.affiliatedSectors = [user.sector]
      }

      user.userType = 'school'
      requestWithPromise('/user/create', HttpMethods.POST, user, props.getUser, true, true).then(json => {
        const newUser = json.data
        let school = props.getSchools[0]

        if (props.schoolId) {
          school = schoolById()
        }

        const users = school.users
        users.unshift(newUser)
        school.users = users
        if (props.schoolId) {
          props.updateSchool(school)
        } else {
          props.setSchools([school])
        }
      }).catch(error => {
        if (error.status === HttpStatuses.CONFLICT) {
          error.body.getReader().read().then(({ value }) => {
            const decoded = new TextDecoder('utf-8').decode(value)
            const json = JSON.parse(decoded)

            if (json.message === DELETED_USER) {
              setDeletedModal({
                footer: true,
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {props.t('users_view.handle_add_user.on_error.deleted_user.body', { email: user.username })}
                  </p>
                ),
                title: props.t('users_view.handle_add_user.on_error.deleted_user.title'),
                user: { ...user, roles: rawRoleId }
              })
            } else if (json.message === DUPLICATED_USER) {
              setDeletedModal({
                footer: false,
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {props.t('users_view.handle_add_user.duplicated_user.body')}
                  </p>
                ),
                title: props.t('users_view.handle_add_user.duplicated_user.title'),
                user: { ...user, roles: rawRoleId }
              })
            }
          })
        }
      })
    })
  }

  const handleEditUser = (user, stopTableLoading, savingValidator = false, sectors = []) => {
    if (roles.length > 0) {
      user.roles = [roles.filter(r => r.id === user.roles)[0].apiName]

      if (savingValidator) {
        const body = {
          userToAdd: user.id,
          sectors: sectors
        }

        requestWithPromise('/sectors/contact-persons', HttpMethods.POST, body, props.getUser)
      }

      if ((isUserCoordinator || savingValidator) && user.sector) {
        user.affiliatedSectors = [user.sector]
      } else if (user.roles && user.roles[0] !== SchoolRoles.COORDINATOR) {
        user.coordinatedSections = []
      }
      if (userIsSuperAdmin && props.schoolId) {
        user.schoolId = props.schoolId
      }
      requestWithPromise('/user/modify/' + user.id, HttpMethods.POST, user, props.getUser)
        .then(json => {
          if (json.status && json.status === 'error') {
            stopTableLoading()
            generalErrorHandler(json.message)
            return
          }
          let school = props.getSchools[0]
          if (props.schoolId) {
            school = schoolById()
          }

          let users = school.users
          users = users.map(u => {
            if (u.id === user.id) u = json.data
            return u
          })
          school.users = users
          if (props.schoolId) {
            props.updateSchool(school)
          } else {
            props.setSchools([school])
          }
          if (savingValidator) {
          // this calls the method to close the validator drawer
            stopTableLoading()
          }
        })
        .catch(error => {
          generalErrorHandler(error)
        })
    }
  }

  const handleRemoveUser = user => {
    if (typeof user !== 'undefined') {
      if (userIsSuperAdmin && props.schoolId) {
        requestWithPromise('/api/User/' + user.id, HttpMethods.DELETE, null, props.getUser)
          .then(json => {
            const school = schoolById()
            remove(school.users, item => item.id === user.id)
            props.updateSchool(school)
          })
          .catch(error => {
            generalErrorHandler(error)
          })
      } else {
        props.removeSchoolUser({ userId: user.id, currentUser: props.getUser })
      }
    }
  }

  const handleReviveUser = () => {
    setRevivalLoading(true)
    restoreUserByEmail(deletedModal.user, props.getUser)
      .then(json => {
        const newUser = json.data
        let school = props.getSchools[0]

        if (props.schoolId) {
          school = schoolById()
        }

        const users = school.users
        users.unshift(newUser)
        school.users = users

        if (props.schoolId) {
          props.updateSchool(school)
        } else {
          props.setSchools([school])
        }

        onSuccess(props.t('users_view.handle_revive_user.restore_user_by_email.on_success'))
      }).catch(error => {
        onError(error.code)
      })

    handleReviveCancel()
  }

  const handleReviveCancel = () => {
    setDeletedModal({ ...deletedModal, user: null })
    setRevivalLoading(false)
  }

  const render = () => {
    let data = []
    if (props.getSchools.length > 0 && roles.length > 0) {
      let users = []
      if (props.schoolId) {
        const selectedSchool = schoolById
        if (selectedSchool) {
          users = selectedSchool.users
        }
      } else {
        users = props.getSchools[0].users
      }

      if (isUserCoordinator) {
        users = users.filter(item => item.roles.length === 1 && [SchoolRoles.SUPERVISOR].includes(item.roles[0]))
      }

      data = users.map(u => {
        let userRoleId = null
        if (u.roles && u.roles.length) {
          const foundRole = roles.find(r => r.apiName === u.roles[0])
          if (foundRole) {
            userRoleId = foundRole.id
          }
        }

        const userItem = {
          id: u.id,
          email: u.email,
          username: u.username,
          lastname: u.lastname,
          firstname: u.firstname,
          language: u.language,
          roles: userRoleId,
          isExternal: u.isExternal,
          lastLogin:
            u.lastLogin === null
              ? null
              : moment(u.lastLogin.date).format('DD/MM/YYYY')
        }

        if (isUserCoordinator) {
          if (u.institutions.length) {
            /**
             * after creation/update we get back objects in institutions & supervisedSectors arrays
             * When we load the page, the main get returns just the corresponding id in these arrays, not an object
             */
            if (u.institutions[0].id) {
              userItem.institution = u.institutions[0].id
            } else {
              userItem.institution = u.institutions[0]
            }
          } else {
            userItem.institution = -1
          }

          if (u.supervisedSectors.length) {
            if (u.supervisedSectors[0].id) {
              userItem.sector = u.supervisedSectors[0].id
            } else {
              userItem.sector = u.supervisedSectors[0]
            }
          } else {
            userItem.sector = -1
          }

          // set the sector additional props: disabled and options
          userItem.sectorDisabled = userItem.institution === -1
          userItem.sectorOptions = []
          if (userItem.institution > -1) {
            // set the options
            const selectedInstitution = props.getInstitutions.find(item => item.id === userItem.institution)
            if (selectedInstitution) {
              userItem.sectorOptions = selectedInstitution.sectors.map(item => ({ id: item.id, name: item.name }))
            }
          }
        }

        return userItem
      })
    }

    const columns = [
      { type: SmartTableDataTypes.ID, key: 'id' },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('users_view.column_title.email'),
        key: 'email',
        validate: data => validateFormInput(SmartTableInputTypes.EMAIL, data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('users_view.column_title.username'),
        key: 'username',
        validate: data => validateFormInput(SmartTableInputTypes.EMAIL, data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('users_view.column_title.lastname'),
        key: 'lastname',
        validate: data => validateFormInput(SmartTableInputTypes.NAME, data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('users_view.column_title.firstname'),
        key: 'firstname',
        validate: data => validateFormInput(SmartTableInputTypes.NAME, data, true)
      },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('users_view.column_title.language'),
        key: 'language',
        options: languages,
        preventAutoSelectDefaultValue: true,
        validate: data => data !== null
      },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('users_view.column_title.role'),
        key: 'roles',
        options: isUserCoordinator ? roles : roles.filter(item => item.notAllowedInSelect !== true)
      },
      {
        type: SmartTableDataTypes.DATE,
        name: props.t('users_view.column_title.last_connection'),
        key: 'lastLogin',
        format: 'DD/MM/YYYY',
        disabled: true
      }
    ]

    const additionalActions = [
      {
        iconName: faSitemap,
        type: 'primary',
        title: props.t('users_view.additional_actions.manage_students'),
        onClick: setSelectedUserSupervisedStudent,
        disabledCallback: u => u.roles !== 3, // Sections edition is disabled for other users than supervisor.
        customClassCallback: u => countSupervisedStudents[u.id] > 0 ? 'green-button' : ''
      },
      {
        iconName: faGraduationCap,
        type: 'primary',
        title: props.t('users_view.additional_actions.manage_sections'),
        onClick: setSelectedUserCoordinatedSection,
        disabledCallback: u => u.roles !== 1 && u.roles !== 2 // Sections edition is disabled for other users than coordinator and encoder.
      },
      {
        iconName: faStethoscope,
        type: 'primary',
        title: props.t('users_view.additional_actions.manage_care_units'),
        onClick: setSelectedUserManagedSector,
        disabledCallback: u => u.roles !== 3 // Sector edition is disabled for other users than supervisor.
      }
    ]

    if (!isUserCoordinator) {
      additionalActions.push({
        iconName: faHouseMedical,
        type: 'primary',
        title: props.t('users_view.additional_actions.set_institution_and_care_unit'),
        onClick: setSelectedValidatorUser,
        disabledCallback: u => u.roles !== 4
      })
    }

    if (isUserEncoder) {
      additionalActions.push({
        iconName: faSyringe,
        type: 'primary',
        title: props.t('users_view.additional_actions.manage_act_types'),
        onClick: setSelectedUserActTypes,
        disabledCallback: u => u.roles !== 5
      })
    }

    if (canResetPassword()) {
      // add the button to reset the password
      additionalActions.push({
        iconName: faKey,
        type: 'primary',
        title: props.t('users_view.additional_actions.reset_password'),
        onClick: setSelectedUserForPasswordReset,
        disabledCallback: u => u.isExternal,
        titleCallback: u => u.isExternal ? props.t('users_view.additional_actions.reset_password.callback') : props.t('users_view.additional_actions.reset_password')
      })
    }

    return (
      <div>
        <SmartTable
          columns={columns}
          data={data}
          loading={!props.getDataReady.institutions}
          onDataEdit={!isReadOnly ? handleEditUser : undefined}
          onDataAdd={!isReadOnly ? handleAddUser : undefined}
          onDataDelete={(!isReadOnly && props.enableDelete) ? handleRemoveUser : undefined}
          addDataText={props.t('users_view.smart_table.add_data_text')}
          noDataText={props.t('users_view.smart_table.no_data_text')}
          additionalActions={additionalActions}
        />
        <UserSupervisedStudents
          user={selectedUserSupervisedStudent}
          onClose={() => {
            setSelectedUserSupervisedStudent(null)

            getCountSupervisorStudents(props.getUser)
              .then(data => {
                setCountSupervisedStudents(data)
              })
          }}
          schoolId={props.schoolId}
        />
        <UserCoordinatedSections
          user={selectedUserCoordinatedSection}
          onClose={() => setSelectedUserCoordinatedSection(null)}
          schoolId={props.schoolId}
        />
        <UserManagedSectors
          user={selectedUserManagedSector}
          onClose={() => setSelectedUserManagedSector(null)}
          schoolId={props.schoolId}
        />
        <UserValidatorInstitutionSector
          user={selectedValidatorUser}
          onClose={() => setSelectedValidatorUser(null)}
          schoolId={props.schoolId}
          onSave={handleEditUser}
        />
        <UserActTypesDrawer
          selectedUser={selectedUserActTypes}
          setSelectedUser={setSelectedUserActTypes}
        />
        {canResetPassword() && (
          <AdminResetPassword
            userToResetPassword={selectedUserForPasswordReset}
            onClose={() => setSelectedUserForPasswordReset(null)}
          />
        )}
        <Modal
          title={deletedModal.title}
          visible={deletedModal.user !== null}
          onOk={handleReviveUser}
          onCancel={handleReviveCancel}
          footer={deletedModal.footer
            ? (
              <div>
                <Button onClick={handleReviveCancel}> {props.t('users_view.delete_modal.footer.cancel')} </Button>
                <Button onClick={handleReviveUser} type='primary' loading={revivalLoading}>
                  {props.t('users_view.delete_modal.footer.restore')}
                </Button>
              </div>
            )
            : null}
        >
          {deletedModal.body}
        </Modal>
      </div>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
