import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getChangelog = user => {
  return requestWithPromise(
    '/user-notification/changelog',
    HttpMethods.GET,
    null,
    user
  )
}

export const consultAllChangelogs = user => {
  return requestWithPromise(
    '/user-notification/consult/all',
    HttpMethods.POST,
    {},
    user
  )
}
