import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColorsPalette } from '../../config/colors'

/** @enum {string} */
export const FontSizes = Object.freeze({
  SMALL: 'small',
  REGULAR: 'regular',
  LARGE: 'large',
  LARGER: 'larger'
})
const ICON_SIZE_DEFAULT = { height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }

const IconLabel = ({ fontSize, icon, padding = 'pdl-8', text, color = ColorsPalette.SECOND }) => {
  const fontSizeClass = useMemo(() => {
    let size = 'ft-reg'

    if (fontSize === FontSizes.SMALL) {
      size = 'ft-sm'
    } else if (fontSize === FontSizes.LARGE) {
      size = 'ft-l'
    } else if (fontSize === FontSizes.LARGER) {
      size = 'ft-lg'
    }

    return size
  }, [fontSize])

  return (
    <div className={`flex-row ${padding} aln-itm-ctr`}>
      <div style={ICON_SIZE_DEFAULT}> <FontAwesomeIcon icon={icon} color={color} /> </div>
      <span className={`${fontSizeClass} ml-8`}> {text} </span>
    </div>
  )
}

export default IconLabel
