import React, { useMemo, useState } from 'react'

import { faCheck, faDownload, faFileImport, faKey } from '@fortawesome/pro-solid-svg-icons'
import { Modal, notification } from 'antd'
import SmartTable, { SmartTableDataTypes } from '../../shared/SmartTable'
import StudentInfo from '../../shared/StudentInfo'
import moment from 'moment'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import { dateStringOrNull, downloadFile, generalErrorHandler, objectDeepCopy, request, SmartTableInputTypes, validateFormInput } from '../../../utils'
import AdminResetPassword from '../../shared/AdminResetPassword'
import StudentsImportModal from '../StudentsImportModal'
import { getFullSectionName } from '../Sections/utils/DataDiverter'
import { formatStudentBirthdateInBackendForm } from '../../../utils/entitiesFormaters/Student'
import { arraySortingByKeyAndType } from '../../../utils/sorting'
import { COUNTRIES, EUROPEAN_DATE, GENDER_OPTIONS, LanguageLocales } from '../../../utils/constants'
import { HttpMethods, onInfo, onSuccess, onWarning } from '../../../utils/apiHelper'
import { isAdmin, isCoordinator, isDomainEvaluator, isEncoder, isSupervisor } from '../../../utils/roles'
import IconButton from '../../antd/Buttons/IconButton'

import '../../../assets/school-students-view.scss'

const Students = props => {
  const [selectedStudentInfo, setSelectedStudentInfo] = useState(null)
  const [selectedStudentForPasswordReset, setSelectedStudentForPasswordReset] = useState(null)
  const [refreshingStudents, setRefreshingStudents] = useState(false)
  const [uploadModalDisplayed, setUploadModalDisplayed] = useState(false)
  const [existingStudentData, setExistingStudentData] = useState(null)
  const [downloadingTemplate, setDownloadingTemplate] = useState(false)

  const permissions = useMemo(() => ({
    canSeeSchoolYearInSection: isAdmin(props.getUser) || isEncoder(props.getUser) || isCoordinator(props.getUser) || isSupervisor(props.getUser) || isDomainEvaluator(props.getUser),
    canSeeNiss: isAdmin(props.getUser) || isEncoder(props.getUser) || isCoordinator(props.getUser),
    canResetPassword: isAdmin(props.getUser) || isEncoder(props.getUser),
    hasEncoderPermissions: isEncoder(props.getUser),
    hasCoordinatorPermissions: isCoordinator(props.getUser),
    hasSupervisorPermissions: isSupervisor(props.getUser)
  }), [props.getUser])

  const languages = useMemo(() => (props.getLanguages == null && props.getUser?.language == null)
    ? []
    : props.getLanguages
      .map(({ code, name }) => ({
        id: code,
        name: props.t(name)
      }))
      .sort((a, b) => {
        const defaultLanguage = props.getUser.language ?? LanguageLocales.EN
        let sortValue

        if (a.id === defaultLanguage) {
          sortValue = -1
        } else if (b.id === defaultLanguage) {
          sortValue = 1
        } else {
          sortValue = 0
        }

        return sortValue
      })
  , [props.getLanguages, props.getUser?.language])

  const addStudent = async (student, stopLoadingCallback) => {
    student.birthdate = student.birthdate === null || student.birthdate.trim().length === 0
      ? undefined
      : `${student.birthdate.split('/')[2]}-${student.birthdate.split('/')[1]
        }-${student.birthdate.split('/')[0]}`

    student.stillInSchool = true
    delete student.isActive

    try {
      const { data } = await request('/api/Student', HttpMethods.POST, student, props.getUser)

      if (!data) {
        stopLoadingCallback()

        return
      }

      handleStudentSaving(data)
      showNotificationIfStudentExist(data)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const showNotificationIfStudentExist = data => {
    if (data.studentAlreadyExists) {
      setExistingStudentData(data)
    }
  }

  const editStudent = async (student, stopLoadingCallback) => {
    student.birthdate = formatStudentBirthdateInBackendForm(student)

    if (student.user && student.user.id) {
      student.user = student.user.id
    }

    delete student.isActive

    const originalStudentValues = props.getStudents.find(item => item.id === student.id)
    student.originalSection = originalStudentValues.sections && originalStudentValues.sections.length ? originalStudentValues.sections[0].id : null

    try {
      const { data, message } = await request('/api/students/' + student.id, HttpMethods.PATCH, student, props.getUser)

      if (!data) {
        stopLoadingCallback()

        return
      }

      if (message === 'student.edit.only.section') {
        onInfo(props.t('students_view.edit_only_section'))
      } else if (message === 'student.edit.not.allowed') {
        onWarning(props.t('students_view.edit_not_allowed'))
      } else {
        onSuccess(props.t('students_view.edit_success'))
      }

      handleStudentSaving(data, student)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const handleStudentSaving = (student, oldStudent = null) => {
    if (student.birthdate) {
      const birthdate = student.birthdate.date ?? student.birthdate

      student.birthdate = { date: birthdate.split('T')[0] }
    }

    oldStudent ? updateStoredStudentData(student, oldStudent) : addNewStudentToStore(student)
  }

  const updateStoredStudentData = (student, oldStudent) => {
    student.user = oldStudent.user

    props.saveStudent(student, true, true)
  }

  const addNewStudentToStore = student => {
    props.saveStudent(student, false, true)
  }

  const deleteStudent = student => {
    props.removeStudent({
      studentId: student.id,
      currentUser: props.getUser
    })
    notification.success({ message: props.t('Recorded'), placement: 'bottomRight' })
  }

  const reloadStudentsAfterImport = async () => {
    setRefreshingStudents(true)
    await props.fetchStudents(props.getUser)
    setRefreshingStudents(false)
  }

  const handleCloseModal = () => {
    setUploadModalDisplayed(false)
  }

  const handleCloseStudentExistsNotification = () => {
    setExistingStudentData(null)
  }

  const handleDownloadTemplate = async () => {
    setDownloadingTemplate(true)
    await downloadFile('/students/download', 'students_template.xlsx', props.getUser)
    setDownloadingTemplate(false)
  }

  const formatSchoolSectionForSmartable = schoolSection => ({
    id: schoolSection.id,
    name: permissions.canSeeSchoolYearInSection
      ? getFullSectionName(schoolSection)
      : schoolSection.name,
    nameTitle: getFullSectionName(schoolSection, false, false)
  })

  const sections = useMemo(() => {
    if (props.getSchools?.length <= 0) return []

    const school = props.getSchools[0]

    let sections = props.getSchoolSections[school.id]
      ? objectDeepCopy(props.getSchoolSections[school.id])
      : []

    if (!permissions.hasEncoderPermissions) {
      sections.unshift(school.rootSection)
    }

    sections = sections.map(s => formatSchoolSectionForSmartable(s))
    sections = arraySortingByKeyAndType(sections, 'name', 'string')

    return sections
  }, [props.getSchools, props.getSchoolSections, permissions])

  const columns = useMemo(() => {
    const columns = [
      { type: SmartTableDataTypes.ID, key: 'id' },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('common.section'),
        key: 'section',
        options: sections
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Lastname'),
        key: 'lastname',
        validate: data => validateFormInput(SmartTableInputTypes.NAME, data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Firstname'),
        key: 'firstname',
        validate: data => validateFormInput(SmartTableInputTypes.NAME, data, true)
      },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('Language'),
        key: 'language',
        options: languages,
        preventAutoSelectDefaultValue: false
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Email'),
        key: 'email',
        validate: data => validateFormInput(SmartTableInputTypes.EMAIL, data, true)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Registration number'),
        key: 'matricule',
        isNullable: true,
        validate: data => validateFormInput(SmartTableInputTypes.FREE_TEXT, data, false)
      },
      {
        type: SmartTableDataTypes.STRING,
        name: props.t('Phone'),
        key: 'phoneNumber',
        validate: data => validateFormInput(SmartTableInputTypes.FREE_TEXT, data, false)
      },
      {
        type: SmartTableDataTypes.DATE,
        name: props.t('Birthdate'),
        key: 'birthdate',
        format: 'DD/MM/YYYY',
        isNullable: true,
        validate: data => validateFormInput(SmartTableInputTypes.FREE_TEXT, data, false)
      },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('Gender'),
        key: 'gender',
        options: GENDER_OPTIONS(props)
      },
      {
        type: SmartTableDataTypes.SELECT,
        name: props.t('Nationality'),
        key: 'nationality',
        options: COUNTRIES(props)
      }
    ]

    if (permissions.canSeeNiss) {
      columns.push(
        {
          type: SmartTableDataTypes.STRING,
          name: props.t('NISS'),
          key: 'niss',
          validate: data => validateFormInput(SmartTableInputTypes.FREE_TEXT, data, false),
          password: true
        }
      )
    }

    if (permissions.hasCoordinatorPermissions) {
      columns.push(
        {
          type: SmartTableDataTypes.DATE,
          name: props.t('Last login'),
          key: 'lastLogin',
          format: 'DD/MM/YYYY',
          disabled: true
        }
      )
      columns.push(
        {
          type: SmartTableDataTypes.STRING,
          name: props.t('Active'),
          key: 'isActive'
        }
      )
    }

    return columns
  }, [props.t, props.getUser, sections, permissions, languages])

  const additionalActions = useMemo(() => {
    const actions = [
      {
        iconName: 'user-graduate',
        type: 'primary',
        title: props.t('Student sheet'),
        onClick: setSelectedStudentInfo
      }
    ]

    if (permissions.canResetPassword) {
      // add the button to reset the password
      actions.push({
        iconName: faKey,
        type: 'primary',
        title: props.t('Reset password'),
        onClick: setSelectedStudentForPasswordReset,
        disabledCallback: s => s.user?.isExternal,
        titleCallback: s => s.user?.isExternal ? props.t('This user is external and cannot have its password reset') : ''
      })
    }

    return actions
  }, [props.t, setSelectedStudentInfo, permissions])

  const students = useMemo(() => Array.isArray(props.getStudents)
    ? props.getStudents.map(s => ({
      id: s.id,
      section: s.sections && s.sections.length > 0 ? s.sections[0].id : null,
      lastname: s.lastname,
      firstname: s.firstname,
      language: s.language,
      email: s.email,
      matricule: s.matricule,
      phoneNumber: s.phoneNumber,
      user: s.user,
      lastLogin: dateStringOrNull(s.lastLogin, EUROPEAN_DATE, 'date'),
      birthdate: dateStringOrNull(s.birthdate, EUROPEAN_DATE, 'date'),
      niss: s.niss ?? null,
      gender: s.gender ?? null,
      genderReadOnlyName: ' ',
      nationality: s.nationality ?? null,
      nationalityReadOnlyName: ' ',
      isActive: s.isActive ? props.t('misc.yes') : props.t('misc.no')
    }))
    : []
  , [props.getStudents])

  return (
    <div>
      <SmartTable
        columns={columns}
        data={students}
        onDataAdd={!isSupervisor(props.getUser) ? addStudent : undefined}
        onDataEdit={!isSupervisor(props.getUser) ? editStudent : undefined}
        onDataDelete={!isSupervisor(props.getUser) ? deleteStudent : undefined}
        loading={!props.getDataReady.students || refreshingStudents}
        noDataText={props.t('You do not have any student.')}
        addDataText={props.t('Add a student')}
        secondaryButtons={permissions.hasEncoderPermissions
          ? [
            <IconButton
              className='mr-10'
              key='download-students-button'
              loading={downloadingTemplate}
              onClick={handleDownloadTemplate}
              icon={faDownload}
              text={props.t('Download template')}
            />,
            <IconButton
              className='mr-10'
              key='import-students-button'
              loading={downloadingTemplate}
              onClick={() => setUploadModalDisplayed(true)}
              icon={faFileImport}
              text={props.t('Import students')}
            />
          ]
          : null}
        showSecondaryButtonsInFront
        additionalActions={additionalActions}
      />
      <StudentInfo
        student={selectedStudentInfo}
        onClose={() => setSelectedStudentInfo(null)}
      />
      {permissions.canResetPassword &&
        <AdminResetPassword
          userToResetPassword={selectedStudentForPasswordReset}
          onClose={() => setSelectedStudentForPasswordReset(null)}
        />}
      <StudentsImportModal
        displayed={uploadModalDisplayed}
        onClose={handleCloseModal}
        reloadStudentsAfterImport={reloadStudentsAfterImport}
      />
      <Modal
        title={props.t('Student already exists')}
        visible={existingStudentData !== null}
        onCancel={handleCloseStudentExistsNotification}
        footer={[
          <IconButton
            type='primary'
            key='close'
            icon={faCheck}
            text={props.t('OK')}
            onClick={handleCloseStudentExistsNotification}
          />
        ]}
      >
        {existingStudentData && (
          <>
            {/* eslint-disable-next-line */}
            <h3>{props.t('We found an existing student for email ${email}', { email: existingStudentData.email })}</h3>
            <div>{props.t('Below you can see the details of the existing student:')}</div>
            <ul className='existing-student-details'>
              <li>
                <label>{props.t('Lastname')}</label>
                <b>{existingStudentData.lastname || props.t('None')}</b>
              </li>
              <li>
                <label>{props.t('Firstname')}</label>
                <b>{existingStudentData.firstname || props.t('None')}</b>
              </li>
              <li>
                <label>{props.t('Registration number')}</label>
                <b>{existingStudentData.phoneNumber || props.t('None')}</b>
              </li>
              <li>
                <label>{props.t('Phone')}</label>
                <b>{existingStudentData.matricule || props.t('None')}</b>
              </li>
              <li>
                <label>{props.t('Birthdate')}</label>
                <b>{existingStudentData.birthdate ? moment(existingStudentData.birthdate.date).format('DD/MM/YYYY') : props.t('None')}</b>
              </li>
            </ul>
            <h3><b>{props.t('This existing student was linked to the section you selected.')}</b></h3>
          </>
        )}
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Students)
