import apiClient from "./apiClient"

const fetchInternshipNote = (user, internship) => apiClient
  .get(user, `api/internships/${internship}/internship-notes`)

const upsertInternshipNote = (user, internship, note) => apiClient
  .post(user, `api/internships/${internship}/internship-notes`, {note})

const deleteInternshipNote = (user, internship) => apiClient
  .delete(user, `api/internships/${internship}/internship-notes`)

export {
  fetchInternshipNote,
  upsertInternshipNote,
  deleteInternshipNote
}
