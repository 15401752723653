import { Environments } from './environments'
import { getEnvironment } from '../utils'

const SSOUrlForEnvironnement = {
  [Environments.DEV]: {
    opalAuthServeurWebapp: 'http://localhost:5175/auth/login',
    redirectUrl: 'redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fcallback&application_id=4008e139-8f85-411e-b223-a9658039b60e'
  },
  [Environments.TEST]: {
    opalAuthServeurWebapp: 'http://login.sandbox.opalsolutions.be/auth/login',
    redirectUrl: 'redirect_uri=https%3A%2F%2Fdemo.interneo.pro%2Fauth%2Fcallback&application_id=4008e139-8f85-411e-b223-a9658039b60e'
  },
  [Environments.SANDBOX]: {
    opalAuthServeurWebapp: 'http://login.sandbox.opalsolutions.be/auth/login',
    redirectUrl: 'redirect_uri=https%3A%2F%2Fsandbox.interneo.pro%2Fauth%2Fcallback&application_id=4008e139-8f85-411e-b223-a9658039b60e'
  },
  [Environments.STAGING]: {
    opalAuthServeurWebapp: 'https://login.staging.opalsolutions.be/auth/login',
    redirectUrl: 'redirect_uri=https%3A%2F%2Fstaging.interneo.pro%2Fauth%2Fcallback&application_id=4008e139-8f85-411e-b223-a9658039b60e'
  },
  [Environments.PROD]: {
    opalAuthServeurWebapp: 'https://login.opalsolutions.be/auth/login',
    redirectUrl: 'redirect_uri=https%3A%2F%2Fapp.interneo.pro%2Fauth%2Fcallback&application_id=4008e139-8f85-411e-b223-a9658039b60e'
  }
}

const appConfig = {
  devServer: process.env.REACT_APP_INTERNEO_API ?? 'http://localhost:8080',
  quotasServer: 'https://api-quotas.interneo.pro',
  sandboxServer: 'https://api.sandbox.interneo.pro',
  testServer: 'https://api-demo.interneo.pro',
  prodServer: 'https://api.interneo.pro',
  stagingServeur: 'https://api.staging.interneo.pro',
  opalAuthServeurWebapp: SSOUrlForEnvironnement[getEnvironment()].opalAuthServeurWebapp,
  redirectUrl: SSOUrlForEnvironnement[getEnvironment()].redirectUrl,
  applicationId: '4008e139-8f85-411e-b223-a9658039b60e',
  version: '1.108.0',
  googleTrackingIdDev: 'UA-143245810-1',
  googleTrackingIdProd: 'UA-143245810-2',
  apiPageSize: 30000,
  supportedBrowsers: [ // add other supported browsers
    {
      name: 'Chrome',
      minVersion: 'all' // use a number here if you want to limit a previous version
    },
    {
      name: 'Firefox',
      minVersion: 'all'
    },
    {
      name: 'Edge',
      minVersion: 'all'
    },
    {
      name: 'Opera',
      minVersion: 'all'
    },
    {
      name: 'Brave',
      minVersion: 'all'
    }
  ]
}

export default appConfig
