import React, { useEffect, useState } from 'react'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import SmartTable, { SmartTableDataTypes } from '../../Components/shared/SmartTable'
import { request, generalErrorHandler, decodeFrenchCharacters } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const MailsListView = props => {
  const [mailsList, setMailsList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // get the list of mails sent
    request('/api/UserMail/none/0/100', HttpMethods.GET, null, props.getUser).then(({ data }) => {
      setLoading(false)
      setMailsList(data.map(item => ({ ...item, subject: decodeFrenchCharacters(item.subject) })))
      // TODO: Implement loading of new pages
    }).catch(error => {
      generalErrorHandler(error)
    })
  }, [])

  const columns = [
    { type: SmartTableDataTypes.ID, key: 'id' },
    {
      type: SmartTableDataTypes.DATE,
      name: props.t('Sent on'),
      key: 'createdAt',
      format: 'DD/MM/YYYY @ HH:mm:ss'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('Recipient'),
      key: 'recipient'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('Subject'),
      key: 'subject'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('Template'),
      key: 'template'
    },
    {
      type: SmartTableDataTypes.BOOLEAN_TAG,
      name: props.t('Sent'),
      key: 'sent'
    }
  ]

  return (
    <div>
      <SmartTable
        columns={columns}
        data={mailsList}
        loading={loading}
        noDataText={props.t('No emails')}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MailsListView)
