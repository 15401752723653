import React, { createContext, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import useLocalStorage from '../hooks/UseLocalStorage'
import moment from 'moment'
import LOCAL_STORAGE_KEYS from '../utils/localStorage'
import { OrderDirections, OrderBy, DEFAULT_NUMBER_OF_WEEKS } from '../utils/constants'
import { OwnershipValues } from '../Components/shared/InternshipsManager/utils/constants'

const { INTERNSHIP_DATE, INTERNSHIP_FILTERS, INTERNSHIP_SEARCH } = LOCAL_STORAGE_KEYS.GLOBAL_FILTERS

export const DEFAULT_INTERNSHIP_FILTERS = Object.freeze({
  areStartedExcluded: false,
  institutions: [],
  order: { by: OrderBy.CREATED_AT, direction: OrderDirections.DESC },
  ownership: OwnershipValues.ALL,
  schools: [],
  sections: [],
  states: [],
  weeksShownCount: DEFAULT_NUMBER_OF_WEEKS
})

const mapStateToProps = state => ({
  user: getUser(state.getUser)
})

export const GlobalFiltersContext = createContext()

const GlobalFiltersProvider = ({ children, user }) => {
  const [internshipDate, setInternshipDate] = useLocalStorage(INTERNSHIP_DATE, moment().startOf('isoWeek'), true)
  const [internshipFilters, setInternshipFilters] = useLocalStorage(INTERNSHIP_FILTERS, DEFAULT_INTERNSHIP_FILTERS)
  const [internshipSearch, setInternshipSearch] = useLocalStorage(INTERNSHIP_SEARCH, '')
  const [selectedInstitution, setSelectedInstitution] = useState(null)

  return (
    <GlobalFiltersContext.Provider
      value={{
        internshipDate,
        internshipFilters,
        internshipSearch,
        selectedInstitution,
        setInternshipDate,
        setInternshipFilters,
        setInternshipSearch,
        setSelectedInstitution
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  )
}

export default connect(mapStateToProps)(GlobalFiltersProvider)
