import { useCallback, useEffect, useState } from 'react'

const sessionStorage = window.sessionStorage
const STORAGE_EVENT = 'storage'

const useSessionStorage = (storageKey, fallbackState) => {
  const [value, setValue] = useState(JSON.parse(sessionStorage.getItem(storageKey)) ?? fallbackState)

  const storeValue = useCallback(value => {
    setValue(value)
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [sessionStorage, storageKey, setValue])

  const updateValue = useCallback(event => {
    if (event.key === storageKey && event.newValue !== value) {
      setValue(event.newValue)
    }
  }, [storageKey, value, setValue])

  useEffect(() => {
    window.addEventListener(STORAGE_EVENT, updateValue)

    return () => window.removeEventListener(STORAGE_EVENT, updateValue)
  }, [updateValue])

  return [value, storeValue]
}

export default useSessionStorage
