import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { Drawer } from 'antd'
import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import { downloadFile, generalErrorHandler, request } from '../../utils'
import { makeUrlParameters, slugify } from '../../utils/dataFactories'
import { STANDARD_DRAWER_WIDTH } from '../../utils/constants'
import isObject from 'lodash'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { fetchTemplateVariables, getInstitutionTemplates, getInstitutionTemplatesVariables } from '../../reducers/InstitutionTemplatesReducer/InstitutionTemplatesReducer'
import { bindActionCreators } from 'redux'
import { HttpMethods } from '../../utils/apiHelper'

/** TODO : 29/10/2021 change this by t() calling when
 *    multi-language template will be implemented
 * */
const WITHOUT_SCHOOL_TEXT = 'Ecole inconnue'
const WIHTOUT_SECTION_TEXT = 'Sans section'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    templates: getInstitutionTemplates(state),
    templatesVariables: getInstitutionTemplatesVariables(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchTemplateVariables: bindActionCreators(fetchTemplateVariables, dispatch)
  }
}

const PublipostageDrawer = ({ student, templates, templatesVariables, user, fetchTemplateVariables, onClose, t }) => {
  const [school, setSchool] = useState(null)

  const columns = useMemo(() => [
    { type: SmartTableDataTypes.ID, key: 'id' },
    { type: SmartTableDataTypes.STRING, name: t('Template'), key: 'originalName' }
  ], [t])
  const localStudent = useMemo(() => {
    const localStudent = isObject(student) ? student : null

    if (localStudent !== null && !localStudent.school) {
      localStudent.school = school ? school.name : WITHOUT_SCHOOL_TEXT
    }

    return localStudent
  }, [student, school])

  useEffect(() => {
    if (templates && templates.length > 0) {
      templates.forEach(template => {
        if (!templatesVariables[template.id]) {
          fetchTemplateVariables(template.id, user)
        }
      })
    }
  }, [templates, user])

  useEffect(() => {
    if (student && !student.school) {
      request(
        `/students/${student.id}/school?section-name=${student.section}`,
        HttpMethods.GET,
        null,
        user
      ).then(json => setSchool(json.data))
    }
  }, [student, user])

  const onFileDownload = useCallback(template => {
    try {
      const filenameParts = template.originalName.split('.')

      filenameParts.pop()
      filenameParts.push(localStudent.lastname, localStudent.firstname)

      downloadFile(
          `/api/institution-templates/${template.id}/process` +
            makeUrlParameters({ ...localStudent, section: localStudent.section ?? WIHTOUT_SECTION_TEXT }, templatesVariables[template.id]),
          slugify(filenameParts.join(' ') + '.' + template.extension),
          user
      )
    } catch (err) {
      generalErrorHandler(err)
    }
  }, [localStudent, templatesVariables])

  return (
    <Drawer
      title={t('Student Publipostage')}
      width={STANDARD_DRAWER_WIDTH}
      onClose={onClose}
      visible={!!localStudent}
    >
      <SmartTable
        columns={columns}
        data={templates}
        addLineOnAddData={false}
        loading={false}
        noDataText={t(
          'There is no template available. If you desire to add a template, please contact Opal Solutions.'
        )}
        additionalActions={[
          { iconName: 'download', type: 'primary', title: t('Download file'), onClick: (i) => onFileDownload(i) }
        ]}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublipostageDrawer)
