import { request, requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'
import { InstitutionRoles, SchoolRoles } from '../../utils/constants'
import { CARPOOL_OPTIN_TEXTS } from '../../utils/entities/optin'

const SET_USER = 'SET_USER'
const SET_USER_AFFILIATED_SECTORS = 'SET_USER_AFFILIATED_SECTORS'
const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
const SET_SCHOOL_EDIT_SCHEDULES = 'SET_SCHOOL_EDIT_SCHEDULES'
const UPDATE_NISS = 'UPDATE_NISS'
const UPDATE_OPTIN = 'UPDATE_OPTIN'

export const getUser = (state) => {
  return state
}

export const setUser = (user) => {
  return {
    type: SET_USER,
    user: user
  }
}

export const setUserManagedSectors = (affiliatedSectors) => {
  return {
    type: SET_USER_AFFILIATED_SECTORS,
    payload: affiliatedSectors
  }
}

export const updateUserProfile = (user) => {
  return {
    type: UPDATE_USER_PROFILE,
    user: user
  }
}

export const getCanSchoolEditSchedules = (state) => {
  return state.canSchoolEditSchedulesByInstitution
}

export const fetchCanSchoolEditSchedulesByInstitution = (currentUser) => {
  return async function canSchoolEditSchedulesByInstitution (dispatch, getState) {
    const response = await request(
      `/api/schools/${currentUser.school.id}/school-permissions`,
      HttpMethods.GET,
      null,
      currentUser,
      { catchError: false })

    dispatch(setCanSchoolEditSchedules(response.data))
  }
}

export const updateNissByStudent = (body, user) => {
  return async function updateNissThunk (dispatch, getState) {
    requestWithPromise(
      '/api/student/update-niss/' + user.student.id,
      HttpMethods.PATCH,
      body,
      user
    ).then(jsonResponse => {
      if (jsonResponse) {
        dispatch(updateNiss(jsonResponse.data))
      }
    })
  }
}

export const setCanSchoolEditSchedules = (institutionList) => {
  return {
    type: SET_SCHOOL_EDIT_SCHEDULES,
    payload: institutionList
  }
}

export const updateNiss = (niss) => {
  return {
    type: UPDATE_NISS,
    payload: { niss }
  }
}

export const updateOptinPayload = (optin) => {
  return {
    type: UPDATE_OPTIN,
    payload: optin
  }
}

export default (state = [], action) => {
  switch (action.type) {
    case SET_USER: {
      // add a new property "hideStudentSectionForHospitalUser"
      // if user has one of the following roles, hide the student section information in some of the views
      let hideStudentSectionForHospitalUser = false

      // add another property which let's us know if the current user can see or not the schedule validation button
      let canValidateInternship = false
      if (action.user && action.user.roles) {
        hideStudentSectionForHospitalUser = action.user.roles.filter(item => [InstitutionRoles.NURSE, SchoolRoles.VALIDATOR].includes(item)).length > 0
        canValidateInternship = action.user.roles.filter(item => [InstitutionRoles.HOSPITAL_ADMIN, InstitutionRoles.NURSE, SchoolRoles.VALIDATOR, SchoolRoles.ENCODER, SchoolRoles.COORDINATOR, SchoolRoles.SUPERVISOR].includes(item)).length > 0
      }

      return { ...action.user, hideStudentSectionForHospitalUser, canValidateInternship }
    }
    case UPDATE_USER_PROFILE: {
      // update the first name, last name & language
      const userToUpdate = { ...state }
      const { firstname, lastname, language, email, username } = action.user
      userToUpdate.firstname = firstname
      userToUpdate.lastname = lastname
      userToUpdate.language = language
      userToUpdate.email = email
      userToUpdate.username = username
      return { ...userToUpdate }
    }
    case SET_USER_AFFILIATED_SECTORS: {
      return {
        ...state,
        affiliatedSectors: action.payload
      }
    }
    case UPDATE_NISS: {
      const updatedUser = { ...state }
      updatedUser.student.niss = action.payload.niss

      return updatedUser
    }
    case SET_SCHOOL_EDIT_SCHEDULES: {
      return { ...state, canSchoolEditSchedulesByInstitution: { ...action.payload } } }
    case UPDATE_OPTIN: {
      const updatedUser = { ...state }

      CARPOOL_OPTIN_TEXTS.forEach((t, i) => {
        if (updatedUser.optIns.find(optin => optin.text === t)) {
          updatedUser.optIns[i].accepted = action.payload
        }
      })

      return updatedUser
    }
    default:
      return state
  }
}
