/** @enum */
const CellThresholds = Object.freeze({
  UNFILLED: 1,
  FILLED: 0,
  OVERFILLED: -1
})

/** @enum {string} */
const MultiSelectModes = Object.freeze({
  SINGLE: 'SINGLE',
  COLUMN: 'COLUMN',
  ROW: 'ROW'
})

/** @enum {string} */
const CellColors = Object.freeze({
  FILLED: '#f9a36d',
  OVERFILLED: '#ff6961',
  OVERFILLED_TRANSPARENCY: 'rgba(255, 105, 97, 0.4)'
})

const getTresholdByPlaces = places => {
  if (places > 0) {
    return CellThresholds.UNFILLED
  }

  if (places < 0) {
    return CellThresholds.OVERFILLED
  }

  return CellThresholds.FILLED
}

export {
  CellThresholds,
  MultiSelectModes,
  CellColors,
  getTresholdByPlaces
}
