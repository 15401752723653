import React, { Component } from 'react'

import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import AlertFreezeModal from '../Components/shared/AlertFreezeModal'
import AppBanner from '../Components/shared/AppBanner'
import AppTour from '../Components/shared/AppTour'
import Help from '../Components/shared/Help'
import MenuDesktop from '../Components/shared/MenuDesktop'
import MenuMobile from '../Components/shared/MenuMobile'
import UserSettings from '../Components/shared/UserSettings'
import { Button, Menu, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog, faInfoCircle, faSignOutAlt, faTimes, faUserSecret } from '@fortawesome/pro-solid-svg-icons'
import UnsupportedBrowser from '../Components/shared/UnsupportedBrowser'
import { mapStateToProps, mapDispatchToProps, connect } from '../reducers/Dispatchers'
import { Routes } from '../routes'
import { SchoolOptions, Contexts, InstitutionRoles, SchoolRoles, OtherRoles } from '../utils/constants'
import { URIDecode } from '../utils/apiHelper'
import InternshipsToBeEvaluatedModal from '../HOC/InternshipsToBeEvaluatedModal'
import EvaluationsProvider from '../Providers/EvaluationsProvider'
import NotificationBell from '../Components/shared/NotificationBell'
import LanguagesMenu from '../Components/shared/LanguagesMenu'
import DomainEvaluatorRouter from '../Routers/DomainEvaluatorRouter'
import SchoolRouter from '../Routers/SchoolRouter'
import InstitutionRouter from '../Routers/InstitutionRouter'
import StudentRouter from '../Routers/StudentRouter'
import AdminRouter from '../Routers/AdminRouter'
import InstitutionGroupAdminRouter from '../Routers/InstitutionGroupAdminRouter'
import ObserverRouter from '../Routers/ObserverRouter'
import { getAdminMenu, getInstitutionMenu, getSchoolMenu, getStudentMenu, getObserversMenu } from '../utils/menus'
import ValidatorRouter from '../Routers/ValidatorRouter'
import InternshipDocumentsDrawer from '../Providers/Drawer/InternshipDocumentsDrawer'
import MessageBox from '../Components/shared/MessageBox'
import InternshipMessageProvider from '../Providers/InternshipMessageProvider'
import NewMessagesNotification from '../Components/shared/NewMessagesNotification'
import StudentQuickMenuDropdownProvider from '../Providers/StudentQuickMenuDropdownProvider'

const { CUSTOM_LOGO } = SchoolOptions

class AppManager extends Component {
  state = {
    desktopMenuCollapsed: true,
    mobileMenuCollapsed: true,
    menuItems: [],
    moduleOption: null
  };

  /**
   * contentWrapperRef -> this is the ref of the content div. It's used to determine how much the div was scrolled
   */
  contentWrapperRef = React.createRef();

  componentDidMount () {
    this.updateMenuData()
  }

  componentDidUpdate (previousProps) {
    const { getActiveLanguage, getUser, getInstitutionOptionTypes, getInstitutionOptions } = this.props

    if (
      previousProps.getActiveLanguage !== getActiveLanguage ||
      previousProps.getUser.id !== getUser.id ||
      getInstitutionOptionTypes.optionTypes !== previousProps.getInstitutionOptionTypes.optionTypes ||
      getInstitutionOptions !== previousProps.getInstitutionOptions

    ) {
      this.updateMenuData(getInstitutionOptionTypes.optionTypes, getInstitutionOptions)
    }
  }

  updateMenuData = (institutionOptionsType, institutionOptions) => {
    const user = this.props.getUser
    const { context, roles, institutions } = user
    const t = this.props.t
    let menuItems = []

    if (context === Contexts.ADMIN) {
      menuItems = getAdminMenu(t)
    } else if (context === Contexts.SCHOOL) {
      menuItems = getSchoolMenu(t, roles)
    } else if (context === Contexts.INSTITUTION && roles.includes(InstitutionRoles.OBSERVER)) {
      menuItems = getObserversMenu(t)
    } else if (context === Contexts.INSTITUTION) {
      menuItems = getInstitutionMenu(t, roles, institutions[0], institutionOptionsType, institutionOptions, (state) => this.setState(state))
    } else if (context === Contexts.STUDENT) {
      menuItems = getStudentMenu(t)
    }

    menuItems.push({
      iconName: faInfoCircle,
      label: t('Changelog'),
      route: Routes.CHANGELOG
    })

    this.setState({ menuItems })
  };

  handleToggleDesktopMenuExpand = () => {
    this.setState({ desktopMenuCollapsed: !this.state.desktopMenuCollapsed })
    // Some components in the app need the window resize event for their rendering (SmartTable, InternshipsManager, ...).
    // The menu transition is set to 500ms in the CSS, we trigger the resize event after 550ms to be sure it is finished.

    setTimeout(function () {
      var event = window.document.createEvent('UIEvents')
      event.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(event)
    }, 550)
  };

  handleToggleMobileMenuExpand = () => {
    this.setState({
      mobileMenuCollapsed: !this.state.mobileMenuCollapsed
    })
  };

  /**
   * This is called in 100 ms after a scroll happens on the main content div
   */
  handleOnScrollDebounced = debounce(() => {
    const {
      getAppScroll: { hScroll },
      setAppHorizontalScroll,
      location
    } = this.props
    if (
      location.pathname === Routes.SHIFTS_MANAGER &&
      this.contentWrapperRef.current
    ) {
      const { scrollLeft } = this.contentWrapperRef.current
      if (hScroll !== scrollLeft) {
        setAppHorizontalScroll(scrollLeft)
      }
    }
  }, 100);

  renderContent = () => {
    const {
      getUser: {
        context,
        roles,
        switchUser,
        coordinatedSections,
        affiliatedSectors,
        institutions,
        school
      },
      getAppScroll: { width }
    } = this.props

    const userSwitchBarWidth =
      width && width > window.innerWidth ? width + 20 : 'auto' // I've added 20 which is 2 * 10px paddings

    if (this.state.menuItems.length === 0) return null

    let accessRestricted = false
    let switchUserInfo = null
    if (typeof switchUser !== 'undefined') {
      switchUserInfo = (
        <div
          className={`inner-spacing userswitch-info flex-row ${context === Contexts.STUDENT ? 'sticky' : ''}`}
          style={{ width: userSwitchBarWidth }}
        >
          <FontAwesomeIcon icon={faUserSecret} />
          &nbsp;{this.props.t('Connected as')}:&nbsp;
          {URIDecode(switchUser)}
          {roles.map(r => {
            return <span key={r}>&nbsp;[{r}]</span>
          })}
          {institutions && institutions.map(i => {
            return <span key={i.id}> &nbsp; - {i.name}</span>
          })}
          {school && <span>&nbsp; {school.name}</span>}
        </div>
      )
    }

    let alertFreezeModal = null
    if (roles.indexOf(SchoolRoles.COORDINATOR) > -1) {
      let userSections = coordinatedSections
      if (this.props.getSchools.length > 0) {
        userSections = userSections.filter(
          us => us.id !== this.props.getSchools[0].rootSection.id
        )
      }
      if (userSections.length === 0) {
        alertFreezeModal = (
          <AlertFreezeModal
            text={
              <span>
                {this.props.t(
                  'You connected as a sections coordinator, but your account does not coordinate any section.'
                )}
                &nbsp;
                {this.props.t(
                  'Please contact your Interneo administrator in order to fix that.'
                )}
              </span>
            }
            actionButton={
              <Button type='primary' onClick={this.props.onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                &nbsp;{this.props.t('Log out')}
              </Button>
            }
          />
        )
        accessRestricted = true
      }
    }

    let Router = null

    if (roles.includes(SchoolRoles.DOMAIN_EVALUATOR)) {
      Router = DomainEvaluatorRouter
    } else if (context === Contexts.SCHOOL) {
      Router = SchoolRouter
    } else if (roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN)) {
      Router = InstitutionGroupAdminRouter
    } else if (roles.includes(SchoolRoles.VALIDATOR)) {
      Router = ValidatorRouter
    } else if (context === Contexts.INSTITUTION && roles.includes(InstitutionRoles.OBSERVER)) {
      Router = ObserverRouter
    } else if (context === Contexts.INSTITUTION) {
      Router = InstitutionRouter
    } else if (context === Contexts.STUDENT) {
      Router = StudentRouter
    } else if (context === Contexts.ADMIN) {
      Router = AdminRouter
    }

    if (roles.indexOf(InstitutionRoles.NURSE) > -1 && affiliatedSectors.length === 0) {
      alertFreezeModal = (
        <AlertFreezeModal
          text={
            <span>
              {this.props.t(
                'You connected as an care units supervisor, but your account does not supervise any care unit.'
              )}
              &nbsp;
              {this.props.t(
                'Please contact your Interneo administrator in order to fix that.'
              )}
            </span>
          }
          actionButton={
            <Button type='primary' onClick={this.props.onLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              &nbsp;{this.props.t('Log out')}
            </Button>
          }
        />
      )
      accessRestricted = true
    }

    return (
      <div
        className='flex-column flex-fill'
        style={{ overflow: 'auto' }}
        onScroll={this.handleOnScrollDebounced}
        ref={this.contentWrapperRef}
      >
        <AppBanner />
        {switchUserInfo}
        {alertFreezeModal}
        <div className={context === Contexts.STUDENT ? '' : 'inner-spacing'} style={{ paddingBottom: '0px' }}>
          {!accessRestricted && (
            <InternshipsToBeEvaluatedModal>
              <InternshipDocumentsDrawer>
                <StudentQuickMenuDropdownProvider>
                  {[Contexts.INSTITUTION, Contexts.SCHOOL].includes(context)
                    ? (
                      <InternshipMessageProvider>
                        <Router moduleOption={this.state.moduleOption} />
                      </InternshipMessageProvider>
                    ) : (
                      <Router moduleOption={this.state.moduleOption} />
                    )}
                </StudentQuickMenuDropdownProvider>
              </InternshipDocumentsDrawer>
            </InternshipsToBeEvaluatedModal>
          )}
        </div>
      </div>
    )
  };

  renderTopBarMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <UserSettings />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.props.onLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          &nbsp;{this.props.t('Log out')}
        </Menu.Item>
        <Menu.Divider />
        {this.props.languagesMenu}
      </Menu>
    )
  };

  render () {
    const {
      location: { pathname }
    } = this.props

    const menuItems = this.state.menuItems
    menuItems.map(menuItem => {
      menuItem.showLabel = !this.state.desktopMenuCollapsed
      return menuItem
    })

    let currentPage = this.state.menuItems.filter(
      mi => mi.route === pathname
    )[0]
    if (!currentPage && pathname === Routes.SHIFTS_MANAGER) {
      // set the page name
      currentPage = { label: this.props.t('Shifts Manager') }
    }

    const allDataReady =
      Object.keys(this.props.getDataReady).filter(
        d => !this.props.getDataReady[d]
      ).length === 0
    const loadingIcon = allDataReady || this.props.getUser.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN) ? null : (
      <Tooltip placement='top' title={this.props.t('Data are loading.')}>
        <div className='loading-icon' />
      </Tooltip>
    )

    const customLogoOption = this.props.schoolOptions.find(so => so.optionType.type === CUSTOM_LOGO && so.optionEnabled)
    let customLogo = null

    if (customLogoOption) {
      customLogo = customLogoOption.optionValue
    }

    const primaryUserInfos = {
      firstname: this.props.getUser.firstname,
      lastname: this.props.getUser.lastname,
      username: this.props.getUser.username
    }

    return (
      <div className='flex-row' style={{ width: '100vw', height: '100vh' }}>
        <MenuDesktop
          collapsed={this.state.desktopMenuCollapsed}
          menuItems={this.state.menuItems}
        />
        <EvaluationsProvider>
          <div className='flex-column flex-fill' style={{ overflow: 'auto' }}>
            <div
              className='inner-spacing flex-row top-bar'
              style={customLogo ? { height: '70px', alignItems: 'center' } : { height: '52px' }}
            >
              <Tooltip
                placement='top'
                title={
                  this.state.desktopMenuCollapsed
                    ? this.props.t('Expand menu')
                    : this.props.t('Collapse menu')
                }
              >
                <Button
                  className='hidden-mobile'
                  type='primary'
                  onClick={this.handleToggleDesktopMenuExpand}
                >
                  <FontAwesomeIcon icon={faBars} />
                </Button>
              </Tooltip>
              <Button
                className='hidden-desktop'
                type='primary'
                onClick={this.handleToggleMobileMenuExpand}
              >
                <FontAwesomeIcon
                  icon={this.state.mobileMenuCollapsed ? faBars : faTimes}
                />
              </Button>
              <div className='h-spacing' />
              <div style={{ fontSize: '18px', marginTop: '2px' }}>
                {typeof currentPage === 'undefined' ? '' : currentPage.label}
              </div>
              <div className='h-spacing' />
              {customLogo && (
                <img src={'/assets/' + customLogo} style={{ marginRight: '10px' }} />
              )}
              {loadingIcon}
              <div className='flex-fill' />
              {this.props.getUser.roles.includes(OtherRoles.STUDENT) && (
                <NotificationBell />
              )}
              {[Contexts.INSTITUTION, Contexts.SCHOOL].includes(this.props.getUser.context) && (
                <InternshipMessageProvider>
                  <NewMessagesNotification />
                  <MessageBox />
                </InternshipMessageProvider>
              )}
              <div className='h-spacing' />
              <div className='flex-row'>
                <div style={{ fontSize: '18px', marginTop: '2px' }}>
                  {typeof currentPage === 'undefined' ? ''
                    : <div>{primaryUserInfos.firstname} {primaryUserInfos.lastname} ({primaryUserInfos.username})</div>}
                </div>
                <div className='h-spacing' />
                <Help page={currentPage} />
                <div className='h-spacing' />
                <LanguagesMenu
                  icon={faCog}
                  title={this.props.t('Settings')}
                  type='primary'
                  onLogout={this.props.onLogout}
                />
              </div>
            </div>
            <MenuMobile
              collapsed={this.state.mobileMenuCollapsed}
              menuItems={this.state.menuItems}
            />
            <AppTour />
            <UnsupportedBrowser />
            {this.renderContent()}
          </div>
        </EvaluationsProvider>
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppManager)
