import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import { connect } from '../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const ContactPersonSections = props => {
  const [contactPersonSections, setContactPersonSections] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.section !== undefined && props.section !== null && props.getUser) {
      requestWithPromise(`/api/school-sections/${props.section.id}/contact-persons`, HttpMethods.GET, null, props.getUser)
        .then((jsonData) => {
          setContactPersonSections(jsonData.data)
          setLoading(false)
        })
    } else {
      setLoading(true)
    }
  }, [props.section, props.getUser])

  const COLUMNS = [
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('lastname'),
      key: 'firstname'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('firstname'),
      key: 'lastname'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('Email'),
      key: 'email'
    },
    {
      type: SmartTableDataTypes.STRING,
      name: props.t('Phone number'),
      key: 'phone'
    }
  ]

  return (
    <Drawer
      title={props.section !== undefined && props.section !== null ? props.t('Contact persons of') + ' ' + props.section.name : '?'}
      width='640px'
      onClose={props.onClose}
      visible={props.section !== null}
    >
      <SmartTable
        columns={COLUMNS}
        data={contactPersonSections}
        loading={loading}
        noDataText={props.t('Without person contact')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSections)
