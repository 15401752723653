import React, { createContext, useState } from 'react'
import UserActTypesDrawer from './UserActTypesDrawer';

const UserActTypesDrawerContext = createContext();

const UserActTypesDrawerProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(null)

  return (
    <>
      <UserActTypesDrawerContext.Provider value={{ selectedUser, setSelectedUser }}>
        {children}
      </UserActTypesDrawerContext.Provider>
      <UserActTypesDrawer selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    </>
  )
}

export {
  UserActTypesDrawerProvider as default,
  UserActTypesDrawerContext
}
