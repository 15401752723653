import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getChildren = (option, user) => {
  return requestWithPromise(
    `/api/institution-options/${option.id}/children`,
    HttpMethods.GET,
    null,
    user
  )
}
