import React, { useMemo } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import OrderBySelector from './OrderBySelector'
import { Contexts, OrderBy } from '../../utils/constants'

const DEFAULT_OPTIONS = [
  { key: 'creation-date', value: OrderBy.CREATED_AT, text: 'Creation date' },
  { key: 'start-date', value: OrderBy.START_DATE, text: 'Start date' },
  { key: 'student', value: OrderBy.STUDENT, text: 'Students' },
  { key: 'section', value: OrderBy.SECTION, text: 'Sections' },
  { key: 'sector', value: OrderBy.SECTOR, text: 'Care units' }
]
const INSTITUTION_ADDITIONAL_OPTION = {
  key: 'school', value: OrderBy.SCHOOL, text: 'Schools'
}
const SCHOOL_ADDITIONAL_OPTION = {
  key: 'institution', value: OrderBy.INSTITUTION, text: 'Institutions'
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state)
  }
}

const InternshipStatesOrderBy = ({ direction, user, value, onChange, onDirectionChange, t }) => {
  const additionalOption = useMemo(() => {
    let option = null

    if (user.context === Contexts.SCHOOL) {
      option = SCHOOL_ADDITIONAL_OPTION
    } else if (user.context === Contexts.INSTITUTION) {
      option = INSTITUTION_ADDITIONAL_OPTION
    }

    return option
  }, [user.context])
  const options = useMemo(() => {
    const options = DEFAULT_OPTIONS.map(option => {
      return { ...option, text: t(option.text) }
    })

    if (additionalOption !== null) {
      options.push(additionalOption)
    }

    return options
  }, [additionalOption, t])

  return (
    <OrderBySelector
      direction={direction}
      options={options}
      value={value ?? OrderBy.CREATED_AT}
      onChange={onChange}
      onDirectionChange={onDirectionChange}
    />
  )
}

export default connect(mapStateToProps)(InternshipStatesOrderBy)
