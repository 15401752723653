import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getShiftActs = (shift, user) => {
  return requestWithPromise(
    `/api/shifts/${shift.id}/acts`,
    HttpMethods.GET,
    null,
    user
  )
}

export const createShiftAct = (shift, act, user) => {
  return requestWithPromise(
    `/api/shifts/${shift.id}/acts`,
    HttpMethods.POST,
    act,
    user
  )
}
