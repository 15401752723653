import moment, { isMoment } from 'moment'
import { getTimezoneLessMoment } from '../momentjs'
import { EUROPEAN_DATE } from '../constants'
import { getIdOrNull, isObject, validateString } from '..'
import { ValidationFields } from '../validators'
import { isNumber, isString } from 'lodash'
import { API_DATE_FORMAT } from '../apiHelper'

// internship's states
export const PRE_ACCEPTED = -3
export const PENDING_STUDENT_DATA_MISSING = -2
export const PENDING_NO_SECTOR = -1
export const CREATED = 0
export const PENDING = 1
export const VALIDATED = 2 // actually unused
export const ACCEPTED = 3
export const AUTO_ACCEPTED = 4
export const REFUSED = 5
export const CANCELED = 6
export const ACCEPTED_UNMANAGED = 7
export const SCHEDULE_ADDED = 8
export const SCHEDULE_VALIDATED = 9
export const SCHEDULE_TO_VALIDATE = 10

export const Ownership = Object.freeze({
  INSTITUTION: 'INSTITUTION',
  SCHOOL: 'SCHOOL'
})

export const makeInternship = ({ startDate, endDate, school, institution, sector, internshipYear, student }) => {
  return {
    startDate: startDate ?? moment(),
    endDate: endDate ?? moment(),
    school: school ?? null,
    institution: institution ?? null,
    sector: sector ?? null,
    student: student ?? null
  }
}

export const INTERNSHIP_WHITHOUT_STUDENT_VALIDATORS = [
  { key: ValidationFields.SCHOOL, validator: i => validateString(i.school, { notEmpty: true }) },
  { key: ValidationFields.START_DATE, validator: i => isMoment(i.startDate) },
  { key: ValidationFields.END_DATE, validator: i => isMoment(i.endDate) },
  { key: ValidationFields.SECTION, validator: i => isNumber(i.section) },
  { key: ValidationFields.SECTOR, validator: i => isNumber(i.sector) },
  { key: ValidationFields.INSTITUTION, validator: i => validateString(i.institution, { notEmpty: true }) }
]

export const getSchoolName = i => {
  return i.school?.name ?? '/'
}

export const getSchoolAbbreviation = i => {
  return i.school?.abbreviation ?? i.school?.name ?? '/'
}

export const getSectionName = i => {
  return i.internshipYear?.sectionAbbreviation ?? '/'
}
export const getSectionFullName = i => {
  return i.internshipYear?.sectionName ?? '/'
}

export const getSectionNameOrFull = i => {
  return i.internshipYear?.sectionAbbreviation ?? i.internshipYear?.sectionName ?? '/'
}

export const getSchoolYearName = i => {
  return i.internshipYear?.schoolYearName ?? '/'
}

export const getInstitutionName = i => {
  return i.institution?.name ?? '/'
}
export const getSectorName = i => {
  return i.sector?.name ?? '/'
}
export const getStudentLastname = i => {
  return i.student?.lastname ?? '/'
}

export const getStudentFirstname = i => {
  return i.student?.firstname ?? '/'
}

export const getStudentFullname = i => {
  return i.student ? `${i.student?.firstname} ${i.student?.lastname}` : '/'
}

export const getStudentRegistrationNumber = i => {
  let matricule = i.student?.matricule

  if (!matricule) {
    matricule = '/'
  }

  return matricule
}

export const getStudentEmail = i => {
  return i.student?.email ?? '/'
}

export const getStudentPhoneNumber = i => {
  let phoneNumber = i.student?.phoneNumber

  if (!phoneNumber) {
    phoneNumber = '/'
  }

  return phoneNumber
}

export const getStudentBirthdate = i => {
  let birthdate = i.student?.birthdate

  if (!birthdate) {
    birthdate = '/'
  }

  if (birthdate !== '/') {
    birthdate = getTimezoneLessMoment(birthdate).format(EUROPEAN_DATE)
  }

  return birthdate
}
export const getState = i => {
  return i.state
}
export const getEndDate = i => {
  return getTimezoneLessMoment(i.endDate).format(EUROPEAN_DATE)
}

export const getStartDate = i => {
  return getTimezoneLessMoment(i.startDate).format(EUROPEAN_DATE)
}

export const getCoordinatorFullname = i => {
  return isObject(i.coordinator) ? `${i.coordinator?.firstname} ${i.coordinator?.lastname}` : '/'
}

export class Internship {
  constructor ({ id = -1, institution = null, school = null, section = null, sector = null, student = null, startDate = moment(), endDate = moment() } = {}) {
    this.id = id
    this.institution = getIdOrNull(institution)
    this.school = getIdOrNull(school)
    this.section = getIdOrNull(section)
    this.sector = getIdOrNull(sector)
    this.student = getIdOrNull(student)
    this.startDate = isString(startDate) ? getTimezoneLessMoment(startDate) : startDate
    this.endDate = isString(endDate) ? getTimezoneLessMoment(endDate) : endDate
  }

  toRequestBody = () => {
    const body = { ...this }

    body.startDate = this.startDate.format(API_DATE_FORMAT)
    body.endDate = this.endDate.format(API_DATE_FORMAT)

    return body
  }
}
