import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import DataTable, { TIVOLI_EXPORT } from './DataTable/DataTable'
import { Metadata, retrieveMetadata } from '../../utils/http'
import moment from 'moment'
import { connect } from 'react-redux'
import { exportInternshipsByPeriod, exportInternshipsByPeriodTivoli, getAllInternships } from '../../utils/api/internship'
import {
  ACCEPTED, AUTO_ACCEPTED, PRE_ACCEPTED, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED, getInstitutionName, getSchoolName, getSchoolYearName,
  getSectionFullName, getSectionName, getSectorName, getState, getStudentBirthdate, getStudentEmail, getStudentFirstname, getStudentLastname, getStudentPhoneNumber,
  getStudentRegistrationNumber
} from '../../utils/entities/internship'
import { onError } from '../../utils/apiHelper'
import { FULL_DATE_FORMAT, getTimezoneLessMoment } from '../../utils/momentjs'
import { EUROPEAN_DATE, OrderBy, InstitutionRoles, InstitutionOptions } from '../../utils/constants'
import { faGraduationCap, faInfo } from '@fortawesome/pro-solid-svg-icons'
import { Action } from './DataTable/utils/actions'
import StudentInfo from './StudentInfo'
import InternshipAdditionalInfo from './InternshipAdditionalInfo'
import { PeriodExportParameters, getDocument } from '../../utils/api/export'
import { Tag } from 'antd'
import useSessionStorage from '../../hooks/UseSessionStorage'
import { TableParameters } from '../../utils/entities/tableParameters'
import Cookie from 'js-cookie'
import SelectInstitutionFilter from './DataTable/utils/SelectInstitutionFilter'
import { isArray } from 'lodash'
import { GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'
import { getInstitutionOptions } from '../../reducers/InstitutionOptionsReducer/actions'

const AUTHORIZED_STATES = [PRE_ACCEPTED, ACCEPTED, AUTO_ACCEPTED, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED]
const SESSION_STORAGE_SEARCH_KEY = 'badges-reporting-view.parameters'
const INITIAL_PERIOD = { startDate: moment().format(FULL_DATE_FORMAT), endDate: moment().add(4, 'weeks').format(FULL_DATE_FORMAT) }
const DEFAULT_PARAMETERS = { orderBy: OrderBy.EMAIL, pageSize: 20, period: INITIAL_PERIOD }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser),
  institutionOptions: getInstitutionOptions(state.institutionOptions)
})

const InternshipsReporting = ({ institutions, institutionOptions, user, t }) => {
  const { selectedInstitution, setSelectedInstitution } = useContext(GlobalFiltersContext)

  const [data, setData] = useState({ data: [], meta: new Metadata({ perPage: 20 }) })
  const [rawParameters, setRawParameters] = useSessionStorage(SESSION_STORAGE_SEARCH_KEY, new TableParameters({ ...DEFAULT_PARAMETERS }))
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [loading, setLoading] = useState(false)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '240px' : '199px'})`, [Cookie.get('switch_user_id')])
  const columns = useMemo(() => {
    const rawColumns = [
      { title: t('State'), orderBy: OrderBy.STATE, key: d => getTagState(getState(d)) },
      { title: t('Start'), orderBy: OrderBy.START_DATE, key: d => getTimezoneLessMoment(d.startDate).format(EUROPEAN_DATE) },
      { title: t('End'), orderBy: OrderBy.END_DATE, key: d => getTimezoneLessMoment(d.endDate).format(EUROPEAN_DATE) }
    ]

    if (user.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN)) {
      rawColumns.push({
        title: t('common.institution'),
        orderBy: OrderBy.INSTITUTION,
        key: getInstitutionName,
        style: { maxWidth: '200px' },
        tooltip: { getter: getInstitutionName, placement: 'topLeft' }
      })
    }

    return rawColumns.concat([
      { title: t('Care unit'), orderBy: OrderBy.SECTOR, key: getSectorName },
      { title: t('common.school'), orderBy: OrderBy.SCHOOL, key: getSchoolName },
      { title: t('common.section'), orderBy: OrderBy.SECTION, key: getSectionName, tooltip: getSectionFullName },
      { title: t('Internship year'), orderBy: OrderBy.SCHOOL_YEAR_NAME, key: getSchoolYearName },
      { title: t('Lastname'), orderBy: OrderBy.LASTNAME, key: getStudentLastname },
      { title: t('Firstname'), orderBy: OrderBy.FIRSTNAME, key: getStudentFirstname },
      { title: t('Email'), orderBy: OrderBy.EMAIL, key: getStudentEmail },
      { title: t('Registration number'), orderBy: OrderBy.REGISTRATION, key: getStudentRegistrationNumber },
      { title: t('Phone number'), orderBy: OrderBy.PHONE_NUMBER, key: getStudentPhoneNumber },
      { title: t('Birthdate'), orderBy: OrderBy.BIRTHDATE, key: getStudentBirthdate }
    ])
  }, [t, user])
  const actions = useMemo(() => [
    new Action({ title: 'Additional information', icon: faInfo, onClick: setSelectedInternship }),
    new Action({ title: 'Student sheet', icon: faGraduationCap, onClick: d => setSelectedStudent(d.student) })
  ], [setSelectedStudent, setSelectedInternship])
  const parameters = useMemo(() => new TableParameters({ ...rawParameters, filters: { ...rawParameters.filters, institution: selectedInstitution } }), [rawParameters, selectedInstitution])
  const filters = useMemo(() => {
    const filters = []

    if (isArray(institutions) && institutions.length > 0) {
      filters.push({
        key: 'institution',
        label: t('common.institution'),
        component: SelectInstitutionFilter,
        props: { institutions, value: selectedInstitution, onChange: setSelectedInstitution }
      })
    }

    return filters
  }, [t, SelectInstitutionFilter, institutions, selectedInstitution, setSelectedInstitution])
  const useCustomExport = useMemo(() => {
    let use = false

    for (const io of institutionOptions) {
      if (io.optionType.type === InstitutionOptions.TIVOLI_CUSTOM_EXPORT) {
        use = io.optionEnabled

        break
      }
    }

    return use
  }, [institutionOptions])

  const timer = useRef(null)

  useEffect(() => {
    if (parameters) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => fetchData(user, parameters), 300)
    }
  }, [user, parameters, timer])

  useEffect(() => {
    fetchData(user, parameters)
  }, [user, parameters])

  const getTagState = state => {
    let stateTag = <Tag color='red'> {t('Invalid state')} </Tag>

    if (state === PRE_ACCEPTED) {
      stateTag = <Tag color='geekblue'> {t('Accepted')} </Tag>
    } else if (state === ACCEPTED) {
      stateTag = <Tag color='green'> {t('Accepted')} </Tag>
    } else if (state === AUTO_ACCEPTED) {
      stateTag = <Tag color='green'> {t('Accepted')} </Tag>
    } else if (state === SCHEDULE_ADDED) {
      stateTag = <Tag color='blue'> {t('Scheduled')} </Tag>
    } else if (state === SCHEDULE_VALIDATED) {
      stateTag = <Tag color='purple'> {t('Validated')} </Tag>
    } else if (state === SCHEDULE_TO_VALIDATE) {
      stateTag = <Tag color='orange'> {t('In waiting of validation')} </Tag>
    }

    return stateTag
  }

  const fetchData = (user, parameters) => {
    setLoading(true)

    const queryParameters = new TableParameters({ ...parameters }).toQueryParametersOld()

    queryParameters.states = AUTHORIZED_STATES

    getAllInternships(user, queryParameters).then(json => {
      if (json?.data || json?.meta) {
        const data = {}

        data.data = json?.data ?? []
        data.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setData(data)
      }

      setLoading(false)
    })
  }

  const exportData = format => {
    const periodParams = new PeriodExportParameters({ ...parameters.period, format })
    const params = periodParams.toBody()
    params.filters = parameters.filters

    const promise = format === TIVOLI_EXPORT
      ? exportInternshipsByPeriodTivoli(user, params)
      : exportInternshipsByPeriod(user, params)

    promise.then(json => {
      getDocument(
        json.job,
        user,
        () => onError(t('An error has occured during the exported file retrieving'))
      )
    }).catch(response => {
      onError(t('An error has occured during the export'))
    })
  }

  return (
    <div className='internships-reporting-container' style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data.data}
        display={{ period: true }}
        filters={filters}
        loading={loading}
        metadata={data.meta}
        parameters={parameters}
        onExport={exportData}
        onParametersChange={setRawParameters}
        useCustomExport={useCustomExport}
      />
      <StudentInfo student={selectedStudent} onClose={() => setSelectedStudent(null)} />
      <InternshipAdditionalInfo
        internship={selectedInternship}
        onCancel={() => setSelectedInternship(null)}
        onOk={() => setSelectedInternship(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsReporting)
