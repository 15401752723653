import React from 'react'

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { DEFAULT_INFO_TOOLTIP_COLOR, FA_1X, TOOLTIP_TOP } from '../../institution/Quotas/utils/Constants'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const IconTooltip = ({ icon, iconColor, placement, size = FA_1X, title, t }) => {
  return (
    <Tooltip
      placement={placement ?? TOOLTIP_TOP}
      title={t(title)}
    >
      <FontAwesomeIcon
        icon={icon ?? faInfoCircle}
        color={iconColor ?? DEFAULT_INFO_TOOLTIP_COLOR}
        size={size}
      />
    </Tooltip>
  )
}

export default connect(mapStateToProps)(IconTooltip)
