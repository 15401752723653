import React, { useContext, useMemo } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Button, Divider, Menu, Select, DatePicker } from 'antd'
import { faBusinessTime, faCalendar, faCalendarAlt, faChartPie, faSchool, faSort } from '@fortawesome/pro-solid-svg-icons'
import IconLabel, { FontSizes } from '../../../../Components/icons/IconLabel'
import { isEncoder, isHospitalAdmin } from '../../../../utils/roles'
import DateTrimmer from '../../../../Components/shared/DateTrimmer'
import InternshipStatesOrderBy from '../../../../Components/shared/InternshipStatesOrderBy'
import { faHospital } from '@fortawesome/pro-regular-svg-icons'
import { EUROPEAN_DATE, Contexts, OrderBy, OrderDirections } from '../../../../utils/constants'
import { GlobalFiltersContext } from '../../../../Providers/GlobalFiltersProvider'
import { isArray, isFunction } from 'lodash'
import InstitutionsSelector from '../../../../Components/shared/InternshipsManager/Selectors/InstitutionsSelector'
import PeriodSelector from '../../../../Components/shared/InternshipsManager/Selectors/PeriodSelector'
import SchoolsSelector from '../../../../Components/shared/InternshipsManager/Selectors/SchoolsSelector'
import SectionsSelector from '../../../../Components/shared/InternshipsManager/Selectors/SectionsSelector'
import StatesSelector from '../../../../Components/shared/InternshipsManager/Selectors/StatesSelector'
import { Ownership } from '../../../../utils/entities/internship'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const OwnershipFilters = ['ALL', ...Object.values(Ownership)]

const InternshipFilters = ({
  loading, precision, precisionOptions, t, user,
  onPrecisionChange, onResetFilters, onFilterChange
}) => {
  const { internshipDate, internshipFilters, setInternshipFilters, setInternshipDate } = useContext(GlobalFiltersContext)
  const { areStartedExcluded, institutions, order, ownership, schools, sections, states, weeksShownCount } = internshipFilters

  const isEncoderUser = useMemo(() => isEncoder(user), [user])

  const Option = Select.Option

  const handleFilterChange = (value, key) => {
    onFilterChange()
    setInternshipFilters({ ...internshipFilters, [key]: value })
  }

  return (
    <Menu inlineIndent={0}>
      <div className='h-spacing' />
      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faBusinessTime} text={t('common.internship')} />}>
        <Menu.Item key='state' disabled style={{ cursor: 'default' }}>
          <StatesSelector
            padding={0}
            onCheck={s => handleFilterChange(s, 'states')}
            values={states}
          />
        </Menu.Item>
      </Menu.ItemGroup>

      {isEncoderUser && (<div className='h-spacing' />)}
      {isEncoderUser && (
        <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faHospital} text={t('common.institutions')} />}>
          <Menu.Item key='institutions' disabled style={{ cursor: 'default' }}>
            <InstitutionsSelector
              key='institutionsSelector'
              values={institutions}
              onChange={i => handleFilterChange(i, 'institutions')}
              style={{ width: '210px' }}
            />
          </Menu.Item>
        </Menu.ItemGroup>
      )}

      {isEncoderUser && (<div className='h-spacing' />)}
      {isEncoderUser && (
        <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faSchool} text={t('common.sections')} />}>
          <Menu.Item key='sections' disabled style={{ cursor: 'default' }}>
            <SectionsSelector
              key='sectionsSelector'
              values={sections}
              onChange={s => handleFilterChange(s, 'sections')}
              style={{ width: '210px' }}
            />
          </Menu.Item>
        </Menu.ItemGroup>
      )}

      {isHospitalAdmin(user) && (<div className='h-spacing' />)}
      {isHospitalAdmin(user) && (
        <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faSchool} text={t('common.schools')} />}>
          <Menu.Item key='schools' disabled style={{ cursor: 'default' }}>
            <SchoolsSelector
              key='schoolsSelector'
              values={schools}
              onChange={s => handleFilterChange(s, 'schools')}
              style={{ width: '210px' }}
            />
          </Menu.Item>
        </Menu.ItemGroup>
      )}

      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faSort} text={t('common.ownership')} />}>
        <Menu.Item key='config' disabled style={{ cursor: 'default', paddingBottom: 0 }}>
          <Select
            style={{ width: '210px' }}
            onSelect={o => handleFilterChange(o, 'ownership')}
            value={ownership}
          >
            {OwnershipFilters.map((o, index) => <Option key={o} value={o}> {t('common.' + o.toLowerCase())}  </Option>)}
          </Select>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faCalendar} text={t('internships_header.filters.date_trimmer')} />}>
        <Menu.Item key='filter-on-start-date' disabled style={{ cursor: 'default' }}>
          <DateTrimmer
            toggled={areStartedExcluded}
            onToggle={bool => handleFilterChange(bool, 'areStartedExcluded')}
          />
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faSort} text={t('common.order_by')} />}>
        <Menu.Item key='order' disabled style={{ cursor: 'default' }}>
          <InternshipStatesOrderBy
            value={order?.by ?? OrderBy.CREATED_AT}
            onChange={ob => setInternshipFilters({ ...internshipFilters, order: { ...internshipFilters.order, by: ob } })}
            direction={order?.direction ?? OrderDirections.DESC}
            onDirectionChange={sd => setInternshipFilters({ ...internshipFilters, order: { ...internshipFilters.order, direction: sd } })}
          />
        </Menu.Item>
      </Menu.ItemGroup>

      {user.context.includes(Contexts.INSTITUTION) && isArray(precisionOptions) && isFunction(onPrecisionChange) && (
        <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faChartPie} text={t('common.quotas')} />}>
          <Menu.Item key='precision' disabled style={{ cursor: 'default' }}>
            <Select value={precision} onChange={onPrecisionChange} style={{ width: '210px' }}>
              {precisionOptions.map((o, index) => {
                return (
                  <Select.Option key={'opt_' + index} value={o.value}>
                    {o.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Menu.Item>
        </Menu.ItemGroup>
      )}

      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faCalendar} text={t('common.date')} />}>
        <Menu.Item key='config' disabled style={{ cursor: 'default', paddingBottom: 0 }}>
          <DatePicker
            value={internshipDate}
            onChange={date => setInternshipDate(date.startOf('isoWeek'))}
            format={EUROPEAN_DATE}
            allowClear={false}
          />
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title={<IconLabel fontSize={FontSizes.SMALL} padding={0} icon={faCalendarAlt} text={t('common.period')} />}>
        <Menu.Item key='config' disabled style={{ cursor: 'default', paddingBottom: 0 }}>
          <PeriodSelector
            currentWeeksQuantity={weeksShownCount}
            onWeeksQuantityChange={w => handleFilterChange(w, 'weeksShownCount')}
          />
        </Menu.Item>
      </Menu.ItemGroup>

      <div><Divider style={{ marginTop: '9px', marginBottom: '9px' }} /></div>

      <div className='center'>
        <Button
          type='primary'
          onClick={onResetFilters}
          disabled={loading}
          ghost
          style={{ marginBottom: '5px' }}
        >
          {t('Reset filters')}
        </Button>
      </div>
    </Menu>
  )
}

export default connect(mapStateToProps)(InternshipFilters)
