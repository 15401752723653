import { downloadFile, requestMultipart, requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const getInstitution = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const fetchInstitutionSchools = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInstitutionSectors = (user, institution, parameters = { archived: false, strictMode: false }) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/sectors${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const updateInstitutionOption = (institution, institutionOption, enable, user) => {
  return requestWithPromise(`/api/institutions/${institution.id}/institution-options/${institutionOption.id}`,
    HttpMethods.PATCH,
    { optionEnabled: enable },
    user
  )
}

export const fetchInstitutionScore = (user, institution, endDate, startDate, additionalData) => {
  return requestWithPromise(
    `/institution/${institution.id}/evaluation-internships/score?end-date=${endDate}&start-date=${startDate}&additional-data=${additionalData}`,
    HttpMethods.GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getAllInstitutions = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInstitutionDocuments = (user, institution) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/documents`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInstitutionShiftPresets = (user, institution) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/shift-presets`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInstitutionUsers = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/users${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const updateInstitution = (user, institution) => {
  return requestWithPromise(
    '/api/Institution/' + institution.id,
    HttpMethods.PATCH,
    institution,
    user
  )
}

export const deleteInstitution = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}${makeQueryString(parameters)}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

/** @deprecated 2024-01-16 */
export const deleteInstitutionOld = (user, institution) => {
  return requestWithPromise(
    '/api/Institution/' + institution.id,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const createInstitution = (user, institution) => {
  return requestWithPromise(
    '/api/institution',
    HttpMethods.POST,
    institution,
    user
  )
}

export const getDesiderataLimitValue = (institution, user, parameters) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/desiderata-limit${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const linkSchoolToInstitution = (user, institution, school) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools`,
    HttpMethods.POST,
    { school: school.id },
    user
  )
}

export const unlinkSchoolFromInstitution = (user, institution, school) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools/${school.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const linkAreaInstitution = (user, institution, areas) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/areas`,
    HttpMethods.PATCH,
    { areas },
    user
  )
}

export const unLinkAreaInstitution = (user, institution, areas) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/areas${makeQueryString({ areas })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const getInstitutionEvaluationQuestions = (user, institution) => {
  return requestWithPromise(
    `/evaluation/questions/${institution.id}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const uploadInstitutionDocument = (user, institution, file) => {
  return requestMultipart(
    '/institution/document/upload',
    HttpMethods.POST,
    {
      'document[institution]': institution.id,
      file
    },
    user
  )
}

export const downloadInstitutionDocument = (user, document) => {
  return downloadFile(`/institution/document/download/${document.id}`, document.originalName, user)
}

export const deleteInstitutionDocument = (user, document) => {
  return requestWithPromise(
    `/api/InstitutionDocument/${document.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}
