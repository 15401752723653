import React, { useContext, useEffect, useState } from 'react'

import { faBell } from '@fortawesome/pro-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EvaluationsContext } from '../../Providers/EvaluationsProvider'
import { List, Popover, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { faSmile } from '@fortawesome/pro-regular-svg-icons'
import { Routes } from '../../routes'

import '../../assets/notification-bell.scss'

const ATTRACTIVITY_MODULE_SMILE_COLOR = '#0096ff'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const EVALUATION_INTERNSHIP_TYPE = 0

const NotificationBell = ({ history, t }) => {
  const { internshipsToEvaluate } = useContext(EvaluationsContext)

  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const newNotifications = []

    if (internshipsToEvaluate.length > 0) {
      const internshipString = ' internship' + (internshipsToEvaluate.length > 1 ? 's ' : ' ')

      newNotifications.push({
        text: (
          t('You have ') + t(internshipsToEvaluate.length.toString()) +
          t(internshipString + 'in waiting to be evaluated.')
        ),
        read: false,
        type: EVALUATION_INTERNSHIP_TYPE,
        icon: faSmile,
        color: ATTRACTIVITY_MODULE_SMILE_COLOR,
        route: Routes.INTERNSHIPS
      })
    }

    setUnreadNotifications(newNotifications.length)
    setNotifications(newNotifications)
  }, [internshipsToEvaluate, t])

  return (
    <div className='notification-bell-container'>
      <Popover
        content={(
          <List>
            {notifications.map((n, index) => {
              return (
                <List.Item key={'notification_' + index}>
                  <div
                    style={{ cursor: n.route ? 'pointer' : 'default' }}
                    className='notification-item-container'
                    onClick={() => {
                      if (n.route) {
                        setIsOpen(false)
                        history.push(n.route)
                      }
                    }}
                  >
                    <div className='icon-div'>
                      {n.icon && (
                        <FontAwesomeIcon icon={n.icon} color={n.color} size='2x' />
                      )}
                    </div>
                    <div className='text-div'>
                      {n.text}
                    </div>
                  </div>
                </List.Item>
              )
            })}
          </List>
        )}
        visible={isOpen}
        onVisibleChange={setIsOpen}
        placement='bottomRight'
        trigger='click'
        title={(<b>{t('Notifications')}</b>)}
      >
        <Tooltip
          placement='left'
          title={t('You have ') + unreadNotifications + t(' notification' + (unreadNotifications > 1 ? 's' : '') + ' in waiting')}
        >
          <FontAwesomeIcon icon={faBell} />
          {unreadNotifications > 0 && (
            <div className='number-bubble'> {unreadNotifications} </div>
          )}
        </Tooltip>
      </Popover>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(NotificationBell))
