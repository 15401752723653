import React, { useContext, useMemo, useRef, useState } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { faFileExport, faFilter, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { Dropdown, Menu, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import InternshipsManagerActionsHeaderExportModal from '../InternshipsManagerActionsHeaderExportModal'
import { checkIfJsonIsCorrect, downloadFile, generalErrorHandler, getLanguageByLocale } from '../../../utils'
import { HttpStatuses } from '../../../utils/http'
import { HttpMethods } from '../../../utils/apiHelper'
import PaginationHeader from './PaginationHeader'
import WeekNavigator from './WeekNavigator'
import CurrentWeekButton from './CurrentWeekButton'
import { DATE_FORMAT_SHOWN } from './utils/constants'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../../routes'
import ImportInternshipsButton from './ImportInternshipsButton'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import InternshipFilters from '../../../Views/institution/InternshipsView/InternshipsList/InternshipFilters'
import { isCoordinator, isDomainEvaluator, isEncoder, isValidator, isHospitalAdmin } from '../../../utils/roles'
import apiClient from '../../../utils/api/apiClient'
import IconButton from '../../antd/Buttons/IconButton'
import TooltipIconButton from '../../antd/Buttons/TooltipIconButton'
import InternshipFormModal, { InternshipFormModalContext } from '../../../Providers/Forms/InternshipForm/InternshipFormModal'
import { Internship } from '../../../utils/entities/internship'
import FormButton from '../../antd/Buttons/FormButton'

import '../../../assets/data-table.scss'
import './assets/internships-manager.scss'

export const PRECISION_YEAR = 'year'
const PRECISION_SCHOOL = 'school'
const PRECISION_SECTION = 'section'
const PRECISION_SECTOR = 'sector'
const PRECISION_OPTIONS = [
  { value: PRECISION_SECTOR, label: 'Quotas per sector' },
  { value: PRECISION_SECTION, label: 'Quotas per section' },
  { value: PRECISION_SCHOOL, label: 'Quotas per school' },
  { value: PRECISION_YEAR, label: 'Default quotas' }
]

const SEARCH_FIELD_STYLE = { width: 175 }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipsHeader = ({
  addMode, history, loading, metadata, page, precision, user,
  onFilterChange, onPageChange, onPrecisionChange, onResetFilters, t, toggleAddMode, refresh
}) => {
  const { setCurrentDate } = useContext(GlobalContext)
  const { internshipDate, internshipFilters, internshipSearch, setInternshipDate, setInternshipSearch } = useContext(GlobalFiltersContext)
  const { institutions, order, schools, sections, states, weeksShownCount } = internshipFilters

  const [exportLoading, setExportLoading] = useState(false)
  const [displayExportModal, setDisplayExportModal] = useState(false)

  const exportTimer = useRef()
  const clearingTimeout = useRef()

  const buttonsConfig = useMemo(() => {
    const shouldDisplayAddButton = isEncoder(user) || isCoordinator(user)
    let scheduleButtonLabel = 'internships_header.schedule_button.edit'

    if (isValidator(user)) {
      scheduleButtonLabel = 'internships_header.schedule_button.validate'
    } else if (isDomainEvaluator(user)) {
      scheduleButtonLabel = 'internships_header.schedule_button.see'
    }

    return { shouldDisplayAddButton, scheduleButtonLabel }
  }, [user])

  const precisionOptions = useMemo(() => PRECISION_OPTIONS.map(o => ({ ...o, label: t(o.label) })), [t])

  const handleExportTimeline = (startDate, endDate, checked, filter) => {
    setDisplayExportModal(false)
    setExportLoading(true)

    const body = {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      language: getLanguageByLocale(user.language)
    }

    if (checked) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          if (typeof body.filter === 'undefined') {
            body.filter = {}
          }

          body.filter[key] = filter[key]
        }
      })
    }

    apiClient.post(user, '/internship/export-timeline', body).then(json => {
      exportTimer.current = setInterval(() => getJobResult(json), 1500)
      clearingTimeout.current = setTimeout(() => {
        clearInterval(exportTimer.current)
        setExportLoading(false)
        notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })
      }, 20000)
    })
  }

  const getJobResult = json => {
    // Security in case of export failed to prevent crash loop
    if (!json) {
      clearInterval(exportTimer.current)
      clearTimeout(clearingTimeout.current)
      setExportLoading(false)

      notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })

      return
    }

    apiClient.get(user, `/job-result/${json.job}`).then(json => {
      if (checkIfJsonIsCorrect(json)) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)

        const fileName = json.data.result

        try {
          downloadFile(
            '/spreadsheet/download/' + fileName,
            fileName,
            user,
            HttpMethods.GET
          )
        } catch (err) {
          generalErrorHandler(err)

          notification.error({ message: t('Spreadsheet download failed'), placement: 'bottomRight' })
        }
      }
    }).catch(response => {
      if (response.status === HttpStatuses.INTERNAL_SERVER_ERROR) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)
      }
    })
  }

  return (
    <div className='internships-manager-header'>
      <div className='actions-and-filters'>
        <Search
          value={internshipSearch}
          placeholder={t('Filter internships')}
          onChange={e => { setInternshipSearch(e.target.value) }}
          style={SEARCH_FIELD_STYLE}
          className='app-tour-2'
        />

        <div className='h-spacing' />

        <Dropdown
          overlay={
            <Menu>
              <Menu.ItemGroup title={t('Filters')}>
                <Menu.Item disabled style={{ cursor: 'default' }}>
                  <InternshipFilters
                    internshipFilters={internshipFilters}
                    loading={loading}
                    precision={precision}
                    precisionOptions={precisionOptions}
                    user={user}
                    onPrecisionChange={onPrecisionChange}
                    onResetFilters={onResetFilters}
                    onFilterChange={onFilterChange}
                  />
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
          overlayClassName='custom-overlay'
          trigger={['click']}
        >
          <IconButton icon={faFilter} />
        </Dropdown>

        <div className='flex-fill' />
        <IconButton
          type='primary'
          icon={faCalendar}
          disabled={loading}
          ghost
          onClick={() => {
            setCurrentDate(internshipDate)
            history.push(Routes.SHIFTS_MANAGER)
          }}
          text={t(buttonsConfig.scheduleButtonLabel)}
        />
        {buttonsConfig.shouldDisplayAddButton && (
          <>
            <div className='h-spacing' />
            <TooltipIconButton
              title={t(`internships_header.add_internship_button.tooltip.${addMode ? 'hide' : 'show'}`)}
              text={t('internships_header.add_internship_button.label')}
              onClick={toggleAddMode}
              icon={faPlus}
              tooltipProps={{ placement: 'top' }}
              buttonProps={{
                type: 'primary',
                className: 'app-tour-1',
                disabled: loading,
                ghost: true
              }}
            />
          </>
        )}
        {isHospitalAdmin(user) && (
          <InternshipFormModal>
            <FormButton
              context={InternshipFormModalContext}
              icon={faPlus}
              text={t('internships_header.add_internship_button.label')}
              type='primary'
              disabled={loading}
              onClick={setter => setter({ object: new Internship({ institution: user.institutions[0].id }), callback: refresh })}
              ghost
            />
          </InternshipFormModal>
        )}
      </div>
      <div className='table-modifier-buttons'>
        <PaginationHeader
          pageIndex={page}
          maxPageIndex={metadata.totalPages}
          dataType='Internship'
          dataCount={metadata.total}
          onPageChange={onPageChange}
        />
        <div className='flex-fill' />
        <WeekNavigator
          loading={loading}
          date={internshipDate}
          dateFormat={DATE_FORMAT_SHOWN}
          onDateChange={setInternshipDate}
        />
        <div className='h-spacing' />
        <CurrentWeekButton
          date={internshipDate}
          loading={loading}
          title={t('Go back to current week')}
          onClick={setInternshipDate}
        />
        {isEncoder(user) && (
          <>
            <div className='h-spacing' />
            <ImportInternshipsButton
              afterUpload={() => setInternshipDate(internshipDate.clone())}
              school={user.school?.id ?? -1}
            />
          </>
        )}
        <div className='h-spacing' />
        <TooltipIconButton
          title={t('internships_header.export_timeline_button')}
          icon={faFileExport}
          onClick={() => setDisplayExportModal(true)}
          tooltipProps={{ placement: 'top' }}
          buttonProps={{ loading: exportLoading }}
        />
      </div>
      {displayExportModal && (
        <InternshipsManagerActionsHeaderExportModal
          startDate={internshipDate}
          weeksShownCount={weeksShownCount}
          filterSearch={internshipSearch}
          filterStates={states}
          filterSchools={schools}
          filterSections={sections}
          filterInstitutions={institutions}
          orderBy={order.by}
          orderDirection={order.direction}
          onCancel={() => setDisplayExportModal(false)}
          onExport={handleExportTimeline}
          visible
        />
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(InternshipsHeader))
