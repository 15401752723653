import { isObject } from '..'
import { getTimezoneLessMoment } from '../momentjs'
import { EventCodeTypes } from './eventCodeType'

export class Shift {
  constructor ({ id, endDate, endTime, eventCodeType, exactLocation, isDesiderata, pause, periodCode, shiftPreset, startDate, startTime }) {
    this.id = id ?? -1
    this.endDate = getTimezoneLessMoment(endDate)
    this.endTime = endTime ?? '00:00:00'
    this.eventCodeType = isObject(eventCodeType) ? eventCodeType : null
    this.exactLocation = exactLocation ?? null
    this.isDesiderata = isDesiderata ?? false
    this.pause = pause ?? 0
    this.periodCode = periodCode ?? null
    this.shiftPreset = shiftPreset ?? null
    this.startDate = getTimezoneLessMoment(startDate)
    this.startTime = startTime ?? '00:00:00'
  }

  getSchedule () {
    const startParts = this.startTime.split(':')
    const endParts = this.endTime.split(':')

    return startParts[0] + 'h' + startParts[1] + ' - ' + endParts[0] + 'h' + endParts[1]
  }

  getPauseIcon () {
    return this.shiftPreset?.shiftPause?.icon ?? null
  }

  isAbsent () {
    return this.eventCodeType && this.eventCodeType.category === EventCodeTypes.ABSENCE
  }
}
