import React, { useContext, useEffect, useRef, useState } from 'react'

import InternshipsTable from '../../Components/shared/InternshipsManager/InternshipsTable'
import InternshipsHeader, { PRECISION_YEAR } from '../../Components/shared/InternshipsManager/InternshipsHeader'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { DATE_WITHOUT_TIME, InternshipStates, Contexts } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'
import InternshipsManagerProvider from '../../Context/InternshipsManager/InternshipsManagerProvider'
import StudentInfo from '../../Components/shared/StudentInfo'
import InternshipInfo from '../../Components/shared/InternshipInfo'
import { deleteInternship, getAllInternships } from '../../utils/api/internship'
import { onError, onSuccess, purgeMetadata } from '../../utils/apiHelper'
import InternshipValidation from '../../Components/shared/ShiftsManager/InternshipValidation'
import { getState } from '../../reducers/InternshipsManagerReducer/actions'
import { INTERNSHIPS_MANAGER } from '../../reducers/InternshipsManagerReducer/actionsType'
import EarlyDateModal from '../../Components/shared/Modals/EarlyDateModal'
import { getTranslate } from 'react-localize-redux'
import { fetchQuotasTree } from '../../reducers/QuotasReducer/actions'
import { getInternshipsQuotas } from '../../utils/api/quota'
import InternshipRejection from '../../Components/shared/InternshipsManager/InternshipRejection'
import useLocalStorage from '../../hooks/UseLocalStorage'
import { DEFAULT_INTERNSHIP_FILTERS, GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'
import MessageBox from '../../Components/shared/MessageBox'
import { InternshipMessageContext } from '../../Providers/InternshipMessageProvider'
import LOCAL_STORAGE_KEYS from '../../utils/localStorage'

const { PRECISION } = LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER

const DEFAULT_META_DATA = {
  page: 1,
  last: 0,
  perPage: 10,
  totalPages: 1,
  total: 0
}
const TIME_BEFORE_SEARCH = 200
const INTERNSHIPS_NUMBER = 10

const mapStateToProps = state => ({
  refusedInternship: getState(state).refusedInternship,
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  fetchQuotasTree: bindActionCreators(fetchQuotasTree, dispatch)
})

const InternshipsManagerView = props => {
  const { setTrackedInternships } = useContext(InternshipMessageContext)

  const { internshipDate, internshipFilters, internshipSearch, setInternshipFilters, setInternshipSearch } = useContext(GlobalFiltersContext)
  const { areStartedExcluded, institutions, order, ownership, schools, sections, states, weeksShownCount } = internshipFilters

  const [addMode, setAddMode] = useState(false)
  const [displayEarlyModal, setDisplayEarlyModal] = useState(false)
  const [internshipToValidate, setInternshipToValidate] = useState(null)
  const [internships, setInternships] = useState([])
  const [metadata, setMetadata] = useState(DEFAULT_META_DATA)
  const [page, setPage] = useState(1)
  const [quotas, setQuotas] = useState({})
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [loading, setLoading] = useState(false)

  const [precision, setPrecision] = useLocalStorage(PRECISION, PRECISION_YEAR)

  const dispatch = useDispatch()
  const fetchTimer = useRef()

  useEffect(() => setPage(1), [internshipDate.format(DATE_WITHOUT_TIME), internshipSearch])

  useEffect(() => {
    if (props.user) {
      setLoading(true)

      const parameters = buildUrlParameters({
        startDate: internshipDate,
        weeks: weeksShownCount,
        search: internshipSearch,
        states,
        schools,
        institutions,
        sections,
        areStartedExcluded,
        ownership,
        order,
        page,
        perPage: INTERNSHIPS_NUMBER
      })

      postponeInternshipsFetch(parameters, props.user)
    }
  }, [
    props.user, page, internshipSearch, states, schools, institutions, sections, areStartedExcluded, ownership,
    order, weeksShownCount, internshipDate.format(DATE_WITHOUT_TIME)
  ])

  const postponeInternshipsFetch = (urlParameters, user) => {
    if (typeof fetchTimer.current === 'number') {
      clearTimeout(fetchTimer.current)
    }

    fetchTimer.current = setTimeout(() => {
      fetchInternships(urlParameters, user)
    }, TIME_BEFORE_SEARCH)
  }

  const handleInternshipDeletion = internship => {
    deleteInternship(internship, props.user).then(() => {
      onSuccess(props.t('internships_manager.delete_internship_success'))
      refreshInternships()
    }).catch(error => {
      onError(props.t('internships_manager.delete_internship.' + error.status))
    })
  }

  const refreshInternships = () => {
    const parameters = buildUrlParameters({
      startDate: internshipDate,
      weeks: weeksShownCount,
      search: internshipSearch,
      states,
      schools,
      institutions,
      sections,
      areStartedExcluded,
      order,
      page,
      perPage: INTERNSHIPS_NUMBER
    })

    fetchInternships(parameters, props.user)
  }

  const refreshQuotas = customPrecision => {
    const endDate = internshipDate.clone().add(weeksShownCount - 1, 'week').endOf('isoWeek')

    fetchQuotas(
      props.user,
      internships,
      {
        precision: customPrecision ?? precision,
        period: {
          startDate: internshipDate.format(DATE_WITHOUT_TIME),
          endDate: endDate.format(DATE_WITHOUT_TIME)
        }
      }
    )
  }

  const buildUrlParameters = parameters => {
    const endDate = parameters.startDate.clone()
      .add(parameters.weeks - 1, 'week')
      .endOf('isoWeek')

    return {
      order: parameters.order,
      page: parameters.page,
      perPage: parameters.perPage,
      period: {
        startDate: parameters.startDate.format(DATE_WITHOUT_TIME),
        endDate: endDate.format(DATE_WITHOUT_TIME)
      },
      search: parameters.search,
      filters: {
        schools: parameters.schools,
        institutions: parameters.institutions,
        sections: parameters.sections,
        states: parameters.states,
        areStartedExcluded: parameters.areStartedExcluded,
        ownership: parameters.ownership
      }
    }
  }

  const fetchInternships = (urlParameters, user) => {
    if (user.context === Contexts.SCHOOL) {
      urlParameters.contexts = [Contexts.SCHOOL.toLowerCase()]
    }

    getAllInternships(user, urlParameters).then(json => {
      if (json?.data) {
        setInternships(json.data)
        setTrackedInternships(json.data.map(i => i.id))

        if (json.data.length > 0) {
          fetchQuotas(user, json.data, { precision, period: urlParameters.period })
        }
      }

      if (json?.meta) {
        setMetadata(purgeMetadata(json.meta.pages))
      }

      setLoading(false)
    })
  }

  const fetchQuotas = (user, internships, parameters) => {
    getInternshipsQuotas(user, internships, parameters).then(json => {
      if (json?.data) {
        setQuotas(json.data)
      }
    })
  }

  const handleInternshipValidation = () => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: InternshipStates.SCHEDULE_VALIDATED }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: InternshipStates.SCHEDULE_VALIDATED })
  }

  const handleInternshipInvalidation = newState => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: newState }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: newState })
  }

  const handleInternshipRefused = () => {
    refreshInternships()
  }

  const handleRejectionClosing = () => {
    dispatch({
      type: INTERNSHIPS_MANAGER.SET_REFUSED_INTERNSHIP,
      payload: null
    })
  }

  const toggleAddMode = () => {
    setAddMode(!addMode)
  }

  const handleFilterChange = () => {
    setPage(1)
  }

  const handleResetFilters = () => {
    setPage(1)
    setInternshipSearch('')
    setPrecision(PRECISION_YEAR)
    setInternshipFilters(DEFAULT_INTERNSHIP_FILTERS)
  }

  return (
    <>
      <InternshipsHeader
        addMode={addMode}
        page={page}
        precision={precision}
        metadata={metadata}
        loadShiftsManager={props.loadShiftsManager}
        onPageChange={setPage}
        onPrecisionChange={newPrecision => {
          setPrecision(newPrecision)
          refreshQuotas(newPrecision)
        }}
        toggleAddMode={toggleAddMode}
        onFilterChange={handleFilterChange}
        onResetFilters={handleResetFilters}
        refresh={refreshInternships}
      />
      <InternshipsManagerProvider
        addMode={addMode}
        internships={internships}
        quotas={quotas}
        weeksShownCount={weeksShownCount}
        onEarlyStartDate={setDisplayEarlyModal}
        onInternshipDelete={handleInternshipDeletion}
        onInternshipSelected={setSelectedInternship}
        onStudentSelected={setSelectedStudent}
        refreshInternships={refreshInternships}
        refreshQuotas={refreshQuotas}
        setInternshipToValidate={setInternshipToValidate}
      >
        <InternshipsTable loading={loading} />
      </InternshipsManagerProvider>
      <StudentInfo
        student={selectedStudent}
        onClose={() => setSelectedStudent(null)}
      />
      <InternshipInfo
        internship={selectedInternship}
        onClose={() => setSelectedInternship(null)}
      />
      {internshipToValidate && (
        <InternshipValidation
          internship={internshipToValidate}
          onClose={() => setInternshipToValidate(null)}
          onValidate={handleInternshipValidation}
          onInvalidate={handleInternshipInvalidation}
        />
      )}
      <InternshipRejection
        internship={props.refusedInternship}
        onRejection={handleInternshipRefused}
        onClose={handleRejectionClosing}
      />
      {displayEarlyModal && (
        <EarlyDateModal
          t={props.t}
          onVisibleChange={setDisplayEarlyModal}
          visible
        />
      )}
      <MessageBox />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipsManagerView)
