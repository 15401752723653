import React from 'react'

import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import TooltipIconButton from '../../../antd/Buttons/TooltipIconButton'

const DateTitle = (
  { startDate, endDate, actionShown, icon, iconTitle, index, isCurrent, highlighted, onAction, dateFormat }
) => {
  const getClassName = (currentIndex, isHighlighted) => {
    let headerClassName = 'week'

    if (isCurrent) {
      headerClassName += ' is-current-week'
    }

    if (isHighlighted) {
      headerClassName += ' highlighted'
    }

    return headerClassName
  }

  const handleClick = () => {
    callFunctionIfDefined(
      onAction,
      [{ startDate: startDate, endDate: endDate }]
    )
  }

  return (
    <th
      className={getClassName(
        index,
        highlighted
      )}
    >
      <div>
        {startDate.format(dateFormat)}
        <br /> - <br />
        {endDate.format(dateFormat)}
      </div>
      {actionShown && (
        <TooltipIconButton
          placement='bottom'
          title={iconTitle}
          type='primary'
          size='small'
          onClick={handleClick}
          icon={icon}
        />
      )}
    </th>
  )
}

export default DateTitle
