import { requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const createUser = (user, newUser, disabledErrorHandler = true, followError = true) => {
  return requestWithPromise(
    '/user/create',
    HttpMethods.POST,
    newUser,
    user,
    disabledErrorHandler,
    followError
  )
}

export const deleteUser = (user, userToDelete) => {
  return requestWithPromise(
    `/api/User/${userToDelete.id}`,
    HttpMethods.DELETE,
    null,
    user,
    true,
    true
  )
}

export const getRoles = user => {
  return requestWithPromise(
    '/api/roles',
    HttpMethods.GET,
    null,
    user
  )
}

export const getUsers = (filter, user, excludeAdmins = 0, role = null) => {
  let url = `/users?excludeAdminUsers=${excludeAdmins}&newFilter=${filter}`

  if (typeof role === 'string') {
    url += '&role=' + role
  }

  return requestWithPromise(
    url,
    HttpMethods.GET,
    null,
    user
  )
}

export const getMe = token => {
  return requestWithPromise(
    '/user/me',
    HttpMethods.GET,
    null,
    { token, switchUser: null },
    true,
    true
  )
}

export const getPaginateUsers = (user, parameters) => {
  return requestWithPromise(
    `/api/users${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const addSupervisorStudents = (user, body, currentUser) => {
  return requestWithPromise(
    `/api/users/${user.id}/supervised-students`,
    HttpMethods.POST,
    body,
    currentUser
  )
}

export const deleteSupervisorStudents = (user, studentId, currentUser) => {
  return requestWithPromise(
    `/api/users/${user.id}/supervised-students/${studentId}`,
    HttpMethods.DELETE,
    null,
    currentUser
  )
}

export const getSupervisorStudents = (user, currentUser, valueSearch = '') => {
  const search = typeof valueSearch !== 'undefined' ? `?search=${valueSearch}` : ''

  return requestWithPromise(
    `/api/users/${user.id}/supervised-students${search}`,
    HttpMethods.GET,
    null,
    currentUser
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getCountSupervisorStudents = user => {
  return requestWithPromise(
    '/user/supervised-students-count',
    HttpMethods.GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getUserOptins = user => {
  return requestWithPromise(
    `/api/users/${user.id}/optins`,
    HttpMethods.GET,
    null,
    user
  )
}

export const createUserOptin = (optin, user) => {
  return requestWithPromise(
    `/api/users/${user.id}/optins`,
    HttpMethods.POST,
    optin.toRequestBody(),
    user
  )
}

export const updateUser = (user, body, disabledErrorHandler = false, followError = false) => {
  return requestWithPromise(
    `/api/User/${body.id}`,
    HttpMethods.PATCH,
    body,
    user,
    disabledErrorHandler,
    followError
  )
}

export const updateUserOptin = (optin, user) => {
  return requestWithPromise(
    `/api/users/${user.id}/optins/${optin.id}`,
    HttpMethods.PATCH,
    optin.toRequestBody(),
    user
  )
}

export const getUserSectors = (user, targetUser) => {
  return requestWithPromise(
    `/api/users/${targetUser.id}/sectors`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getUserSupervisedSectors = (selectedUser, user, parameters = {}) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/supervised-sectors${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const setUserSupervisedSectors = (sectors, supervisorUser, user) => {
  return requestWithPromise(
    `/user/set-supervisor-sectors/${supervisorUser.id}`,
    HttpMethods.POST,
    { sectors: sectors.map(s => s.id) },
    user
  )
}

export const restoreUserByEmail = (user, currentUser) => {
  return requestWithPromise(
    '/user/restore',
    HttpMethods.PATCH,
    { email: user.email },
    currentUser,
    true,
    true
  )
}

export const getTutoredSectors = (user, targetUser) => {
  return requestWithPromise(
    `/api/users/${targetUser.id}/tutored-sectors`,
    HttpMethods.GET,
    null,
    user
  )
}

export const linkTutoredSectors = (user, targetUser, sectors) => {
  return requestWithPromise(
    `/api/users/${targetUser.id}/tutored-sectors`,
    HttpMethods.POST,
    { ids: sectors },
    user
  )
}

export const unLinkTutoredSectors = (user, targetUser, sectors) => {
  return requestWithPromise(
    `/api/users/${targetUser.id}/tutored-sectors${makeQueryString({ ids: sectors })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const getUserUnreadMessages = user => {
  return requestWithPromise(
    `/api/users/${user.id}/unread-messages`,
    HttpMethods.GET,
    null,
    user
  )
}

export const readAllUserMessages = user => {
  return requestWithPromise(
    '/internship-message/read-all',
    HttpMethods.POST,
    {},
    user
  )
}
