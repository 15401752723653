import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getSupportedEmailTypes = (user) => {
  return requestWithPromise(
    '/institution-email/email-types',
    HttpMethods.GET,
    null,
    user
  ).then(json => {
    return json?.data ?? []
  })
}

export const getEmailByType = (user, emailType) => {
  return requestWithPromise(
    `/api/institution-emails?email-type=${emailType}`,
    HttpMethods.GET,
    null,
    user
  ).then(json => {
    return json?.data ?? []
  })
}

export const sendEmailMyself = (user, email, languageLocale, entity) => {
  return requestWithPromise(
    '/sendgrid/send-dynamic-template/myself',
    HttpMethods.POST,
    {
      emailType: email.emailType,
      id: entity.id,
      language: languageLocale === null ? null : languageLocale.languageTag,
      institution: user.institutions[0].id
    },
    user
  )
}
