import React, { useContext, useMemo, useState } from 'react'

import { Modal, Button } from 'antd'
import { faKey, faStethoscope, faUsers } from '@fortawesome/pro-solid-svg-icons'
import SmartTable, { SmartTableDataTypes } from '../shared/SmartTable'
import UserManagedSectors from './UserManagedSectors'
import UserTutoredSectors from './UserTutoredSectors'
import moment from 'moment'
import { validateFormInput, objectDeepCopy, requestWithPromise } from '../../utils'
import AdminResetPassword from '../shared/AdminResetPassword'
import { DELETED_USER, DUPLICATED_USER, getErrorBody, HttpMethods, onError, onSuccess } from '../../utils/apiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { restoreUserByEmail } from '../../utils/api/user'
import { HttpStatuses } from '../../utils/http'
import { InstitutionRoles, SchoolRoles } from '../../utils/constants'
import { getLanguages, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getInstitutions, removeInstitutionUser, setInstitutions } from '../../reducers/InstitutionsReducer'
import { bindActionCreators } from 'redux'
import { getDataReady } from '../../reducers/DataReadyReducer'
import { isHospitalAdmin, isObserver } from '../../utils/roles'
import { GlobalContext } from '../../Providers/GlobalProvider'

const mapStateToProps = state => ({
  getDataReady: getDataReady(state.getDataReady),
  languages: getLanguages(state.locale),
  user: getUser(state.getUser),
  t: getTranslate(state.locale),
  institutions: getInstitutions(state)
})

const mapDispatchToProps = dispatch => ({
  setInstitutions: bindActionCreators(setInstitutions, dispatch),
  removeInstitutionUser: bindActionCreators(removeInstitutionUser, dispatch)
})

const Users = ({ institutions, getDataReady, languages, user, removeInstitutionUser, setInstitutions, t }) => {
  const { isReadOnly } = useContext(GlobalContext)

  const [selectedUserSectors, setSelectedUserSectors] = useState(null)
  const [userTutoredSectors, setUserTutoredSectors] = useState(null)
  const [selectedUserPassword, setSelectedUserPassword] = useState(null)
  const [revivalLoading, setRevivalLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState({ user: null, body: null, footer: false, title: '' })

  const localLanguages = useMemo(() => languages.map(({ code, name }) => ({
    id: code,
    name: t(name)
  })), [languages, t])
  const isAdmin = useMemo(() => isHospitalAdmin(user), [user.roles])
  const userId = useMemo(() => user.id, [user.id])
  const roles = useMemo(() => [
    {
      id: 1,
      name: t('Administrator'),
      apiName: InstitutionRoles.HOSPITAL_ADMIN
    },
    {
      id: 2,
      name: t('Nurse'),
      apiName: InstitutionRoles.NURSE
    },
    {
      id: 3,
      name: t('Validator'),
      apiName: SchoolRoles.VALIDATOR,
      notAllowedInSelect: true
    },
    {
      id: 4,
      name: t(InstitutionRoles.INSTITUTION_GROUP_ADMIN),
      apiName: InstitutionRoles.INSTITUTION_GROUP_ADMIN,
      notAllowedInSelect: true
    },
    {
      id: 5,
      name: t('user.roles.observer'),
      apiName: InstitutionRoles.OBSERVER
    }
  ], [t])
  const columns = useMemo(() => [
    { type: SmartTableDataTypes.ID, key: 'id' },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Email'),
      key: 'email',
      validate: data => validateFormInput('email', data, true),
      disabled: isReadOnly
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Username'),
      key: 'username',
      validate: data => validateFormInput('email', data, true),
      disabled: isReadOnly
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Lastname'),
      key: 'lastname',
      validate: data => validateFormInput('name', data, true),
      disabled: isReadOnly
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Firstname'),
      key: 'firstname',
      validate: data => validateFormInput('name', data, true),
      disabled: isReadOnly
    },
    {
      type: SmartTableDataTypes.SELECT,
      name: t('Language'),
      key: 'language',
      options: localLanguages,
      preventAutoSelectDefaultValue: true,
      validate: data => {
        return data !== null
      },
      disabled: isReadOnly

    },
    {
      type: SmartTableDataTypes.SELECT,
      name: t('Role'),
      key: 'roles',
      options: roles.filter(item => item.notAllowedInSelect !== true),
      disabled: isReadOnly
    },
    {
      type: SmartTableDataTypes.DATE,
      name: t('Last login'),
      key: 'lastLogin',
      format: 'DD/MM/YYYY',
      disabled: true
    }
  ], [t, localLanguages, roles])
  const additionalActions = useMemo(() => {
    const actions = [
      {
        iconName: faStethoscope,
        type: 'primary',
        title: t('Manage care units coordinated by this user'),
        onClick: u => setSelectedUserSectors({ ...u, roles: [roles.find(r => r.id === u.roles)?.apiName] }),
        disabledCallback: u => {
          let disabled = isReadOnly || !u.roles

          if (!disabled) {
            const role = roles.find(r => r.id === u.roles)

            disabled = !role || isObserver({ roles: [role.apiName] })
          }

          return disabled
        }
      },
      {
        iconName: faUsers,
        type: 'primary',
        title: t('manage_care_units_tutored_by_this_user'),
        onClick: u => setUserTutoredSectors({ ...u, roles: [roles.find(r => r.id === u.roles)?.apiName] }),
        disabledCallback: u => {
          if (isObserver(user)) {
            return u.id !== user.id
          }

          if (isHospitalAdmin(user)) {
            const roleUser = roles.find(r => r.id === u.roles)

            return !isObserver({ roles: [roleUser?.apiName] })
          }

          return true
        }
      }
    ]

    if (isAdmin) {
      actions.push({
        iconName: faKey,
        type: 'primary',
        title: t('Reset password'),
        onClick: setSelectedUserPassword,
        disabledCallback: u => u.isExternal,
        titleCallback: u => u.isExternal ? t('This user is external and cannot have its password reset') : ''
      })
    }
    return actions
  }, [isAdmin, roles, t, setSelectedUserSectors, setSelectedUserPassword])
  const data = useMemo(() => {
    let users = []

    if (institutions.length > 0 && roles.length > 0) {
      users = institutions[0].users.map(u => {
        let userRoleId = null
        // in readOnlyRoleName -> we store the role name, if the role is not allowed
        let readOnlyRoleName = null

        if (u.roles && u.roles.length) {
          const foundRole = roles.find(r => r.apiName === u.roles[0])
          if (foundRole) {
            if (foundRole.notAllowedInSelect) {
              readOnlyRoleName = foundRole.name
            } else {
              userRoleId = foundRole.id
            }
          }
        }
        return {
          id: u.id,
          email: u.email,
          username: u.username,
          firstname: u.firstname,
          roles: userRoleId,
          rolesReadOnlyName: readOnlyRoleName,
          isExternal: u.isExternal,
          lastLogin:
            u.lastLogin === null
              ? null
              : moment(u.lastLogin.date).format('DD/MM/YYYY'),
          lastname: u.lastname,
          language: u.language,
          institutions: [{ id: institutions[0].id }]
        }
      })
    }

    return users
  }, [institutions, roles])

  const handleAddUser = (createdUser, stopTableLoading, reviveUser = false) => {
    return new Promise((resolve, reject) => {
      const rawRoleId = createdUser.roles
      createdUser.roles = [roles.filter(r => r.id === createdUser.roles)[0].apiName]
      createdUser.userType = 'institution'

      if (reviveUser) {
        createdUser.reviveDeletedUser = true
      }

      requestWithPromise('/user/create', HttpMethods.POST, createdUser, user, true, true)
        .then(json => {
          const newUser = json.data
          const institution = institutions[0]
          const users = institution.users
          users.unshift(newUser)
          institution.users = users
          setInstitutions([institution])
        }).catch(error => {
          if (error.status === HttpStatuses.CONFLICT) {
            error.body.getReader().read().then(({ value }) => {
              const decoded = new TextDecoder('utf-8').decode(value)
              const json = JSON.parse(decoded)

              if (json.message === DELETED_USER) {
                setDeleteModal({
                  footer: true,
                  body: (
                    <p style={{ fontWeight: 'bold' }}>
                      <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                      {t('revive.deleted.user', { email: createdUser.username })}
                    </p>
                  ),
                  title: t('Restore an user ?'),
                  user: { ...createdUser, roles: rawRoleId }
                })
              } else if (json.message === DUPLICATED_USER) {
                setDeleteModal({
                  footer: false,
                  body: (
                    <p style={{ fontWeight: 'bold' }}>
                      <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                      {t('There is already an account linked to this email address')}
                    </p>
                  ),
                  title: t('Invalid email/username'),
                  user: { ...createdUser, roles: rawRoleId }
                })
              }
            })
          }
        })
    })
  }

  const handleEditUser = (updatedUser, stopTableLoading) => {
    if (updatedUser.roles) {
      updatedUser.roles = [roles.filter(r => r.id === updatedUser.roles)[0].apiName]
    }
    // TODO: SHould be moved in Reducer
    requestWithPromise('/user/modify/' + updatedUser.id, HttpMethods.POST, updatedUser, user, true, true)
      .then(json => {
        if (json?.data) {
          const newInstitutions = objectDeepCopy(institutions)
          newInstitutions[0].users = newInstitutions[0].users.map(u => u.id === updatedUser.id ? json.data : u)
          setInstitutions(newInstitutions)
        }
      })
      .catch(error => {
        stopTableLoading()
        getErrorBody(error).then(({ message }) => onError(t(message)))
      })
  }

  const removeUser = deletedUser => {
    if (isAdmin) {
      removeInstitutionUser({
        institutionId: institutions[0].id,
        userId: deletedUser.id,
        currentUser: user
      })
    }
  }

  const handleReviveUser = () => {
    setRevivalLoading(true)

    restoreUserByEmail(deleteModal.user, user).then(json => {
      const newUser = json.data
      const newInstitutions = objectDeepCopy(institutions)
      const users = newInstitutions[0].users

      users.unshift(newUser)

      newInstitutions[0].users = users

      setInstitutions(newInstitutions)
      onSuccess(t('The user was successfully revived'))
    }).catch(error => {
      onError(error.code)
    })

    handleReviveCancel()
  }

  const handleReviveCancel = () => {
    setDeleteModal({ user: null, body: null, footer: false, title: '' })
    setRevivalLoading(false)
  }

  return (
    <>
      <SmartTable
        columns={columns}
        data={data}
        loading={!getDataReady.institutions}
        onDataEdit={handleEditUser}
        onDataAdd={handleAddUser}
        addDataText={t('Add user')}
        showAddButtonDisabled={isReadOnly}
        noDataText={t('No user')}
        additionalActions={additionalActions}
        onDataDelete={isAdmin ? removeUser : undefined}
        hideDeleteButtonForIds={[userId]}
      />

      <UserTutoredSectors
        readOnly={isObserver(user)}
        selectedUser={userTutoredSectors}
        onClose={() => setUserTutoredSectors(null)}
      />
      <UserManagedSectors
        selectedUser={selectedUserSectors}
        onClose={() => setSelectedUserSectors(null)}
      />

      {isAdmin &&
        <AdminResetPassword
          userToResetPassword={selectedUserPassword}
          onClose={() => setSelectedUserPassword(null)}
        />}
      <Modal
        title={deleteModal.title}
        visible={deleteModal.user !== null}
        onOk={handleReviveUser}
        onCancel={handleReviveCancel}
        footer={deleteModal.footer
          ? (
            <div>
              <Button onClick={handleReviveCancel}> {t('Cancel')} </Button>
              <Button onClick={handleReviveUser} type='primary' loading={revivalLoading}>
                {t('Restore')}
              </Button>
            </div>
          )
          : null}
      >
        {deleteModal.body}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
