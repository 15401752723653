import { requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const getHospitalDepartments = (user, parameters) => {
  return requestWithPromise(
    `/api/hospital-departments${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getHospitalDepartmentSectors = (user, hospitalDepartment) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/sectors`,
    HttpMethods.GET,
    null,
    user
  )
}

export const createHospitalDepartment = (user, hospitalDepartment) => {
  return requestWithPromise(
    '/api/hospital-departments',
    HttpMethods.POST,
    hospitalDepartment,
    user
  )
}

export const deleteHospitalDepartment = (user, hospitalDepartment) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const linkHospitalDepartmentSectors = (user, hospitalDepartment, ids = []) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/sectors`,
    HttpMethods.POST,
    { ids },
    user
  )
}

export const updateHospitalDepartment = (user, hospitalDepartment) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}`,
    HttpMethods.PATCH,
    hospitalDepartment,
    user
  )
}

export const unlinkHospitalDepartmentSectors = (user, hospitalDepartment, ids = []) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/sectors${makeQueryString({ ids })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const getHospitalDepartmentUsers = (user, hospitalDepartment) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/users`,
    HttpMethods.GET,
    null,
    user
  )
}

export const linkHospitalDepartmentUsers = (user, hospitalDepartment, ids = []) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/users`,
    HttpMethods.POST,
    { ids },
    user
  )
}

export const unlinkHospitalDepartmentUsers = (user, hospitalDepartment, ids = []) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/users${makeQueryString({ ids })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const syncHospitalDepartmentUsers = (user, hospitalDepartment) => {
  return requestWithPromise(
    `/api/hospital-departments/${hospitalDepartment.id}/users/sync`,
    HttpMethods.POST,
    {},
    user
  )
}
