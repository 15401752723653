import { requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'
import { INSTITUTION_OPTION_TYPES } from './actionsType'

const {
    SET_INSTITUTION_OPTION_TYPES
} = INSTITUTION_OPTION_TYPES

const getInstitutionOptionTypes = state => {
    return state.institutionOptionTypes
}

const fetchAllInstitutionOptionTypes = (user) => {
    return async function fetchInstititionOptionsThunk (dispatch, getState) {
        requestWithPromise(`/api/institution-option-types`, HttpMethods.GET, null, user)
        .then(jsonResponse => {
            if (jsonResponse && jsonResponse.data) {
                dispatch({
                    type: SET_INSTITUTION_OPTION_TYPES,
                    payload: jsonResponse.data
                })
            }
        })
    }
}

export {
    getInstitutionOptionTypes,
    fetchAllInstitutionOptionTypes
}