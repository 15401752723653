import { isObject, validateString, formatAsArray } from '..'
import { ValidationFields } from '../validators'

export const SCHEDULES = {
  DAY: 'DAY',
  NIGHT: 'NIGHT'
}
export const TYPES = {
  SECTOR: 'SECTOR',
  TRAIL: 'TRAIL'
}

export const SECTOR_VALIDATORS = [
  { key: ValidationFields.COMMENT, validator: s => validateString(s.comment, { nullable: true }) },
  { key: ValidationFields.EMPLACEMENT, validator: s => validateString(s.emplacement, { nullable: true }) },
  { key: ValidationFields.NAME, validator: s => validateString(s.name, { notEmpty: true }) },
  { key: ValidationFields.INSTITUTION, validator: s => s.institution !== null },
  { key: ValidationFields.SCHEDULE, validator: s => s.schedule === null || Object.values(SCHEDULES).includes(s.schedule) },
  { key: ValidationFields.TYPE, validator: s => s.type === null || Object.values(TYPES).includes(s.type) }
]

export const getName = s => {
  return s.name ?? null
}

export const getComment = s => {
  return s.comment ?? null
}

export const getEmplacement = s => {
  return s.emplacement ?? null
}

export const getInstitutionName = s => {
  return s.institution?.name ?? null
}

export const getHospitalDepartmentName = s => {
  return s.hospitalDepartment?.name ?? null
}

export class Sector {
  constructor ({
    id, actTypes = [], archived = false, comment = '', composition = null, emplacement = '', institution, managers = [], name = '', pausesEnabled = false, phoneNumber = '', schedule = null,
    studentPathways = [], tutors = [], type = null
  } = {}) {
    this.id = id ?? -1
    this.actTypes = actTypes
    this.archived = archived
    this.comment = comment
    this.composition = composition
    this.emplacement = emplacement
    this.institution = isObject(institution) ? institution : null
    this.managers = formatAsArray(managers)
    this.name = name
    this.pausesEnabled = pausesEnabled
    this.phoneNumber = phoneNumber
    this.schedule = schedule
    this.studentPathways = studentPathways
    this.tutors = formatAsArray(tutors)
    this.type = type
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = this.institution?.id ?? null
    body.managers = this.managers.map(m => m.id)
    body.tutors = this.tutors.map(t => t.id)
    body.studentPathways = this.studentPathways.map(sp => ({ id: sp.id, details: sp.details, sectionToYear: sp.sectionToYear.id }))

    return body
  }
}
