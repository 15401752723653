import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, notification } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, validateFormInput, getUrlParameterByName, generalErrorHandler } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

const FormItem = Form.Item

class NormalPasswordResetForm extends React.Component {
  state = {
    confirmDirty: false,
    loading: false,
    passwordResetToken: null
  }

  componentDidMount () {
    const passwordResetToken = getUrlParameterByName('password-reset-token')
    this.setState({ passwordResetToken })
    if (typeof passwordResetToken !== 'undefined' && passwordResetToken !== null && passwordResetToken !== '') {
      window.history.pushState({ id: 'interneo' }, 'interneo', window.location.origin)
      request('/user/forgot-password/check/' + passwordResetToken, HttpMethods.GET, null, null, { catchError: false })
        .then((json) => {
          if (typeof json.status !== 'undefined' && json.status === 'error') {
            notification.error({ message: this.props.t('This link does not exist or has expired.'), placement: 'bottomRight' })
            this.props.onBack()
          }
        })
    } else {
      this.props.onBack()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })

        const body = {
          password: values.newPassword,
          password_confirm: values.newPasswordConfirm,
          token: this.state.passwordResetToken
        }

        request('/user/forgot-password/change', HttpMethods.POST, body, null, { catchError: false })
          .then((json) => {
            this.setState({ loading: false })
            if (json.status !== 'error') {
              notification.success({ message: this.props.t('Password update successful.'), placement: 'bottomRight' })
            } else {
              notification.error({ message: this.props.t('This link does not exist or has expired.'), placement: 'bottomRight' })
            }
            this.props.onBack()
          })
          .catch((error) => {
            generalErrorHandler(error)
          })
      }
    })
  }

  checkFormFields = (rule, value, callback) => {
    switch (rule.field) {
      case 'newPassword':
        if (value && this.state.confirmDirty) this.props.form.validateFields(['newPasswordConfirm'], { force: true })
        if (!validateFormInput('password', value, true)) callback(this.props.t('The password must contain between 8 and 255 characters !'))
        callback()
        break
      case 'newPasswordConfirm':
        if (value !== this.props.form.getFieldValue('newPassword')) callback(this.props.t('The passwords do not match !'))
        callback()
        break
      default:
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = { labelCol: { xs: { span: 24 } }, wrapperCol: { xs: { span: 24 } } }

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.t('Please choose a new password')} :
        <div className='v-spacing' />
        <FormItem {...formItemLayout}>
          {getFieldDecorator('newPassword', { rules: [{ validator: this.checkFormFields }] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('New password')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('newPasswordConfirm', { rules: [{ validator: this.checkFormFields }] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('Confirm the new password')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          <Button type='primary' htmlType='submit' className='full-width' loading={this.state.loading}>
            <FontAwesomeIcon icon='check' />&nbsp;{this.props.t('Confirm')}
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const PasswordResetForm = Form.create()(NormalPasswordResetForm)
export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm)
