import React, { useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, Checkbox } from 'antd'
import { connect } from '../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'
import { AuthContext } from '../../Providers/AuthProvider'
import { lookup } from '../../utils/api/auth'
import { HttpStatuses } from '../../utils/http'
const FormItem = Form.Item

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const LoginForm = ({ onLogin, loginPending, onPasswordResetClick, t, form }) => {
  const { redirectToSSOLoginPage } = useContext(AuthContext)

  const [isLocalAccount, setIsLocalAccount] = useState(false)
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!isLocalAccount) {
        setDisplayForgotPassword(false)

        const response = await lookup(values.username)
          .catch(errror => {
            setDisplayForgotPassword(true)

            if (errror.status === HttpStatuses.NOT_FOUND) {
              form.setFields({
                username: {
                  value: values.username,
                  errors: [new Error(t('login_lookup.error.404'))]
                }
              })
            } else {
              form.setFields({
                username: {
                  value: values.username,
                  errors: [new Error(t('An unknown error has occured.'))]
                }
              })
            }
          })

        if (response) {
          response.isExternal ? redirectToSSOLoginPage(values.username) : setIsLocalAccount(true)
        }
      } else if (!err && values.username.length > 0 && values.password.length > 0) {
        onLogin(values)
      }
    })
  }

  const handleForgotPassword = (e) => {
    e.preventDefault()
    onPasswordResetClick()
  }

  const handleEmailChange = () => {
    setIsLocalAccount(false)
  }

  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className='login-form'>
      <div className='labels'>
        <div className='title'>{t('Internships management')}</div>

        <div style={{ textAlign: 'center' }}>
          {t('nurses')}&nbsp;
          <span>-</span>&nbsp;{t('doctors')}&nbsp;
          <span>-</span>&nbsp;{t('paramedics')}&nbsp;
          <span>-</span>&nbsp;{t('midwifes')}
        </div>
      </div>
      <FormItem style={{ marginBottom: '10px' }}>
        <label>{t('Email address')}</label>
        {getFieldDecorator('username', { rules: [{ required: true, message: t('login_email_missing') }] })(
          <Input prefix={<FontAwesomeIcon icon='user' />} placeholder={t('Email address')} onChange={handleEmailChange} />
        )}
      </FormItem>
      {isLocalAccount && (
        <>
          <FormItem style={{ marginBottom: '10px' }}>
            <label>{t('Password')}</label>
            {getFieldDecorator('password', { rules: [] })(
              <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={t('Password')} />
            )}
          </FormItem>
          <a href='#/' onClick={handleForgotPassword} className='forgot-password-link'>{t('Forgotten password ?')}</a>
        </>
      )}
      {displayForgotPassword && (
        <a href='#/' onClick={handleForgotPassword} className='forgot-password-link'>{t('login_send_account_finalization_email')}</a>
      )}
      <br />
      <a className='forgot-password-link' href='https://opalsolutions.zendesk.com/hc/fr/categories/360003066859-Comment-utiliser-Interneo-'>F.A.Q.</a>
      {t('and')}&nbsp;
      <a className='forgot-password-link' href='https://www.youtube.com/channel/UCdF4BK3XhULkd3809NHp5pw/featured'>{t('video')}</a>

      {isLocalAccount && (
        <div className='sign-in-wrapper'>
          <FormItem>
            {getFieldDecorator('remember', { valuePropName: 'checked', initialValue: true })(
              <Checkbox><span className='remember-text'>{t('Remember me')}</span></Checkbox>
            )}
          </FormItem>
        </div>
      )}
      <div className='sign-in-wrapper' style={{ position: 'relative' }}>
        <Button type='primary' htmlType='submit' className='login-button' loading={loginPending}>
          {isLocalAccount ? t('Log in') : t('Next')}
        </Button>

        <img src='/assets/opal-logo.png' className='opal-logo-form' />
      </div>
    </Form>
  )
}

export default connect(mapStateToProps)(Form.create()(LoginForm))
