import moment from 'moment'
import { isObject } from '..'
import { API_DATE_FORMAT } from '../apiHelper'
import { OrderDirections } from '../constants'
import { isString } from 'lodash'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_ORDER = { by: null, direction: OrderDirections.ASC }

/**
 * @param orderBy {string} @deprecated
 * @param orderDirection {string} @deprecated
 */
export class TableParameters {
  constructor ({ order = DEFAULT_ORDER, page = 1, pageSize = DEFAULT_PAGE_SIZE, period, filters = {}, search = '', orderBy, orderDirection } = {}) {
    this.filters = filters
    this.order = { ...DEFAULT_ORDER, ...order }
    this.page = page
    this.pageSize = pageSize
    this.period = period
    this.search = search

    if (isString(orderBy)) {
      this.order.by = orderBy
    }

    if (isString(orderDirection)) {
      this.order.direction = orderDirection
    }
  }

  toQueryParameters () {
    return {
      page: this.page,
      pageSize: this.pageSize,
      search: this.search,
      filters: this.filters,
      period: {
        startDate: isObject(this.period) ? moment(this.period.startDate).format(API_DATE_FORMAT) : null,
        endDate: isObject(this.period) ? moment(this.period.endDate).format(API_DATE_FORMAT) : null
      },
      order: {
        by: this.order.by,
        direction: this.order.direction
      }
    }
  }

  /**
   * @deprecated 2025-01-20
   * @see {toQueryParameters} Use instead.
   * Difference : old method place most params directly in query. Now, params are grouped in prefered objects.
   */
  toQueryParametersOld () {
    return {
      endDate: isObject(this.period) ? moment(this.period.endDate).format(API_DATE_FORMAT) : null,
      filters: this.filters,
      search: this.filters.search ?? this.search,
      orderBy: this.order.by,
      orderDirection: this.order.direction,
      page: this.page,
      pageSize: this.pageSize,
      startDate: isObject(this.period) ? moment(this.period.startDate).format(API_DATE_FORMAT) : null
    }
  }
}
