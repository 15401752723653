import React, { useContext, useMemo } from 'react'

import InternshipCard from './InternshipCard'
import { InternshipFormModalContext } from '../../../../Providers/Forms/InternshipForm/InternshipFormModal'
import { Internship } from '../../../../utils/entities/internship'
import { Contexts } from '../../../../utils/constants'

const OwnedInternshipCard = ({ context, disable, internship, refresh }) => {
  const { setData } = useContext(InternshipFormModalContext)

  const className = useMemo(() => {
    let className = 'pdl-8 bdr-rds-8'

    if (context === Contexts.SCHOOL || disable) {
      className += ' cu-nt-alwd'
    } else if (context === Contexts.INSTITUTION) {
      className += ' cu-ptr'
    }

    return className
  }, [context])

  const handleClick = () => {
    if (!disable) {
      setData({ object: new Internship(internship), callback: refresh })
    }
  }

  return (
    <div
      className='internship-info-old'
      onClick={handleClick}
    >
      <InternshipCard
        className={className}
        displaySector
        internship={internship}
        legend='ownership_internship_card.legend'
      />
    </div>
  )
}

export default OwnedInternshipCard
