import React from 'react'

import InternshipCard from './InternshipCard'

const InternshipRequestCard = ({ internship }) => {
  return (
    <div className='bdr-rds-8'>
      <InternshipCard internship={internship} legend='internship_request_card.legend' />
    </div>
  )
}

export default InternshipRequestCard
