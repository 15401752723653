import React, { useContext, useMemo } from 'react'

import { connect } from 'react-redux'
import { InternshipContext } from '../../../../Context/InternshipsManager/InternshipProvider'
import { getSchoolSections } from '../../../../reducers/SchoolSectionsReducer/actions'
import { getStudents } from '../../../../reducers/StudentsReducer/actions'
import InstitutionsAndSectorsSelector from '../../Selectors/InstitutionsAndSectorsSelector'
import StudentsAndYearsSelector from '../../Selectors/StudentsAndYearsSelector'
import { SchoolContext } from '../../../../Providers/SchoolProvider'
import { getDisablingState } from '../../InternshipsManager/utils/utils'

const mapStateToProps = state => ({
  students: getStudents(state.getStudents),
  schoolSections: getSchoolSections(state.schoolSections)
})

const ActionInternshipCard = ({ disabled, internship, students, schoolSections, onInternshipUpdate }) => {
  const { institutions } = useContext(SchoolContext)

  const { onUpdate } = useContext(InternshipContext)

  const localDisabled = useMemo(() => getDisablingState(internship) || disabled, [disabled, internship])

  const handleStudentAndYearChange = data => {
    const body = {}

    Object.keys(data).forEach(key => {
      if (data[key]) {
        body[key] = data[key].id
      } else if (key === 'internshipYear') {
        body[key] = data[key]
      }
    })

    if (body.section) {
      body.student = null
    }

    onUpdate(body)
  }

  const handleLocationChange = data => {
    if (data.institution) {
      data.institution = data.institution.id
    }

    if (data.sector) {
      data.sector = data.sector.id
    }

    onUpdate(data)
  }

  return (
    <div className='internship-info-old'>
      <StudentsAndYearsSelector
        className='internship-student-year-wrapper'
        disabled={localDisabled}
        internship={internship}
        students={students}
        schoolSections={schoolSections}
        onSelect={handleStudentAndYearChange}
      />
      <InstitutionsAndSectorsSelector
        disabled={localDisabled}
        internship={internship}
        institutions={institutions}
        onSelect={handleLocationChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ActionInternshipCard)
