import React, { Component } from 'react'

import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { getContextFromRoles } from '../../utils'
import { Contexts } from '../../utils/constants'

const steps = [
  {
    key: 1,
    page: 'SCHOOL_INTERNSHIPS',
    context: Contexts.SCHOOL,
    title: 'Ajouter un stage',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed dui turpis. Nullam vitae semper ipsum. Suspendisse vestibulum enim at ipsum tincidunt tempor id mollis ex. Proin ornare ultrices nisl in eleifend. Vestibulum malesuada sit amet arcu ac iaculis. Nam semper pellentesque lorem in sodales. Vivamus sed nibh porta, cursus sem id, aliquam purus.'
  },
  {
    key: 2,
    page: 'SCHOOL_INTERNSHIPS',
    context: Contexts.SCHOOL,
    title: 'Filter les données',
    text: 'Duis tincidunt, arcu non tempus volutpat, nisi erat porta lorem, nec sollicitudin nisi mauris a velit. Maecenas dictum sem libero. Donec commodo, eros at molestie porta, tortor ex sollicitudin tellus, ac elementum massa justo sed ipsum. Praesent consectetur tortor non ante dapibus ultricies. Donec enim lorem, luctus at ornare dignissim, hendrerit sed magna. Vivamus diam lectus, dapibus eu rutrum sit amet, pulvinar quis arcu. Sed a dapibus ligula, eu efficitur nulla. Aenean semper odio sit amet tortor ultrices, sed lacinia mauris blandit.'
  },
  {
    key: 3,
    page: 'HOSPITAL_STUDENT',
    context: Contexts.INSTITUTION,
    title: 'Gérer les étudiants',
    text: 'Lorem empty.'
  }
]

class AppTour extends Component {
  state = {
    step: { key: 0 }
  }

  nextStep = () => {
    const roles = this.props.getUser.roles
    const context = getContextFromRoles(roles)
    const filteredSteps = steps.filter(s => s.context === context)
    if (filteredSteps.length === 0) return

    filteredSteps.unshift({ key: 0 })
    const currentStep = this.state.step
    for (let i = 0; i < filteredSteps.length; i++) {
      if (currentStep.key === filteredSteps[i].key) {
        this.applyStep(filteredSteps[i + 1])
        break
      }
    }
  }

  applyStep = (step) => {
    const appTourWrapper = document.getElementsByClassName('app-tour-wrapper')
    if (appTourWrapper.length > 0) {
      appTourWrapper[0].remove()
    }
    const appTourElementCloneWrapper = document.getElementsByClassName('app-tour-element-clone-wrapper')
    if (appTourElementCloneWrapper.length > 0) {
      appTourElementCloneWrapper[0].remove()
    }

    if (typeof step === 'undefined') {
      this.setState({ step: { key: 0 } })
      return
    }

    const node = document.getElementsByClassName('app-tour-' + step.key)[0]
    const clone = node.cloneNode(true)
    const nodePositionLeft = node.offsetLeft
    const nodePositionTop = node.offsetTop
    const nodeWidth = node.offsetWidth
    const nodeHeight = node.offsetHeight
    const documentWidth = document.body.offsetWidth

    const cloneWrapper = document.createElement('div')
    cloneWrapper.classList.add('app-tour-element-clone-wrapper')
    cloneWrapper.style.background = 'white'
    cloneWrapper.style.zIndex = 9999999999
    cloneWrapper.style.position = 'absolute'
    cloneWrapper.style.left = nodePositionLeft + 'px'
    cloneWrapper.style.top = nodePositionTop + 'px'

    // Used to disabled trigger on click/hover on element.
    const cloneHover = document.createElement('div')
    cloneHover.style.width = nodeWidth + 'px'
    cloneHover.style.height = nodeHeight + 'px'
    cloneHover.style.position = 'fixed'
    cloneHover.style.left = nodePositionLeft + 'px'
    cloneHover.style.top = nodePositionTop + 'px'

    cloneWrapper.appendChild(clone)
    cloneWrapper.appendChild(cloneHover)
    document.getElementsByClassName('interneo-app')[0].appendChild(cloneWrapper)

    // Tour text
    const tourTitle = document.createElement('div')
    tourTitle.style.fontWeight = 'bold'
    tourTitle.style.fontSize = '24px'
    tourTitle.appendChild(document.createTextNode(step.title))

    const tourContent = document.createElement('div')
    tourContent.style.fontSize = '16px'
    tourContent.appendChild(document.createTextNode(step.text))

    const tourNextHint = document.createElement('div')
    tourNextHint.style.fontSize = '16px'
    tourNextHint.style.marginTop = '50px'
    tourNextHint.style.fontStyle = 'italic'
    tourNextHint.appendChild(document.createTextNode(this.props.t('Click anywhere on the black blackground in order to continue.')))

    const tourWrapper = document.createElement('div')
    const tourWrapperWidth = (documentWidth / 2) - 60
    tourWrapper.classList.add('app-tour-wrapper')
    tourWrapper.appendChild(tourTitle)
    tourWrapper.appendChild(tourContent)
    tourWrapper.appendChild(tourNextHint)
    tourWrapper.style.position = 'fixed'
    tourWrapper.style.top = nodePositionTop + 'px'
    tourWrapper.style.color = 'white'
    tourWrapper.style.zIndex = 9999999999
    tourWrapper.style.width = tourWrapperWidth + 'px'
    tourWrapper.style.textShadow = '0px 0px 2px black'
    if (nodePositionLeft >= documentWidth / 2) {
      tourWrapper.style.left = (nodePositionLeft - 30 - tourWrapperWidth) + 'px'
      tourWrapper.style.textAlign = 'right'
    } else {
      tourWrapper.style.textAlign = 'left'
      tourWrapper.style.left = (nodePositionLeft + 30 + nodeWidth) + 'px'
    }
    document.getElementsByClassName('interneo-app')[0].appendChild(tourWrapper)

    this.setState({ step: step })
  }

  render () {
    const backgroundStyle = {
      position: 'absolute',
      top: 0,
      background: 'rgba(0, 0, 0, 0.6)',
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999999999
    }

    return (
      <div>
        <div onClick={this.nextStep} />
        <div style={this.state.step.key === 0 ? { display: 'none' } : backgroundStyle} onClick={this.nextStep} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppTour)
