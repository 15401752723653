import React, { createContext, useState } from 'react'

export const StudentQuickMenuDropdownContext = createContext()

const StudentQuickMenuDropdownProvider = ({ children }) => {
  const [selectedInternshipDropdown, setSelectedInternshipDropDown] = useState(null)

  return (
    <StudentQuickMenuDropdownContext.Provider
      value={{
        selectedInternshipDropdown,
        setSelectedInternshipDropDown
      }}
    >
      {children}
    </StudentQuickMenuDropdownContext.Provider>
  )
}

export default StudentQuickMenuDropdownProvider
