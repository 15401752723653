import React, { useContext, useEffect, useMemo, useState } from 'react'

import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import InternshipValidationButton from '../../ShiftsManager/InternshipValidationButton'
import InstitutionDateCell from '../DateCell/InstitutionDateCell'
import StudentQuickMenu from '../StudentQuickMenu'
import { getStatesPellet } from '../utils/componentGetters'
import { getIndexesByColumns, getValidationTooltipText } from '../utils/utils'
import ActionInternshipCard from '../../InternshipCard/institution/ActionInternshipCard'
import { Ownership } from '../../../../utils/entities/internship'
import OwnedInternshipCard from '../../InternshipCard/institution/OwnedInternshipCard'

const DEFAULT_INDEXES = { startIndex: 0, lastIndex: 0 }

const InstitutionInternshipLine = ({ internshipContext, getQuotasState, onLineHover }) => {
  const { internship, onUpdate, handleStateChange } = useContext(internshipContext)
  const {
    dateColumns, user, onInternshipDelete, onInternshipSelected, onStudentSelected, refreshInternships, setInternshipToValidate, t
  } = useContext(InternshipsManagerContext)

  const [isHovered, setIsHovered] = useState(false)
  const [periodIndexes, setPeriodIndexes] = useState(DEFAULT_INDEXES)
  const [displaySectorSelector, setDisplaySectorSelector] = useState(false)

  const StatesPellet = getStatesPellet(user.context)

  const customStyle = useMemo(() => {
    let style = {}

    if (internship.individualInternshipRequest !== null) {
      style = { border: '2px solid #ED7F10', borderRadius: '5px', backgroundColor: 'rgba(237, 127, 16, 0.4)' }
    } else if (internship.ownership === Ownership.INSTITUTION) {
      style = { border: '2px solid #228B22', borderRadius: '5px', backgroundColor: 'rgb(34,139,34, 0.2)' }
    }

    return style
  }, [internship])

  useEffect(() => {
    if (internship.startDate && internship.endDate) {
      setPeriodIndexes(getIndexesByColumns(internship, dateColumns))
    }
  }, [internship, dateColumns])

  const handleLineHover = () => {
    setIsHovered(true)
    onLineHover(periodIndexes)
  }

  const handleLineLeave = () => {
    setIsHovered(false)
  }

  const handleUpdate = sector => {
    setDisplaySectorSelector(false)
    onUpdate({ sector: sector.id })
  }

  return (
    <tr
      className='internship-line'
      onMouseOver={handleLineHover}
      onMouseLeave={handleLineLeave}
    >
      <td>
        <div className='internship-card-old' style={customStyle}>
          <div className='quick-menu-container'>
            <StudentQuickMenu
              noMargin
              internship={internship}
              onInternshipDelete={onInternshipDelete}
              onSelectedInternshipInfo={onInternshipSelected}
              onSelectedForSectorChange={() => setDisplaySectorSelector(!displaySectorSelector)}
              onSelectedStudentInfo={onStudentSelected}
            />
          </div>
          {internship.ownership !== Ownership.INSTITUTION && (
            <ActionInternshipCard
              displaySectorSelector={displaySectorSelector}
              internship={internship}
              onUpdate={handleUpdate}
              tooltip={!!internship.individualInternshipRequest}
            />
          )}
          {internship.ownership === Ownership.INSTITUTION && (
            <OwnedInternshipCard
              context={user.context}
              disable={!!internship.student}
              internship={internship}
              refresh={refreshInternships}
            />
          )}
          <div className='status-validation-wrapper'>
            <StatesPellet
              internship={internship}
              onChange={handleStateChange}
            />
            {user.canValidateInternship && internship.shifts && internship.shifts.length > 0 &&
              <InternshipValidationButton
                validateButtonTooltip={t(getValidationTooltipText(internship))}
                internship={internship}
                onDisplayValidationDrawer={setInternshipToValidate}
              />}
          </div>
        </div>
      </td>
      {dateColumns.map((dates, index) => {
        return (
          <InstitutionDateCell
            key={index}
            startDate={dates.startDate}
            endDate={dates.endDate}
            index={index}
            isHovered={isHovered}
            periodIndexes={periodIndexes}
          />
        )
      })}
    </tr>
  )
}

export default InstitutionInternshipLine
