import apiClient from './apiClient'

// #region CRUD
export const fetchAllActTypes = (user, queryParams = { school: null }) => apiClient
  .get(user, 'api/act-types', queryParams)

export const createActType = (user, body) => apiClient
  .post(user, 'api/act-types', body)

export const updateActType = (user, actType, body) => apiClient
  .patch(user, `api/act-types/${actType.id}`, body)

export const deleteActType = (user, actType) => apiClient
  .delete(user, `api/act-types/${actType.id}`)
// #endregion

// #region Sections
export const fetchActTypeSections = (user, actType) => apiClient
  .get(user, `api/act-types/${actType.id}/school-sections`)

export const linkSectionsToActType = (user, actType, schoolSectionsIds = []) => apiClient
  .post(user, `api/act-types/${actType.id}/school-sections`, { ids: schoolSectionsIds })

export const unlinkSectionsToActType = (user, actType, schoolSectionsIds = []) => apiClient
  .delete(user, `api/act-types/${actType.id}/school-sections`, { ids: schoolSectionsIds })
// #endregion

// #region User
export const fetchLinkedActTypes = (user, linkedUser) => apiClient
  .get(user, `api/users/${linkedUser.id}/act-types`)

export const linkActTypes = (user, linkedUser, queryParams = { ids: [] }) => apiClient
  .post(user, `api/users/${linkedUser.id}/act-types`, queryParams)

export const unlinkActTypes = (user, linkedUser, queryParams = { ids: [] }) => apiClient
  .delete(user, `api/users/${linkedUser.id}/act-types`, queryParams)
// #endregion
