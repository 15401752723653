import React, { useContext } from 'react'

import { SettingsFactory } from '../TablePieces/TableHeadersTools'
import SearchFieldFactory from '../TablePieces/TableHeadersTools/SearchFieldFactory'
import AddButton from './TableHeaderPart/AddButton'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { isFunction } from 'lodash'

const TableHeader = props => {
  const { isReadOnly } = useContext(GlobalContext)

  const handleDataAdd = () => {
    runFunctionIfExist(props.onDataAdd)
  }

  const handleFilter = searchValue => {
    runFunctionIfExist(props.onDataFilter, [searchValue])
  }

  const handleLineShownSelected = lineShown => {
    runFunctionIfExist(props.onLineShownSelected, [lineShown])
  }

  const handleLinesDensitySelected = linesDensity => {
    runFunctionIfExist(props.onLineDensitySelected, [linesDensity + 'px'])
  }

  const runFunctionIfExist = (func, functionParameters) => {
    if (typeof func === 'function') {
      functionParameters ? func(...functionParameters) : func()
    }
  }

  return (
    <div className='flex-row table-header'>
      {!props.disableSearchFields &&
        <SearchFieldFactory
          updateFilter={handleFilter}
          placeHolder={props.searchFieldPlaceholder}
        />}
      {!props.disableSettings &&
        <SettingsFactory
          onLineShownSelected={handleLineShownSelected}
          onLineDensitySelected={handleLinesDensitySelected}
          onColumnVisibilitySelected={props.onColumnVisibilitySelected}
        />}

      <div className='flex-fill' />

      {(!props.disableAdd && !isReadOnly && isFunction(props.onDataAdd)) &&
        <AddButton
          onDataAdd={handleDataAdd}
          addText={props.addButtonText}
        />}
    </div>
  )
}

export default TableHeader
