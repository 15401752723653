import { ValidationFields } from '../validators'
import { isSupportedLanguage, validateString } from '../index'

export const EXTERNAL_CONTACT_VALIDATORS = [
  { key: ValidationFields.EMAIL, validator: u => validateString(u.email, { notEmpty: true, isEmail: true }) },
  { key: ValidationFields.LASTNAME, validator: u => validateString(u.lastname, { notEmpty: true }) },
  { key: ValidationFields.FIRSTNAME, validator: u => validateString(u.firstname, { notEmpty: true }) },
  { key: ValidationFields.LANGUAGE, validator: u => isSupportedLanguage(u.languageLocale?.languageTag, { notEmpty: true }) },
  { key: ValidationFields.INSTITUTION, validator: u => u.institution !== null }]

export class ExternalContact {
  constructor ({ id, lastname, firstname, institution, email, languageLocale }) {
    this.id = id ?? -1
    this.lastname = lastname ?? null
    this.firstname = firstname ?? null
    this.institution = institution ?? null
    this.email = email ?? null
    this.languageLocale = languageLocale ?? null
  }

  toRequestBody () {
    return {
      lastname: this.lastname,
      firstname: this.firstname,
      institution: this.institution === null ? null : this.institution.id,
      email: this.email,
      languageLocale: this.languageLocale === null ? null : this.languageLocale.languageTag
    }
  }
}
