import React, { useCallback, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import FormItem from './FormItem'
import { faFile, faSave } from '@fortawesome/pro-solid-svg-icons'
import { ValidationFields } from '../../../utils/validators'
import Dragger from 'antd/lib/upload/Dragger'
import IconButton from '../../antd/Buttons/IconButton'
import { InstitutionDocument } from '../../../utils/entities/institutionDocument'
import { ACCEPTED_DOCUMENT_META } from '../../../utils/entities/internshipDocument'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InstitutionDocumentForm = ({ item, t, onSubmit }) => {
  const [error, setError] = useState({ missingFields: [] })
  const [fileList, setFilesList] = useState([])

  const errorClass = useCallback(error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  const handleSubmit = useCallback(() => {
    onSubmit({ file: fileList[0], data: new InstitutionDocument(item) })
  }, [item, error, setError, fileList, t])

  return (
    <div className='flex-col'>
      <FormItem className={errorClass[ValidationFields.ORIGINAL_NAME]} icon={faFile} label='form.label.file' required>
        <Dragger
          accept={ACCEPTED_DOCUMENT_META.join(',')}
          fileList={fileList}
          name='file'
          beforeUpload={(file) => {
            setFilesList([file])

            return false
          }}
          onRemove={() => setFilesList([])}
        >
          <p className='ant-upload-text'>{t('import_quotas_button.upload_text')} </p>
        </Dragger>
      </FormItem>
      <IconButton type='primary' icon={faSave} onClick={handleSubmit} text={t('actions.save')} />
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionDocumentForm)
