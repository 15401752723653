import { ValidationFields } from '../validators'

export const INTERNSHIP_FILE_TYPES = {
  COVER_LETTER: 'COVER_LETTER',
  RESUME: 'RESUME',
  OTHER: 'OTHER'
}
export const INTERNSHIP_FILE_TYPE_COLORS = {
  COVER_LETTER: 'blue',
  RESUME: 'green',
  OTHER: 'purple'
}

export const INTERNSHIP_REQUEST_FILE_VALIDATORS = [
  { key: ValidationFields.TYPE, validator: s => s.type !== null },
  { key: ValidationFields.ORIGINAL_NAME, validator: s => s.originalName !== null }
]

export const getOriginalName = file => file.originalName
export const getExtension = file => file.extension
export const getType = file => file.type
export const getTypeColor = file => INTERNSHIP_FILE_TYPE_COLORS[getType(file)]
export class InternshipRequestFile {
  constructor ({ id = -1, type = INTERNSHIP_FILE_TYPES.OTHER, originalName = null, mimeType = null, extension = null, size = null, individualInternshipRequest = null } = {}) {
    this.id = id
    this.type = type
    this.originalName = originalName
    this.mimeType = mimeType
    this.extension = extension
    this.size = size
    this.individualInternshipRequest = individualInternshipRequest
  }

  toRequestBody = () => {
    const body = { ...this }

    body.individualInternshipRequest = body.individualInternshipRequest === null ? null : body.individualInternshipRequest.id

    return body
  }
}
