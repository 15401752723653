import { requestWithPromise } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'
import { INSTITUTION_OPTIONS } from './actionsType'

const {
  SET_INSTITUTION_OPTIONS, UPDATE_INSTITUTION_OPTIONS
} = INSTITUTION_OPTIONS

const getInstitutionOptions = state => {
  return state.options
}

const getInstitutionOptionsLoaded = state => {
  return state.loaded
}

const fetchInstitutionOptions = (institutionId, user, callback = null) => {
  return async function fetchInstititionOptionsThunk (dispatch, getState) {
    requestWithPromise(`/api/institutions/${institutionId}/institution-options`, HttpMethods.GET, null, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch(setInstitutionOptions(jsonResponse.data))
        }

        if (callback !== null) {
          callback()
        }
      })
  }
}

const updateInstitutionOptions = (institutionId, institutionOptionId, body, user, callback = null) => {
  return async function fetchInstititionOptionsThunk (dispatch, getState) {
    requestWithPromise(`/api/institutions/${institutionId}/institution-options/${institutionOptionId}`, HttpMethods.PATCH, body, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: UPDATE_INSTITUTION_OPTIONS,
            payload: jsonResponse.data
          })

          if (callback) {
            callback(jsonResponse.data)
          }
        }
      })
  }
}

const setInstitutionOptions = options => {
  return {
    type: SET_INSTITUTION_OPTIONS,
    payload: options
  }
}

export {
  getInstitutionOptions,
  getInstitutionOptionsLoaded,
  fetchInstitutionOptions,
  updateInstitutionOptions
}
