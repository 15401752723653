import React, { createContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { getInternshipStudentEvaluations } from '../utils/api/evaluationInternship'
import { getEvaluationQuestions } from '../utils/api/evaluation'
import { getAttracivityModuleInstitutionsStatesByStudent, getStudentInternships } from '../utils/api/student'
import { OtherRoles } from '../utils/constants'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

export const EvaluationsContext = createContext()

const EvaluationsProvider = ({ children, user }) => {
  const [questions, setQuestions] = useState({})
  const [evaluations, setEvaluations] = useState({})
  const [attractivityStateByInstitution, setAttractivityStateByInstitution] = useState({})
  const [internshipsToEvaluate, setInternshipsToEvaluate] = useState([])

  useEffect(() => {
    if (user.id && user.roles.includes(OtherRoles.STUDENT)) {
      getAttracivityModuleInstitutionsStatesByStudent(user, user.student)
        .then(data => {
          setAttractivityStateByInstitution(data)
        })
      getStudentInternships(user, user.student, { studentEvaluated: false, contexts: ['sector', 'institution'] }).then(json => {
        if (json?.data) {
          setInternshipsToEvaluate(json.data)
        }
      })
    }
  }, [user.id])

  const getQuestions = institution => {
    if (questions[institution.id]) {
      return new Promise(resolve => {
        resolve(questions[institution.id])
      })
    }

    return getEvaluationQuestions(user, institution)
      .then(json => {
        const newQuestions = { ...questions }
        newQuestions[institution.id] = json.data

        setQuestions(newQuestions)

        return json?.data ?? []
      })
  }

  const getEvaluations = internship => {
    if (evaluations[internship.id]) {
      return new Promise(resolve => {
        resolve(evaluations[internship.id])
      })
    }

    return getInternshipStudentEvaluations(user, internship)
      .then(json => {
        const newEvaluations = { ...evaluations }

        newEvaluations[internship.id] = json.data

        setEvaluations(newEvaluations)

        return json?.data ?? []
      })
  }

  const deleteEvaluations = (internship) => {
    const newEvaluations = { ...evaluations }

    delete newEvaluations[internship.id]

    setEvaluations(newEvaluations)
  }

  return (
    <EvaluationsContext.Provider
      value={{ getQuestions, getEvaluations, deleteEvaluations, attractivityStateByInstitution, internshipsToEvaluate, setInternshipsToEvaluate }}
    >
      {children}
    </EvaluationsContext.Provider>
  )
}

export default connect(mapStateToProps)(EvaluationsProvider)
