import React, { useContext, useEffect, useMemo, useState } from 'react'

import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import { BACKEND_DATE_FORMAT, InternshipStatesCanBeValidated, SchoolRoles } from '../../../../utils/constants'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import InternshipValidationButton from '../../ShiftsManager/InternshipValidationButton'
import SchoolDateCell from '../DateCell/SchoolDateCell'
import StudentQuickMenu from '../StudentQuickMenu'
import { getStatesPellet } from '../utils/componentGetters'
import { getIndexesByColumns, getValidationTooltipText } from '../utils/utils'
import { Modal, Tooltip } from 'antd'
import { recursiveIncludes } from '../../../../utils'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import ActionInternshipCard from '../../InternshipCard/school/ActionInternshipCard'
import OwnedInternshipCard from '../../InternshipCard/school/OwnedInternshipCard'
import { Ownership } from '../../../../utils/entities/internship'
import InternshipRequestCard from '../../InternshipCard/school/InternshipRequestCard'

const DEFAULT_INDEXES = { startIndex: 0, lastIndex: 0 }

const SchoolInternshipLine = ({ internshipContext, getQuotasState, onLineHover }) => {
  const {
    internship, overlaping, onPeriodUpdate, onUpdate, handleStateChange, handleDuplicateInternship, setOverlaping
  } = useContext(internshipContext)
  const {
    dateColumns, user, onInternshipSelected, onStudentSelected,
    onInternshipDelete, refreshInternships, setInternshipToValidate, t
  } = useContext(InternshipsManagerContext)
  const { isReadOnly } = useContext(GlobalContext)

  const [isHovered, setIsHovered] = useState(false)
  const [periodIndexes, setPeriodIndexes] = useState(DEFAULT_INDEXES)

  const isInternshipRequest = useMemo(() => !!internship.individualInternshipRequest, [internship])
  const customStyle = useMemo(() => {
    let style = {}

    if (internship.individualInternshipRequest !== null) {
      style = { border: '2px solid #ED7F10', borderRadius: '5px', backgroundColor: 'rgba(237, 127, 16, 0.4)' }
    } else if (internship.ownership === Ownership.INSTITUTION) {
      style = { border: '2px solid #228B22', borderRadius: '5px', backgroundColor: 'rgb(34,139,34, 0.2)' }
    }

    return style
  }, [internship])

  const StatesPellet = getStatesPellet(user.context)
  const validationEnabled = user.canValidateInternship && internship.shifts && internship.shifts.length > 0 && Object.values(InternshipStatesCanBeValidated).includes(internship.state)

  useEffect(() => {
    if (internship.startDate && internship.endDate) {
      setPeriodIndexes(getIndexesByColumns(internship, dateColumns))
    }
  }, [internship, dateColumns])

  const updatePeriod = ({ startDate, endDate }, direction, index) => {
    const newInternship = {
      ...internship,
      startDate: typeof startDate === 'undefined'
        ? internship.startDate
        : startDate.format(BACKEND_DATE_FORMAT),
      endDate: typeof endDate === 'undefined'
        ? internship.endDate
        : endDate.format(BACKEND_DATE_FORMAT)
    }

    callFunctionIfDefined(onPeriodUpdate, [newInternship])
  }

  const handleLineHover = () => {
    setIsHovered(true)
    onLineHover(periodIndexes)
  }

  const handleLineLeave = () => {
    setIsHovered(false)
  }

  return (
    <tr
      className='internship-line'
      onMouseOver={handleLineHover}
      onMouseLeave={handleLineLeave}
    >
      <Modal
        footer={null}
        visible={overlaping}
        title={t('Warning!')}
        onCancel={() => setOverlaping(false)}
      >
        {t('This student is already doing an internship during this period.')}
      </Modal>
      <td>
        <div className='internship-card-old'>
          <Tooltip title={isInternshipRequest ? t('internship_line.internship_request_info') : ''}>
            <div className='internship-card-old' style={customStyle}>
              <div className='quick-menu-container'>
                <StudentQuickMenu
                  noMargin
                  internship={internship}
                  onSelectedStudentInfo={onStudentSelected}
                  onSelectedInternshipInfo={onInternshipSelected}
                  onInternshipDelete={onInternshipDelete}
                  onDuplicateInternship={handleDuplicateInternship}
                />
              </div>
              {internship.ownership !== Ownership.INSTITUTION && !isInternshipRequest && (
                <ActionInternshipCard
                  disabled={isReadOnly || isInternshipRequest || !recursiveIncludes(user.roles, [SchoolRoles.ENCODER, SchoolRoles.COORDINATOR])}
                  internship={internship}
                  onInternshipUpdate={onUpdate}
                />
              )}
              {isInternshipRequest && (
                <InternshipRequestCard internship={internship} />
              )}
              {internship.ownership === Ownership.INSTITUTION && !isInternshipRequest && (
                <OwnedInternshipCard internship={internship} refresh={refreshInternships} />
              )}
              <div className='status-validation-wrapper'>
                <StatesPellet
                  internship={internship}
                  onChange={handleStateChange}
                />
                {!isReadOnly && validationEnabled && (
                  <InternshipValidationButton
                    validateButtonTooltip={t(getValidationTooltipText(internship))}
                    internship={internship}
                    onDisplayValidationDrawer={setInternshipToValidate}
                  />
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      </td>
      {dateColumns.map((dates, index) => {
        return (
          <SchoolDateCell
            key={index}
            disabled={isInternshipRequest || internship.ownership === Ownership.INSTITUTION}
            startDate={dates.startDate}
            endDate={dates.endDate}
            index={index}
            isHovered={isHovered}
            periodIndexes={periodIndexes}
            onPeriodChange={updatePeriod}
          />
        )
      })}
    </tr>
  )
}

export default SchoolInternshipLine
