import React, { useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Routes } from '../routes'
import { getUser } from '../reducers/UserReducer'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { connect } from 'react-redux'
import SectorsView from '../Views/school/validator/SectorsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionProvider from '../Providers/InstitutionProvider'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'

const mapStateToProps = state => ({user: getUser(state.getUser)})

const ValidatorRouter = ({ history, moduleOption, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  return (
    <InstitutionProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={setCurrentDate} />
          </Route>
          <Route path={Routes.SHIFTS_MANAGER}>
            <ShiftsManagerView
              currentDate={currentDate}
              displayManagePresets={() => history.push(Routes.PRESETS_MANAGER)}
            />
          </Route>
          <Route path={Routes.INSTITUTION_SECTORS}>
            <SectorsView />
          </Route>
          <Route path={Routes.TAGS_MANAGEMENT}>
            <TagsManagementView />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
        </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(connect(mapStateToProps)(ValidatorRouter))
