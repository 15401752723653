import React from 'react'

import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import TooltipIconButton from '../../antd/Buttons/TooltipIconButton'
import { isFunction } from 'lodash'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const PaginationHeader = ({ maxPageIndex, pageIndex, dataType, dataCount, onPageChange, t }) => {
  const handlePageIndexChange = newPageIndex => {
    if (isFunction(onPageChange)) {
      onPageChange(newPageIndex)
    }
  }

  const renderPaginationText = () => {
    return (
      <div className='page-index'>
        {`${getDataText()} - ${getPageText()}`}
      </div>
    )
  }

  const getDataText = () => {
    if (dataCount > 1) {
      return `${dataCount} ${t(dataType + 's')}`
    }

    return `${dataCount} ${t(dataType)}`
  }

  const getPageText = () => {
    return `${t('Page')} ${pageIndex} / ${maxPageIndex}`
  }

  return (
    <div className='flex-row'>
      <TooltipIconButton
        title={t('First page')}
        size='small'
        icon={faAngleDoubleLeft}
        onClick={() => handlePageIndexChange(1)}
        disabled={pageIndex <= 1}
      />
      <div className='h-spacing' />
      <TooltipIconButton
        title={t('Previous page')}
        size='small'
        icon={faAngleLeft}
        onClick={() => handlePageIndexChange(pageIndex - 1)}
        disabled={pageIndex <= 1}
      />
      <div className='h-spacing' />
      {renderPaginationText()}
      <div className='h-spacing' />
      <TooltipIconButton
        title={t('Next page')}
        size='small'
        icon={faAngleRight}
        onClick={() => handlePageIndexChange(pageIndex + 1)}
        disabled={pageIndex >= maxPageIndex}
      />
      <div className='h-spacing' />
      <TooltipIconButton
        title={t('Last page')}
        size='small'
        icon={faAngleDoubleRight}
        onClick={() => handlePageIndexChange(maxPageIndex)}
        disabled={pageIndex >= maxPageIndex}
      />
    </div>
  )
}

export default connect(mapStateToProps)(PaginationHeader)
