import React, { useState, useMemo, useCallback } from 'react'

import { connect } from '../../../reducers/Dispatchers'
import { Contexts, OrderBy } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import { fetchInsitutionContactPersons, fetchSchoolContactPersons } from '../../../utils/api/contactPersons'
import useQuery from '../../../hooks/useQuery'
import { Metadata } from '../../../utils/http'
import { TableParameters } from '../../../utils/entities/tableParameters'
import VisibilityValues from './VisibilityValues'
import ContactPersonsTable from './ContactPersonsTable'
import Cookie from 'js-cookie'

const QUERY_VARIABLES = {
  [Contexts.INSTITUTION]: 'sectors',
  [Contexts.SCHOOL]: 'sections'
}

const DEFAULT_TABLE_PARAMS = {
  order: { by: OrderBy.EMAIL },
  pageSize: 20
}

const mapStateToProps = state => ({
  user: getUser(state.getUser)
})

const ContactPersonsView = ({ user, visibility = VisibilityValues.INTERNAL, defaultTableParameters = new TableParameters({ ...DEFAULT_TABLE_PARAMS }) }) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [tableParameters, setTableParameters] = useState(defaultTableParameters)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '255px' : '215px'})`, [Cookie.get('switch_user_id')])

  const contextConfig = useMemo(() => {
    let fetchContacts
    let pathVariable
    const queryVariable = QUERY_VARIABLES[user.context]

    if (user.context === Contexts.INSTITUTION) {
      fetchContacts = fetchInsitutionContactPersons
      pathVariable = user.institutions[0].id
    } else if (user.context === Contexts.SCHOOL) {
      fetchContacts = fetchSchoolContactPersons
      pathVariable = user.school.id
    }

    return { fetchContacts, pathVariable, queryVariable }
  }, [user.id])

  const request = useCallback((user, queryParams) =>
    contextConfig.fetchContacts(user, contextConfig.pathVariable, queryParams)
  , [contextConfig.fetchContacts, contextConfig.pathVariable])

  const contactsHandler = useQuery({
    request,
    defaultParameters: { visibility },
    defaultMetadata: new Metadata({ pageSize: defaultTableParameters.pageSize })
  })

  const updateQueryParameters = useCallback(tableParameters => {
    const queryParameters = new TableParameters({ ...tableParameters }).toQueryParametersOld()

    if (tableParameters.order.by === 'phone_number') {
      queryParameters.orderBy = 'phone'
    } else if (tableParameters.order.by === 'role') {
      queryParameters.orderBy += 's'
    }

    contactsHandler.setParameters({ ...contactsHandler.parameters, ...queryParameters })
  }, [contactsHandler.parameters])

  const handlePaginationChange = useCallback(targetPage => {
    const { first, last, prev, next } = contactsHandler.metadata

    switch (targetPage) {
      case first: contactsHandler.first()
        break
      case last: contactsHandler.last()
        break
      case next: contactsHandler.next()
        break
      case prev: contactsHandler.previous()
        break
      default:
        break
    }
  }, [contactsHandler.metadata])

  const handleOptionsChange = useCallback((options = []) => {
    setSelectedOptions(options)
    updateQueryParameters({ [contextConfig.queryVariable]: options })
  }, [contactsHandler.parameters, contextConfig.queryVariable])

  const handleTableParametersChange = useCallback(tableParameters => {
    setTableParameters(new TableParameters(tableParameters))

    const { page } = contactsHandler.metadata

    if (tableParameters.page !== page) {
      handlePaginationChange(tableParameters.page)
    } else {
      updateQueryParameters(tableParameters)
    }
  }, [contactsHandler.metadata])

  return (
    <div style={{ height: maxHeight }}>
      <ContactPersonsTable
        visibility={visibility}
        data={contactsHandler.data}
        metadata={contactsHandler.metadata}
        loading={contactsHandler.loading}
        tableParameters={tableParameters}
        onTableParametersChange={handleTableParametersChange}
        options={selectedOptions}
        onOptionsChange={handleOptionsChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ContactPersonsView)
