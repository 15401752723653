import React, { useEffect, useState } from 'react'

import UserSectionTable, { DATA_TYPE_SELECT, DATA_TYPE_CHECK_CONTACT_PERSON } from './UserSectionTable'
import { Drawer, notification } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { generalErrorHandler, requestWithPromise } from '../../utils'
import { formatOldSection, isNewSection, schoolSectionToString } from '../../utils/entitiesFormaters/SchoolSection'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { Contexts } from '../../utils/constants'
import { HttpMethods } from '../../utils/apiHelper'

const UserCoordinatedSections = props => {
  const [userCoordinatedSections, setUserCoordinatedSections] = useState([])
  const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false)
  const [contactPersonsSection, setContactPersonsSection] = useState([])

  useEffect(() => {
    if (props.getUser.context) {
      setUserIsSuperAdmin(props.getUser.context === Contexts.ADMIN)
    }

    if (props.user === null) {
      setUserCoordinatedSections([])
    }

    if (props.user !== null) {
      requestWithPromise(`/api/users/${props.user.id}/school-sections`, HttpMethods.GET, null, props.getUser)
        .then(json => {
          setUserCoordinatedSections(json.data)

          const contactPersonsSection = []

          for (const section of json.data) {
            contactPersonsSection[section.id] = section.contactPersons.map(contactPerson => {
              return contactPerson.id
            })
          }

          setContactPersonsSection(contactPersonsSection)
        })
    }
  }, [props.user, props.getUser])

  const selectedSchool = () => {
    const { schoolId, getSchools } = props
    if (schoolId) {
      // also check cache
      if (this._previouslySelectedSchool && this._previouslySelectedSchool.id === schoolId) {
        return this._previouslySelectedSchool
      }
      const school = getSchools.find(item => item.id === schoolId)
      this._previouslySelectedSchool = school
    }
    return getSchools[0]
  }

  const handleAddSection = section => {
    const sections = getSections()
    const newSection = sections.filter(s => s.id === section.newId)[0]

    const newUserCoordinatedSections = userCoordinatedSections.concat(newSection)

    updateUserCoordinatedSection(newUserCoordinatedSections)
  }

  const handleEditSection = section => {
    const sections = getSections()
    const editedSection = sections.filter(s => s.id === section.newId)[0]

    const newUserCoordinatedSections = userCoordinatedSections.map(ucs => {
      if (ucs.id === section.prevId) return editedSection
      return ucs
    })

    updateUserCoordinatedSection(newUserCoordinatedSections)
  }

  const handleDeleteSection = section => {
    const newUserCoordinatedSections = userCoordinatedSections.filter(ucs => ucs.id !== section.section)

    updateUserCoordinatedSection(newUserCoordinatedSections)
  }

  const updateUserCoordinatedSection = userCoordinatedSections => {
    setUserCoordinatedSections(userCoordinatedSections)

    const body = { coordinatedSections: userCoordinatedSections.map(ucs => ucs.id) }

    if (userIsSuperAdmin && props.schoolId) {
      body.schoolId = props.schoolId
    }

    requestWithPromise(`/user/modify/${props.user.id}`, HttpMethods.POST, body, props.getUser)
      .then((json) => { })
      .catch((error) => { generalErrorHandler(error) })
  }

  const userIsContactPerson = sectionId => {
    if (props.user && contactPersonsSection[sectionId]) {
      return contactPersonsSection[sectionId].includes(props.user.id)
    }
  }

  const setUserContactPerson = sectionId => {
    const body = {
      userToAdd: props.user.id
    }

    requestWithPromise(`/api/school-sections/${sectionId}/contact-persons`, HttpMethods.POST, body, props.getUser)
      .then(jsonResponse => {
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setMultipleUserContactPersons = schoolSections => {
    const body = {
      userToAdd: props.user.id,
      schoolSections: schoolSections
    }

    requestWithPromise('/school-sections/contact-persons', HttpMethods.POST, body, props.getUser)
      .then(jsonResponse => {
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const searchWithFilter = value => {
    const search = typeof value !== 'undefined' ? `?search=${value}` : ''

    requestWithPromise(`/api/users/${props.user.id}/school-sections${search}`, HttpMethods.GET, null, props.getUser)
      .then(json => {
        setUserCoordinatedSections(json.data)

        const contactPersonsSection = []

        for (const section of json.data) {
          contactPersonsSection[section.id] = section.contactPersons.map(contactPerson => {
            return contactPerson.id
          })
        }

        setContactPersonsSection(contactPersonsSection)
      })
  }

  const getSections = () => {
    const schoolId = selectedSchool()
      ? selectedSchool().id
      : undefined

    return schoolId && props.getSchoolSections[schoolId]
      ? props.getSchoolSections[schoolId]
      : []
  }

  const render = () => {
    let userCoordinatedSectionsRender = userCoordinatedSections
    let sections = getSections()

    if (props.getSchools.length > 0) {
      sections = sections.map(section => {
        const sectionName = isNewSection(section)
          ? schoolSectionToString(section)
          : formatOldSection(section)

        return {
          id: section.id,
          name: sectionName
        }
      })

      userCoordinatedSectionsRender = userCoordinatedSectionsRender.map(ucs => {
        return {
          id: ucs.id,
          section: ucs.id
        }
      })

      // Some sections (root sections and 'No section' for internships without section) have been removed from received data.
      userCoordinatedSectionsRender = userCoordinatedSectionsRender.filter(ucs => {
        return sections.filter(s => s.id === ucs.id).length === 1
      })
    }

    const COLUMNS = [
      {
        type: DATA_TYPE_SELECT,
        name: 'common.section',
        options: sections
      },
      {
        type: DATA_TYPE_CHECK_CONTACT_PERSON,
        name: props.t('common.contact_persons')
      }
    ]

    const additionalActions = [
      {
        icon: faTrash,
        type: 'danger',
        title: 'Delete',
        titlePopconfirm: props.t('Delete this section?'),
        handleOnClick: data => handleDeleteSection(data)
      }
    ]

    return (
      <Drawer
        title={props.user !== null ? props.t('Sections coordinated by') + ' ' + props.user.email : '?'}
        width='640px'
        onClose={props.onClose}
        visible={props.user !== null}
      >
        <UserSectionTable
          onDataAdd={handleAddSection}
          onDataEdit={handleEditSection}
          data={userCoordinatedSectionsRender}
          columns={COLUMNS}
          additionalActions={additionalActions}
          userIsContactPerson={userIsContactPerson}
          setUserContactPerson={setUserContactPerson}
          setMultipleUserContactPersons={setMultipleUserContactPersons}
          searchWithFilter={searchWithFilter}
        />
      </Drawer>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCoordinatedSections)
