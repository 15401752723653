import React, { useContext, useState } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Routes } from '../routes'
import ChangeLogView from '../Views/shared/ChangeLogView'
import SectorsView from '../Views/institution/SectorsView/SectorsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionProvider from '../Providers/InstitutionProvider'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import InstitutionSettingsView from '../Views/institution/Settings/InstitutionSettingsView'
import StudentsView from '../Views/institution/StudentsView'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'

const ObserverRouter = ({ history }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const [selectedSector, setSelectedSector] = useState(null)

  const goToShiftPresets = sector => {
    setSelectedSector(sector || null)
    history.push(Routes.PRESETS_MANAGER)
  }

  return (
    <InstitutionProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={setCurrentDate} />
          </Route>
          <Route path={Routes.SHIFTS_MANAGER}>
            <ShiftsManagerView currentDate={currentDate} />
          </Route>
          <Route path={Routes.STUDENTS}>
            <StudentsView />
          </Route>
          <Route path={Routes.INSTITUTION_SECTORS}>
            <SectorsView displayManagePresets={goToShiftPresets} />
          </Route>
          <Route path={Routes.PRESETS_MANAGER}>
            <ShiftPresetsManager sector={selectedSector} />
          </Route>
          <Route path={Routes.SETTINGS}>
            <InstitutionSettingsView isObserver />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
        </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(ObserverRouter)
