import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { InstitutionOptions, InstitutionRoles, InternshipStates, SchoolRoles } from '../../../../utils/constants'
import StatesSelector from './StatesSelector'
import { getInstitutionOptions } from '../../../../reducers/InstitutionOptionsReducer/actions'

const {
  PRE_ACCEPTED, ACCEPTED, AUTO_ACCEPTED, ACCEPTED_UNMANAGED, CANCELED, CREATED, PENDING, PENDING_NO_SECTOR,
  REFUSED, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED
} = InternshipStates
const DEFAULT_PARAMETERS = {
  disabled: true,
  states: []
}
const ENABLE_STATES = [
  ACCEPTED, PENDING, SCHEDULE_ADDED, SCHEDULE_VALIDATED,
  SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED
]
const ACCEPTED_STATES = [
  ACCEPTED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE
]

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  modules: getInstitutionOptions(state.institutionOptions)
})

const InstitutionStatesSelector = ({ internship, modules, user, onChange }) => {
  const [disabled, setDisabled] = useState(true)
  const [states, setStates] = useState([])

  useEffect(() => {
    if (user && internship) {
      const parameters = getParametersByInternshipAndUser(internship, user, modules)

      if (user.roles.includes(SchoolRoles.VALIDATOR)) {
        parameters.disabled = true
      }

      setDisabled(parameters.disabled)
      setStates(parameters.states)
    }
  }, [user, internship, modules])

  const getParametersByInternshipAndUser = (internship, user, modules) => {
    const useDraftInternships = !!modules.find(m => (m.optionType.type === InstitutionOptions.DRAFT_INTERNSHIPS && m.optionEnabled))
    const useNurseInternshipAcceptance = !!modules.find(m => (m.optionType.type === InstitutionOptions.NURSE_INTERNSHIP_ACCCEPTANCE && m.optionEnabled))
    const isAdmin = user.roles.includes(InstitutionRoles.HOSPITAL_ADMIN)
    const parameters = { ...DEFAULT_PARAMETERS }
    const isCompleted = internship.student && internship.internshipYear && internship.sector && internship.institution

    parameters.disabled = !isAdmin

    if (ENABLE_STATES.includes(internship.state)) {
      parameters.states = [
        { title: 'Put on hold', state: PENDING },
        { title: 'Accept', state: ACCEPTED_STATES.includes(internship.state) ? internship.state : ACCEPTED }
      ]

      if (!isAdmin && useNurseInternshipAcceptance && internship.state === PENDING) {
        parameters.disabled = false
      } else {
        if (isAdmin) {
          parameters.states.push({ title: 'Refuse', state: REFUSED })
        }

        if (internship.state === ACCEPTED_UNMANAGED) {
          parameters.states.push({ title: 'Accepted unmanaged', state: ACCEPTED_UNMANAGED })
        }

        if (useDraftInternships && internship.state === PENDING) {
          parameters.states.push({ title: 'Pre-accepted', state: PRE_ACCEPTED })
        }
      }
    } else if (internship.state === AUTO_ACCEPTED) {
      parameters.states = [
        { title: 'Put on hold', state: PENDING },
        { title: 'Accept', state: ACCEPTED },
        { title: 'Auto accept', state: AUTO_ACCEPTED }
      ]

      if (isAdmin) {
        parameters.states.push({ title: 'Refuse', state: REFUSED })
      }
    } else if (internship.state === CANCELED) {
      parameters.disabled = true
      parameters.states = [
        { title: 'Cancel', state: CANCELED }
      ]
    } else if (
      internship.state === PENDING_NO_SECTOR ||
      internship.state === CREATED
    ) {
      parameters.disabled = !useDraftInternships || !isAdmin
      parameters.states = [
        { title: 'Pending', state: internship.state },
        { title: 'Refuse', state: REFUSED }
      ]

      if (useDraftInternships) {
        parameters.states.push({ title: 'Pre-accepted', state: PRE_ACCEPTED })
        parameters.states[0].state = internship.state === PENDING_NO_SECTOR ? PENDING_NO_SECTOR : CREATED
      }
    } else if (internship.state === PRE_ACCEPTED) {
      parameters.states = [{ title: 'Pre-accepted', state: PRE_ACCEPTED }]

      if (isCompleted) {
        parameters.states.push({ title: 'Accepted', state: ACCEPTED })
      }

      if (!isAdmin && useNurseInternshipAcceptance && isCompleted) {
        parameters.disabled = false
      } else {
        parameters.states.push(isCompleted ? { title: 'Pending', state: PENDING } : { title: 'Created', state: CREATED })
        parameters.states.push({ title: 'Refuse', state: REFUSED })
      }
    } else if (internship.state === REFUSED) {
      if (isCompleted) {
        parameters.states = [
          { title: 'Put on hold', state: PENDING },
          { title: 'Accept', state: ACCEPTED_STATES.includes(internship.state) ? internship.state : ACCEPTED },
          { title: 'Refuse', state: REFUSED }
        ]
      } else {
        parameters.states = [
          { title: 'Created', state: CREATED },
          { title: 'Pre-accepted', state: PRE_ACCEPTED },
          { title: 'Refuse', state: REFUSED }
        ]
      }
    }

    return parameters
  }

  return (
    <StatesSelector
      disabled={disabled}
      internship={internship}
      states={states}
      onChange={onChange}
      showLabel={false}
    />
  )
}

export default connect(mapStateToProps)(InstitutionStatesSelector)
