import { Contexts, SchoolRoles, InstitutionRoles, OtherRoles } from './constants'
import { isArray, isObject } from 'lodash'

export const isNurse = user => isValidUser(user) && user.roles.includes(InstitutionRoles.NURSE)

export const isObserver = user => isValidUser(user) && user.roles.includes(InstitutionRoles.OBSERVER)

export const isDomainEvaluator = user => isValidUser(user) && user.roles.includes(SchoolRoles.DOMAIN_EVALUATOR)

export const isSupervisor = user => isValidUser(user) && user.roles.includes(SchoolRoles.SUPERVISOR)

export const isValidator = user => isValidUser(user) && user.roles.includes(SchoolRoles.VALIDATOR)

export const isEncoder = user => isValidUser(user) && user.roles.includes(SchoolRoles.ENCODER)

export const isCoordinator = user => isValidUser(user) && user.roles.includes(SchoolRoles.COORDINATOR)

export const isHospitalAdmin = user => isValidUser(user) && user.roles.includes(InstitutionRoles.HOSPITAL_ADMIN)

export const isAdmin = user => isValidUser(user) && user.roles.includes(OtherRoles.ADMIN)

export const isStudent = user => isValidUser(user) && user.roles.includes(OtherRoles.STUDENT)

export const isSchoolUser = user => isValidUser(user) && user.context.includes(Contexts.SCHOOL)

export const isInstitutionUser = user => isValidUser(user) && user.context.includes(Contexts.INSTITUTION)

const isValidUser = user => user && isObject(user) && isArray(user.roles)

export default {
  isNurse,
  isObserver,
  isDomainEvaluator,
  isSupervisor,
  isValidator,
  isEncoder,
  isCoordinator,
  isHospitalAdmin,
  isAdmin,
  isStudent
}
