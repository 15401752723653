import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faList } from '@fortawesome/pro-solid-svg-icons'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import InternshipsManagerView from '../../shared/InternshipsManagerView'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { InstitutionRoles } from '../../../utils/constants'
import InternshipsRequestsView from './InternshipsRequestsView/InternshipsRequestsView'
import InternshipsListView from './InternshipsList/InternshipsListView'
import IndividualInternshipRequestModal from '../../../Providers/Forms/IndividualInternshipRequestModal'
import { InstitutionContext } from '../../../Providers/InstitutionProvider'
import InternshipRequestFilesDrawer from './InternshipsRequestsView/InternshipRequestFilesDrawer'
import useSessionStorage from '../../../hooks/UseSessionStorage'
import { getUser } from '../../../reducers/UserReducer'

const { TabPane } = Tabs

export const INTERNSHIP_VIEW_ACTIVE_TAB_KEY = 'institution.internships_view_active_key'

/** @enum {string} */
export const InternshipsViewTabs = Object.freeze({
  LIST: '1',
  CALENDAR: '2',
  REQUESTS: '3'
})

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipsView = ({ user, t }) => {
  const { currentRole } = useContext(GlobalContext)
  const institutionContext = useContext(InstitutionContext)
  const [activeTab, setActiveTab] = useState(InternshipsViewTabs.LIST)

  const [storedValue, updateStoredValue] = useSessionStorage(INTERNSHIP_VIEW_ACTIVE_TAB_KEY + user.id, activeTab)

  const handleTabChange = useCallback((value) => {
    updateStoredValue(value)
    setActiveTab(value)
  }, [updateStoredValue, setActiveTab])

  /** @note This effect is needed to keep in sync with the session storage because we cannot listen to it directly. */
  useEffect(() => setActiveTab(String(storedValue)), [storedValue, setActiveTab])

  return (
    <Tabs activeKey={activeTab} animated={false} onChange={handleTabChange}>
      <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('List view')}</span></div>} key={InternshipsViewTabs.LIST}>
        <InternshipsListView />
      </TabPane>
      <TabPane tab={<div><FontAwesomeIcon icon={faCalendar} /> <span>{t('internships_view.titles.internships_timeline')}</span></div>} key={InternshipsViewTabs.CALENDAR}>
        <InternshipsManagerView />
      </TabPane>
      {currentRole === InstitutionRoles.HOSPITAL_ADMIN && (
        <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('internships_view.titles.internships_request')}</span></div>} key={InternshipsViewTabs.REQUESTS}>
          <IndividualInternshipRequestModal schools={institutionContext.schools} institutions={[institutionContext.institution]} sectors={institutionContext.sectors}>
            <InternshipRequestFilesDrawer>
              <InternshipsRequestsView />
            </InternshipRequestFilesDrawer>
          </IndividualInternshipRequestModal>
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(InternshipsView)
