import React, { useContext, useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { OrderBy, InstitutionRoles } from '../../../utils/constants'
import { Sector, getComment, getEmplacement, getInstitutionName, getName } from '../../../utils/entities/sector'
import { TableActions, Action, ArchiveSector, EditSector, ExternalAction } from '../../../Components/shared/DataTable/utils/actions'
import { connect } from 'react-redux'
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons'
import ArchivedSectorsModal from '../../../Components/institution/ArchivedSectorsModal'
import { getUser } from '../../../reducers/UserReducer'
import SelectInstitutionFilter from '../../../Components/shared/DataTable/utils/SelectInstitutionFilter'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import { Filter } from '../../../Components/shared/DataTable/utils/tableFilter'
import Cookies from 'js-cookie'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorsTable = ({ data, institution = null, loading, metadata, parameters, user, onParametersChange, onTriggerAction, t }) => {
  const igContext = useContext(InstitutionGroupContext)
  const { selectedInstitution, setSelectedInstitution } = useContext(GlobalFiltersContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '180px' : '139px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => {
    const columns = [
      {
        title: t('sectors_table.column_title.name'),
        orderBy: OrderBy.NAME,
        key: i => {
          const name = getName(i)
          const maxLength = !institution ? 50 : 30

          return name.length > maxLength ? `${name.slice(0, maxLength - 3)}...` : name
        },
        tooltip: getName
      }
    ]

    if (institution === null) {
      columns.push({
        title: t('sectors_table.column_title.institution'),
        orderBy: OrderBy.INSTITUTION,
        key: i => {
          const name = getInstitutionName(i)

          return name.length > 50 ? `${name.slice(0, 47)}...` : name
        },
        tooltip: getInstitutionName
      })
    }

    columns.push(
      { title: t('sectors_table.column_title.comment'), orderBy: OrderBy.COMMENT, key: getComment },
      { title: t('sectors_table.column_title.emplacement'), orderBy: OrderBy.EMPLACEMENT, key: getEmplacement }
    )

    return columns
  }, [institution, t, getComment, getEmplacement, getName])
  const actions = useMemo(() => {
    const actions = [new EditSector({ onClick: s => onTriggerAction(s, TableActions.EDIT_SECTOR) }).build()]

    if (user.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN)) {
      actions.push(new Action({ title: 'institutions_view.actions.switch_institution', icon: faExchangeAlt, onClick: s => onTriggerAction(s, TableActions.SWITCH_SECTOR) }))
    }

    actions.push(new ArchiveSector({ onDelete: s => onTriggerAction(s, TableActions.ARCHIVE_SECTOR) }).build())

    return actions
  }, [onTriggerAction])
  const externalActions = useMemo(() => [
    new ExternalAction({
      Component: ArchivedSectorsModal,
      key: 'archive',
      props: {
        institution: institution,
        unarchive: s => onTriggerAction(s, TableActions.UNARCHIVE_SECTOR),
        onDelete: s => onTriggerAction(s, TableActions.DELETE)
      },
      title: t('sectors_table.external_actions.display_archive_sectors')
    })
  ], [institution, onTriggerAction, t])

  const filters = useMemo(() => {
    const filters = []

    if (institution === null && user.roles.includes(InstitutionRoles.INSTITUTION_GROUP_ADMIN)) {
      filters.push(new Filter({
        key: 'institution',
        label: t('common.institution'),
        component: SelectInstitutionFilter,
        props: { institutions: igContext?.institutions, value: selectedInstitution, onChange: setSelectedInstitution }
      }))
    }

    return filters
  }, [user, igContext?.institutions, selectedInstitution, setSelectedInstitution, t])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        externalActions={externalActions}
        filters={filters}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onTriggerAction(new Sector({ institution }), TableActions.CREATE_SECTOR)}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SectorsTable)
