import React, { createContext, useContext, useMemo, useState } from 'react'

import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import InternshipFilesManager from '../../Components/shared/FilesManager/InternshipFilesManager/InternshipFilesManager'
import InternshipFileModal from '../Forms/InternshipFileModal'
import { GlobalContext } from '../GlobalProvider'

export const InternshipDocumentsDrawerContext = createContext()

export const DEFAULT_STATE = { data: null }

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const InternshipDocumentsDrawer = ({ children, t }) => {
  const { isReadOnly } = useContext(GlobalContext)
  const [selectedInternship, setSelectedInternship] = useState(DEFAULT_STATE)

  return (
    <>
      <InternshipDocumentsDrawerContext.Provider value={{ setSelectedInternship }}>
        {children}
      </InternshipDocumentsDrawerContext.Provider>
      <Drawer
        title={t('internship_files_drawer.title')}
        width='650px'
        onClose={() => setSelectedInternship(DEFAULT_STATE)}
        visible={!!selectedInternship.data}
        zIndex={5000}
        destroyOnClose
      >
        <InternshipFileModal>
          <InternshipFilesManager internship={selectedInternship.data} readOnly={isReadOnly} />
        </InternshipFileModal>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(InternshipDocumentsDrawer)
