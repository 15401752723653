
import { request } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'

export const INITIAL_STATE = {
  templates: [],
  variables: {}
}

const SET_INSTITUTION_TEMPLATES = 'SET_INSTITUTION_TEMPLATES'
const SET_INSTITUTION_TEMPLATE_VARIABLES = 'SET_INSTITUTION_TEMPLATE_VARIABLES'
const SET_INSTITUTION_TEMPLATES_STATE = 'SET_INSTITUTION_TEMPLATES_STATE'

const getInstitutionTemplates = (state) => {
  return state.institutionTemplates.templates
}

const getInstitutionTemplatesVariables = (state) => {
  return state.institutionTemplates.variables
}

const fetchInstitutionTemplates = (institutionId, user) => {
  return async function (dispatch, getState) {
    const response = await request(
      '/api/institution-templates?institution=' + institutionId,
      HttpMethods.GET,
      null,
      user
    )

    if (response.data) {
      dispatch(setInstitutionTemplates(response.data))
    }
  }
}

const fetchTemplateVariables = (templateId, user) => {
  return async function fetchTemplateVariablesThunk (dispatch, getState) {
    const response = await request(
      `/api/institution-templates/${templateId}/variables`,
      HttpMethods.GET,
      null,
      user
    )

    if (response.data) {
      dispatch(setInstitutionTemplateVariables(response.data, templateId))
    }
  }
}

const setInstitutionTemplatesState = newState => {
  return {
    type: SET_INSTITUTION_TEMPLATES_STATE,
    payload: newState
  }
}

const setInstitutionTemplates = institutionTemplates => {
  return {
    type: SET_INSTITUTION_TEMPLATES,
    payload: institutionTemplates
  }
}

const setInstitutionTemplateVariables = (variables, templateId) => {
  return {
    type: SET_INSTITUTION_TEMPLATE_VARIABLES,
    payload: {
      variables: variables,
      id: templateId
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INSTITUTION_TEMPLATES_STATE: {
      return { ...action.payload }
    }
    case SET_INSTITUTION_TEMPLATES: {
      return {
        ...state, templates: [...action.payload]
      }
    }
    case SET_INSTITUTION_TEMPLATE_VARIABLES: {
      const templatesVariables = { ...state.variables }

      templatesVariables[action.payload.id] = action.payload.variables

      return {
        ...state, variables: { ...templatesVariables }
      }
    }
    default:
      return state
  }
}

export {
  getInstitutionTemplates,
  getInstitutionTemplatesVariables,
  fetchInstitutionTemplates,
  fetchTemplateVariables,
  setInstitutionTemplatesState,
  setInstitutionTemplates,
  setInstitutionTemplateVariables
}
