import React from 'react'

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { ColorsPalette } from '../../config/colors'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const TooltipIcon = ({ title, t }) => {
  return (
    <Tooltip title={t(title)}>
      <FontAwesomeIcon icon={faInfoCircle} color={ColorsPalette.SECOND} />
    </Tooltip>
  )
}

export default connect(mapStateToProps)(TooltipIcon)
