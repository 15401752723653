import sortBy from 'lodash/sortBy'
import remove from 'lodash/remove'
import { request, generalErrorHandler } from '../../utils'
import { HttpMethods } from '../../utils/apiHelper'
const SET_ACT_TYPE_CATEGORIES_LIST = 'SET_ACT_TYPE_CATEGORIES_LIST'

export const getActTypeCategoriesForSchool = (state, ownProps) => {
  let schoolId = null
  if (ownProps.section && (ownProps.section.schoolId || ownProps.section.school)) {
    schoolId = ownProps.section.schoolId || ownProps.section.school
  }
  if (ownProps && schoolId && state && state.categories) {
    const listOfCategoriesForSchool = state.categories.filter(item => item.schoolId === schoolId)
    return sortBy(listOfCategoriesForSchool, ['name'])
  }
  return []
}

export const fetchActTypeCategories = (currentUser) => {
  return async dispatch => {
    try {
      const { data } = await request('/api/ActTypeCategory', HttpMethods.GET, null, currentUser)
      if (data) {
        const actTypeCategoriesList = data.map(({ id, name, school }) => ({
          id,
          name,
          schoolId: school
        }))
        dispatch({
          type: SET_ACT_TYPE_CATEGORIES_LIST,
          categories: actTypeCategoriesList
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const createActTypeCategory = (newActTypeCategory, errorCallback, currentUser) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await request('/api/ActTypeCategory', HttpMethods.POST, newActTypeCategory, currentUser)
      if (data) {
        const { id, name, school } = data
        const newList = [...getState().actTypeCategories.categories, { id, name, schoolId: school }]
        dispatch({
          type: SET_ACT_TYPE_CATEGORIES_LIST,
          categories: newList
        })
        return data
      } else if (typeof errorCallback === 'function') {
        errorCallback()
      }
      return null
    } catch (err) {
      generalErrorHandler(err)
      return null
    }
  }
}

export const editActTypeCategory = (actType, errorCallback, originalValues, currentUser) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await request(`/api/ActTypeCategory/${actType.id}`, HttpMethods.PATCH, { name: actType.name }, currentUser)
      if (data) {
        const listToUpdate = getState().actTypeCategories.categories.slice()
        const itemToUpdate = listToUpdate.find(item => item.id === actType.id)
        if (itemToUpdate) {
          itemToUpdate.name = actType.name
        }
        dispatch({
          type: SET_ACT_TYPE_CATEGORIES_LIST,
          categories: listToUpdate
        })
      } else if (typeof errorCallback === 'function') {
        errorCallback()
        const listToUpdate = getState().actTypeCategories.categories.slice()
        const itemToUpdate = listToUpdate.find(item => item.id === actType.id)
        const originalItem = originalValues.find(item => item.id === actType.id)
        if (itemToUpdate && originalItem) {
          itemToUpdate.name = originalItem.name
        }
        dispatch({
          type: SET_ACT_TYPE_CATEGORIES_LIST,
          categories: listToUpdate
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const deleteActTypeCategory = (categoryId, currentUser) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await request(`/api/ActTypeCategory/${categoryId}`, HttpMethods.DELETE, null, currentUser)
      if (data) {
        const listToUpdate = getState().actTypeCategories.categories.slice()
        remove(listToUpdate, item => item.id === categoryId)
        dispatch({
          type: SET_ACT_TYPE_CATEGORIES_LIST,
          categories: listToUpdate
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export default (state = { categories: [], loading: true }, action) => {
  switch (action.type) {
    case SET_ACT_TYPE_CATEGORIES_LIST:
      return { categories: action.categories, loading: false }
    default:
      return state
  }
}
