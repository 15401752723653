import isObject from 'lodash/isObject'
import { request } from '../../utils'
import remove from 'lodash/remove'
import { HttpMethods } from '../../utils/apiHelper'
const SET_SCHOOLS = 'SET_SCHOOLS'
const UPDATE_SCHOOL = 'UPDATE_SCHOOL'
const ADD_NEW_SCHOOL = 'ADD_NEW_SCHOOL'
const REMOVE_SCHOOL = 'REMOVE_SCHOOL'
const REMOVE_SCHOOL_USER = 'REMOVE_SCHOOL_USER'

export const getSchools = (state) => {
  return state.list
}

export const setSchools = (schools) => {
  return {
    type: SET_SCHOOLS,
    schools
  }
}

export const updateSchool = school => {
  return {
    type: UPDATE_SCHOOL,
    school
  }
}

export const addNewSchool = school => {
  return {
    type: ADD_NEW_SCHOOL,
    school
  }
}

export const removeSchool = school => {
  return {
    type: REMOVE_SCHOOL,
    school
  }
}

export const addSchoolUser = (schoolId, user) => {
  return (dispatch, getState) => {
    const { list } = getState().getSchools
    const updatedList = list.slice()

    const schoolToUpdate = updatedList.find(item => item.id === schoolId)
    if (schoolToUpdate) {
      schoolToUpdate.users.push(user)

      dispatch({
        type: SET_SCHOOLS,
        schools: updatedList
      })
    }
  }
}

export const removeSchoolUser = ({
  userId,
  currentUser
}) => {
  request(`/api/User/${userId}`, HttpMethods.DELETE, null, currentUser)
  return {
    type: REMOVE_SCHOOL_USER,
    payload: { schoolId: currentUser.school.id, userId }
  }
}

const handleUserRemove = ({ state, schoolId, userId }) => {
  return state.list.map(school => {
    if (school.id === schoolId) {
      remove(school.users, item => item.id === userId)
    }
    return school
  })
}

export default (state = { list: [], schoolYears: [], schoolYearsLoaded: false }, action) => {
  switch (action.type) {
    case SET_SCHOOLS:
      return { ...state, list: action.schools }
    case UPDATE_SCHOOL:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.school.id) {
            const { address, city, country, email, name, zipcode, area } = action.school

            const newSchool = { ...item, address, city, email, name, zipcode, area: null }

            newSchool.country = country.id !== undefined ? country : { id: country }

            if (area) {
              newSchool.area = isObject(area) ? area : { id: area }
            }

            return newSchool
          }

          return item
        })
      }
    case ADD_NEW_SCHOOL:
      return { ...state, list: [...state, action.school] }
    case REMOVE_SCHOOL:
      return { ...state, list: state.list.filter(item => item.id !== action.school.id) }
    case REMOVE_SCHOOL_USER:
      return { ...state, list: handleUserRemove({ ...action.payload, state }) }
    default:
      return state
  }
}
