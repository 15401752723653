import { createSelector } from 'reselect'
import { InstitutionRoles } from '../../utils/constants'

const getUserRoles = state => state.roles
const getUserManagedSectors = state => state.affiliatedSectors

export const isLocalAdmin = createSelector(
  [getUserRoles, getUserManagedSectors],
  (roles, affiliatedSectors) => {
    if (roles === undefined || affiliatedSectors === undefined) {
      return false
    }

    return roles.includes(InstitutionRoles.HOSPITAL_ADMIN) && affiliatedSectors.length !== 0
  }
)
