import React, { useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSectionAbbreviation, isObject } from '../../../../utils'
import IconTooltip from '../../../antd/Icons/IconTooltip'

const NO_INSTITUTION_TEXT = 'Without institution'
const NO_STUDENT_TEXT = 'Without student'
const NO_SECTOR_TEXT = 'Without care unit'
const NO_INTERNSHIP_YEAR_TEXT = 'Without internship year'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const InternshipCard = ({ internship, legend, t }) => {
  const textualData = useMemo(() => internship ? ({
    institution: isObject(internship.institution) ? internship.institution.name : t(NO_INSTITUTION_TEXT),
    student: isObject(internship.student) ? `${internship.student.lastname} ${internship.student.firstname}` : t(NO_STUDENT_TEXT),
    sector: isObject(internship.sector) ? internship.sector.name : t(NO_SECTOR_TEXT),
    internshipYear: isObject(internship.internshipYear) ? internship.internshipYear.schoolYearName : t(NO_INTERNSHIP_YEAR_TEXT),
    section: getSectionAbbreviation(internship.section, t)
  }) : {}, [internship, t])

  return (
    <div className='internship-info-old'>
      <div style={{ height: '25px', lineHeight: '25px', display: 'flex' }}>
        {textualData.section + ` (${textualData.internshipYear})`}
        &nbsp; - &nbsp;
        <span
          style={{ maxWidth: '340px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <b> {textualData.student} </b>
        </span>
      </div>
      <div className='flex-row' style={{ height: '25px', lineHeight: '25px' }}>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '50%' }}>
          {textualData.institution}
        </span>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        &nbsp; - &nbsp; {textualData.sector}
        </span>
      </div>
      {legend && (<div className='internship-card-note'> <IconTooltip title={t(legend)} /> </div>)}
    </div>
  )
}

export default connect(mapStateToProps)(InternshipCard)
