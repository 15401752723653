import React, { Suspense } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Routes } from '../routes'
import ChangeLogView from '../Views/shared/ChangeLogView'
import InstitutionEvaluationSummaryView from '../Views/institution/InstitutionEvaluationSummaryView'
import ReportingView from '../Views/shared/ReportingView'
import GroupsSettingsView from '../Views/institution/Settings/GroupsSettingsView'
import InstitutionGroupProvider from '../Providers/InstitutionGroupProvider'
import SchoolsView from '../Views/institutionGroup/SchoolsView/SchoolsView'
import InstitutionsView from '../Views/institutionGroup/InstitutionsView/InstitutionsView'
import SectorsView from '../Views/institutionGroup/SectorsView/SectorsView'
import StudentsView from '../Views/institution/StudentsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'

const InstitutionGroupAdminRouter = ({ history, moduleOption }) => {
  return (
    <InstitutionGroupProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView />
          </Route>
          <Route path={Routes.STUDENTS}>
            <StudentsView />
          </Route>
          <Route path={Routes.SETTINGS}>
            <GroupsSettingsView />
          </Route>
          <Route path={Routes.BADGES}>
            <ReportingView />
          </Route>
          <Route path={Routes.SCHOOLS}>
            <SchoolsView />
          </Route>
          <Route path={Routes.INSTITUTIONS}>
            <InstitutionsView />
          </Route>
          <Route path={Routes.INSTITUTION_SECTORS}>
            <SectorsView />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          <Route path={Routes.STUDENTS_EVALUATION}>
            <Suspense fallback={<div>Loading...</div>}>
              <InstitutionEvaluationSummaryView />
            </Suspense>
          </Route>
        </Switch>
    </InstitutionGroupProvider>
  )
}

export default withRouter(InstitutionGroupAdminRouter)
