import React, { useState, useEffect, useContext } from 'react'

import InformationDisplay from './InformationDisplay'
import LoadingSkeleton from './LoadingSkeleton'
import moment from 'moment'
import { Drawer, Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleArrows, faPlusCircle, faCalendarAlt, faCog, faStethoscope, faFile } from '@fortawesome/pro-solid-svg-icons'
import { faHouseMedical } from '@fortawesome/pro-duotone-svg-icons'
import { request, generalErrorHandler, requestWithPromise, isNotNullObject } from '../../utils'
import InternshipValidationButton from './ShiftsManager/InternshipValidationButton'
import { Contexts, InternshipStates } from '../../utils/constants'
import InternshipValidation from './ShiftsManager/InternshipValidation'
import ConventionHandler from './ConventionHandler'
import ScheduleBonusTable from './ScheduleBonusTable'
import History from './History'
import { Shift } from '../../utils/entities/shift'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import { getInternshipShifts } from '../../utils/api/internship'
import { getIconByName } from '../../utils/fontAwesomeHelper'
import { DEFAULT_STATE, InternshipDocumentsDrawerContext } from '../../Providers/Drawer/InternshipDocumentsDrawer'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { isFunction } from 'lodash'
import { connect } from 'react-redux'
import IconButton from '../antd/Buttons/IconButton'
import { isSchoolUser } from '../../utils/roles'

import '../../assets/shifts-manager.scss'
import { HttpMethods } from '../../utils/apiHelper'
import { GlobalContext } from '../../Providers/GlobalProvider'

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const InternshipInfo = ({ internship, loading, user, fetchInternshipDocuments, onClose, onInternshipValidate, t }) => {
  const { isReadOnly } = useContext(GlobalContext)
  const { setSelectedInternship } = useContext(InternshipDocumentsDrawerContext)

  const [localInternship, setLocalInternship] = useState(null)
  const [shifts, setShifts] = useState([])
  const [openValidationDrawer, setOpenValidationDrawer] = useState(false)
  const [localLoading, setLocalLoading] = useState(false)
  const [loadingContactPersons, setLoadingContactPersons] = useState(true)
  const [contactPersons, setContactPersons] = useState([])
  const [contactPersonsSector, setContactPersonsSector] = useState([])
  const [contactPersonsSection, setContactPersonsSection] = useState([])
  const [scheduleBonusModalVisible, setScheduleBonusModalVisible] = useState(false)

  useEffect(() => {
    if (isNotNullObject(internship)) {
      getInternshipShifts(internship, user).then(json => {
        if (json?.data) {
          setShifts(json.data)
        }
      })
      request('/internship/details/' + internship.id, HttpMethods.GET, null, user).then(json => {
        setLocalInternship(json.data)
        setLocalLoading(false)
        loadContactPersons(json.data)
      }).catch(error => { generalErrorHandler(error) })
    } else {
      setLocalInternship(null)
      setLoadingContactPersons(true)
      setContactPersons([])
    }
  }, [internship, user])

  const handleInternshipValidation = () => {
    const currentInternship = { ...localInternship }

    currentInternship.state = InternshipStates.SCHEDULE_VALIDATED

    setLocalInternship(currentInternship)

    if (isFunction(onInternshipValidate)) {
      onInternshipValidate(currentInternship)
    }
  }

  const handleInternshipInvalidation = newState => {
    const currentInternship = { ...localInternship }

    currentInternship.state = newState

    setLocalInternship(currentInternship)

    if (isFunction(onInternshipValidate)) {
      onInternshipValidate(currentInternship)
    }
  }

  const renderSchedule = internship => {
    const sortedShifts = shifts.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate))
    })

    return (
      <History
        data={sortedShifts.map(s => {
          const shift = new Shift(s)
          const pauseIcon = shift.getPauseIcon()

          return {
            date: shift.startDate,
            body: shift.isAbsent()
              ? <span className='absent-schedule'><b> {t('event.code.label.' + shift.eventCodeType.type)} </b></span>
              : <span className='default-schedule'> {shift.getSchedule()} {pauseIcon && <FontAwesomeIcon icon={getIconByName(pauseIcon)} />} </span>
          }
        })}
        period={{
          startDate: getTimezoneLessMoment(internship.startDate),
          endDate: getTimezoneLessMoment(internship.endDate)
        }}
        strictMode
      />
    )
  }

  const loadContactPersons = async (internship) => {
    const { institution, school, sector, section } = internship

    if (institution && sector && section && school) {
      try {
        const contactPersonsUrl = isSchoolUser(user) ? `/api/institutions/${institution.id}/contact-persons` : `/api/schools/${school.id}/contact-persons`

        const { data } = await requestWithPromise(contactPersonsUrl, HttpMethods.GET, null, user)
        const dataSector = await requestWithPromise(`/api/sectors/${sector.id}/contact-persons`, HttpMethods.GET, null, user)
        const dataSection = await requestWithPromise(`/api/school-sections/${section.id}/contact-persons`, HttpMethods.GET, null, user)

        if (!data.status || data.status !== 'error') {
          setContactPersons(data)
          setContactPersonsSector(dataSector.data)
          setContactPersonsSection(dataSection.data)
        } else {
          setContactPersons([])
          setContactPersonsSector([])
          setContactPersonsSection([])
        }
      } catch (err) {
        generalErrorHandler(err)
      }
    }

    setLoadingContactPersons(false)
  }

  const renderContactPersons = () => {
    if (loadingContactPersons) {
      return (
        <div className='flex-column'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='loading-icon black' />
            <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading contact persons')}</div>
          </div>
        </div>
      )
    }

    if (!contactPersons.length && !contactPersonsSector.length && !contactPersonsSection.length) {
      return (
        <div className='flex-column'>
          {t('None')}
        </div>
      )
    }

    const schoolContactPersons = contactPersons.filter(item => item.type === 'school').concat(contactPersonsSection)
    const institutionContactPersons = contactPersons.filter(item => item.type === 'institution').concat(contactPersonsSector)

    return (
      <div className='flex-column'>
        {schoolContactPersons.length > 0 && schoolContactPersons.map(schoolContactPerson => (
          <div key={schoolContactPerson.email}>
            <b>{t('common.school')}:</b>&nbsp;
            {`${schoolContactPerson.lastname} ${schoolContactPerson.firstname} - `}
            <a href={'mailto:' + schoolContactPerson.email}>{schoolContactPerson.email}</a>
            {schoolContactPerson.phone !== null && schoolContactPerson.phone !== '' ? ` - ${schoolContactPerson.phone}` : ''}
          </div>
        ))}
        {institutionContactPersons.length > 0 && institutionContactPersons.map(institutionContactPerson => (
          <div key={institutionContactPerson.email}>
            <b>{t('common.institution')}:</b>&nbsp;
            {`${institutionContactPerson.lastname} ${institutionContactPerson.firstname} - `}
            <a href={'mailto:' + institutionContactPerson.email}>{institutionContactPerson.email}</a>
            {institutionContactPerson.phone !== null && institutionContactPerson.phone !== '' ? ` - ${institutionContactPerson.phone}` : ''}
          </div>
        ))}
      </div>
    )
  }

  const renderBody = () => {
    let title = ''
    let content = <LoadingSkeleton />

    if (localInternship !== null) {
      let validateButtonTooltip = t(`internship_infos.validate_button.${isReadOnly ? 'see' : 'validate'}`)

      switch (localInternship.state) {
        case InternshipStates.SCHEDULE_TO_VALIDATE:
          validateButtonTooltip = t('internship_infos.validate_button.to_validate')
          break
        case InternshipStates.SCHEDULE_VALIDATED:
          validateButtonTooltip = t('internship_infos.validate_button.validated')
          break
      }

      title = (
        <div className='internship-sheet-title'>
          <span>
            {t('Internship sheet')}
            {localLoading || loading
              ? (
                <div className='loading-icon black' style={{ marginTop: '1px' }} />
              )
              : null}
          </span>
          <span className='internship-validation-button-custom'>
            <InternshipValidationButton
              validateButtonTooltip={validateButtonTooltip}
              onDisplayValidationDrawer={() => setOpenValidationDrawer(true)}
              internship={localInternship}
            />
          </span>
        </div>
      )

      const internshipInfo = [
        {
          id: 9,
          icon: 'user',
          title: t('Student'),
          caption: localInternship.student ? localInternship.student.firstname + ' ' + localInternship.student.lastname : t('Without student')
        },
        {
          id: 1,
          icon: faHouseMedical,
          title: t('common.institution'),
          caption:
          localInternship.institution === null ? t('Without institution') : localInternship.institution.name
        },
        {
          id: 2,
          icon: faStethoscope,
          title: t('Care unit'),
          caption:
          localInternship.sector === null ? t('Without care unit') : localInternship.sector.name
        },
        {
          id: 3,
          icon: faPeopleArrows,
          title: t('common.contact_persons'),
          caption: renderContactPersons()
        },
        {
          id: 4,
          icon: faCog,
          title: t('Internship year'),
          caption:
          localInternship.internshipYear === null
            ? t('Without internship year')
            : localInternship.internshipYear.schoolYearName ?? localInternship.internshipYear.name
        },
        {
          id: 5,
          icon: faCalendarAlt,
          title: t('Dates'),
          caption:
            t('From') +
            ' ' +
            moment(localInternship.startDate).format('DD MMMM YYYY') +
            ' ' +
            t('to') +
            ' ' +
            moment(localInternship.endDate).format('DD MMMM YYYY')
        },
        {
          id: 6,
          icon: faFile,
          title: t('Internship convention'),
          caption: <ConventionHandler internship={localInternship} />
        },
        {
          id: 7,
          icon: faFile,
          title: t('Other internship documents'),
          caption: (
            <div>
              <Button
                type='default'
                size='small'
                onClick={() => setSelectedInternship({ ...DEFAULT_STATE, data: internship })}
              >
                <FontAwesomeIcon icon='paperclip' />
                &nbsp;{t('Attached file(s)')}
              </Button>
            </div>
          )
        },
        {
          id: 8,
          icon: faCalendarAlt,
          style: { width: '100%' },
          title: (
            <div className='schedule-title full-width flex-row'>
              <b> {t('common.schedule')} </b>
              {!isReadOnly && isSchoolUser(user) && (
                <IconButton
                  icon={faPlusCircle} 
                  color='blue' 
                  size='small' 
                  text={t('Hour bonus')} 
                  onClick={() => setScheduleBonusModalVisible(true)} 
                />
              )}
            </div>
          ),
          caption: renderSchedule(localInternship)
        }
      ]

      content = (
        <div className='flex-column'>
          <InformationDisplay information={internshipInfo} />
        </div>
      )
    }

    return (
      <Drawer
        title={title}
        width='550px'
        onClose={onClose}
        visible={internship !== null}
      >
        {content}
        {openValidationDrawer &&
          <InternshipValidation
            internship={localInternship}
            onClose={() => setOpenValidationDrawer(false)}
            onValidate={handleInternshipValidation}
            onInvalidate={handleInternshipInvalidation}
          />}
        {isSchoolUser(user) && (
          <Modal
            title={t('Hour bonus')}
            visible={scheduleBonusModalVisible}
            onCancel={() => setScheduleBonusModalVisible(false)}
            footer={null}
            destroyOnClose
            width={750}
          >
            <ScheduleBonusTable internship={localInternship} />
          </Modal>
        )}
      </Drawer>
    )
  }

  return (renderBody())
}

export default connect(mapStateToProps)(InternshipInfo)
