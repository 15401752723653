import { requestWithPromise } from '..'
import { HttpMethods, makeQueryStringFromObject } from '../apiHelper'

export const getAllSectionToYears = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/section-to-years${makeQueryStringFromObject(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getBySection = (section, user) => {
  return requestWithPromise(
    `/api/sections/${section.id}/section-to-years`,
    HttpMethods.GET,
    null,
    user
  )
}
