import React, { useState, useEffect, useRef } from 'react'
import { string, object } from 'prop-types'
import { Button, Dropdown, Menu } from 'antd'
import { downloadFile, generalErrorHandler, request } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapDispatchToProps, mapStateToProps } from '../../reducers/Dispatchers'
import { HttpMethods } from '../../utils/apiHelper'

function SmartTableCustomExportButton (props) {
  const [formats] = useState([
    { label: '.csv', value: 'csv' },
    { label: '.xlsx', value: 'xlsx' }
  ])
  const [format, setFormat] = useState(null)
  const downloadPollingInterval = useRef(null)

  useEffect(() => {
    if (!format) {
      return
    }

    let path
    let body
    if (props.path === 'internships') {
      path = '/internship/export-internships-daterange'
      body = {
        format: format
      }
    } else if (props.path === 'institutions') {
      path = '/institutions/export'
      body = {
        format: format
      }
    } else if (props.path === 'evaluations') {
      path = '/evaluation/export'
      body = {
        format: format
      }
    }

    if (props.requestParams) {
      body = { ...body, ...props.requestParams }
    }

    request(
      path,
      HttpMethods.POST,
      body,
      props.getUser
    ).then(json => {
      if (json.status !== 'error') {
        downloadPollingInterval.current = setInterval(() => {
          request('/job-result/' + json.job, HttpMethods.GET, null, props.getUser).then(json => {
            if (json.data) {
              clearInterval(downloadPollingInterval.current)

              // reset the selected format so user can use the button again
              setFormat(null)

              const fileName = json.data.result
              try {
                downloadFile(
                  '/spreadsheet/download/' + fileName,
                  fileName,
                  props.getUser,
                  HttpMethods.GET
                )
              } catch (err) {
                generalErrorHandler(err)
                clearInterval(downloadPollingInterval.current)
              }
            } else if (json.status === 'error') {
              clearInterval(downloadPollingInterval.current)
            }
          })
        }, 1500)
      }
    })
    return () => {
      if (downloadPollingInterval.current) {
        clearInterval(downloadPollingInterval)
      }
    }
  }, [format])

  return (
    <Dropdown overlay={
      <Menu onClick={(e) => setFormat(e.key)}>
        {formats.map(({ label, value }) => (
          <Menu.Item key={value}>{label}</Menu.Item>
        ))}
      </Menu>
    }
    >
      <Button>
        <FontAwesomeIcon icon='file-export' />
      </Button>
    </Dropdown>
  )
}

SmartTableCustomExportButton.propTypes = {
  path: string,
  requestParams: object // this is an object with different properties which will be sent to the API
}

SmartTableCustomExportButton.defaultProps = {
  requestParams: undefined
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartTableCustomExportButton)
