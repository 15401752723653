import React from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { Routes } from '../routes'
import SchoolInstitutionsView from '../Views/school/SchoolInstitutionsView'
import InstitutionSchoolsView from '../Views/institution/InstitutionSchoolsView'
import MailsListView from '../Views/super-admin/MailsListView'
import ChangelogManagementView from '../Views/super-admin/ChangelogManagementView'
import ChangeLogView from '../Views/shared/ChangeLogView'

const AdminRouter = () => {
  return (
    <Switch>
      <Route path={Routes.INSTITUTIONS}>
        <SchoolInstitutionsView />
      </Route>
      <Route path={Routes.SCHOOLS}>
        <InstitutionSchoolsView />
      </Route>
      <Route path={Routes.MAILS_LIST}>
        <MailsListView />
      </Route>
      <Route path={Routes.CHANGELOG_MANAGEMENT}>
        <ChangelogManagementView />
      </Route>
      <Route path={Routes.CHANGELOG}>
        <ChangeLogView />
      </Route>
    </Switch>
  )
}

export default withRouter(AdminRouter)
