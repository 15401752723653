import React, { useMemo } from 'react'
import { getTranslate } from 'react-localize-redux'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { OrderBy } from '../../../utils/constants'
import { TableActions, Action, DeleteAction } from '../../shared/DataTable/utils/actions'
import { connect } from 'react-redux'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { getUser } from '../../../reducers/UserReducer'
import Cookies from 'js-cookie'
import { ExternalContact } from '../../../utils/entities/ExternalContact'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ExternalContactsTable = ({ data, loading, metadata, parameters, user, onParametersChange, onTriggerAction, t }) => {
  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '180px' : '139px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => {
    return [
      { title: t('Lastname'), orderBy: OrderBy.LASTNAME, key: 'lastname' },
      { title: t('Firstname'), orderBy: OrderBy.FIRSTNAME, key: 'firstname' },
      { title: t('Email'), orderBy: OrderBy.EMAIL, key: 'email' },
      { title: t('Language'), orderBy: OrderBy.LANGUAGE, key: ec => t(ec.languageLocale.nameInNativeLanguage) }
    ]
  }, [t, user])

  const actions = useMemo(() => [
    new Action({ title: 'Edit', icon: faEdit, onClick: s => onTriggerAction(s, TableActions.EDIT_EC) }),
    new DeleteAction({ onDelete: s => onTriggerAction(s, TableActions.DELETE_EC) })
  ], [onTriggerAction])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onTriggerAction(
          new ExternalContact({ lastname: null, firstname: null, email: null, languageLocale: null }),
          TableActions.CREATE_EC
        )}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ExternalContactsTable)
