import React, { createContext, useState } from 'react'

import { isFunction, isObject } from 'lodash'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { createInternshipWithoutStudent, updateInternship } from '../../../utils/api/internship'
import { orderByNameSectors } from '../../../Selectors/Sectors/SectorsSelector'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { Modal } from 'antd'
import InternshipForm from './InternshipForm'
import { connect } from 'react-redux'

export const InternshipFormModalContext = createContext()

const DEFAULT_DATA = {
  object: null,
  callback: null
}

const mapStateToProps = state => ({
  sectors: orderByNameSectors(state),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipFormModal = ({ children, user, sectors, t }) => {
  const [data, setData] = useState(DEFAULT_DATA)

  const handleSubmit = updatedInternship => {
    const config = updatedInternship.id === -1
      ? { promise: createInternshipWithoutStudent, parameters: [user, updatedInternship], action: 'create' }
      : { promise: updateInternship, parameters: [updatedInternship, updatedInternship.toRequestBody(), user], action: 'update' }

    config.promise(...config.parameters).then(json => {
      if (json?.data && isFunction(data.callback)) {
        data.callback(json.data)
      }

      setData(DEFAULT_DATA)
      onSuccess(t(`internship_form.${config.action}.success`))
    }).catch(error => {
      console.error(error)
      onError(t(`internship_form.${config.action}.error`))
    })
  }

  return (
    <>
      <InternshipFormModalContext.Provider value={{ setData }}>
        {children}
      </InternshipFormModalContext.Provider>
      <Modal
        className='form-modal'
        title={t('internship_form.title')}
        visible={isObject(data.object)}
        onCancel={() => setData(DEFAULT_DATA)}
        destroyOnClose
        footer={null}
      >
        {isObject(data.object) && (
          <InternshipForm
            data={data.object}
            sectors={sectors}
            onSubmit={handleSubmit}
            onCancel={() => setData(DEFAULT_DATA)}
          />
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(InternshipFormModal)
