import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import InstitutionSectors from '../../Components/school/InstitutionSectors'
import SmartTable, { SmartTableDataTypes } from '../../Components/shared/SmartTable'
import { request, validateFormInput, generalErrorHandler } from '../../utils'
import Analytics from '../../utils/analytics'
import SmartTableCustomExportButton from '../../Components/shared/SmartTableCustomExportButton'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSchools } from '../../reducers/SchoolsReducer'
import { deleteInstitutionOld, updateInstitution } from '../../utils/api/institution'
import { SchoolContext } from '../../Providers/SchoolProvider'
import { InstitutionDocumentsDrawerContext } from '../../Providers/Drawer/InstitutionDocumentsDrawer'
import { HttpMethods } from '../../utils/apiHelper'
import { isEncoder } from '../../utils/roles'
import { GlobalContext } from '../../Providers/GlobalProvider'

const mapStateToProps = state => ({
  dataReady: state.getDataReady,
  schools: getSchools(state.getSchools),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const SchoolInstitutionsView = ({ dataReady, schools, user, t }) => {
  const { countries } = useContext(GlobalContext)
  const { institutions, setInstitutions, schoolLoading } = useContext(SchoolContext)
  const DocumentsContext = useContext(InstitutionDocumentsDrawerContext)

  const [selectedInstitutionSectors, setSelectedInstitutionSectors] = useState(null)

  const mappedInstitutions = useMemo(() => institutions.map(i => ({
    id: i.id,
    name: i.name,
    address: i.address,
    zipcode: i.zipcode,
    country: i.country === null ? null : i.country.id,
    city: i.city,
    description: i.description,
    countryDisabled: i.managed,
    managed: i.managed,
    filesCount: i.filesCount
  })), [institutions])

  const handleDocumentSelection = useCallback(institution => {
    if (DocumentsContext) {
      DocumentsContext.setSelectedInstitution({ data: institution })
    }
  }, [DocumentsContext])

  useEffect(() => {
    Analytics.pageView('/school-institution')
  }, [])

  const hasWritePermission = useMemo(() => {
    let canCrud = user.school?.managed ?? false

    if (canCrud) {
      canCrud = false

      if (schools.length > 0) {
        const { lockedInstitutionsCount } = schools[0]

        canCrud = lockedInstitutionsCount && isEncoder(user)
      } else if (isEncoder(user)) {
        canCrud = true
      }
    }

    return canCrud
  }, [user.school, user.roles, schools])

  const columns = useMemo(() => [
    { type: SmartTableDataTypes.ID, key: 'id' },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Name'),
      key: 'name',
      validate: data => validateFormInput('freeText', data, true)
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Address'),
      key: 'address',
      validate: data => validateFormInput('freeText', data, true)
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Zipcode'),
      key: 'zipcode',
      validate: data => validateFormInput('freeText', data, true)
    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('City'),
      key: 'city',
      validate: data => validateFormInput('name', data, true)
    },
    {
      type: SmartTableDataTypes.SELECT,
      name: t('Country'),
      key: 'country',
      options: countries.map(c => {
        return { id: c.id, name: t(c.name) }
      }),
      disabled: !hasWritePermission

    },
    {
      type: SmartTableDataTypes.STRING,
      name: t('Description'),
      key: 'description'
    }
  ], [t, hasWritePermission, countries])

  const addInstitution = (institution, stopLoading) => {
    institution.acronym = institution.name
    request('/api/Institution', HttpMethods.POST, institution, user)
      .then(json => {
        if (json.status !== 'error') {
          setInstitutions([...institutions, json.data])
        }

        stopLoading()
      })
      .catch(error => { generalErrorHandler(error) })
  }

  const editInstitution = institution => {
    const updatedInstitution = { ...institution }

    if (typeof updatedInstitution.countryDisabled !== 'undefined') {
      delete updatedInstitution.countryDisabled
    }

    if (typeof updatedInstitution.filesCount !== 'undefined') {
      delete updatedInstitution.filesCount
    }

    updateInstitution(user, updatedInstitution).then(json => {
      if (json?.data) {
        setInstitutions(institutions.map(i => {
          if (i.id === institution.id) {
            Object.keys(json.data).map(k => {
              i[k] = typeof (i[k]) === 'object' ? i[k] : json.data[k]
              return k
            })

            if (
              typeof json.data.country !== 'undefined' &&
                json.data.country !== null
            ) {
              i.country = countries.filter(
                c => c.id === json.data.country
              )[0]
            }
          }
          return i
        }))
      }
    })
  }

  const handleDeleteInstitution = institution => {
    deleteInstitutionOld(user, institution).then(json => {
      if (json?.data) {
        setInstitutions(institutions.filter(i => i.id !== institution.id))
      }
    })
  }

  const checkIfRowIsControllable = row => {
    if (row.id === -1) {
      return true
    }

    return isEncoder(user) && row.managed === false
  }

  return (
    <div>
      <SmartTable
        columns={columns}
        data={mappedInstitutions}
        loading={schoolLoading}
        onDataAdd={hasWritePermission ? addInstitution : undefined}
        onDataEdit={hasWritePermission ? editInstitution : undefined}
        onDataDelete={hasWritePermission ? handleDeleteInstitution : undefined}
        noDataText={<span> {t("Votre établissement n'a encore aucune institution vers laquelle envoyer des étudiants en stage.")} </span>}
        addDataText={t('Add an institution')}
        customExportButton={<SmartTableCustomExportButton path='institutions' />}
        checkIfRowIsControllable={hasWritePermission ? checkIfRowIsControllable : undefined}
        additionalActions={[
          {
            iconName: 'file',
            type: 'primary',
            title: t('Manage files'),
            onClick: handleDocumentSelection,
            customClassCallback: data => data.filesCount > 0 ? 'green-button' : ''
          },
          {
            iconName: 'stethoscope',
            type: 'primary',
            title: t('See care units'),
            onClick: setSelectedInstitutionSectors
          }
        ]}
      />
      <InstitutionSectors
        institution={selectedInstitutionSectors}
        onClose={() => setSelectedInstitutionSectors(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SchoolInstitutionsView)
