import React, { useEffect, useRef, useState } from 'react'

import { Select, Tooltip } from 'antd'
import { Metadata, buildUrlParameters, retrieveMetadata } from '../../../utils/http'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isArray } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faCog } from '@fortawesome/pro-solid-svg-icons'
import { isObject } from '../../../utils'

const Option = Select.Option
const TIME_BEFORE_SYNC = 500
export const ALL_DATA = {
  value: -1,
  label: '*',
  title: 'Any'
}
export const NO_DATA = {
  value: 0,
  label: '/',
  title: 'None'
}

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const LazySelect = ({
  allOption, dataKey, disabled, emptyOption, getData, parameters, defaultParameters, placement, showSearch, style, user, value, onSelect, t
}) => {
  const [data, setData] = useState([])
  const [metadata, setMetadata] = useState(new Metadata({ pageSize: 50 }))
  const [additionalRequestProperties, setAdditionalRequestProperties] = useState({})
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)

  const timer = useRef(null)
  const synchro = useRef(null)

  useEffect(() => {
    if (isObject(defaultParameters)) {
      setAdditionalRequestProperties(defaultParameters)
    }
  }, [])

  useEffect(() => {
    if (isObject(parameters)) {
      setAdditionalRequestProperties(parameters)
      setData([])
      setMetadata(new Metadata({ pageSize: 50 }))
    }

    return () => {
      setAdditionalRequestProperties({})
    }
  }, [parameters])

  useEffect(() => {
    if (typeof getData === 'function' && user) {
      setLoading(true)

      if (timer.current) {
        clearTimeout(timer.current)
      }

      const urlParameters = {
        ...additionalRequestProperties,
        page: metadata.page,
        pageSize: metadata.pageSize,
        search
      }

      timer.current = setTimeout(() => {
        getData(user, buildUrlParameters(urlParameters)).then(json => {
          if (json && json.data) {
            setData([...data, ...json.data])
          }

          if (json && json.meta) {
            setMetadata(retrieveMetadata(json.meta))
          }

          setLoading(false)
        })
      }, TIME_BEFORE_SYNC)
    }
  }, [getData, search, metadata.page, metadata.perPage, additionalRequestProperties, user])

  const handleSelect = (value, option) => {
    if (typeof onSelect === 'function') {
      onSelect(option.props.data)
    }
  }

  const handleScroll = ({ target }) => {
    if (
      target.scrollTop + target.offsetHeight >= (target.scrollHeight - 50) &&
      metadata.page < metadata.last
    ) {
      setMetadata({ ...metadata, page: metadata.page + 1 })
    }
  }

  const handleSearch = newSearch => {
    if (synchro.current) {
      clearTimeout(synchro.current)
    }

    synchro.current = setTimeout(() => {
      if (metadata.page > 1) {
        setMetadata({ ...metadata, page: 1 })
      }

      setData([])
      setSearch(newSearch)
    }, TIME_BEFORE_SYNC)
  }

  const getValue = () => {
    let currentValue = value

    if (typeof value === 'object' && value !== null) {
      currentValue = typeof dataKey === 'function' ? dataKey(value) : value[dataKey]
    }

    return currentValue
  }

  return (
    <Select
      style={style ?? { width: '300px' }}
      value={getValue()}
      showSearch={showSearch}
      onDropdownVisibleChange={open => {
        if (open && search) {
          setSearch('')
          setData([])
          setMetadata(new Metadata({ perPage: 10 }))
        }
      }}
      onSelect={handleSelect}
      onSearch={newSearch => handleSearch(newSearch)}
      filterOption={() => true}
      disabled={disabled}
      suffixIcon={loading ? (
        <div className='lazy-select-suffix-icon'>
          {t('misc.loading')}
          <FontAwesomeIcon icon={faCog} spin />
        </div>
      ) : <FontAwesomeIcon icon={faAngleDown} />}
      onPopupScroll={handleScroll}
    >
      {allOption && (
        <Option value={ALL_DATA.value} data={{ id: ALL_DATA.value }} label={t(ALL_DATA.label)}>
          <Tooltip placement={placement} title={t(ALL_DATA.title)}>
            <b>{t(ALL_DATA.label)}</b>
          </Tooltip>
        </Option>
      )}
      {emptyOption && (
        <Option value={NO_DATA.value} data={{ ID: NO_DATA.value }} label={t(NO_DATA.label)}>
          <Tooltip placement={placement} title={NO_DATA.title}>
            <b>{t(NO_DATA.label)}</b>
          </Tooltip>
        </Option>
      )}
      {isArray(data) && dataKey && data.map((o, index) => {
        const value = typeof dataKey === 'function' ? dataKey(o) : o[dataKey]
        return (
          <Option key={`${index}_${value}`} data={o} title={value}>
            {value}
          </Option>
        )
      })}
    </Select>
  )
}

export default connect(mapStateToProps)(LazySelect)
