import { fetch } from '..'
import { HttpMethods } from '../apiHelper'

export const createStudentFreeField = (user, studentFreeField) => {
  return fetch(
    '/api/free-fields',
    HttpMethods.POST,
    studentFreeField,
    user
  )
}

export const updateStudentFreeField = (user, studentFreeField) => {
  return fetch(
    `/api/free-fields/${studentFreeField.id}`,
    HttpMethods.PATCH,
    studentFreeField,
    user
  )
}

export const deleteStudentFreeFields = (user, studentFreeField) => {
  return fetch(
    `/api/studentFreeFields/${studentFreeField.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}
