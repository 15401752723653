import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const getConstants = user => {
  return requestWithPromise(
    '/global/constants',
    HttpMethods.GET,
    null,
    user
  )
}
