import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const lookup = (username) => {
  return requestWithPromise(`/auth/lookup?email=${encodeURIComponent(username)}`, HttpMethods.GET, null, null, true, true)
}

export const loginSSO = async (authorizationCode, codeVerifier) => {
  return await requestWithPromise('/auth/login-sso', HttpMethods.POST, { code: authorizationCode, codeVerifier: codeVerifier })
}
