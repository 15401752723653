import React, { useState, useEffect } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSectionAbbreviation } from '../../../../utils'
import { isFunction } from 'lodash'
import IconTooltip from '../../../antd/Icons/IconTooltip'

const NO_SCHOOL_TEXT = 'Without school'
const NO_STUDENT_TEXT = 'Without student'
const NO_SECTOR_TEXT = 'Without care unit'
const NO_INTERNSHIP_YEAR_TEXT = 'Without internship year'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const InternshipCard = ({ className, displaySector, internship, legend, t }) => {
  const [textualData, setTextualData] = useState({})

  useEffect(() => {
    if (internship && isFunction(t)) {
      setTextualData({
        school: internship.school ? internship.school.name : t(NO_SCHOOL_TEXT),
        student: internship.student ? `${internship.student.lastname} ${internship.student.firstname}` : t(NO_STUDENT_TEXT),
        sector: internship.sector ? internship.sector.name : t(NO_SECTOR_TEXT),
        internshipYear: internship.internshipYear ? internship.internshipYear.schoolYearName : t(NO_INTERNSHIP_YEAR_TEXT),
        section: getSectionAbbreviation(internship.section, t)
      })
    }
  }, [internship, t])

  return (
    <div className={className}>
      <div style={{ height: '25px', lineHeight: '25px', display: 'flex' }}>
        <span
          style={{ maxWidth: '340px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {textualData.school}
        </span>
            &nbsp; - &nbsp;
        <b> {textualData.student} </b>
      </div>
      {displaySector && (
        <div className='flex-row' style={{ height: '25px', lineHeight: '25px' }}>
          <span className='text-ellipsis'> {textualData.section} - {textualData.internshipYear} - {textualData.sector}</span>
        </div>
      )}
      {legend && (<div className='internship-card-note'> <IconTooltip title={t(legend)} /> </div>)}
    </div>
  )
}

export default connect(mapStateToProps)(InternshipCard)
