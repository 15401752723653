import { request, reverseDate } from '../../utils'
import moment from 'moment'
import { HttpMethods } from '../../utils/apiHelper'

// must be synchronized with the constants of the src/Entity/Badge backend file
export const TERMINATED = -1
export const INACTIVE = 0
export const ACTIVE = 1

export const OTHER = 0
export const LOST = 1
export const DEFECT = 2
export const STOLEN = 3
export const INSTITUTION = 4

// constant use in the reducer
const SET_BADGES = 'SET_BADGES'
const SET_BADGES_ASSIGNATIONS = 'SET_BADGES_ASSIGNATIONS'
const SET_STUDENT_BADGES = 'SET_STUDENT_BADGES'
const SET_PENDING_ASSIGNATION_DATA = 'SET_PENDING_ASSIGNATION_DATA'
const SET_DIRTY_BADGE_ASSIGNATION_ID = 'SET_DIRTY_BADGE_ASSIGNATION_ID'
const SAVE_BADGE_ASSIGNATION = 'SAVE_BADGE_ASSIGNATION'
const MODIFY_BADGE_ASSIGNATION = 'MODIFY_BADGE_ASSIGNATION'
const DELETE_BADGE_ASSIGNATION = 'DELETE_BADGE_ASSIGNATION'
const SET_BADGE_ASSIGNATION_ERROR_MESSAGE = 'SET_BADGE_ASSIGNATION_ERROR_MESSAGE'

/**
 * Get all the badges contained in the global store
 *
 * @param {Object} state data of the global store
 * @returns
 */
export const getBadgesProperties = (state) => {
  return state
}

export const getBadges = (state) => {
  return state.badges
}

export const getBadgesAssignations = (state) => {
  return state.getBadgesProperties.badgesAssignations
}

export const getPendingAssignation = (state) => {
  return state.getBadgesProperties.pendingAssignation
}

export const getDirtyBadgeAssignationId = (state) => {
  return state.dirtyBadgeAssignation
}

export const getBadgeAssignationErrorMessage = (state) => {
  return state.badgeAssignationError
}

export const addBadgeAssignation = (badgeAssignationData, institutionId, user) => {
  const formatStartDate = badgeAssignationData.startDate.format('YYYY-MM-DD')
  const formatEndDate = badgeAssignationData.endDate === null ? null : badgeAssignationData.endDate.format('YYYY-MM-DD')
  return async function addBadgeAssignationThunk (dispatch, getState) {
    const response = await request('/badges/addAssignationAndBadgeIfNotExist/' + institutionId,
      HttpMethods.POST,
      {
        ...badgeAssignationData,
        startDate: formatStartDate,
        endDate: formatEndDate
      },
      user,
      { catchError: true }
    )
    if (response.status === 'overlap') {
      dispatch(setBadgeAssignationErrorMessage(response.message))
    } else if (response.status === 'rfidDuplication') {
      document.dispatchEvent(
        new window.CustomEvent('api-message', { detail: { title: 'Wrong RFID number', message: response.message.split('"') } })
      )
    } else if (response.data) {
      const dataFormated = {
        ...response.data,
        startDate: moment(reverseDate(response.data.startDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY'),
        endDate: response.data.endDate === null
          ? response.data.endDate
          : moment(reverseDate(response.data.endDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY')
      }

      dispatch(saveBadgeAssignation(dataFormated.badgeAssignationId, dataFormated))
    }
  }
}

export const removeBadgeAssignation = (badgeAssignationId, user, updateStore = true) => {
  return async function removeBadgeAssignationThunk (dispatch, getState) {
    const response = await request('/api/BadgeAssignation/' + badgeAssignationId,
      HttpMethods.DELETE,
      null,
      user,
      { catchError: false }
    )
    if (updateStore && response.data && response.data.deleted) {
      dispatch(deleteBadgeAssignation(badgeAssignationId))
    }
  }
}

export const updateBadgeAssignation = (badgeAssignationData, institutionId, user, updateStore = true) => {
  const formatStartDate = badgeAssignationData.startDate.format('YYYY-MM-DD')
  const formatEndDate = badgeAssignationData.endDate === null ? null : badgeAssignationData.endDate.format('YYYY-MM-DD')
  return async function updateBadgeAssignationThunk (dispatch, getState) {
    const response = await request('/badges/updateAssignationAndAddBadgeIfNotExist/' +
                            institutionId +
                            '/' +
                            badgeAssignationData.badgeAssignationId,
    HttpMethods.PATCH,
    {
      ...badgeAssignationData,
      startDate: formatStartDate,
      endDate: formatEndDate
    },
    user,
    { catchError: true }
    )

    if (response.status === 'overlap') {
      dispatch(setBadgeAssignationErrorMessage(response.message))
    } else if (response.status === 'rfidDuplication') {
      document.dispatchEvent(
        new window.CustomEvent('api-message', { detail: { title: 'Wrong RFID number', message: response.message.split('"') } })
      )
    } else if (response.data) {
      if (response.data && updateStore) {
        const dataFormated = {
          ...response.data,
          startDate: moment(reverseDate(response.data.startDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY'),
          endDate: response.data.endDate === null
            ? response.data.endDate
            : moment(reverseDate(response.data.endDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY')
        }

        dispatch(modifyBadgeAssignation(badgeAssignationData.badgeAssignationId, dataFormated))
      }
    }
  }
}

/**
 * Get all badges for a particular institution in the backend database
 * Save the fetched badges in the global store
 *
 * @param {string}    institutionId the id of the current institution
 * @param {Object} user          the current user
 *
 * @returns Thunk Function
 */
export const fetchBadges = (institutionId, user) => {
  return async function fetchStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/badges/all/' + institutionId,
      HttpMethods.GET,
      null,
      user,
      { catchError: false }
    )

    dispatch(setBadges(response.data))
  }
}

export const fetchBadgesAssignationsByDate = (institutionId, date, user) => {
  return async function fetchBadgesAssignationsByDateThunk (dispatch, getState) {
    const response = await request('/badges/assignations/' + institutionId + '?date=' + date,
      HttpMethods.GET,
      null,
      user,
      { catchError: false }
    )
    const dataFormated = {}

    Object.keys(response.data).forEach(badgesAssignationId => {
      dataFormated[badgesAssignationId] = {
        ...response.data[badgesAssignationId],
        startDate: moment(reverseDate(response.data[badgesAssignationId].startDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY'),
        endDate: response.data[badgesAssignationId].endDate === null
          ? response.data[badgesAssignationId].endDate
          : moment(reverseDate(response.data[badgesAssignationId].endDate.date.split(' ')[0], '-', '/'), 'DD/MM/YYYY')
      }
    })

    dispatch(setBadgesAssignations(dataFormated))
  }
}

/**
 * Get all badges for a particular student and institution
 * Add the fetched badges to the existing badges in the global store
 *
 * @param {int}    studentId     a valid student identifier
 * @param {string} institutionId the id of the current institution
 * @param {Object} user          the current user
 *
 * @returns Thunk Function
 */
export const fetchStudentBadges = (studentId, institutionId, user) => {
  return async function fetchInstitutionFieldsTypesThunk (dispatch, getState) {
    const response = await request('/badges/' + studentId + '/' + institutionId,
      HttpMethods.GET,
      null,
      user,
      { catchError: false }
    )

    dispatch(setStudentBadges(response.data))
  }
}

export const fetchBadgeRFIDNumber = (institutionId, badgeNumber, user, badgeAssignationId = null) => {
  return async function fetchBadgeRFIDNumberThunk (dispatch, getState) {
    const response = await request('/badges/getWithNumber/' + institutionId + '/' + badgeNumber,
      HttpMethods.GET,
      null,
      user,
      { catchError: false }
    )

    if (response.data) {
      badgeAssignationId === null
        ? dispatch(setPendingAssignationData({
          ...getState().getBadgesProperties.pendingAssignation,
          badgeNumber: badgeNumber,
          rfidNumber: response.data.rfidNumber
        }))
        : dispatch(modifyBadgeAssignation(badgeAssignationId, {
          ...getState().getBadgesProperties.badgesAssignations[badgeAssignationId],
          badgeNumber: badgeNumber,
          rfidNumber: response.data.rfidNumber
        }))
    }
  }
}

export const saveBadgeAssignation = (assignationId, assignationData) => {
  return {
    type: SAVE_BADGE_ASSIGNATION,
    payload: {
      id: assignationId,
      data: assignationData
    }
  }
}

export const deleteBadgeAssignation = (assignationId) => {
  return {
    type: DELETE_BADGE_ASSIGNATION,
    payload: assignationId
  }
}

/**
 * Build a action in order to save badges in the global store
 *
 * @param {Object} badges an Object containing students badges data
 *
 * @returns redux actions
 */
export const setBadges = (badges) => {
  return {
    type: SET_BADGES,
    payload: badges
  }
}

export const setBadgesAssignations = (badges) => {
  return {
    type: SET_BADGES_ASSIGNATIONS,
    payload: badges
  }
}

/**
 * Build a action in order to save badges for a particular student in the global store
 *
 * @param {Object} badges an Object containing badges data of a student
 *
 * @returns redux actions
 */
export const setStudentBadges = (badges) => {
  return {
    type: SET_STUDENT_BADGES,
    payload: badges
  }
}

export const setPendingAssignationData = (newPendingAssignation) => {
  return {
    type: SET_PENDING_ASSIGNATION_DATA,
    payload: newPendingAssignation
  }
}

export const setDirtyBadgeAssignationId = (badgeAssignationId) => {
  return {
    type: SET_DIRTY_BADGE_ASSIGNATION_ID,
    payload: badgeAssignationId
  }
}

export const setBadgeAssignationErrorMessage = (errorMessage) => {
  return {
    type: SET_BADGE_ASSIGNATION_ERROR_MESSAGE,
    payload: errorMessage
  }
}

export const modifyBadgeAssignation = (assignationId, assignationData) => {
  return {
    type: MODIFY_BADGE_ASSIGNATION,
    payload: {
      id: assignationId,
      data: assignationData
    }
  }
}

/**
 * Reducer for the Badges
 */
export default (state = [], action) => {
  switch (action.type) {
    case SAVE_BADGE_ASSIGNATION: {
      const newBadgesAssignations = { ...state.badgesAssignations }

      newBadgesAssignations[action.payload.id] = action.payload.data

      return {
        ...state,
        badgesAssignations: newBadgesAssignations,
        pendingAssignation: undefined
      }
    }
    case DELETE_BADGE_ASSIGNATION: {
      const newBadgesAssignations = { ...state.badgesAssignations }

      delete newBadgesAssignations[action.payload]

      return {
        ...state,
        badgesAssignations: newBadgesAssignations
      }
    }
    case SET_BADGES: {
      return {
        ...state,
        badges: { ...action.payload }
      }
    }
    case SET_BADGES_ASSIGNATIONS: {
      return {
        ...state,
        badgesAssignations: { ...action.payload }
      }
    }
    case SET_STUDENT_BADGES: {
      if (action.payload.length > 0) {
        const newState = { ...state }

        newState[action.payload[0].studentId] = { ...action.payload }

        return newState
      } else {
        return state
      }
    }
    case SET_PENDING_ASSIGNATION_DATA: {
      return {
        ...state,
        pendingAssignation: action.payload
      }
    }
    case SET_DIRTY_BADGE_ASSIGNATION_ID: {
      return {
        ...state,
        dirtyBadgeAssignation: action.payload
      }
    }
    case SET_BADGE_ASSIGNATION_ERROR_MESSAGE: {
      return {
        ...state,
        badgeAssignationError: action.payload
      }
    }
    case MODIFY_BADGE_ASSIGNATION: {
      const newAssignationData = { ...state.badgesAssignations }

      newAssignationData[action.payload.id] = action.payload.data

      return {
        ...state,
        badgesAssignations: newAssignationData
      }
    }
    default:

      return state
  }
}
