import React, { useCallback, useContext, useMemo, useState } from 'react'

import useRequest from '../../../../hooks/useRequest'
import { deleteActType, fetchAllActTypes } from '../../../../utils/api/actType'
import ActTypesTable from './ActTypesTable'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { OrderBy } from '../../../../utils/constants'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import ActTypeFormModal from '../../../../HOC/Forms/ActTypeFormModal'
import { SchoolContext } from '../../../../Providers/SchoolProvider'
import ActCategoriesModal from '../../../../HOC/ActCategoriesModal'
import { TableActions } from '../../../../Components/shared/DataTable/utils/actions'
import { onSuccess } from '../../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import ActTypeSectionsDrawer from './ActTypeSectionsDrawer'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ActsTableView = ({ user, t }) => {
  const sContext = useContext(SchoolContext)

  const [actsParameters, setActsParameters] = useState(new TableParameters({ order: { by: OrderBy.NAME }, pageSize: 20 }))

  const acts = useRequest({ request: fetchAllActTypes, parameters: useMemo(() => [user, actsParameters.toQueryParametersOld()], [user, actsParameters]) })

  const removeActType = useCallback(actType => {
    deleteActType(user, actType).then(json => {
      if (json?.data) {
        acts.reload()
        onSuccess(t('act_type.delete.success'))
      }
    })
  }, [user, acts.reload])
  const handleActionTrigger = useCallback((entity, action) => {
    if (action === TableActions.DELETE) {
      removeActType(entity)
    }
  }, [removeActType])

  return (
    <ActCategoriesModal onTriggerAction={handleActionTrigger}>
      <ActTypeSectionsDrawer sections={sContext.schoolSections}>
        <ActTypeFormModal categories={sContext.actTypeCategories} onSave={() => acts.reload()}>
          <ActTypesTable
            data={acts.data}
            loading={acts.loading}
            metadata={acts.metadata}
            parameters={actsParameters}
            school={user.school}
            onParametersChange={setActsParameters}
          />
        </ActTypeFormModal>
      </ActTypeSectionsDrawer>
    </ActCategoriesModal>
  )
}

export default connect(mapStateToProps)(ActsTableView)
