import { requestMultipart, requestWithPromise } from '..'
import { HttpMethods, makeQueryString } from '../apiHelper'

export const createInternshipRequest = (user, internshipRequest) => {
  return requestWithPromise(
    '/api/individual-internship-requests',
    HttpMethods.POST,
    internshipRequest.toRequestBody(),
    user
  )
}

export const getAllInternshipRequests = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/individual-internship-requests${makeQueryString(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const deleteInternshipRequest = (user, internshipRequest) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const updateInternshipRequest = (user, internshipRequest) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}`,
    HttpMethods.PATCH,
    internshipRequest.toRequestBody(),
    user
  )
}

export const setInternshipRequestToOnHold = (user, internshipRequest) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/on-hold`,
    HttpMethods.PATCH,
    {},
    user
  )
}

export const getInternshipRequestFiles = (user, internshipRequest) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/files`,
    HttpMethods.GET,
    null,
    user
  )
}

export const deleteInternshipRequestFile = (user, internshipRequest, internshipRequestFile) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/files/${internshipRequestFile.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const addInternshipRequestFile = (user, internshipRequest, file) => {
  return requestMultipart(
    `/api/individual-internship-requests/${internshipRequest.id}/files`,
    HttpMethods.POST,
    file,
    user
  )
}

export const createInternshipRequestPeriod = (user, internshipRequest, requestPeriod) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/request-periods`,
    HttpMethods.POST,
    requestPeriod,
    user
  )
}

export const deleteInternshipRequestPeriod = (user, internshipRequest, requestPeriod) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/request-periods/${requestPeriod.id}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const deleteInternshipRequestPeriods = (user, internshipRequest, parameters = { ids: [] }) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/request-periods${makeQueryString(parameters)}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const updateInternshipRequestPeriod = (user, internshipRequest, requestPeriod) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/request-periods/${requestPeriod.id}`,
    HttpMethods.PATCH,
    requestPeriod,
    user
  )
}

export const acceptInternshipRequest = (user, internshipRequest, requestPeriods) => {
  return requestWithPromise(
    '/internship/create-from-request',
    HttpMethods.POST,
    { individualInternshipRequest: internshipRequest.id, requestPeriods: requestPeriods },
    user
  )
}

export const refuseInternshipRequest = (user, internshipRequest) => {
  return requestWithPromise(
    `/api/individual-internship-requests/${internshipRequest.id}/refuse`,
    HttpMethods.DELETE,
    null,
    user
  )
}
