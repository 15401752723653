import React, { useState, useEffect, useMemo, useContext } from 'react'

import BodyRow from '../../shared/Tables/BodyRow'
import { disableLineIf } from '../../../utils/componentFactory'
import { arraySortingByKeyAndType, recursiveColumnsTesting } from '../../../utils/sorting'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import TableHeader from '../../shared/Tables/TableHeader'
import TableFooter from '../../shared/Tables/TableFooter'
import { GlobalContext } from '../../../Providers/GlobalProvider'

import '../../../assets/interactive-table.scss'
import '../../../assets/profile-page.scss'

const ENTITY_NAME = 'Section'
const DEFAULT_NO_DATA_PLACEHOLDER = 'No data are actually available for this page.'
const DEFAULT_LOADING_MESSAGE = 'Data is currently being loaded. Please be patient.'
const DEFAULT_LINE_SHOWN = 10

const SectionsTable = (props) => {
  const { isReadOnly } = useContext(GlobalContext)

  const [data, setData] = useState([])
  const [filterKey, setFilterKey] = useState(null)
  const [revertFilter, setRevertFilter] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [lineShown, setLineShown] = useState(DEFAULT_LINE_SHOWN)
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [linesCount, setLinesCount] = useState(0)
  const [linesDensity, setLinesDensity] = useState('32px')

  const isDataAvailable = useMemo(() => props.data && props.data.length > 0, [props.data])

  useEffect(() => {
    if (props.defaultFilterKey) {
      setFilterKey(props.defaultFilterKey)
    }
  }, [props.defaultFilterKey])

  useEffect(() => {
    if (props.columns && props.columns.length > 0) {
      setFilterKey({
        key: props.columns[0].filterKey ?? props.columns[0].key,
        type: props.columns[0].dataType
      })
      if (!searchKey) {
        setSearchKey(props.columns[0].filterKey ?? props.columns[0].key)
      }
    }
  }, [props.columns])

  useEffect(() => {
    if (props.defaultSearchKey) {
      setSearchKey(props.defaultSearchKey)
    }
  }, [props.defaultSearchKey])

  useEffect(() => {
    if (props.defaultFilterKey) {
      setFilterKey(props.defaultFilterKey)
    }
  }, [props.defaultFilterKey])

  useEffect(() => {
    if (props.data) {
      const data = props.data.filter(object => {
        if (!searchValue) {
          return true
        }

        return recursiveColumnsTesting(object, searchValue, props.columns)
      })

      setData(!filterKey ? data : arraySortingByKeyAndType(data, filterKey.key, filterKey.type, revertFilter))
    }
  }, [props.data, filterKey, searchValue, searchKey, revertFilter])

  useEffect(() => {
    setMaxPageIndex(data.length % lineShown === 0
      ? Math.floor(data.length / lineShown)
      : Math.floor(data.length / lineShown) + 1
    )
    setLinesCount(data.length)
  }, [data, lineShown])

  const handleDataDelete = (deletedData, index) => {
    props.onDataDelete(deletedData, index)
  }

  const handleDataSave = (data, dataIndex) => {
    if (data.id === -1) {
      props.onDataAdd(null)
    }

    props.onDataSave(data, dataIndex)
  }

  const handleFilterKeyChange = newKey => {
    if (filterKey && newKey.key === filterKey.key) {
      setRevertFilter(!revertFilter)

      return
    }

    setFilterKey(newKey)

    if (revertFilter) {
      setRevertFilter(false)
    }
  }

  const handleSearchFieldChange = searchValue => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    setSearchValue(searchValue)
  }

  const handleSearchColumnSelect = columnFilterKey => {
    setSearchKey(columnFilterKey)
  }

  const handleLineShownSelected = lineShown => {
    setLineShown(lineShown)
  }

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const renderContainer = () => {
    if (isDataAvailable || props.newData) {
      return (
        <table>
          <thead>
            {renderHead()}
          </thead>
          <tbody>
            {renderAddedData()}
            {isDataAvailable && renderBody()}
          </tbody>
        </table>
      )
    }

    return (
      <span>
        {getPlaceHolderMessage()}
      </span>
    )
  }

  const renderHead = () => {
    return (
      <tr>
        {props.columns ? renderHeadDetails() : undefined}
        {props.additionalActions &&
          <th style={{ width: getButtonsColumnsWidth() }} />}
      </tr>
    )
  }

  const renderHeadDetails = () => {
    return props.columns.map((column, index) => {
      return (
        <th
          key={index}
          className={getClassNameByFilter(column.filterKey ?? column.key)}
          onClick={() => handleFilterKeyChange({
            key: column.filterKey ?? column.key,
            type: column.dataType
          })}
        >
          {props.t(column.name)}
        </th>
      )
    })
  }

  const getClassNameByFilter = filter => {
    let className = ''

    if (filter === filterKey?.key) {
      className = revertFilter ? 'order-down' : 'order-up'
    }

    return className
  }

  const getButtonsColumnsWidth = () => {
    const widthInPixel = (props.additionalActions.length + (props.onDataDelete ? 1 : 0)) * 33
    return widthInPixel + 'px'
  }

  const renderAddedData = () => {
    if (props.newData) {
      return (
        <BodyRow
          key='row-1'
          columns={props.columns}
          data={props.newData}
          index={-1}
          onDataSave={handleDataSave}
          validator={ENTITY_NAME}
          density={linesDensity}
        />
      )
    }

    return undefined
  }

  const renderBody = () => {
    if (props.columns) {
      const minIndex = lineShown * (pageIndex - 1)
      const maxIndex = minIndex + lineShown

      return data.slice(minIndex, maxIndex).map((row, index) => {
        return (
          <BodyRow
            key={'row' + index}
            columns={props.columns}
            data={row}
            index={index}
            additionalActions={props.additionalActions}
            onDataDelete={handleDataDelete}
            onDataSave={handleDataSave}
            disable={isReadOnly || disableLineIf(row, props.disableLineIf)}
            validator={ENTITY_NAME}
            density={linesDensity}
          />
        )
      })
    }

    return (<tr> {props.t('No columns have been found')} </tr>)
  }

  const getPlaceHolderMessage = () => {
    if (props.loading) {
      return props.loadingMessage ?? DEFAULT_LOADING_MESSAGE
    }

    return props.noDataMessage ?? DEFAULT_NO_DATA_PLACEHOLDER
  }

  return (
    <div className='interactive-table'>
      <TableHeader
        addButtonText={props.addButtonText}
        onDataAdd={props.onDataAdd}
        onDataFilter={handleSearchFieldChange}
        onLineShownSelected={handleLineShownSelected}
        onLineDensitySelected={setLinesDensity}
        searchFieldPlaceholder={props.searchFieldPlaceholder}
        onSearchColumnSelect={handleSearchColumnSelect}
      />
      {renderContainer()}
      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionsTable)
