import { requestWithPromise } from '..'
import { HttpMethods } from '../apiHelper'

export const invalidateTutorial = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/invalidate`,
    HttpMethods.POST,
    {},
    user,
    { catchError: false }
  )
}

export const getTutorialSteps = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/steps`,
    HttpMethods.GET,
    null,
    user,
    { catchError: false }
  )
}

export const validateTutorial = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/validate`,
    HttpMethods.POST,
    {},
    user,
    { catchError: false }
  )
}
