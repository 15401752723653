const INTERNSHIPS_MANAGER_ROOT = 'internships-manager-view'
const GLOBAL_FILTERS_ROOT = 'global-filters'

const LOCAL_STORAGE_KEYS = Object.freeze({
  INTERNSHIPS_MANAGER: Object.freeze({
    ROOT: INTERNSHIPS_MANAGER_ROOT,
    PRECISION: INTERNSHIPS_MANAGER_ROOT + '.precision',
    PARAMETERS: INTERNSHIPS_MANAGER_ROOT + '.parameters'
  }),
  GLOBAL_FILTERS: Object.freeze({
    ROOT: GLOBAL_FILTERS_ROOT,
    INTERNSHIP_DATE: GLOBAL_FILTERS_ROOT + '.internship-date',
    INTERNSHIP_SEARCH: GLOBAL_FILTERS_ROOT + '.search',
    INTERNSHIP_FILTERS: GLOBAL_FILTERS_ROOT + '.internship-filters'
  })
})

export const LOCAL_STORAGE_KEYS_TO_REMOVE_ON_LOGOUT = [
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.SCHOOLS,
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.INSTITUTIONS,
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.SECTIONS,
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.OWNERSHIP
]

export default LOCAL_STORAGE_KEYS
