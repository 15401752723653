/** @enum {string} */
export const EventCodeTypes = Object.freeze({
  ABSENCE: 'ABSENCE',
  OTHER: 'OTHER',
  UNJUSTIFIED: 'UNJUSTIFIED',
  INTEGRATION: 'INTEGRATION',
  SUPERVISION: 'SUPERVISION',
  OFF: 'OFF',
  SCHOOL: 'SCHOOL'
})

export const NOT_IN_INSTITUTION_TYPES = [EventCodeTypes.UNJUSTIFIED, EventCodeTypes.ABSENCE, EventCodeTypes.INTEGRATION, EventCodeTypes.SCHOOL]
