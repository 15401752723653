import { requestWithPromise } from '../../utils'
import { getUrlParametersFromObject, HttpMethods } from '../../utils/apiHelper'
import { InstitutionOptions } from '../../utils/constants'
import { SHIFT_MANAGER } from './actionsType'

const {
  SET_PAUSE_INSTITUTIONS_STATE, SET_PAUSE_QUOTAS_COUNTS, SET_PAUSE_QUOTAS_STATE,
  SET_PAUSE_USED_INSTITUTIONS, SET_SELECTED_INSTITUTION, SET_SELECTED_SECTOR,
  SET_SHIFTS_COUNTS, SET_SHIFTS_STATE, UPDATE_SHIFT_COUNTS, UPDATE_SHIFT_COUNTS_BY_PRESETS
} = SHIFT_MANAGER

const getShiftsManagerState = state => {
  return state.shiftsManager
}

const getSelectedInstitution = state => {
  return state.shiftsManager.selectedInstitution
}

const getSelectedSector = state => {
  return state.shiftsManager.selectedSector
}

const getPauseQuotasCounts = state => {
  return state.shiftsManager.pauseQuotasCounts
}

const getShiftsCounts = state => {
  return state.shiftsManager.shiftsCounts
}

const getPauseInstitutions = state => {
  return state.shiftsManager.pauseInstitutions
}

const getPauseQuotasState = state => {
  return state.shiftsManager.pauseQuotasIsLoading
}

const getShiftsState = state => {
  return state.shiftsManager.shiftsIsLoading
}

const fetchPauseQuotasCountsPerSector = (sectorId, urlParameters, user) => {
  return async function fetchPauseQuotasCountsPerSectorThunk (dispatch, getState) {
    dispatch(setPauseQuotasState(true))

    requestWithPromise(`/api/sectors/${sectorId}/pause-quotas?${getUrlParametersFromObject(urlParameters)}`, HttpMethods.GET, null, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch(setPauseQuotasCounts(jsonResponse.data))
        }
      })
  }
}

const fetchShiftsCountsPerSector = (sectorId, urlParameters, user) => {
  return async function fetchShiftsCountsPerSectorThunk (dispatch, getState) {
    dispatch(setShiftsState(true))

    requestWithPromise(`/api/sectors/${sectorId}/shifts?${getUrlParametersFromObject(urlParameters)}`, HttpMethods.GET, null, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch(setShiftsCounts(jsonResponse.data))
        }
      })
  }
}

const fetchInstitutionsWithActivatedPauseQuotaOption = (schoolId, user, useLoading = false) => {
  return async function fetchInstitutionsWithActivatedPauseQuotaOptionThunk (dispatch, getState) {
    if (useLoading) {
      dispatch(setPauseInstitutionsState(true))
    }

    requestWithPromise(`/api/schools/${schoolId}/institutions?institution-option=${InstitutionOptions.QUOTA_PER_PAUSE}`, HttpMethods.GET, null, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch(setPausesUsedInstitutions(jsonResponse.data))
        }
      })
  }
}

const setPauseQuotasCounts = counts => {
  return {
    type: SET_PAUSE_QUOTAS_COUNTS,
    payload: counts
  }
}

const setShiftsCounts = counts => {
  return {
    type: SET_SHIFTS_COUNTS,
    payload: counts
  }
}

const setSelectedInstitution = institution => {
  return {
    type: SET_SELECTED_INSTITUTION,
    payload: institution

  }
}

const setSelectedSector = sector => {
  return {
    type: SET_SELECTED_SECTOR,
    payload: sector
  }
}

const setPauseQuotasState = newState => {
  return {
    type: SET_PAUSE_QUOTAS_STATE,
    payload: newState
  }
}

const setPauseInstitutionsState = newState => {
  return {
    type: SET_PAUSE_INSTITUTIONS_STATE,
    payload: newState
  }
}

const setShiftsState = newState => {
  return {
    type: SET_SHIFTS_STATE,
    payload: newState
  }
}

const setPausesUsedInstitutions = institutions => {
  return {
    type: SET_PAUSE_USED_INSTITUTIONS,
    payload: institutions
  }
}

const updateShiftCountByIndex = (index, value) => {
  return {
    type: UPDATE_SHIFT_COUNTS,
    payload: { index, value }
  }
}

const updateShiftCountByIndexAndPresets = (index, newPreset, oldPreset = null) => {
  return {
    type: UPDATE_SHIFT_COUNTS_BY_PRESETS,
    payload: { index, newPreset, oldPreset }
  }
}

export {
  getShiftsManagerState,
  getSelectedInstitution,
  getSelectedSector,
  getShiftsCounts,
  getPauseInstitutions,
  getPauseQuotasState,
  getShiftsState,
  getPauseQuotasCounts,
  fetchPauseQuotasCountsPerSector,
  fetchShiftsCountsPerSector,
  fetchInstitutionsWithActivatedPauseQuotaOption,
  setSelectedInstitution,
  setSelectedSector,
  updateShiftCountByIndexAndPresets,
  updateShiftCountByIndex
}
