import { faBan, faCalendarAlt, faCalendarCheck, faCheck, faExclamation, faExclamationCircle, faQuestion, faTimes } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'

import { InternshipStates } from '../../../utils/constants'
import StatesPellet from './StatesPellet'

const {
  ACCEPTED, AUTO_ACCEPTED, ACCEPTED_UNMANAGED, CANCELED, CREATED,
  PENDING, PENDING_NO_SECTOR, PENDING_STUDENT_DATA_MISSING, PRE_ACCEPTED, REFUSED,
  SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED
} = InternshipStates
const SECONDARY_ICON_COLOR = '#d32f2f'
const DEFAULT_PARAMETERS = {
  color: '#FFFFFF',
  icon: faQuestion,
  secondaryIcon: null,
  secondaryIconColor: null,
  tooltipTitle: ''
}

const GenericStatesPellet = ({ context, missingConstraintsChunk, showLabel, state }) => {
  const [parameters, setParameters] = useState(DEFAULT_PARAMETERS)

  useEffect(() => {
    if (typeof state === 'number') {
      setParameters(getParametersByState(state))
    }
  }, [state])

  const getParametersByState = state => {
    const parameters = { ...DEFAULT_PARAMETERS }

    if (state === PENDING_STUDENT_DATA_MISSING) {
      parameters.color = '#ff5722'
      parameters.icon = faExclamation
      parameters.tooltipTitle = missingConstraintsChunk
    } else if (state === PENDING_NO_SECTOR || state === CREATED) {
      parameters.color = '#B7B7B7'
      parameters.icon = faQuestion
      parameters.tooltipTitle = 'Without institution/care unit, cannot be approved/refused.'
    } else if (state === PENDING) {
      parameters.color = '#03A9F4'
      parameters.icon = faQuestion
      parameters.tooltipTitle = 'Waiting for approval/refusal.'
    } else if (state === PRE_ACCEPTED) {
      parameters.color = '#ADD8E6'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Pre-accepted, waiting completion of data completion by the school.'
    } else if (state === ACCEPTED) {
      parameters.color = '#7EB73E'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Accepted, cannot be edited.'
    } else if (state === AUTO_ACCEPTED) {
      parameters.color = 'rgba(126, 183, 62, 0.5)'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Accepted automatically.'
    } else if (state === CANCELED) {
      parameters.color = '#FFC107'
      parameters.icon = faBan
      parameters.tooltipTitle = 'Canceled, cannot be edited.'
    } else if (state === REFUSED) {
      parameters.color = '#F44336'
      parameters.icon = faTimes
      parameters.tooltipTitle = 'Refused, see the internship discussion thread.'
    } else if (state === ACCEPTED_UNMANAGED) {
      parameters.color = '#7EB73E80'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Accepted automatically (the institution does not use Interneo).'
    } else if (state === SCHEDULE_ADDED) {
      parameters.color = '#7EB73E'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Accepted & modified by institution, cannot be edited'
      parameters.secondaryIcon = faCalendarAlt
    } else if (state === SCHEDULE_VALIDATED) {
      parameters.color = '#7EB73E'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Internship ended & validated'
      parameters.secondaryIcon = faCalendarCheck
    } else if (state === SCHEDULE_TO_VALIDATE) {
      parameters.color = '#7EB73E'
      parameters.icon = faCheck
      parameters.tooltipTitle = 'Internship to be validated'
      parameters.secondaryIcon = faExclamationCircle
      parameters.secondaryIconColor = SECONDARY_ICON_COLOR
    }

    return parameters
  }

  return (
    <StatesPellet
      color={parameters.color}
      icon={parameters.icon}
      tooltipTitle={parameters.tooltipTitle}
      secondaryIcon={parameters.secondaryIcon}
      secondaryIconColor={parameters.secondaryIconColor}
      showLabel={showLabel}
    />
  )
}

export default GenericStatesPellet
