import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellSlash } from '@fortawesome/pro-regular-svg-icons'
import { connect } from 'react-redux'
import TooltipIconButton from '../antd/Buttons/TooltipIconButton'
import { getTranslate } from 'react-localize-redux'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons'
import { Divider, Input } from 'antd'

import '../../assets/chat-widget.scss'

const { TextArea } = Input

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
export const MESSAGE_SENT = 'MESSAGE_SENT'
const DEFAULT_CONTAINER_STYLE = { textAlign: 'center', color: '#9E9E9E', padding: '5px 10px 15px 10px' }

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const ChatWidget = ({ hasUnreadMessages, loading, messages, readOnly, startDate, subtitle, title, onClose, onSendMessage, onUnread, onFind, t }) => {
  const [message, setMessage] = useState('')

  const messagesContainer = useRef()

  const localMessages = useMemo(() => {
    const now = moment()

    return messages.map(m => {
      const timestamp = moment(m.timestamp.date + '+00')
      let format = 'DD/MM/YYYY - HH:mm'

      if (now.format('DD') === timestamp.format('DD') && timestamp.clone().add(1, 'day').isSameOrAfter(now)) {
        format = 'HH:mm'
      } else if (timestamp.clone().add(1, 'week').isSameOrAfter(now)) {
        format = 'dddd - HH:mm'
      }

      return { ...m, timestamp: timestamp.format(format) }
    })
  }, [messages])

  const scrollToBottom = useCallback(() => {
    messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  function handleKeyUp (e) {
    if (e.key === 'Enter') {
      onSendMessage(message)
      setMessage('')
    }
  }

  return (
    <div className='chat-widget flex-column messages-displayed'>
      <div className='top-bar flex-column'>
        <div className='flex-row'>
          <div className='contact flex-fill'>{title}</div>
          <FontAwesomeIcon
            icon='times'
            className='close-button'
            onClick={onClose}
          />
        </div>
        <div className='subject'>{subtitle}</div>
        <Divider style={{ margin: '8px 0px' }} />
        <div className='action-buttons'>
          {hasUnreadMessages && (
            <TooltipIconButton
              icon={faBellSlash}
              overlayStyle={{ zIndex: 99999 }}
              title={t('chat_widget.header_button.mark_as_unread')}
              onClick={onUnread}
            />
          )}
          <TooltipIconButton
            icon={faCalendar}
            overlayStyle={{ zIndex: 99999 }}
            title={t('chat_widget.header_button.go_to_internship')}
            onClick={onFind}
          />
        </div>

      </div>
      <div
        className='messages-container flex-fill flex-column'
        ref={messagesContainer}
      >
        {localMessages.map(m => (
          <div key={m.id} className={'message ' + (m.origin === MESSAGE_RECEIVED ? 'received' : 'sent')}>
            <div className='timestamp'> {m.timestamp} </div>
            <div className='text-bubble'>
              <div className='author-username'>{m.from.username}</div>
              <div className='text'>{m.message}</div>
            </div>
          </div>
        ))}
        {localMessages.length === 0 && <div style={DEFAULT_CONTAINER_STYLE}> {t('chat_widget.container.without_message')} </div>}
        {loading && <div style={DEFAULT_CONTAINER_STYLE}> {t('misc.loading')} </div>}
      </div>
      <div className='input-container'>
        <TextArea
          disabled={readOnly}
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={t('chat_widget.placeholder.' + (readOnly ? 'disable' : 'enable'))}
          autoSize={{ minRows: 1, maxRows: 4 }}
          onKeyUp={handleKeyUp}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ChatWidget)
