import React, { useState, useEffect } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { orderByNameSectors } from '../../../../Selectors/Sectors/SectorsSelector'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import Selector from '../../Selectors/Selector'
import InternshipCard from './InternshipCard'
import { getSectionAbbreviation } from '../../../../utils'

const NO_INTERNSHIP_YEAR_TEXT = 'Without internship year'

const mapStateToProps = state => ({
  sectors: orderByNameSectors(state),
  t: getTranslate(state.locale)
})

const ActionInternshipCard = ({ displaySectorSelector, internship, sectors, tooltip, t, onUpdate }) => {
  const [textualData, setTextualData] = useState({})

  useEffect(() => {
    if (internship && typeof t === 'function') {
      setTextualData({
        internshipYear: internship.internshipYear ? internship.internshipYear.schoolYearName : t(NO_INTERNSHIP_YEAR_TEXT),
        section: getSectionAbbreviation(internship.section, t)
      })
    }
  }, [internship, t])

  const handleSectorSelected = (id, sector) => {
    callFunctionIfDefined(onUpdate, [sector])
  }

  return (
    <div className='internship-info-old'>
      <InternshipCard
        displaySector={!displaySectorSelector}
        internship={internship}
        legend={tooltip && 'internship_request_card.legend'}
      />
      {displaySectorSelector && (
        <div className='flex-row' style={{ height: '25px', lineHeight: '25px' }}>
          {textualData.section} - {textualData.internshipYear} -
          <Selector
            data={sectors}
            dataKey='name'
            onSelect={handleSectorSelected}
            showArrow={false}
            showEmpty
            showSearch
            value={!internship.sector || internship.sector.parent === null ? -1 : internship.sector.id}
          />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ActionInternshipCard)
