import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeh, faSmile, faFrown } from '@fortawesome/pro-regular-svg-icons'
import {
  mapStateToProps,
  connect
} from '../../../reducers/Dispatchers'
import '../../../assets/evaluation-questions-and-answers.scss'
import { LanguageTags } from '../../../utils/constants'

const EvaluationQuestionsWithAnswers = props => {
  const [studentEvaluationAnswers, setStudentEvaluationAnswers] = useState([])
  const [institutionEvaluationAnswers, setInstitutionEvaluationAnswers] = useState([])

  const language = useMemo(() => props.getActiveLanguage.split('_')[0], props.getActiveLanguage)

  const parseQuestionsAndAnswers = arrayToParse => {
    return arrayToParse.map(({ question, answer }) => {
      // find the question text according to the current language
      let questionItem = question.questions.find(item => item.language === language)

      if (!questionItem) {
      // default to French
        questionItem = question.questions.find(item => item.language === LanguageTags.FR)
      }

      return { id: question.id, questionText: questionItem.value, answer }
    })
  }

  useEffect(() => {
    setStudentEvaluationAnswers(parseQuestionsAndAnswers(props.studentEvaluationAnswers))
    setInstitutionEvaluationAnswers(parseQuestionsAndAnswers(props.institutionEvaluationAnswers))
  }, [])

  const renderQuestionRow = (index) => {
    return (
      <div className='question-row' key={`question-row-${index}`}>
        <div className='question-column'>
          {studentEvaluationAnswers[index] ? renderQuestionItem(studentEvaluationAnswers[index]) : <span />}
        </div>
        <div className='question-column'>
          {institutionEvaluationAnswers[index] ? renderQuestionItem(institutionEvaluationAnswers[index]) : <span />}
        </div>
      </div>
    )
  }

  const renderQuestionItem = ({ id, questionText, answer }) => {
    let icon = faFrown
    let classColor = ''
    switch (answer) {
      case 'RED_FACE':
        icon = faFrown
        classColor = 'red'
        break
      case 'ORANGE_FACE':
        icon = faMeh
        classColor = 'orange'
        break
      case 'GREEN_FACE':
        icon = faSmile
        classColor = 'green'
        break
      default:
        icon = faFrown
    }
    return (
      <div className='question-item' key={`${props.evaluationId}-${id}`}>
        <div className='question-text'>{questionText}</div>
        <div className={`question-answer ${classColor}`}>
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
    )
  }
  return (
    <div className={`evaluation-questions-and-answers-wrapper ${props.expanded ? 'expanded' : ''}`}>
      <div className='body'>

        {studentEvaluationAnswers.length >= institutionEvaluationAnswers.length
          ? studentEvaluationAnswers.map((item, index) => (renderQuestionRow(index)))
          : institutionEvaluationAnswers.map((item, index) => (renderQuestionRow(index)))}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EvaluationQuestionsWithAnswers)
