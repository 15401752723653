import React, { useContext, useMemo } from 'react'

import {
  getDateCellClassName, getDisablingState, getNewPeriod, getParametersByIndexes
} from '../utils/utils'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import { InternshipContext } from '../../../../Context/InternshipsManager/InternshipProvider'
import IconDateCell from './IconDateCell'
import QuotaDateCell from './QuotaDateCell'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import moment from 'moment'
import { InternshipStates } from '../../../../utils/constants'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SchoolDateCell = ({ disabled, startDate, endDate, index, periodIndexes, onPeriodChange, isHovered, t }) => {
  const { internship, quotas } = useContext(InternshipContext)
  const { isReadOnly } = useContext(GlobalContext)

  const className = useMemo(() => getDateCellClassName(index, periodIndexes), [index, periodIndexes])
  const parameters = useMemo(() => getParametersByIndexes(periodIndexes, index), [periodIndexes, index])
  const inPeriod = useMemo(() => index >= periodIndexes.startIndex && index <= periodIndexes.lastIndex, [index, periodIndexes])
  const localDisabled = useMemo(() => isReadOnly || getDisablingState(internship) || internship.state === InternshipStates.PRE_ACCEPTED || disabled, [internship, disabled, isReadOnly])

  const changePeriod = () => {
    const period = getNewPeriod(parameters, startDate, endDate)

    if (period === null) {
      console.error('invalid parameters for DateCell : ', parameters)
    }

    callFunctionIfDefined(onPeriodChange, [period, parameters.direction, index])
  }

  return (
    <td className={className}>
      {isHovered && parameters.icon && !localDisabled && (
        <IconDateCell
          className={className}
          placement='top'
          title={t(parameters.title)}
          icon={parameters.icon}
          onClick={changePeriod}
        />
      )}
      {inPeriod && (!parameters.icon || !isHovered || localDisabled) && (
        <QuotaDateCell
          endDate={typeof internship.endDate === 'string' ? moment(internship.endDate.split('+')[0]) : moment()}
          placement='top'
          school={internship.school}
          sector={internship.sector}
          startDate={typeof internship.startDate === 'string' ? moment(internship.startDate.split('+')[0]) : moment()}
          t={t}
          value={quotas && typeof quotas[index] === 'number' ? quotas[index] : null}
        />
      )}
    </td>
  )
}

export default connect(mapStateToProps)(SchoolDateCell)
