import { ColorsPalette } from '../../../../config/colors'
import { CellThresholds, CellColors, getTresholdByPlaces } from '../constants'

const { UNFILLED, FILLED, OVERFILLED } = CellThresholds

const computeCellRemainingPlace = (pauseQuotasCount, shiftsCount, preset, usePause = false) => {
  if (pauseQuotasCount && shiftsCount) {
    if (usePause && preset && preset.shiftPause) {
      const pausePlaces = pauseQuotasCount.details[preset.shiftPause.id] ?? 0
      const shiftPlaces = shiftsCount.details[preset.shiftPause.id] ?? 0

      return pausePlaces - shiftPlaces
    }

    return pauseQuotasCount.total - shiftsCount.total
  }

  return UNFILLED
}

const computeCellTreshold = (pauseQuotasCount, shiftsCount, preset, usePause = false) => {
  if (pauseQuotasCount && shiftsCount) {
    if (usePause && preset && preset.shiftPause) {
      const pausePlaces = pauseQuotasCount.details[preset.shiftPause.id]
      const shiftPlaces = shiftsCount.details[preset.shiftPause.id]
      const remainingPlaces = pausePlaces - shiftPlaces

      return getTresholdByPlaces(remainingPlaces)
    }

    const remainingPlaces = pauseQuotasCount.total - shiftsCount.total

    return getTresholdByPlaces(remainingPlaces)
  }

  return UNFILLED
}

const getCellStyleByTreshold = treshold => {
  if (treshold === FILLED) {
    return { color: CellColors.FILLED, fontWeight: 'bold' }
  }

  if (treshold === OVERFILLED) {
    return { color: CellColors.OVERFILLED, fontWeight: 'bold' }
  }

  return { color: ColorsPalette.FIRST }
}

export {
  computeCellRemainingPlace,
  computeCellTreshold,
  getCellStyleByTreshold
}
