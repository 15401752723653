import React, { useMemo } from 'react'

import EntitiesSelector from './EntitiesSelector'
import { getStudentFullSection } from '../../../../utils/entities/student'

const StudentsSelector = ({ disabled, multiple = 'default', style = {}, students = [], values, onChange }) => {
  const options = useMemo(() => students.map(student => ({
    label: `${student.lastname} ${student.firstname}`,
    value: student.id,
    tooltip: `${student.lastname} ${student.firstname} - ${getStudentFullSection(student)}`
  })), [students])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={options}
      multiple={multiple}
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default StudentsSelector
